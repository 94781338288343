// on the parent application, links to the renewal record

import React from "react";
import * as R from "ramda";
import { Link } from "react-router-dom";

import { getApplicationAdminURL, getApplicationURL } from "selectors/routes";

import EventLogPropTypes from "./propTypes";

const ApplicationRenewalCreated = ({
  Layout,
  event,
  timestamp,
  applicantContext,
  applicationView,
}) => {
  const requirementName = R.path(["payload", "data", "requirement_name"], event);
  const { renewal_project_id, renewal_requirement_application_id, requirement_id } = R.path(
    ["payload", "data"],
    event,
  );

  const url = applicantContext
    ? getApplicationURL({ project_id: renewal_project_id, requirement_id })
    : getApplicationAdminURL({
        id: renewal_requirement_application_id,
        project_id: renewal_project_id,
        requirement_id,
      });

  return (
    <Layout icon="arrow-alt-circle-right">
      {applicationView ? (
        <>
          Renewal <Link to={url}>application</Link> created
        </>
      ) : (
        <>
          <Link to={url}>{requirementName}</Link> renewal created
        </>
      )}
      {timestamp}
    </Layout>
  );
};
ApplicationRenewalCreated.propTypes = EventLogPropTypes;

export default ApplicationRenewalCreated;
