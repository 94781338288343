// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-projects-requirement-Process__container--Rx9gZ {\n  display: flex;\n  height: 100vh;\n  justify-content: center;\n  align-items: center;\n}\n.ui-components-projects-requirement-Process__container--Rx9gZ .ui-components-projects-requirement-Process__description--G1hUC {\n  margin-left: 1em;\n  font-weight: bold;\n  font-size: 2em;\n}", "",{"version":3,"sources":["webpack://./ui/components/projects/requirement/Process.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,iBAAA;EACA,cAAA;AACJ","sourcesContent":[".container {\n  display: flex;\n  height: 100vh;\n  justify-content: center;\n  align-items: center;\n\n  .description {\n    margin-left: 1em;\n    font-weight: bold;\n    font-size: 2em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ui-components-projects-requirement-Process__container--Rx9gZ",
	"description": "ui-components-projects-requirement-Process__description--G1hUC"
};
export default ___CSS_LOADER_EXPORT___;
