import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Icon from "components/Icon";
import { childrenPropType } from "utils/sharedPropTypes";

import styles from "./StickyNote.scss";

const Speaker = ({ name }) => (
  <>
    <span className="fa-stack">
      <Icon
        faStyle="solid"
        icon="circle"
        className={classnames(styles.avatarBackground, "fa-stack-2x")}
      />
      <Icon icon="user" className="fa-stack-1x" />
    </span>
    <span>{name}</span>
  </>
);
Speaker.propTypes = { name: PropTypes.string.isRequired };

export const StickyNoteBubble = ({ showSpeaker, speakerName, children }) => (
  <div>
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {children}
        <div className={styles.corner} />
        <div className={styles.cornerShadow} />
        {showSpeaker && <div className={styles.arrow} />}
      </div>
    </div>
    {showSpeaker && <Speaker name={speakerName} />}
  </div>
);
StickyNoteBubble.propTypes = {
  children: childrenPropType,
  speakerName: PropTypes.string.isRequired,
  showSpeaker: PropTypes.bool.isRequired,
};
