import * as R from "ramda";
import { createAction } from "redux-actions";
import { v4 as uuid } from "uuid";

const createActionWithMeta = (name, payloadCreator = R.identity, metaCreator = R.identity) =>
  createAction(
    name,
    (payloadArg, ..._other) => payloadCreator(payloadArg),
    (_payloadArg, metaArg, ..._other) => metaCreator(metaArg),
  );

const noop = () => {};
const metaCreator = (_, resolve = noop, reject = noop) => ({ resolve, reject });
const a = (name, ...args) => [name, createAction(name, ...args)];
const identity = (arg) => arg;

export const INITIALIZE_STATE = "INITIALIZE_STATE";
export const initializeState = createAction(INITIALIZE_STATE);

export const FETCH_INITIAL_STATE = "FETCH_INITIAL_STATE";
export const fetchInitialState = createAction(FETCH_INITIAL_STATE);

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const setCurrentUser = createAction(SET_CURRENT_USER);

export const UPDATE_USER = "UPDATE_USER";
export const updateUser = createAction(UPDATE_USER);

export const CREATE_PROJECT = "CREATE_PROJECT";
export const createProject = createAction(CREATE_PROJECT);

export const CREATE_DIRECT_APPLICATION_PROJECT = "CREATE_DIRECT_APPLICATION_PROJECT";
export const createDirectApplicationProject = createAction(CREATE_DIRECT_APPLICATION_PROJECT);

export const CREATE_RENEWAL = "CREATE_RENEWAL";
export const createRenewal = createAction(CREATE_RENEWAL);

export const ADD_PROJECT = "ADD_PROJECT";
export const addProject = createAction(ADD_PROJECT);

export const UPDATE_CONTEXT = "UPDATE_CONTEXT";
export const updateContext = createAction(UPDATE_CONTEXT);

export const REMOVE_INACTIVE_ANSWERS = "REMOVE_INACTIVE_ANSWERS";
export const removeInactiveAnswers = createAction(REMOVE_INACTIVE_ANSWERS);

export const UPDATE_VALIDATION_ERROR = "UPDATE_VALIDATION_ERROR";
export const updateValidationError = createAction(UPDATE_VALIDATION_ERROR);

export const CLEAR_VALIDATION_ERROR = "CLEAR_VALIDATION_ERROR";
export const clearValidationError = createAction(CLEAR_VALIDATION_ERROR);

export const DOWNLOAD_PDF = "DOWNLOAD_PDF";
export const downloadPDF = createAction(DOWNLOAD_PDF);

export const DOWNLOAD_REQUIREMENT_APPLICATION_PDF = "DOWNLOAD_REQUIREMENT_APPLICATION_PDF";
export const downloadRequirementApplicationPDF = createAction(DOWNLOAD_REQUIREMENT_APPLICATION_PDF);

export const DOWNLOAD_ISSUED_REQUIREMENT_PDF = "DOWNLOAD_ISSUED_REQUIREMENT_PDF";
export const downloadIssuedRequirementPDF = createAction(DOWNLOAD_ISSUED_REQUIREMENT_PDF);

export const SAVE_ANSWER = "SAVE_ANSWER";
export const saveAnswer = createAction(SAVE_ANSWER);

export const SAVE_ANSWERS = "SAVE_ANSWERS";

export const FETCH_PROJECT = "FETCH_PROJECT";
export const fetchProject = createActionWithMeta(FETCH_PROJECT);

export const MARK_CLEARANCES_LOADED = "MARK_CLEARANCES_LOADED";
export const markClearancesLoaded = createAction(MARK_CLEARANCES_LOADED);

export const UPDATE_TENANT = "UPDATE_TENANT";
export const updateTenant = createAction(UPDATE_TENANT);

export const SET_FEES = "SET_FEES";
export const setFees = createAction(SET_FEES);

export const SET_GUIDES = "SET_GUIDES";
export const setGuides = createAction(SET_GUIDES);

export const LOCK_TO_GUIDE = "LOCK_TO_GUIDE";
export const lockToGuide = createAction(LOCK_TO_GUIDE);

export const SET_PAGES = "SET_PAGES";
export const setPages = createAction(SET_PAGES);

export const SET_REQUIREMENTS = "SET_REQUIREMENTS";
export const setRequirements = createAction(SET_REQUIREMENTS);

export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const setDepartments = createAction(SET_DEPARTMENTS);

export const ADD_FIELDS = "ADD_FIELDS";
export const addFields = createAction(ADD_FIELDS);

export const CLEAR_ANSWER = "CLEAR_ANSWER";
export const clearAnswer = createAction(CLEAR_ANSWER);

export const SET_ANSWER = "SET_ANSWER";
export const setAnswer = createAction(SET_ANSWER);

export const SET_DEVICE_TYPE = "SET_DEVICE_TYPE";
export const setDeviceType = createAction(SET_DEVICE_TYPE);

export const SET_PROJECT_SAVE_PENDING = "SET_PROJECT_SAVE_PENDING";
export const setProjectSavePending = createAction(SET_PROJECT_SAVE_PENDING);

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const updateUserSuccess = createAction(UPDATE_USER_SUCCESS);

export const DOWNLOAD_READY = "DOWNLOAD_READY";
export const downloadReady = createAction(DOWNLOAD_READY);

export const DOWNLOAD_ERROR = "DOWNLOAD_ERROR";
export const downloadError = createAction(DOWNLOAD_ERROR);

export const DOWNLOAD_SUCCESS = "DOWNLOAD_SUCCESS";
export const downloadSuccess = createAction(DOWNLOAD_SUCCESS);

export const SET_LOCALE = "SET_LOCALE";
export const setLocale = createAction(SET_LOCALE);

export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const changeLocale = createAction(CHANGE_LOCALE);

export const NETWORK_REQUEST_START = "NETWORK_REQUEST_START";
export const networkRequestStart = createAction(NETWORK_REQUEST_START);

export const NETWORK_REQUEST_ERROR = "NETWORK_REQUEST_ERROR";
export const networkRequestError = createAction(NETWORK_REQUEST_ERROR);

export const NETWORK_REQUEST_SUCCESS = "NETWORK_REQUEST_SUCCESS";
export const networkRequestSuccess = createAction(NETWORK_REQUEST_SUCCESS);

export const NETWORK_REQUEST_CANCELLED = "NETWORK_REQUEST_CANCELLED";
export const networkRequestCancelled = createAction(NETWORK_REQUEST_CANCELLED);

export const FETCH_REQUIREMENT_APPLICATIONS = "FETCH_REQUIREMENT_APPLICATIONS";
export const fetchRequirementApplications = createAction(FETCH_REQUIREMENT_APPLICATIONS);

export const FETCH_REQUIREMENT_APPLICATION = "FETCH_REQUIREMENT_APPLICATION";
export const fetchRequirementApplication = createAction(FETCH_REQUIREMENT_APPLICATION);

export const UPDATE_REQUIREMENT_APPLICATIONS = "UPDATE_REQUIREMENT_APPLICATIONS";
export const updateRequirementApplications = createAction(UPDATE_REQUIREMENT_APPLICATIONS);

export const UPDATE_REQUIREMENT_APPLICATION = "UPDATE_REQUIREMENT_APPLICATION";
export const updateRequirementApplication = createAction(UPDATE_REQUIREMENT_APPLICATION);

export const MERGE_REQUIREMENT_APPLICATION = "MERGE_REQUIREMENT_APPLICATION";
export const mergeRequirementApplication = createAction(MERGE_REQUIREMENT_APPLICATION);

export const ADD_REQUIREMENT_APPLICATION_NOTE = "ADD_REQUIREMENT_APPLICATION_NOTE";
export const addRequirementApplicationNote = createAction(ADD_REQUIREMENT_APPLICATION_NOTE);

export const ADD_REQUIREMENT_APPLICATION = "ADD_REQUIREMENT_APPLICATION";
export const addRequirementApplication = createAction(ADD_REQUIREMENT_APPLICATION);

export const ADD_STATE_TRANSITION = "ADD_STATE_TRANSITION";
export const addStateTransition = createAction(ADD_STATE_TRANSITION);

export const ASSIGN_REQUIREMENT_APPLICATION = "ASSIGN_REQUIREMENT_APPLICATION";
export const assignRequirementApplication = createAction(ASSIGN_REQUIREMENT_APPLICATION);

export const CLAIM_REQUIREMENT_APPLICATION = "CLAIM_REQUIREMENT_APPLICATION";
export const claimRequirementApplication = createAction(CLAIM_REQUIREMENT_APPLICATION);

export const PROCESS_REQUIREMENT_APPLICATION = "PROCESS_REQUIREMENT_APPLICATION";
export const processRequirementApplication = createAction(PROCESS_REQUIREMENT_APPLICATION);

export const REVIEW_REQUIREMENT_APPLICATION = "REVIEW_REQUIREMENT_APPLICATION";
export const reviewRequirementApplication = createAction(REVIEW_REQUIREMENT_APPLICATION);

export const REVOKE_REQUIREMENT_APPLICATION = "REVOKE_REQUIREMENT_APPLICATION";
export const revokeRequirementApplication = createAction(REVOKE_REQUIREMENT_APPLICATION);

export const FETCH_TENANT_SUMMARY = "FETCH_TENANT_SUMMARY";
export const fetchTenantSummary = createAction(FETCH_TENANT_SUMMARY);

export const REFRESH_TENANT_SUMMARY = "REFRESH_TENANT_SUMMARY";
export const refreshTenantSummary = createAction(REFRESH_TENANT_SUMMARY);

export const SUBMIT_REFUND = "SUBMIT_REFUND";
export const submitRefund = createAction(SUBMIT_REFUND, identity, metaCreator);

export const FETCH_USERS = "FETCH_USERS";
export const fetchUsers = createAction(FETCH_USERS);

export const UPDATE_USERS = "UPDATE_USERS";
export const updateUsers = createAction(UPDATE_USERS);

export const FETCH_USER = "FETCH_USER";
export const fetchUser = createAction(FETCH_USER);

export const FETCH_REQUIREMENT = "FETCH_REQUIREMENT";
export const fetchRequirement = createAction(FETCH_REQUIREMENT);

export const UPDATE_REQUIREMENT = "UPDATE_REQUIREMENT";
export const updateRequirement = createAction(UPDATE_REQUIREMENT);

export const SAVE_ADMIN_USER = "SAVE_ADMIN_USER";
export const saveAdminUser = createAction(SAVE_ADMIN_USER);

export const DOWNLOAD_EXPORT = "DOWNLOAD_EXPORT";
export const downloadExport = createAction(DOWNLOAD_EXPORT);

export const DOWNLOAD_TRANSACTIONS = "DOWNLOAD_TRANSACTIONS";
export const downloadTransactions = createAction(DOWNLOAD_TRANSACTIONS);

export const FETCH_VERSIONS = "FETCH_VERSIONS";
export const fetchVersions = createAction(FETCH_VERSIONS);

export const UPDATE_VERSION = "UPDATE_VERSION";
export const updateVersion = createAction(UPDATE_VERSION);

export const SET_VERSIONS = "SET_VERSIONS";
export const setVersions = createAction(SET_VERSIONS);

export const CHANGE_VERSION = "CHANGE_VERSION";
export const changeVersion = createAction(CHANGE_VERSION);

export const SET_ADMIN_BAR_EXPANDED = "SET_ADMIN_BAR_EXPANDED";
export const setAdminBarExpanded = createAction(SET_ADMIN_BAR_EXPANDED);

export const [CLEAR_TAC_ERRORS, clearTACErrors] = a("CLEAR_TAC_ERRORS");
export const [UPDATE_PASSWORD, updatePassword] = a("UPDATE_PASSWORD");
export const [FETCH_SESSION, fetchSession] = a("FETCH_SESSION", (reason) => ({
  uuid: uuid(),
  reason,
}));
