import React from "react";
import { stringify as toParam } from "query-string";
import { useLocation } from "react-router-dom";

import { Button } from "components/Button";
import Modal from "components/LegacyModal";
import ModalHeader from "components/ModalHeader";
import Text from "containers/Text";
import { useSession } from "contexts/session";
import navigateHard from "utils/navigateHard";
import { getParam } from "utils/urls";

import styles from "./SetupAccelaCitizenAccess.scss";

const getAuthorizeURL = () => {
  const base = "/api/accela/authorize";
  const query = {
    return_to: window.location.href,
  };
  return `${base}?${toParam(query)}`;
};

// This page is loaded always as a separate Tab, both to initially kick off the process of applying, and again following the OAuth loop (after which the Rails session will have the token)
const SetupAccelaCitizenAccess = () => {
  const { accela_connected: hasProfile } = useSession();
  const location = useLocation();
  const error = getParam(location, "error");
  const onAuthorize = () => {
    navigateHard(getAuthorizeURL());
  };

  return (
    <Modal hideClose>
      <div className={styles.container}>
        <ModalHeader
          iconType="mouse-pointer"
          title={
            <Text
              t={
                hasProfile
                  ? "projects.setup_aca.banner.success"
                  : "projects.setup_aca.banner.initial"
              }
            />
          }
        />
        {hasProfile ? (
          <div className={styles.success}>
            <Text t="projects.setup_aca.success" />
          </div>
        ) : (
          <ol className={styles.steps}>
            <li>
              <p>
                <Text t="projects.setup_aca.to_connect.instruction" />
              </p>
              <div>
                <Button
                  withSpinner
                  label={<Text t="projects.setup_aca.to_connect.cta" />}
                  onClick={onAuthorize}
                />
              </div>
              {error && (
                <p className={styles.error}>
                  <Text t="projects.setup_aca.error" error={error} />
                  <br />
                  <Text t="projects.setup_aca.try_again" />
                </p>
              )}
            </li>
            <li>
              <p>
                <Text t="projects.setup_aca.close_and_return" />
              </p>
            </li>
          </ol>
        )}
      </div>
    </Modal>
  );
};
export default SetupAccelaCitizenAccess;
