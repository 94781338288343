import React, { useCallback, useEffect, useState } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";

import { TextButton } from "components/Button";
import Icon from "components/Icon";
import { useFindEntities } from "queries/entities";
import { getFeeCost, selectFeesForRequirementID } from "reducers/fees";
import { getRequirementIDs } from "reducers/projects";
import { maybeMoney } from "utils/format";
import { isBlank, isPresent } from "utils/func";

import CurrencyField from "./CurrencyField";
import styles from "./OverridesField.scss";
import UnansweredField from "./UnansweredField";

const FeeOverride = ({ record, fee, onChange, value }) => {
  const field = {
    key: fee.identifier,
  };
  const placeholder = value ? undefined : getFeeCost(record, fee) || 0;
  return (
    <div data-fee-override={fee.identifier} className={styles.feeContainer}>
      <h4>{fee.name}</h4>
      <CurrencyField field={field} onChange={onChange} value={value} placeholder={placeholder} />
    </div>
  );
};

const RequirementFeeOverrides = ({ record, requirement, updateValueFor, values }) => {
  const fees = useSelector((state) => selectFeesForRequirementID(state, requirement.id));

  return (
    <div>
      {fees.map((fee) => (
        <FeeOverride
          key={fee.id}
          fee={fee}
          onChange={updateValueFor(fee.identifier)}
          record={record}
          value={R.prop(fee.identifier, values)}
        />
      ))}
    </div>
  );
};

const OverrideIcon = ({ value }) => {
  const icon = value ? "file-check" : "file-slash";

  return <Icon className={value ? styles.enabled : styles.disabled} icon={icon} />;
};

const RequirementActiveOverride = ({ requirement, onChange, value, active }) => {
  const [buttonText, setButtonText] = useState();
  const [explanationText, setExplanationText] = useState();
  const [explanationIcon, setExplanationIcon] = useState();
  const [actionValue, setActionValue] = useState(null);

  const onAction = useCallback(() => onChange(actionValue), [onChange, actionValue]);

  useEffect(() => {
    if (value === undefined) {
      let activeText;
      let actionText;
      if (active) {
        activeText = "enabled";
        actionText = "disable";
        setActionValue(false);
      } else {
        activeText = "disabled";
        actionText = "enable";
        setActionValue(true);
      }

      setExplanationIcon();
      setButtonText(`manually ${actionText} this requirement`);
      setExplanationText(`This requirement is currently ${activeText}, per system rules.`);
    } else {
      const activeText = value ? "enabled" : "disabled";
      setExplanationText(`This requirement has been manually ${activeText}.`);

      setExplanationIcon(true);

      setButtonText("REMOVE OVERRIDE AND RETURN TO ORIGINAL STATE");
      setActionValue(null);
    }
  }, [value, active]);

  return (
    <div data-requirement-override={requirement.identifier}>
      <div>{requirement.name}</div>
      <div className={styles.explanation}>
        {explanationIcon && <OverrideIcon value={value} />}
        {explanationText}
      </div>
      <div className={styles.action} data-toggle-override={actionValue}>
        <TextButton onClick={onAction}>{buttonText}</TextButton>
      </div>
    </div>
  );
};

const RequirementOverride = ({
  requirement,
  activeRequirementIDs,
  updateValueFor,
  value,
  record,
}) => (
  <div className={styles.container}>
    <h3>Requirement</h3>
    <RequirementActiveOverride
      requirement={requirement}
      onChange={updateValueFor(requirement.identifier)}
      value={R.prop(requirement.identifier, value)}
      active={activeRequirementIDs.includes(requirement.id)}
    />
    {activeRequirementIDs.includes(requirement.id) && (
      <div className={styles.feesContainer}>
        <h3>Associated Fees</h3>
        <div className={styles.explanation}>Set the fee amount to $0 to remove a fee.</div>
        <RequirementFeeOverrides
          record={record}
          requirement={requirement}
          values={value}
          updateValueFor={updateValueFor}
        />
      </div>
    )}
  </div>
);

const ExistingOverrides = ({ value, updateValueFor, record, activeRequirementIDs }) => {
  const overridenIdents = R.keys(value);
  const { data: entities = [] } = useFindEntities(overridenIdents);

  const initialRequirements = entities.filter((e) => e.type === "Requirement");

  const strayFees = entities.filter(
    (e) => e.type === "Fee" && !overridenIdents.includes(e.requirement_identifier),
  );

  const { data: feeRequirements = [] } = useFindEntities(
    R.map(R.prop("requirement_identifier"), strayFees),
  );

  const requirements = [...initialRequirements, ...feeRequirements];

  return (
    <>
      {requirements.map((requirement) => (
        <RequirementOverride
          key={requirement.id}
          requirement={requirement}
          activeRequirementIDs={activeRequirementIDs}
          record={record}
          value={value}
          updateValueFor={updateValueFor}
          onlyExisting
        />
      ))}
    </>
  );
};

const OverrideValue = ({ value }) => {
  if (R.isNil(value)) {
    return "override removed";
  }
  if (typeof value === "boolean") {
    return value ? "Enabled" : "Disabled";
  }
  return maybeMoney(value);
};

const OverridesField = ({ answerContext: { record }, scope, value, onChange, onSave }) => {
  const activeRequirementIDs = getRequirementIDs(record);

  const updateValueFor = R.curry((identifier, overrideValue) => {
    if (isPresent(overrideValue)) {
      onChange(R.assoc(identifier, overrideValue, value));
      onSave();
    } else {
      onChange(R.dissoc(identifier, value));
      onSave();
    }
  });

  if (scope) {
    return (
      <RequirementOverride
        requirement={scope}
        activeRequirementIDs={activeRequirementIDs}
        record={record}
        value={value}
        updateValueFor={updateValueFor}
      />
    );
  }

  if (isBlank(value)) return null;

  return (
    <ExistingOverrides
      value={value}
      record={record}
      updateValueFor={updateValueFor}
      activeRequirementIDs={activeRequirementIDs}
    />
  );
};
export default OverridesField;

export const DisabledOverridesField = ({ value, filter = R.T }) => {
  if (isBlank(value)) return <UnansweredField />;
  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const { data: entities = [] } = useFindEntities(R.keys(value));

  return (
    <div>
      {entities.filter(filter).map((entity) => (
        <div key={entity.identifier}>
          {entity.type} - {entity.name}: <OverrideValue value={value[entity.identifier]} />
        </div>
      ))}
    </div>
  );
};
export const disabled = DisabledOverridesField;
