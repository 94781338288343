/* global google */

import React, { useCallback } from "react";
import * as R from "ramda";
import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  Polyline,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";

import withProps from "components/utilities/withProps";
import { useColors } from "contexts/colors";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { withGoogleMapsUrl } from "services/withGoogleMapsUrl";
import * as Address from "utils/address";
import { mapIndexed } from "utils/func";
import { isComplete } from "utils/location";
import { addressToPosition, locationToPosition } from "utils/map";
import * as Route from "utils/route";

import styles from "./Map.scss";

const EventMap = R.compose(
  withProps({
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    defaultOptions: {
      disableDefaultUI: true,
      gestureHandling: "greedy",
      streetViewControl: true,
      zoomControl: true,
      scrollwheel: true,
    },
  }),
  withGoogleMapsUrl({ libraries: "geometry,drawing,places" }),
  withGoogleMap,
)((props) => (
  <GoogleMap ref={props.setRef} {...props}>
    {props.children}
  </GoogleMap>
));

const NON_ACTIVE_ROUTE_MARKER = {
  path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
  fillColor: "#FFF",
  fillOpacity: 1,
  strokeWeight: 1,
  strokeColor: "#000",
};

const NON_ACTIVE_LOCATION_MARKER = {
  path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
  fillColor: "#FFF",
  fillOpacity: 1,
  strokeWeight: 1,
  strokeColor: "#000",
  scale: 1.3,
};

const mapIndexedWithoutLast = (f) => R.compose(mapIndexed(f), R.dropLast(1));

const Map = ({
  locations,
  routes,
  hoveredLocationIndex,
  hoveredRouteIndex,
  currentRoute,
  currentLocation,
  mapMode,
  outsideCityBoundaryLocation,
  mapState,
  onMapClick,
  onRouteChange,
  disableInteraction,
}) => {
  const { bounds, municipality_type } = useCurrentTenantInfo();

  const { center, zoom } = mapState;
  const routeMarker = R.merge({ anchor: new google.maps.Point(12, 24) }, NON_ACTIVE_ROUTE_MARKER);
  const locationScale = NON_ACTIVE_LOCATION_MARKER.scale;
  const locationMarker = R.merge(
    {
      anchor: new google.maps.Point(9 * locationScale, 17 * locationScale),
    },
    NON_ACTIVE_LOCATION_MARKER,
  );
  const { primary: primaryColor } = useColors();
  const setFill = R.assoc("fillColor", primaryColor);
  const selectedLocationMarker = setFill(locationMarker);
  const selectedRouteMarker = setFill(routeMarker);

  const setInitialZoom = useCallback((node) => {
    if (!node) return;

    node.fitBounds(
      new google.maps.LatLngBounds(
        new google.maps.LatLng(bounds.min_lat, bounds.min_lon),
        new google.maps.LatLng(bounds.max_lat, bounds.max_lon),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routeMode = mapMode === "route";
  const routeIncomplete = routeMode && !Route.isComplete(currentRoute);
  return (
    <div className={styles.container} data-map={!!center}>
      <EventMap
        setRef={setInitialZoom}
        {...(center ? { center } : {})}
        zoom={zoom}
        onClick={onMapClick}
        clickableIcons={false}
        interactive={!disableInteraction}
      >
        {isComplete(currentLocation) && (
          <Marker position={locationToPosition(currentLocation)} icon={locationMarker} />
        )}

        {outsideCityBoundaryLocation && (
          <MarkerWithLabel
            position={addressToPosition(outsideCityBoundaryLocation)}
            labelAnchor={new google.maps.Point(200, 85)}
            labelStyle={{
              backgroundColor: "white",
              fontSize: "16px",
              padding: "16px",
            }}
            icon={locationMarker}
          >
            <span>Please select an address that is inside the {municipality_type} limits.</span>
          </MarkerWithLabel>
        )}
        {routeIncomplete && Route.getStartAddress(currentRoute) && (
          <Marker position={addressToPosition(Route.getStartAddress(currentRoute))} />
        )}
        {routeIncomplete && Route.getEndAddress(currentRoute) && (
          <Marker position={addressToPosition(Route.getEndAddress(currentRoute))} />
        )}

        {routeMode && Route.isComplete(currentRoute) && currentRoute.directions && (
          <DirectionsRenderer
            directions={R.prop("directions", currentRoute)}
            onDirectionsChanged={onRouteChange}
            options={{ draggable: true }}
          />
        )}

        {mapIndexedWithoutLast(
          (loc, i) => (
            <Marker
              key={`location-${Address.getFullAddress(loc.address)}`}
              position={locationToPosition(loc)}
              icon={hoveredLocationIndex === i ? selectedLocationMarker : locationMarker}
            />
          ),
          locations,
        )}

        {mapIndexedWithoutLast((route, i) => {
          const path = window.google.maps.geometry.encoding.decodePath(route.overview_polyline);
          return (
            <div key={`route-${i}`}>
              <Polyline
                path={path}
                options={{
                  strokeColor: "#4a80f5",
                  strokeWeight: 6,
                  strokeOpacity: 0.6,
                }}
              />
              <Marker
                position={addressToPosition(Route.getStartAddress(route))}
                icon={hoveredRouteIndex === i ? selectedRouteMarker : routeMarker}
              />
              <Marker
                position={addressToPosition(route.end_address)}
                icon={hoveredRouteIndex === i ? selectedRouteMarker : routeMarker}
              />
            </div>
          );
        }, routes)}
      </EventMap>
    </div>
  );
};

export default R.compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMapsUrl({ libraries: "geometry,drawing,places" }),
  withScriptjs,
)(Map);
