import React from "react";
import PropTypes from "prop-types";

import Icons from "images/icons.sprite.svg";

const SVG = ({ className, iconKey }) => (
  <svg className={className}>
    <use xlinkHref={`#${Icons.id}_${iconKey}`} />
  </svg>
);
SVG.propTypes = {
  iconKey: PropTypes.string.isRequired,
};

export default SVG;
