import * as R from "ramda";

import { markClearancesLoaded } from "actions";
import { getAnswersForFields } from "reducers/answerContexts";
import {
  selectActiveUseCodeConditionFields,
  selectCurrentProject,
  selectUseCode,
} from "reducers/projects";
import { isPresent } from "utils/func";
import debug from "utils/safeDebug";
import { getSlug } from "utils/useCode";

import { featuresOfTypeForPoint, fetchTileAt } from "./MVT";
import zoningApi from "./zoningApi";

const UNUSED_ZONE_ATTRIBUTES = [
  "conditional_clearances_count",
  "keywords",
  "name",
  "overlay",
  "permitted_clearances_count",
  "prohibited_clearances_count",
  "searchable_text",
  "tenant_id",
  "tenant_slug",
  "total_clearances_count",
  "zone_group_id",
];
const zonesToAnswer = R.map((zone) => {
  zone.display_name = zone.name;
  zone.is_overlay = zone.overlay;
  return R.omit(UNUSED_ZONE_ATTRIBUTES, zone);
});

const reshapeParcels = R.pipe(
  R.filter(R.prop("parcel_number")),
  R.map((p) => ({ key: "Parcel Number", value: p.parcel_number })),
);

class ZoningCache {
  constructor() {
    this.clearancesCache = {};
    this.radiusRulesCache = {};
  }

  configure(store) {
    const { tenant } = store.getState();
    this.version = tenant.version_number;
    this.tileVersion = tenant.tile_version;
    this.store = store;
    store.subscribe(() => this.loadClearances(store.getState()));
  }

  configureVersion(version, tileVersion) {
    this.version = version;
    this.tileVersion = tileVersion;
  }

  clearancesPerZone(useCodeSlug) {
    return this.clearancesCache[this.cacheKey(useCodeSlug)];
  }

  cacheKey(useCodeSlug) {
    return `${this.version}:${useCodeSlug}`;
  }

  setCacheFor(useCodeSlug, clearances, radiusRules) {
    this.clearancesCache[this.cacheKey(useCodeSlug)] = clearances;
    this.radiusRulesCache[this.cacheKey(useCodeSlug)] = radiusRules;
    debug("ZoningCache.setCacheFor", this.version, useCodeSlug, clearances, radiusRules);
  }

  getPermissionsForZones(useCodeSlug, zones, ruleIDs, radiusRuleIDs) {
    const applicableRuleIDs = R.concat(ruleIDs, radiusRuleIDs);

    return R.pipe(
      R.map((zone) =>
        R.mergeRight(
          zone,
          this.activePermissionAndRuleForZoneID(useCodeSlug, zone.id, applicableRuleIDs),
        ),
      ),
      R.sortWith([
        R.descend(R.has("permission")),
        R.descend(R.prop("is_overlay")),
        R.descend(R.prop("overlay")),
        R.descend(R.prop("rule")),
        R.descend(R.path(["permission", "priority"])),
      ]),
    )(zones);
  }

  getZonesPerPermission(state, record) {
    const useCode = selectUseCode(state, record);
    const fields = selectActiveUseCodeConditionFields(state, record);
    const answers = getAnswersForFields(record, fields);

    const applicableRuleIDs = this.getApplicableYesNoRuleIDs(getSlug(useCode), answers);
    const clearances = this.clearancesPerZone(getSlug(useCode));
    const zoneIDs = R.pipe(R.keys, R.map(Number))(clearances);

    return R.reduce(
      (p, zoneID) => {
        const { permission } = this.activePermissionAndRuleForZoneID(
          getSlug(useCode),
          zoneID,
          applicableRuleIDs,
        );
        if (!permission) return p; // this happens for unspecified
        const { category } = permission;
        return R.over(R.lensProp(category), R.append(zoneID), p);
      },
      {},
      zoneIDs,
    );
  }

  activePermissionAndRuleForZoneID(useCodeSlug, zoneID, applicableRuleIDs) {
    const clearances = this.clearancesPerZone(useCodeSlug)[zoneID];
    if (!clearances) return {}; // overlays might not have clearance
    const clearance = R.find(
      (c) => R.contains(R.path(["rule", "id"], c), applicableRuleIDs) || !c.rule,
      clearances,
    );
    if (!clearance) return {}; // overlays might not have applicable clearance

    return R.over(
      R.lensPath(["permission", "category"]),
      (s) => s.toLowerCase().replace("permission::", ""),
      clearance,
    );
  }

  getApplicableYesNoRuleIDs(useCodeSlug, answers) {
    const rules = this._allRules(useCodeSlug);

    return R.pipe(
      R.filter((r) => answers[r.slug.replace(/-/g, "_")] === r.value),
      R.map(R.prop("id")),
    )(rules);
  }

  async isInBoundary(tenantID, lat, lon) {
    const { tile, point, x, y, z } = await fetchTileAt(
      "tenants",
      tenantID,
      this.tileVersion,
      lat,
      lon,
    );
    const tiles = featuresOfTypeForPoint("tenants", tile, null, point, x, y, z);
    return !R.isEmpty(tiles);
  }

  async _fetchPoint(lat, lon, tenantID) {
    return R.compose(
      R.mapObjIndexed((t, type) =>
        featuresOfTypeForPoint(type, t.tile, tenantID, t.point, t.x, t.y, t.z),
      ),
      R.zipObj(["zones", "parcels", "rules"]),
    )(
      await Promise.all([
        fetchTileAt("zones", tenantID, this.tileVersion, lat, lon),
        fetchTileAt("parcels", tenantID, this.tileVersion, lat, lon),
        fetchTileAt("rules", tenantID, this.tileVersion, lat, lon),
      ]),
    );
  }

  // refactor with/without use code logic when we refactor clearance loading away from json-api
  async forPointWithoutClearance(tenantID, lat, lon) {
    const { zones, parcels } = await this._fetchPoint(lat, lon, tenantID);

    this._debugToConsole(zones);

    return {
      zones: zonesToAnswer(zones),
      parcels: reshapeParcels(parcels),
    };
  }

  async forPoint(useCodeSlug, tenantID, answers, lat, lon) {
    const { zones, parcels, rules } = await this._fetchPoint(lat, lon, tenantID);

    const radiusRuleIDs = R.map(R.prop("id"))(rules);
    const ruleIDs = this.getApplicableYesNoRuleIDs(useCodeSlug, answers);

    const zonesWithPermission = this.getPermissionsForZones(
      useCodeSlug,
      zones,
      ruleIDs,
      radiusRuleIDs,
    );

    this._debugToConsole(zonesWithPermission);

    const zonesAnswer = R.pipe(
      zonesToAnswer,
      R.map(
        R.over(
          R.lensProp("rule"),
          R.when(isPresent, R.omit(["prompt", "clearance_description", "evaluator", "value"])),
        ),
      ),
    )(zonesWithPermission);

    return {
      zones: zonesAnswer,
      parcels: reshapeParcels(parcels),
    };
  }

  async parcelIDsForPoint(tenantID, lat, lon) {
    const { tile, point, x, y, z } = await fetchTileAt(
      "parcels",
      tenantID,
      this.tileVersion,
      lat,
      lon,
    );
    const parcels = featuresOfTypeForPoint("parcels", tile, tenantID, point, x, y, z);
    return R.pipe(
      R.filter(R.prop("parcel_number")),
      R.map((p) => ({
        key: "Parcel Number",
        value: p.parcel_number,
      })),
    )(parcels);
  }

  getPermissionsForRadiusRules(state, project) {
    const useCode = selectUseCode(state, project);
    return this.radiusRulesCache[this.cacheKey(useCode?.slug)] || {};
  }

  async loadClearances(state) {
    const project = selectCurrentProject(state);
    if (!project || Object.keys(project).length === 0) return;

    const { use = null } = selectUseCode(state, project) || {};
    if (!use || !use.slug) return;
    const { slug } = use;

    if (this.clearancesPerZone(slug)) return;

    await this.fetchClearances(slug);
  }

  async fetchClearances(useCodeSlug) {
    this.setCacheFor(useCodeSlug, {}, {}); // populate w/placeholder to avoid duplicate requests
    const { clearances, radiusRules } = await zoningApi.getClearances(useCodeSlug);
    this.setCacheFor(useCodeSlug, clearances, radiusRules);
    if (this.store) this.store.dispatch(markClearancesLoaded(useCodeSlug));
  }

  _allRules(useCodeSlug) {
    return R.pipe(
      R.values,
      R.flatten,
      R.map(R.prop("rule")),
      R.filter(
        R.allPass([Boolean, R.propEq("rule_type", "Yes/No"), R.propEq("evaluator", "equals")]),
      ),
      R.uniqBy(R.prop("id")),
    )(this.clearancesPerZone(useCodeSlug));
  }

  _debugToConsole(zonesWithPermission) {
    if (typeof window === "undefined" || !window.debug) return;

    debug("\nZoning data for point\n=====================\n");
    R.forEach((z) => {
      debug(`  ⇢  ${z.admin_name} - ${R.pathOr("NONE", ["permission", "category"], z)}\n`, z);
    }, zonesWithPermission);
    debug("\n");
  }
}

const zoningCache = new ZoningCache();
export default zoningCache;
