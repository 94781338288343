import React from "react";
import PropTypes from "prop-types";

import SkeuomorphicRequirement from "components/projects/SkeuomorphicRequirement";
import StartButton from "components/projects/handoff/StartButton";
import Text from "containers/Text";
import { isNoLongerRequired, isPayable, wasSubmitted } from "reducers/requirementApplications";
import { getName } from "reducers/requirements";
import {
  departmentPropType,
  requirementApplicationPropType,
  requirementPropType,
} from "utils/sharedPropTypes";

import styles from "./RequirementHandoffCard.scss";

const RequirementHandoffCard = ({ requirementApplication, requirement, department, href }) => (
  <SkeuomorphicRequirement
    key={getName(requirement)}
    requirement={requirement}
    requirementApplication={requirementApplication}
    pageFoldBgColor="#fff"
    href={href}
  >
    {isNoLongerRequired(requirementApplication) && (
      <div className={styles.noLongerRequired}>
        <Text t="projects.handoff.no_longer_required" />
      </div>
    )}

    {(!wasSubmitted(requirementApplication) || isPayable(requirementApplication)) &&
      !isNoLongerRequired(requirementApplication) && (
        <StartButton
          requirement={requirement}
          requirementApplication={requirementApplication}
          department={department}
        />
      )}
  </SkeuomorphicRequirement>
);
RequirementHandoffCard.propTypes = {
  requirement: requirementPropType,
  requirementApplication: requirementApplicationPropType,
  href: PropTypes.string.isRequired,
  department: departmentPropType,
};

export default RequirementHandoffCard;
