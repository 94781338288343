import React from "react";
import PropTypes from "prop-types";

import { logEntryPropType } from "utils/sharedPropTypes";
import { formatTime } from "utils/time";

import styles from "./ApplicationLogEntry.scss";
import Layout from "./Layout";
import lookup from "./lookup";

const ApplicationLayout = (props) => <Layout styles={styles} {...props} />;
const Timestamp = ({ event }) => (
  <span className={styles.timestamp}>{formatTime(event.timestamp)}</span>
);

const ApplicationLogEntry = ({ event, context = null, applicantContext = false }) => {
  const LogEntry = lookup(event);
  const timestamp = <Timestamp event={event} />;

  return (
    <LogEntry
      Layout={ApplicationLayout}
      timestamp={timestamp}
      event={event}
      context={context}
      applicantContext={applicantContext}
      applicationView
    />
  );
};

ApplicationLogEntry.propTypes = {
  event: logEntryPropType.isRequired,
  context: PropTypes.string,
};

export default ApplicationLogEntry;
