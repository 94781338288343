import React from "react";
import * as R from "ramda";

import Text from "containers/Text";
import { DownloadLink } from "containers/projects/applications/Download";

import applicationStyles from "./ApplicationLogEntry.scss";
import MaybeApplicationLink from "./MaybeApplicationLink";
import EventLogPropTypes from "./propTypes";

const ApplicationContent = ({ event, by, context, timestamp }) => {
  const applicationID = R.prop("requirement_application_id", event);
  const projectID = R.prop("projectID", event);

  return (
    <>
      Submitted {by && ` by ${by}`}
      {timestamp}
      {context === "handoff" && (
        <span className={applicationStyles.viewApplication}>
          <DownloadLink projectID={projectID} requirementApplicationID={applicationID}>
            <Text t="projects.application.view_application" />
          </DownloadLink>
        </span>
      )}
    </>
  );
};
const ApplicationSubmitted = ({
  Layout,
  event,
  applicantContext,
  applicationView,
  context,
  timestamp,
}) => {
  const by = R.path(["payload", "data", "by"], event);

  return (
    <Layout icon="arrow-alt-circle-right">
      {applicantContext || applicationView ? (
        <ApplicationContent context={context} event={event} by={by} timestamp={timestamp} />
      ) : (
        <>
          <MaybeApplicationLink event={event} /> application submitted {by && ` by ${by}`}
          {timestamp}
        </>
      )}
    </Layout>
  );
};
ApplicationSubmitted.propTypes = EventLogPropTypes;

export default ApplicationSubmitted;
