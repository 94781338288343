import React, { useCallback, useEffect, useRef, useState } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";

import Header from "components/Header";
import Fields from "components/guides/Fields";
import Peekbar from "components/guides/Peekbar";
import { ConnectedFooter } from "components/projects/Footer";
import SummaryTable from "components/projects/SummaryTable";
import ZoningBar from "components/zoning/ZoningBar";
import HelpBar from "containers/guides/HelpBar";
import { useAnswerContext } from "containers/withAnswerContext";
import { useZoningData } from "hooks/useZoningField";
import { selectFieldsIndexedByID } from "reducers/fields";
import { selectGuideByID } from "reducers/guides";
import { getUnansweredCountForFields, selectActiveUseCodeConditionFields } from "reducers/projects";
import routes from "utils/routes";

import Step from "../requirement/Step";
import * as Layout from "./Layout";

export default function ProjectForm() {
  const tableProps = {
    sticky: true,
  };

  const answerContext = useAnswerContext();
  const { record: project } = answerContext;
  const useCodeConditionFieldIDs = useSelector((state) =>
    selectActiveUseCodeConditionFields(state, project).map((f) => f.id),
  );

  // TODO: this will all be a hook
  // Track current user position and when fields get inserted above that point
  // move them to the bottom of the page for the life of the form
  const lastTouchedFieldIDRef = useRef();
  const trackLastInput = useCallback(
    (e) => {
      const { fieldId: fieldID } = e.target.closest("[data-field-key]").dataset;
      lastTouchedFieldIDRef.current = fieldID;
    },
    [lastTouchedFieldIDRef],
  );

  const [bottomedFieldIDs, setBottomedFieldIDs] = useState([]);
  const previousFieldIDsRef = useRef(project.scoping_field_ids);
  useEffect(() => {
    // only reorder fields if the user has touched a field
    if (lastTouchedFieldIDRef.current == null) return;

    const lastTouchedFieldID = Number(lastTouchedFieldIDRef.current);
    const previousFieldIDs = previousFieldIDsRef.current;
    const prevIndex = previousFieldIDs.indexOf(lastTouchedFieldID);
    const prevAbove = previousFieldIDs.slice(0, prevIndex);

    const curFieldIDs = project.scoping_field_ids;
    const curIndex = curFieldIDs.indexOf(lastTouchedFieldID);
    const curAbove = curFieldIDs.slice(0, curIndex);

    setBottomedFieldIDs((existingFieldIDs) => [
      ...new Set(existingFieldIDs.concat(R.without(prevAbove, curAbove))),
    ]);
    previousFieldIDsRef.current = project.scoping_field_ids;
  }, [lastTouchedFieldIDRef, project.scoping_field_ids]);
  // END TODO

  const orderedFieldIDs = R.pipe(
    R.without(bottomedFieldIDs),
    R.concat(R.__, bottomedFieldIDs),
    R.without(useCodeConditionFieldIDs),
  )(project.scoping_field_ids);

  const allFields = useSelector(selectFieldsIndexedByID);
  const fields = orderedFieldIDs.map((id) => allFields[id]);

  const unansweredFeeFieldsCount = getUnansweredCountForFields(project, fields);

  if (unansweredFeeFieldsCount != null)
    tableProps.unansweredFeeFieldsCount = unansweredFeeFieldsCount;

  const { category, isZoningEnabled } = useZoningData(answerContext);
  const disableNext = !(category !== "prohibited" || !isZoningEnabled);

  const entryPoint = useSelector((state) => selectGuideByID(state, project.guide_id));
  return (
    <Layout.Page name="project-form">
      <Header hideTitle />
      <Layout.Main>
        <Step header={entryPoint.header_title} instructions={entryPoint.message} />
        <Layout.ContentWithSidebar>
          <Layout.Content>
            <div onBlur={trackLastInput} onFocus={trackLastInput}>
              <Fields fields={fields} behaviorType="inline" />
            </div>
          </Layout.Content>
          <Layout.Sidebar>
            {project.requirement_ids.length > 0 && <SummaryTable {...tableProps} />}
          </Layout.Sidebar>
        </Layout.ContentWithSidebar>
        <HelpBar />
      </Layout.Main>
      <ZoningBar />
      <Peekbar showFees />
      <ConnectedFooter
        fieldIDs={orderedFieldIDs}
        disableNext={disableNext}
        nextPageType="PATH"
        nextPage={routes.projectHandoff(project.id)}
      />
    </Layout.Page>
  );
}
