import React from "react";
import { Link } from "react-router-dom";

import styles from "./ProjectLogEntry.scss";
import EventLogPropTypes from "./propTypes";

const InquirySent = ({ Layout, event }) => (
  <Layout icon="envelope">
    <Link to={`/admin/projects/${event.project_id}/inquiries/${event.context_id}`}>Inquiry</Link>{" "}
    received
    <span className={styles.timestamp}>{event.timestamp.format("h:mm a")}</span>
  </Layout>
);

InquirySent.propTypes = EventLogPropTypes;

export default InquirySent;
