import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { useText } from "containers/Text";
import useMapboxStaticImageUrl from "hooks/useMapboxStaticImageUrl";
import { addressPropType } from "utils/sharedPropTypes";

import styles from "./Map.scss";

const Map = ({ address, permission, width, height, zoom, showPermission }) => {
  const classes = classnames(styles.popupContainer, permission && styles[permission.category]);
  const altText = useText("guides.summary.map");
  const imgSrc = useMapboxStaticImageUrl({
    username: "mapbox",
    style_id: "satellite-v9",
    overlay: showPermission ? null : `pin-l-circle+fff(${address.longitude},${address.latitude})`,
    lat: address.latitude,
    lon: address.longitude,
    zoom,
    width,
    height,
  });

  return (
    <div className={styles.mapContainer}>
      <img data-map-image alt={altText} className={styles.map} src={imgSrc} />

      {showPermission ? (
        <div className={classes}>
          <div className={styles.popup}>{permission.display_name}</div>
        </div>
      ) : null}
    </div>
  );
};

Map.defaultProps = {
  width: 640,
  height: 300,
  zoom: 16,
};

Map.propTypes = {
  permission: PropTypes.object,
  address: addressPropType,
  width: PropTypes.number,
  height: PropTypes.number,
  zoom: PropTypes.number,
  showPermission: PropTypes.bool,
};

export default Map;
