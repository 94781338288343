import { useIsPermittedTo, useSession } from "contexts/session";

export const SuperuserFeature = ({ children }) => {
  const { isSuperuser } = useSession();
  if (!isSuperuser) return null;
  return children;
};

export const SuperAdminFeature = ({ children }) => {
  const { isSuper } = useSession();
  if (!isSuper) return null;
  return children;
};

export function AdminFeature({ children }) {
  const { canAdmin } = useSession();
  if (canAdmin) return children;
  return null;
}

export function ManagerFeature({ children }) {
  const { isManager, isSuper } = useSession();
  if (isManager || isSuper) return children;
  return null;
}

export const ConfigUserFeature = ({ children }) => {
  const canConfig = useIsPermittedTo("config");
  if (!canConfig) return null;
  return children;
};

export default SuperuserFeature;
