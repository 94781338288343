import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { SmallButton } from "components/Button";
import ExternalLink from "components/ExternalLink";
import Icon from "components/Icon";
import { MaybeTable } from "components/admin/utilities/Table";
import Table, { columnHelper, dateCellRenderer } from "components/utilities/DataTable";
import Text from "containers/Text";
import { useIsInAdminSection } from "contexts/applicationSection";
import { useListConfigContext, withFilteredList } from "contexts/listConfig";
import routes from "utils/routes";

import styles from "./UploadRequirementsTable.scss";

const CTACell = ({ cta }) => {
  const isAdmin = useIsInAdminSection();
  const { label, pID: projectID, raID: requirementApplicationID, rID: requirementID } = cta;

  const navigate = useNavigate();

  const onClick = (e) => {
    e.preventDefault();

    const route = isAdmin
      ? routes.admin.requirementApplication(projectID, requirementApplicationID)
      : routes.application(projectID, requirementID);

    navigate(route);
  };

  return <SmallButton onClick={onClick}>{isAdmin ? "View" : label}</SmallButton>;
};

const DocumentCell = ({ requirement }) => {
  const { name, url } = requirement;
  const isAdmin = useIsInAdminSection();

  return isAdmin ? name : <ExternalLink href={url || ""}>{name}</ExternalLink>;
};

const StatusCell = ({ status }) => {
  const isAdmin = useIsInAdminSection();

  const STATUS_ICONS = {
    approved: "file-check",
    in_progress: "retweet",
    no_longer_required: "",
    processed: "check-square",
    rejected: "times-octagon",
    revoked: "exclamation-triangle",
    submitted: "arrow-circle-right",
    submitted_external: "file-import",
    under_review: "ballot-check",
  };

  const STATUS_ICON_STYLES = {
    approved: "regular",
    in_progress: "sharp solid",
    no_longer_required: "regular",
    processed: "regular",
    rejected: "regular",
    revoked: "regular",
    submitted: "regular",
    submitted_external: "regular",
    under_review: "regular",
  };

  switch (status) {
    case null:
      return (
        <div {...(!isAdmin && { className: styles.bold })}>
          <Icon icon="file-alt" faStyle="regular" />
          <Text t="projects.application.states.in_progress" />
        </div>
      );
    case "in_progress":
      return (
        <div {...(!isAdmin && { className: styles.boldCell })}>
          <Icon icon={`${STATUS_ICONS[status]}`} faStyle={`${STATUS_ICON_STYLES[status]}`} />
          <Text t="projects.application.states.revisions_requested" />
        </div>
      );
    default:
      return (
        <div {...(isAdmin && status === "submitted" && { className: styles.boldCell })}>
          <Icon icon={`${STATUS_ICONS[status]}`} faStyle={`${STATUS_ICON_STYLES[status]}`} />
          <Text t={`projects.application.states.${status}`} />
        </div>
      );
  }
};

const ctaCellRenderer = ({ row }) => <CTACell cta={row?.original?.cta} />;

const documentCellRenderer = ({ getValue }) => <DocumentCell requirement={getValue()} />;

const statusCellRenderer = ({ getValue }) => <StatusCell status={getValue()} />;

const COLUMNS = [
  columnHelper.accessor("status", {
    header: "Status",
    cell: statusCellRenderer,
    meta: { size: 200 },
  }),
  columnHelper.accessor("submittedAt", {
    header: "Uploaded",
    cell: dateCellRenderer,
    meta: { size: 200 },
  }),
  columnHelper.accessor("document", {
    header: "Document",
    cell: documentCellRenderer,
    meta: { size: "1fr" },
  }),
  columnHelper.display({
    id: "cta",
    cell: ctaCellRenderer,
    meta: { size: 100 },
  }),
];

const UploadRequirements = withFilteredList(({ uploadRequirements }) => {
  const { config: { sort = [] } = {}, onSortChange } = useListConfigContext();
  return (
    <Table
      columns={COLUMNS}
      data={uploadRequirements}
      onSortingChange={onSortChange}
      sorting={sort}
      manualSorting={false}
    />
  );
});

/**
 * @component UploadRequirementsTable
 * tabular display of upload requirements
 *
 * @param {Object} params
 * @param {Boolean} params.isLoading
 * @param {Object[]} params.uploadRequirements
 * @returns <UploadRequirementsTable />
 */
const UploadRequirementsTable = ({ isLoading = false, uploadRequirements = [] }) => (
  <MaybeTable isLoading={isLoading} listCount={uploadRequirements.length}>
    <UploadRequirements uploadRequirements={uploadRequirements} />
  </MaybeTable>
);

export default UploadRequirementsTable;
