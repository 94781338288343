import * as R from "ramda";
import { useSelector } from "react-redux";

import { selectProjectRequirementSections } from "reducers/projects";

import useIsDirectApply from "../useIsDirectApply";

const feeScopingFieldIDsFromSections = R.compose(
  R.pluck("id"),
  R.propOr([], "fields"),
  R.find(R.propEq("key", "fee_scoping")),
);

const allFieldIDsFromSections = R.pipe(R.pluck("fields"), R.flatten, R.pluck("id"), R.uniq);

const useValidationFieldsForRequirement = (project, requirement) => {
  const { isDirectApply } = useIsDirectApply();

  const sections = useSelector((state) =>
    selectProjectRequirementSections(state, project, requirement, {
      withFeeFields: true,
    }),
  );

  return {
    fieldIDs: allFieldIDsFromSections(sections),
    contextuallyRequiredFieldIDs: isDirectApply ? feeScopingFieldIDsFromSections(sections) : [],
  };
};

export default useValidationFieldsForRequirement;
