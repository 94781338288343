import * as R from "ramda";

import useParam from "hooks/useParam";

const useIsDirectApply = () => {
  const param = useParam("requirementOrDirect");
  return {
    requirementOrDirect: param,
    isDirectApply: R.equals("direct", param),
  };
};

export default useIsDirectApply;
