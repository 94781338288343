import * as R from "ramda";

import en from ":locales/en.yml";
import es_la from ":locales/es-la.yml";
import es from ":locales/es.yml";
import fr from ":locales/fr.yml";
import hi from ":locales/hi.yml";
import ja from ":locales/ja.yml";
import ko from ":locales/ko.yml";
import tl from ":locales/tl.yml";
import vi from ":locales/vi.yml";
import zh from ":locales/zh.yml";

export default R.reduce(R.mergeDeepLeft, {})([en, es_la, es, fr, hi, ja, ko, tl, vi, zh]);
