import React from "react";

import Map from "components/guides/ZoningDetails/Map";
import { addressPropType } from "utils/sharedPropTypes";

const ZoningMap = ({ address, permission }) => {
  if (!address) return null;
  return <Map width={800} height={250} zoom={16} address={address} permission={permission} />;
};

ZoningMap.propTypes = {
  address: addressPropType.isRequired,
};

export default ZoningMap;
