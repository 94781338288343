import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Icon from "components/Icon";
import useIsLocalDev from "utils/useIsLocalDev";

import styles from "./Loader.scss";

const Loader = ({ inverse, large, component, label }) => {
  const isLocalDev = useIsLocalDev();
  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.component]: component,
      })}
      data-loader
      role="progressbar"
    >
      <div
        className={classnames(styles.loader, styles.icon, {
          [styles.large]: large,
        })}
      >
        <Icon
          icon="circle-notch"
          faStyle="light"
          inverse={inverse}
          className={classnames("fa-spin")}
        />
      </div>
      {isLocalDev && label ? <div data-label>{label}</div> : null}
    </div>
  );
};
Loader.propTypes = {
  inverse: PropTypes.bool,
  label: PropTypes.string,
  large: PropTypes.bool,
  component: PropTypes.bool,
};

export const InlineLoader = ({ inverse, size }) => (
  <Icon
    icon="circle-notch"
    faStyle="regular"
    size={size}
    inverse={inverse}
    className={classnames("fa-spin", styles.icon)}
    role="progressbar"
  />
);
InlineLoader.propTypes = { inverse: PropTypes.bool };

export const BlankLoader = () => null;

export const ComponentLoader = (props) => <Loader component {...props} />;

export const FullScreenLoader = (props) => (
  <div className={styles.fullScreenContainer}>
    <Loader {...props} large />
  </div>
);
FullScreenLoader.propTypes = Loader.propTypes;

export default Loader;
