import { useVersionedClientRef } from "contexts/versionedClient";
import keys from "queries/keys";
import resourceOperations from "queries/resourceOperations";
import * as R from "ramda";
import { useMutation, useQueryClient } from "react-query";

export const queryKeys = {
  projectScope: keys.projectScope,
  projects: keys.projects,
};

const { pathFor, useRead, useUpdate } = resourceOperations({
  basePath: "/api/projects",
  baseKey: "projects",
});
export { pathFor };

export const useUpdateProject = useUpdate;
export const useProject = useRead;

export const useScopeProject = (
  id: number,
  { onSuccess = R.identity, ...opts } = {}
) => {
  const queryClient = useQueryClient();
  const client = useVersionedClientRef();

  return useMutation({
    ...opts,
    mutationKey: keys.projectScope(id),
    async mutationFn() {
      const { data } = await client.current.put(pathFor([id, "scope"]), {});
      return data;
    },
    onSuccess(updatedProject) {
      queryClient.invalidateQueries(keys.projects(id));
      onSuccess(updatedProject);
    },
  });
};

interface MutationArgs {
  fieldIDs: number[];
  contextuallyRequiredFieldIDs: number[];
}

export const useValidateProject = (projectID: number, opts) => {
  const client = useVersionedClientRef();
  return useMutation({
    async mutationFn({ fieldIDs, contextuallyRequiredFieldIDs }: MutationArgs) {
      const { data } = await client.current.get(
        pathFor([projectID, "validate"]),
        {
          params: {
            field_ids: fieldIDs,
            contextually_required_field_ids: contextuallyRequiredFieldIDs,
          },
        }
      );
      return data;
    },
    ...opts,
  });
};
