// renders on the renewal to link back to the parent

import React from "react";
import * as R from "ramda";
import { Link } from "react-router-dom";

import { getApplicationAdminURL, getApplicationURL } from "selectors/routes";

import EventLogPropTypes from "./propTypes";

const ApplicationRenewalCreatedFrom = ({
  Layout,
  event,
  timestamp,
  applicantContext,
  applicationView,
}) => {
  const { parent_project_id, parent_requirement_application_id, requirement_id, requirement_name } =
    R.path(["payload", "data"], event);

  const url = applicantContext
    ? getApplicationURL({ project_id: parent_project_id, requirement_id })
    : getApplicationAdminURL({
        id: parent_requirement_application_id,
        project_id: parent_project_id,
        requirement_id,
      });

  return (
    <Layout icon="arrow-alt-circle-right">
      {applicationView ? (
        <>
          Renewal started based on prior <Link to={url}>application</Link>
        </>
      ) : (
        <>
          {requirement_name} renewal started based on prior <Link to={url}>application</Link>
        </>
      )}
      {timestamp}
    </Layout>
  );
};
ApplicationRenewalCreatedFrom.propTypes = EventLogPropTypes;

export default ApplicationRenewalCreatedFrom;
