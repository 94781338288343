import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { SubmitButton } from "components/Button";
import { ButtonDiv } from "components/accessibility/Div";
import * as Inputs from "components/forms/BareFields";
import { ApplicantLabeledInput, Form, FormActions, FormRow } from "components/forms/Layout";
import InlineModal from "components/shared/InlineModal";
import Text from "containers/Text";
import { useAuthnState } from "contexts/auth";
import { useRequestPasswordReset } from "queries/auth";
import { errorHandler, swallowErrors } from "utils/form";

const ForgotPassword = ({ onClose, onReset }) => {
  const { email, setEmail } = useAuthnState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
    setValue,
  } = useForm();
  useEffect(() => {
    if (!email) return;
    setValue("email", email, { shouldDirty: true });
  }, [setValue, email]);

  const { mutateAsync, isSuccess } = useRequestPasswordReset({
    onMutate({ email }) {
      setEmail(email);
    },
    onError: errorHandler(setError),
  });

  return (
    <InlineModal onClose={onClose} heading={<Text t="pages.forgot_password.banner" />}>
      {isSuccess ? (
        <Text t="pages.forgot_password.an_email_has_been_sent" email={email} />
      ) : (
        <Form
          onSubmit={handleSubmit(swallowErrors(mutateAsync))}
          name="forgot-password"
          data-forgot-password-form
        >
          <FormRow>
            <ApplicantLabeledInput
              label={<Text t="pages.forgot_password.your_email" />}
              error={errors?.email}
            >
              <Inputs.Text
                register={register}
                name="email"
                type="email"
                autoComplete="username"
                placeholder="email@example.com"
              />
            </ApplicantLabeledInput>
          </FormRow>

          <FormActions>
            <div>
              <SubmitButton data-login formControl={control} allowSubmitNonDirty>
                <Text t="pages.forgot_password.submit" />
              </SubmitButton>
            </div>

            <div>
              {onReset && (
                <ButtonDiv onClick={onReset}>
                  <Text t="pages.login.reset" />
                </ButtonDiv>
              )}
            </div>
          </FormActions>
        </Form>
      )}
    </InlineModal>
  );
};

ForgotPassword.propTypes = {};

export default ForgotPassword;
