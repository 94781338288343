import React from "react";
import { AxiosError } from "axios";
import * as R from "ramda";
import { useSelector } from "react-redux";

import Forbidden from "components/Forbidden";
import { selectNetworkErrors } from "reducers/network";
import { isForbiddenError } from "services/ocClient";

import ErrorPage from "./ErrorPage";

export class NetworkErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  async componentDidCatch(error, { componentStack }) {
    if (!(error instanceof AxiosError)) return; // This only cares about AxiosErrors
    const errorOwnProps = R.omit(["message", "stack"], error);
    console.error("NetworkErrorBoundary.didCatch", error, { errorOwnProps }, componentStack);
  }

  render() {
    const { children } = this.props;
    const { hasError, error } = this.state;
    if (hasError && isForbiddenError(error)) return <Forbidden />;
    // if (isNotFoundError(error)) return <NotFound />;
    // else let the higher ErrorBoundary handle it.
    return children;
  }
}

// To handle the errors which happen in Sagas and tracked using the apiSaga
export const ReduxNetworkErrorHandler = ({ children }) => {
  const errors = useSelector(selectNetworkErrors);
  const errorCodes = R.compose(R.uniq, R.map(R.prop("errorCode")), R.values)(errors);

  if (R.isEmpty(errors)) return children;

  if (R.includes(403, errorCodes)) {
    return <Forbidden />;
  }

  // TODO ErrorPage is not yet an InlineModal so this does not always render correctly (it's a sibling of major parts of )
  return <ErrorPage error={errors[0]} />;
};
