import { useCallback, useState } from "react";

import Fragment from "components/SafeFragment";

/**
 * Like useState but the value is a react component and provides a resetFn
 * @param {React.Component} defaultComponent - Default component to use on init and when reset
 */
export default (defaultComponent = Fragment) => {
  const [DefaultComponent] = useState(() => defaultComponent);
  const [Component, _setComponent] = useState(() => DefaultComponent);
  const setComponent = useCallback((component) => _setComponent(() => component), [_setComponent]);
  const resetComponent = useCallback(
    () => setComponent(DefaultComponent),
    [setComponent, DefaultComponent],
  );

  return [Component, setComponent, resetComponent];
};
