import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import Icon, { CircularIcon } from "components/Icon";
import Loader from "components/Loader";
import { ButtonDiv } from "components/accessibility/Div";
import ReadMore from "components/utilities/ReadMore";
import Text from "containers/Text";
import { administrativeBackgroundColor } from "utils/sharedStyles";

import { immediateValue } from "../behaviors";
import styles from "./Browser.scss";

const findCategory = (categories, categoryID) => categories.find((c) => c.id === categoryID);

const withoutCategory = (id, categories) =>
  R.map(
    R.evolve({ children: (children) => withoutCategory(id, children) }),
    R.reject(R.propEq("id", id), categories),
  );

const filteredCategories = (categories, filters) => {
  const findByCategoryID = R.curry(findCategory)(categories);

  const filterCategories = (cats, filter) => {
    if (filter.type === "exclude_category") return withoutCategory(filter.id, cats);
    // This only works if only one include_category is configured and won't yield stable
    // results if paired with exclude since the order of the operations isn't guarenteed to the stable
    if (filter.type === "include_category") return [findByCategoryID(filter.id)];
    return cats;
  };

  return R.reduce(filterCategories, categories, filters);
};

const initialCategoryPath = ({ value, tenant: { use_codes, use_code_categories } }) => {
  const useCodeName = value?.full_name;
  if (!useCodeName) return [];
  const useCode = R.find(R.propEq("full_name", useCodeName), use_codes);
  if (!useCode) return [];

  const categories = use_code_categories;
  return R.map(R.curry(findCategory)(categories), useCode.category_ids);
};

const Breadcrumbs = ({ path, select }) => {
  if (!path.length) {
    return null;
  }

  const all = (
    <li key="all">
      <ButtonDiv onClick={() => select([])}>
        <Text t="forms.use_code_browser.all" />
      </ButtonDiv>
    </li>
  );
  const rest = path.map((item, index) => {
    const isLast = path.length === index + 1;
    const onSelect = () => select(R.take(index + 1, path));
    const inner = isLast ? item.name : <ButtonDiv onClick={onSelect}>{item.name}</ButtonDiv>;
    return (
      <span key={item.id}>
        <Icon icon="angle-right" />
        <span>{inner}</span>
      </span>
    );
  });
  return <ol className={styles.breadcrumbs}>{R.prepend(all, rest)}</ol>;
};

Breadcrumbs.propTypes = {
  path: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number,
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
  select: PropTypes.func,
};

const SelectButton = ({ hasChildren, isLoading, selected }) => {
  if (hasChildren) return <Icon icon="angle-right" />;

  if (isLoading && selected) {
    return <Loader />;
  }

  return (
    <span>
      <Text t="forms.use_code_browser.select" />
    </span>
  );
};
const Item = ({ id, name, description, onClick, selected, hasChildren, isLoading, isEnglish }) => (
  <li className={styles.itemRow} key={id}>
    <ButtonDiv className={styles.clickableRow} onClick={onClick}>
      <div className={styles.itemContent}>
        <h3 data-use-code-name>{name}</h3>
        {description && isEnglish && (
          <ReadMore lines={2}>
            <p>{description}</p>
          </ReadMore>
        )}
      </div>
      <div className={styles.itemSelectIcon}>
        <SelectButton selected={selected} hasChildren={hasChildren} isLoading={isLoading} />
      </div>
    </ButtonDiv>
  </li>
);
Item.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  hasChildren: PropTypes.bool,
  isLoading: PropTypes.bool,
  isEnglish: PropTypes.bool,
};

const categoryToItem = R.curry((updateCategoryPath, categoryPath, isEnglish, c) => (
  <Item
    id={c.id}
    key={c.id}
    name={c.name}
    description={c.description}
    onClick={() => updateCategoryPath(R.append(c, categoryPath))}
    isEnglish={isEnglish}
    hasChildren
  />
));

const codeToItem = R.curry((value, isLoading, onSelect, isEnglish, useCode) => (
  <Item
    id={useCode.full_name}
    key={useCode.full_name}
    name={useCode.name}
    description={useCode.description}
    selected={useCode.slug === R.prop("slug", value)}
    isLoading={isLoading}
    onClick={() => onSelect(useCode)}
    isEnglish={isEnglish}
  />
));

const sortByName = R.sortBy(R.compose(R.toLower, R.prop("name")));

const getSubCategories = (t, cat) => t.use_code_categories.filter((c) => c.parent_id === cat.id);

const UseCodeBrowserComponent = ({
  tenant,
  onUseCodeSelect,
  value,
  guide = { use_code_filters: [] },
  isLoading,
  isEnglish,
  simplified,
}) => {
  const [categoryPath, setCategoryPath] = useState(initialCategoryPath({ value, tenant }));
  const updateCategoryPath = useCallback(
    (path) => {
      window.scrollTo(0, 0);
      setCategoryPath(path);
    },
    [setCategoryPath],
  );

  useEffect(() => {
    setCategoryPath(initialCategoryPath({ value, tenant }));
  }, [value, tenant]);

  let categories = [];
  let uses = [];
  let list = [];
  let currentCategory = R.last(categoryPath);

  if (currentCategory) {
    categories = getSubCategories(tenant, currentCategory);
    categories = filteredCategories(categories, guide.use_code_filters);
  } else {
    categories = filteredCategories(tenant.use_code_categories, guide.use_code_filters);
    categories = categories.filter((c) => !c.parent_id);
    if (categories.length === 1) {
      currentCategory = R.head(categories);
      // we can't filter here b/c if there is an include it will wipe the results, thus excludes don't work in this case
      categories = getSubCategories(tenant, currentCategory);
    }
  }

  if (currentCategory) {
    uses = R.filter((u) => currentCategory.id === R.last(u.category_ids), tenant.use_codes);
    uses = sortByName(uses || []);
  }

  categories = sortByName(categories || []);

  if (!R.isEmpty(categories))
    list = R.concat(
      list,
      R.map(categoryToItem(updateCategoryPath, categoryPath, isEnglish), categories),
    );

  if (!R.isEmpty(uses))
    list = R.concat(list, R.map(codeToItem(value, isLoading, onUseCodeSelect, isEnglish), uses));

  return (
    <div className={simplified ? styles.simplifiedContainer : styles.container}>
      <div className={styles.header}>
        <CircularIcon icon="list-alt" size="2x" iconColor={administrativeBackgroundColor} />
        <h1 className={styles.title}>
          <Text t="forms.use_code_browser.title" />
        </h1>
        <div className={styles.instructions}>
          <Text t="forms.use_code_browser.instructions" />
        </div>
      </div>
      <Breadcrumbs path={categoryPath} select={updateCategoryPath} />
      <ul className={styles.browser} data-use-code-browser>
        {list}
      </ul>
    </div>
  );
};

UseCodeBrowserComponent.propTypes = {
  tenant: PropTypes.shape({
    use_codes: PropTypes.arrayOf(
      PropTypes.shape({
        category_ids: PropTypes.arrayOf(PropTypes.number),
        description: PropTypes.string,
        full_name: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
      }),
    ),
    use_code_categories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
  }),
  guide: PropTypes.shape({}),
  onUseCodeSelect: PropTypes.func.isRequired,
  value: PropTypes.shape({
    slug: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  isEnglish: PropTypes.bool,
};

export default R.compose(
  immediateValue,
  // withMountTracking(USE_CODE_BROWSED),
)(UseCodeBrowserComponent);
