import React from "react";
import * as R from "ramda";
import { Link } from "react-router-dom";

import { getApplicationAdminURL, getTransactionAdminURL } from "selectors/routes";
import { formatMoney } from "utils/format";

import EventLogPropTypes from "./propTypes";

const RefundIssued = ({ Layout, event, timestamp, applicantContext, applicationView }) => {
  const applications = R.path(["payload", "data", "applications"], event);
  if (R.length(applications) > 1)
    throw new Error("Refunds over multiple requirements are not supported!");

  const application = R.nth(0, applications);
  const projectID = R.prop("project_id", event);
  const applicationID = R.prop("id", application);
  const requirementID = R.prop("requirement_id", application);
  const requirementName = R.prop("requirement_name", application);
  const transactionID = R.path(["payload", "data", "transaction_id"], event);

  const amount = Math.abs(R.path(["payload", "data", "amount"], event) / 100);
  const by = R.path(["payload", "data", "by"], event);
  const amountBy = (
    <>
      ${formatMoney(amount)} {applicantContext ? " by staff" : by && ` by ${by}`}
    </>
  );

  return (
    <Layout icon="hand-holding-usd">
      {applicantContext || applicationView ? (
        <>Refund issued: {amountBy}</>
      ) : (
        <>
          <Link to={getTransactionAdminURL({ id: transactionID, project_id: projectID })}>
            Refund
          </Link>{" "}
          issued for{" "}
          <Link
            to={getApplicationAdminURL({
              project_id: projectID,
              id: applicationID,
              requirement_id: requirementID,
            })}
          >
            {requirementName}
          </Link>
          : {amountBy}
        </>
      )}

      {timestamp}
    </Layout>
  );
};
RefundIssued.propTypes = EventLogPropTypes;

export default RefundIssued;
