import React, { useEffect, useRef } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  // eslint-disable-next-line import/no-unresolved
} from "@tanstack/react-table";
import classnames from "classnames";
import { Link } from "react-router-dom-v5-compat";

import Icon from "components/Icon";
import Loader from "components/Loader";
import { formatAddress, maybeMoney } from "utils/format";
import { formatDateString, formatShortDateString } from "utils/time";

import styles from "./DataTable.scss";

export const addressCellRenderer = ({ getValue }) => formatAddress(getValue());
export const dateCellRenderer = ({ getValue }) => (
  <time dateTime={getValue()}>{formatShortDateString(getValue())}</time>
);
export const dateTimeCellRenderer = ({ cellData }) => (
  <time dateTime={cellData}>{formatDateString(cellData)}</time>
);
export const moneyCellRenderer = ({ getValue }) => maybeMoney(getValue());
export const snakeCellRenderer = ({ getValue }) => getValue()?.replace(/_/g, " ");
export const checkboxCellRenderer = ({ getValue }) => getValue() && <Icon icon="check" />;

export const columnHelper = createColumnHelper();

const SortIcon = ({ isSorted }) => {
  if (isSorted === "asc") {
    return <Icon icon="caret-up" faStyle="solid" />;
  }
  return <Icon icon="caret-down" faStyle="solid" />;
};

export const HeaderCell = ({ children, style, header }) => {
  const { column } = header;
  const canSort = column.getCanSort();
  const isSorted = column.getIsSorted();

  if (!canSort) {
    return <th style={style}>{children}</th>;
  }

  return (
    <th onClick={column.getToggleSortingHandler()} style={style} className={styles.sortable}>
      <span>{children}</span>
      {isSorted && <SortIcon isSorted={isSorted} />}
    </th>
  );
};

export const useRowNav = (urlBuilder) => (row) => urlBuilder(row.original);

export const useRowFileOpen = (urlBuilder) => (row) => {
  const url = urlBuilder(row.original);
  window.open(url, "_blank", "noopener,noreferrer");
};

const getGridTemplateColumns = (columns) => {
  const columnSizes = columns.map((column) => {
    const size = column.column.columnDef.meta?.size || "1fr";

    if (size === "auto") {
      throw new Error("auto is not supported as a column size");
    }

    if (/^\d+$/.test(size)) {
      return `${size}px`;
    }

    return size;
  });

  return columnSizes.join(" ");
};

const cellProps = (columnDef) => {
  const style = columnDef.meta?.style;
  const className = columnDef.meta?.className;

  return { style, className, "data-col": columnDef.accessorKey };
};

const Table = ({
  columns,
  data = [],
  getRowHref,
  onRowClick,
  sorting,
  initialSorting,
  onSortingChange,
  manualSorting = true,
}) => {
  const tableProps = {
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      sortDescFirst: false,
    },
    state: {},
    manualSorting,
  };

  if (onSortingChange) {
    tableProps.onSortingChange = onSortingChange;
    tableProps.state.sorting = sorting;
  } else {
    tableProps.initialState = { sorting: initialSorting };
  }

  const table = useReactTable(tableProps);

  return (
    <table
      className={styles.table}
      style={{
        gridTemplateColumns: getGridTemplateColumns(table.getFlatHeaders()),
      }}
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <HeaderCell key={header.id} header={header}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </HeaderCell>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, idx) => (
          <tr
            className={classnames(styles.dataRow, {
              [styles.clickable]: getRowHref || onRowClick,
            })}
            onClick={onRowClick ? (e) => onRowClick(row, e) : undefined}
            key={row.id}
            data-row={idx}
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} {...cellProps(cell.column.columnDef)}>
                <Link
                  className={styles.linkWrapper}
                  to={getRowHref ? getRowHref(row) : undefined}
                  key={cell.id}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Link>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const InfiniteTable = ({ data, totalCount, fetchNextPage, isFetching, ...tableProps }) => {
  const totalFetched = data.length;
  const bottomRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && totalFetched < totalCount && !isFetching) {
          console.error("Fetching next page");
          fetchNextPage();
        }
      },
      { rootMargin: "25%" },
    );

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    return () => observer.disconnect();
  }, [fetchNextPage, isFetching, totalFetched, totalCount]);

  return (
    <div className={styles.tableContainer}>
      <Table {...tableProps} data={data} />
      {isFetching && (
        <div className={styles.loading}>
          <Loader large />
        </div>
      )}
      <div ref={bottomRef} data-bottom-ref />
    </div>
  );
};

export default Table;
