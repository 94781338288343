import * as R from "ramda";
import { connect } from "react-redux";

import { withAnswerContextConsumer } from "containers/withAnswerContext";
import { getRequirementID } from "reducers/fees";
import { getRequirementIDs, selectCurrentFees } from "reducers/projects";
import { selectRequirementsByID } from "reducers/requirements";

const mapStateToProps = (state, { answerContext: { record }, requirementID = null }) => {
  let requirements;
  let fees = selectCurrentFees(state, record) || [];

  if (requirementID != null) {
    requirements = selectRequirementsByID(state, [requirementID]);
    fees = R.filter((f) => getRequirementID(f) === requirementID, fees);
  } else {
    const requirementIDs = getRequirementIDs(record);
    requirements = R.sortBy(R.prop("name"), selectRequirementsByID(state, requirementIDs));
  }

  return {
    requirementID,
    requirements,
    feesTotal: R.pipe(R.pluck("cost"), R.sum)(fees),
  };
};

export default (component) =>
  R.compose(withAnswerContextConsumer, connect(mapStateToProps))(component);
