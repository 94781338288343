import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import { Button } from "components/Button";
import Icon from "components/Icon";
import AnimatedTextField from "components/project_form/AnimatedTextField";
import useFocusedState from "hooks/useFocusedState";
import { useUseCodesForQuery } from "queries/zoning";
import { getUseCodeFilters } from "reducers/guides";
import analytics from "services/analytics";
import { USE_CODE_SEARCHED } from "services/tracking";
import filterUseCodes from "utils/filterUseCodes";
import { isPresent } from "utils/func";
import { errorPropType } from "utils/sharedPropTypes";

import styles from "./SearchBox.scss";

const useUseCodesForQueryWithSlugSpecialCase = ({ query, guide }, queryOpts) => {
  const useCodeFilters = getUseCodeFilters(guide);
  const {
    data: matchedUseCodes = [],
    isFetched,
    isLoading,
  } = useUseCodesForQuery(query, {
    enabled: isPresent(query), // && !slugMatch,
    select: (useCodes) => filterUseCodes(useCodes, useCodeFilters),
    ...queryOpts,
  });

  if (isFetched) {
    analytics.track(USE_CODE_SEARCHED, {
      businessType: query,
      searchResultsCount: R.length(matchedUseCodes),
    });
  }
  return { isLoading, isFetched, useCodes: matchedUseCodes };
};

const SearchBox = ({
  error,
  field,
  guide,
  required,
  lastQuery,
  onChange,
  setLastQuery,
  searchInProgress,
  setSearchInProgress,
  value,
}) => {
  const [isFocused, onFocus, onBlur] = useFocusedState(false);
  const [query, setQuery] = useState(R.prop("query", value));
  const onQueryChange = useCallback(
    (q) => {
      setQuery(q);
      onChange(R.assoc("query", q, value));
    },
    [setQuery, onChange, value],
  );

  useUseCodesForQueryWithSlugSpecialCase(
    { query: lastQuery, guide },
    {
      onSuccess(useCodes) {
        onChange(R.assoc("results", useCodes, value));
        setSearchInProgress(false);
      },
    },
  );

  const onSearch = useCallback(async () => {
    setSearchInProgress(true);
    setLastQuery(query); // this kicks off the useUseCodesForQueryWithSlugSpecialCase query to update
  }, [setSearchInProgress, setLastQuery, query]);

  return (
    <form
      className={styles.search}
      onSubmit={(e) => {
        e.preventDefault();
        if (query) {
          onSearch();
        }
      }}
    >
      <AnimatedTextField
        field={field}
        error={error}
        isFocused={isFocused}
        value={query}
        isSearchInput
        autoComplete="off"
        onChange={onQueryChange}
        onSave={() => {}}
        onFocus={onFocus}
        onBlur={onBlur}
        ignorePlaceholders={lastQuery === ""}
        placeholders={["Office", "Restaurant", "Yoga studio", "Retail store", "Salon"]}
        name="Use Code Search"
        data-business-type-search
        aria-invalid={!!error}
        aria-required={!!required}
        labelId="form-use-search-instructions"
        autoFocus
      />
      <Button
        type="submit"
        label={[
          <Icon icon="search" key="icon" />,
          <span className={styles.searchLabel} key="label">
            Search
          </span>,
        ]}
        isLoading={searchInProgress}
        disabled={!query || query === lastQuery}
        data-business-type-submit
      />
      {error && <Icon icon="exclamation" />}
    </form>
  );
};

SearchBox.propTypes = {
  field: PropTypes.shape({
    placeholder: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
  error: errorPropType,
  value: PropTypes.shape({
    query: PropTypes.string,
  }).isRequired,
  lastQuery: PropTypes.string,
  required: PropTypes.bool,
};

export default SearchBox;
