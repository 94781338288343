import React from "react";
import { useSelector } from "react-redux";

import Loader from "components/Loader";
import Markdown from "components/Markdown";
import Text from "containers/Text";
import { useParams } from "hooks/useParam";
import { useInPersonPayableAccount } from "queries/payableAccounts";
import { selectRequirementBySlug } from "reducers/requirements";

import PaymentLayout from "./PaymentLayout";
import Section from "./Section";
import useOnNext from "./useOnNext";

export const InPersonPaymentInstructions = ({ requirement }) => {
  const { isLoading, data: { instructions: paymentInstructions } = {} } = useInPersonPayableAccount(
    requirement.in_person_payable_account_id,
  );

  return (
    <>
      {isLoading ? <Loader /> : <Markdown source={paymentInstructions} />}
      <h4>
        <Text t="projects.requirement.payment.in_person.instructions" />
      </h4>
    </>
  );
};

const InPersonPayment = ({ paymentType, setPaymentType }) => {
  const { requirementSlug } = useParams();
  const requirement = useSelector((state) => selectRequirementBySlug(state, requirementSlug));

  const { onNext, isLoading } = useOnNext({ paymentType, shouldSubmit: true });

  return (
    <PaymentLayout
      paymentType={paymentType}
      setPaymentType={setPaymentType}
      onNext={onNext}
      isLoading={isLoading}
    >
      <Section headerTextKey="projects.requirement.payment.in_person.header">
        <InPersonPaymentInstructions requirement={requirement} />
      </Section>
    </PaymentLayout>
  );
};

export default InPersonPayment;
