import React from "react";

import Icon from "components/Icon";
import Text from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import { getEventStart } from "reducers/projects";
import { getDaysToSubmit } from "reducers/requirements";
import { formatDateStringAsDay, getDueDate } from "utils/time";

import styles from "./DueDate.scss";

const DueDate = ({ requirement }) => {
  const { record } = useAnswerContext();
  const daysToSubmit = getDaysToSubmit(requirement);
  if (typeof daysToSubmit !== "number") return null;

  const eventStart = getEventStart(record);
  if (!eventStart) return null;

  const dueDate = getDueDate(eventStart, daysToSubmit);

  return (
    <div className={styles.container}>
      <Icon icon="calendar" />
      <span>
        <Text t="projects.handoff.due" date={formatDateStringAsDay(dueDate)} />
      </span>
    </div>
  );
};

export default DueDate;
