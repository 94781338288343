// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-projects-applications-Download__container--BpGcV {\n  margin-top: 1em;\n}", "",{"version":3,"sources":["webpack://./ui/components/projects/applications/Download.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF","sourcesContent":[".container {\n  margin-top: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ui-components-projects-applications-Download__container--BpGcV"
};
export default ___CSS_LOADER_EXPORT___;
