import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import Icon from "components/Icon";
import Loader from "components/Loader";
import createStyledElement from "components/utilities/createStyledElement";
import { childrenPropType } from "utils/sharedPropTypes";

import styles from "./Table.scss";

export const TableGroup = createStyledElement(styles.tableGroup);
export const Table = createStyledElement(styles.table);
export const Row = createStyledElement(styles.row);
export const Col = createStyledElement(styles.col);
export const TableHeader = createStyledElement(styles.header);
export const TableHeading = createStyledElement(styles.heading);

export const TableGroupHeading = ({
  className = styles.defaultBackground,
  title,
  rowCount = null,
  query = null,
  queryString,
  icon = "cog",
  iconClassName = styles.defaultIconBackground,
  children,
  hideIcon = false,
  isLoading,
}) => {
  let queryLoading = false;
  if (query) {
    if (query.isLoading) {
      queryLoading = true;
    }
    rowCount = query.data ? R.length(query.data) : null;
  }

  return (
    <div className={classnames(styles.groupHeader, className)}>
      {!hideIcon && (
        <div className={classnames(styles.groupIcon, iconClassName)}>
          <Icon
            size="lg"
            icon={queryLoading ? "sync" : icon}
            className={classnames({ "fa-spin": queryLoading })}
          />
        </div>
      )}
      <div className={styles.groupType}>
        {queryString ? (
          <>
            {isLoading ? <span>Loading</span> : rowCount} <span>RESULTS FOR </span>&apos;
            {queryString}&apos; <span>IN &apos;{title}&apos;</span>
          </>
        ) : (
          <>
            {title}
            {rowCount != null && <span className={styles.rowCount}>( {rowCount} )</span>}
          </>
        )}
      </div>
      {children}
    </div>
  );
};
TableGroupHeading.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  children: childrenPropType,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  rowCount: PropTypes.number,
  hideIcon: PropTypes.bool,
  queryString: PropTypes.string,
  query: PropTypes.shape({
    data: PropTypes.array,
    isLoading: PropTypes.bool,
  }),
};

export const MaybeTable = ({ isLoading, listCount, children }) => {
  if (isLoading)
    return (
      <div className={styles.emptyTableMsg}>
        <Loader large />
      </div>
    );

  if (listCount === 0) return <div className={styles.emptyTableMsg}>Nothing to display</div>;
  return children;
};
MaybeTable.propTypes = {
  isLoading: PropTypes.bool,
  listCount: PropTypes.number.isRequired,
  children: childrenPropType,
};
