import React from "react";

import { hoc } from "utils/hoc";

/**
 * HOC for injecting hook into a Component
 * @param {string} propKey - Prop name to put hook results
 * @param {function} hook - Hook function
 * @param {function} hookArgsFn - Function to generate hook arguments
 */
export default (propKey, hook, hookArgsFn) => {
  const WithHook = ({ Component, ...props }) => {
    const hookArgs = hookArgsFn ? hookArgsFn(props) : [];
    const result = hook(...hookArgs);
    return <Component {...props} {...{ [propKey]: result }} />;
  };
  WithHook.displayName = `WithHook:${hook.name}`;
  return hoc(WithHook);
};
