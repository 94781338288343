import React, { useCallback, useState } from "react";

import ForgotPassword from "components/auth/ForgotPassword";
import InvitedSignupForm from "components/auth/InvitedSignupForm";
import LoginFactorEmail from "components/auth/LoginFactorEmail";
import LoginFactorOkta from "components/auth/LoginFactorOkta";
import LoginFactorPassword from "components/auth/LoginFactorPassword";
import SignupForm from "components/auth/SignupForm";
import {
  FORGOT_PASSWORD,
  INVITED,
  OKTA_REQUIRED,
  PASSWORD_REQUIRED,
  REGISTRATION_REQUIRED,
  UNAUTHENTICATED,
} from "queries/auth";

const unauthenticatedState = { status: UNAUTHENTICATED };

const LoginController = ({ onClose, initialStatus = UNAUTHENTICATED }) => {
  const [{ status, next: { href } = {} }, setState] = useState({ status: initialStatus });

  const onReset = useCallback(() => {
    setState(unauthenticatedState);
  }, [setState]);
  const onForgot = useCallback(() => {
    setState({ status: FORGOT_PASSWORD });
  }, [setState]);

  switch (status) {
    case UNAUTHENTICATED:
      return <LoginFactorEmail onClose={onClose} onNext={setState} />;
    case PASSWORD_REQUIRED:
      return <LoginFactorPassword onClose={onClose} onReset={onReset} onForgot={onForgot} />;
    case INVITED:
      return <InvitedSignupForm onClose={onClose} onReset={onReset} onForgot={onForgot} />;
    case REGISTRATION_REQUIRED:
      return <SignupForm onClose={onClose} onReset={onReset} />;
    case FORGOT_PASSWORD:
      return <ForgotPassword onClose={onClose} onReset={onReset} />;
    case OKTA_REQUIRED:
      return <LoginFactorOkta href={href} />;
    default: {
      throw new Error(`Unexpected status ${status} from Authn API`);
    }
  }
};

export default LoginController;
