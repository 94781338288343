import React from "react";
import { useSelector } from "react-redux";

import Header from "components/Header";
import ZoningSummary from "components/guides/ZoningDetails/ZoningSummary";
import Field from "containers/Field";
import HelpBar from "containers/guides/HelpBar";
import { useAnswerContext } from "containers/withAnswerContext";
import { getAnswerForField } from "reducers/answerContexts";
import { selectFieldByKey } from "reducers/fields";
import { selectGuideByID } from "reducers/guides";

import Step from "../requirement/Step";
import * as Layout from "./Layout";

const ZoningVerificationPage = () => {
  const { record: project } = useAnswerContext();
  const [addressField, projectTypeField] = useSelector((state) => [
    selectFieldByKey(state, "address"),
    selectFieldByKey(state, "project_type"),
  ]);
  const projectTypeAnswered = !!getAnswerForField(project, projectTypeField)?.use?.slug;
  const addressAnswered = !!getAnswerForField(project, addressField)?.latitude;
  const entryPoint = useSelector((state) => selectGuideByID(state, project.guide_id));

  return (
    <Layout.Page name="zoning-check">
      <Header hideTitle />
      <Layout.Main>
        <Layout.Content>
          <Step header={entryPoint.header_title} instructions={entryPoint.message} />
          <Field field={projectTypeField} behaviorType="inline" />
          {projectTypeAnswered && (
            <div>
              <h3>Where would you like to check your zoning for?</h3>
              <Field field={addressField} behaviorType="inline" />
            </div>
          )}
          {addressAnswered && <ZoningSummary />}
        </Layout.Content>
        <HelpBar />
      </Layout.Main>
    </Layout.Page>
  );
};

export default ZoningVerificationPage;
