import { useCallback, useState } from "react";

const useAsyncCallbackPendingState = (callback) => {
  const [isLoading, setIsLoading] = useState(false);
  const wrapped = useCallback(
    async (...args) => {
      let ret;
      try {
        setIsLoading(true);
        ret = await callback(...args);
      } finally {
        setIsLoading(false);
      }
      return ret;
    },
    [setIsLoading, callback],
  );
  return [wrapped, isLoading];
};
export default useAsyncCallbackPendingState;
