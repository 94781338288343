import React from "react";

import DesktopMenu from "components/DesktopMenu";
import MobileMenu from "components/MobileMenu";

import styles from "./Menu.scss";

const Menu = () => (
  <div className={styles.container}>
    <DesktopMenu />
    <MobileMenu />
  </div>
);

export default Menu;
