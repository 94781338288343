import React from "react";
import * as R from "ramda";
import { Link } from "react-router-dom";

import { logEntryPropType } from "utils/sharedPropTypes";

const InquiryProcessed = ({ Layout, event, timestamp }) => (
  <Layout icon="envelope-open">
    <Link to={`/admin/projects/${event.project_id}/inquiries/${event.context_id}`}>Inquiry</Link>{" "}
    processed by {R.path(["payload", "data", "processed_by"], event)}
    {timestamp}
  </Layout>
);
InquiryProcessed.propTypes = {
  event: logEntryPropType.isRequired,
};

export default InquiryProcessed;
