// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-projects-Fee__container--ECed3 {\n  display: flex;\n  align-items: baseline;\n  gap: 0.25em;\n}\n.ui-components-projects-Fee__container--ECed3 i {\n  font-size: 0.75rem;\n}", "",{"version":3,"sources":["webpack://./ui/components/projects/Fee.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,WAAA;AACF;AACE;EACE,kBAAA;AACJ","sourcesContent":[".container {\n  display: flex;\n  align-items: baseline;\n  gap: 0.25em;\n\n  i {\n    font-size: 0.75rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ui-components-projects-Fee__container--ECed3"
};
export default ___CSS_LOADER_EXPORT___;
