import React, { useEffect } from "react";
import * as R from "ramda";
import { FormProvider, useForm } from "react-hook-form";

import { AnswerContextProvider } from "containers/withAnswerContext";
import useAnswerContextManager from "hooks/useAnswerContextManager";
import { hoc } from "utils/hoc";

import { useEphemeralProject } from "./queries";

const BLANK_EPHEMERAL_RECORD = { fees: {}, calculated_fee_totals: {} };
const EphemeralProvider = ({
  Component,
  onSuccess = R.T,
  defaultValues = {},
  generateFakeData = false,
  ephemeralMutation,
  createMutation,
  source,
  context,
  ...props
}) => {
  // The answerContext is backed by an ephemeral mutation, all state is local only
  // A seperate mutation is used to create and apply the changeset

  // This form only holds metadata, the answers are in the context
  const formMethods = useForm({ defaultValues });
  const { handleSubmit } = formMethods;

  const save = async (params) => {
    const formValues = formMethods.getValues();
    if (context) params.requirement_id = context.id;
    params.fake_data = generateFakeData;
    return ephemeralMutation.mutateAsync({ ...params, ...formValues });
  };

  const answerContext = useAnswerContextManager(
    { data: { ...BLANK_EPHEMERAL_RECORD, ...defaultValues } },
    save,
    { ephemeral: true },
  );

  if (context) answerContext.context = context;

  answerContext.onCreate = () =>
    new Promise((resolve, reject) => {
      answerContext.onAction(() =>
        handleSubmit(async (formData) => {
          try {
            const fieldKeys = R.map(R.prop("key"), answerContext.changedFields);
            const answers = R.pick(fieldKeys, answerContext.record.answers);
            const answerEntity = R.mergeAll([{ answers, source }, formData]);
            await createMutation.mutateAsync(answerEntity, { onSuccess });
            resolve();
          } catch (e) {
            console.error("ERR", e);
            reject(e);
          }
        })(),
      );
    });

  answerContext.reset = () => {
    R.keys(answerContext.record.answers).forEach((key) =>
      answerContext.dispatch({ type: "clearAnswer", key }),
    );
  };

  // Need to seed the local entity with a save (is this actually required for projects or just changesets?)
  useEffect(() => {
    answerContext.onSave({ key: "overrides" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnswerContextProvider value={answerContext}>
      <FormProvider {...formMethods}>
        <Component answerContext={answerContext} {...props} />
      </FormProvider>
    </AnswerContextProvider>
  );
};

const withEphemeralProvider = hoc(EphemeralProvider);
const EphemeralProject = ({ ...props }) => {
  const mutation = useEphemeralProject();
  return <EphemeralProvider {...props} ephemeralMutation={mutation} />;
};

export const withEphemeralProject = hoc(EphemeralProject);
export default withEphemeralProvider;
