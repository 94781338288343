import resourceOperations, { ReadOptions } from "./resourceOperations";
import { Message } from "types/entities";

export const useMessages = (projectID: number, opts: ReadOptions = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
    baseKey: ["messages", projectID],
  }).useRead(null, { staleTime: 0, ...opts });

export const useMessage = (
  projectID: number,
  messageID: number,
  opts: ReadOptions = {}
) =>
  useMessages(projectID, {
    select: (messages: Message[]) => messages.find((m) => m.id === messageID),
    ...opts,
  });

export const useSendMessage = (projectID: number, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
    baseKey: "messages",
    dataKey: "message",
  }).useCreate(null, opts);

export const useSubscribeToMessages = (projectID: number, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
    baseKey: "messages",
  }).useCreate("subscribe", opts);

export const useSubscriptionAssignments = (
  projectID: number,
  opts: ReadOptions = {}
) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
    baseKey: ["messages", projectID],
  }).useRead("subscriptions", opts);

export const useAssignedUserIDs = (projectID: number, opts: ReadOptions = {}) =>
  useSubscriptionAssignments(projectID, {
    select: (subscriptions: any) => subscriptions.map((s) => s.user_id),
    ...opts,
  });

export const useAssignSubscriptions = (projectID: number, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
    baseKey: "messages",
  }).useUpdate("update_subscriptions", opts);

export const useUnsubscribe = (projectID: number, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
    baseKey: "messages",
  }).useCreate("unsubscribe", opts);

export const useMarkMessageRead = (
  projectID: number,
  messageID: number,
  opts = {}
) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
    baseKey: "messages",
  }).useUpdate([messageID, "mark_as_read"], {
    ...opts,
    invalidates: ["admin", "messages", "subscribed"],
  });

export const useMarkAllMessagesProcessed = (projectID: number, opts = {}) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/messages`,
    baseKey: "messages",
  }).useUpdate("mark_all_as_processed", {
    ...opts,
    invalidates: ["admin", "messages", "subscribed"],
  });

export const useLatestMessage = (projectID: number, opts: ReadOptions = {}) =>
  useMessages(projectID, {
    select: (allMessages: Message[]) => {
      const messages = allMessages.filter((m) => m.from_applicant);
      const byDate = messages.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
      const unprocessed = messages.filter((m) => !m.read_at);
      return unprocessed.length ? unprocessed[0] : byDate[0];
    },
    ...opts,
  });

export const useAttachments = (projectID: number, opts: ReadOptions = {}) =>
  useMessages(projectID, {
    select: (messages: Message[]) =>
      messages.reduce(
        (acc, m) => [
          ...acc,
          ...m.attachments.map((a) => ({
            ...a,
            message: m.message,
            message_id: m.id,
            project_id: m.project_id,
            sent_by: m.user?.full_name,
          })),
        ],
        []
      ),
    ...opts,
  });
