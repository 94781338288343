import resourceOperations, { ReadOptions } from "../resourceOperations";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import * as R from "ramda";
import { Tenant, TenantRow } from "types/configEntities";

const operation = resourceOperations({
  basePath: "/api/config/tenants",
  baseKey: ["config", "tenants"],
  dataKey: "tenant",
});

export const useTenant = (id: number, opts: ReadOptions = {}) =>
  operation.useRead<Tenant>(id, opts);

export const useCurrentTenant = (opts: ReadOptions = {}) => {
  const { slug } = useCurrentTenantInfo();
  return useTenant(slug, opts);
};

export const useTenantsIndex = (opts: ReadOptions = {}) =>
  operation.useRead<TenantRow[]>(null, opts, R.values);

export const useCreateTenant = (opts = {}) =>
  operation.useCreate<Tenant>(null, opts);

export const useUpdateTenant = (id: number, opts = {}) =>
  operation.useUpdate<Tenant>(id, opts);

export const useDestroyTenant = (opts = {}) => operation.useDestroy(opts);
