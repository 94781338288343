import React from "react";
import * as R from "ramda";

import EventLogPropTypes from "./propTypes";
import sharedStyles from "./sharedStyles.scss";

const ApplicationNoLongerRequired = ({ Layout, event, timestamp, applicationView }) => {
  const requirementName = R.path(["payload", "data", "requirement_name"], event);

  return (
    <Layout icon="exclamation-triangle" containerStyles={[sharedStyles.error]}>
      {applicationView ? "No longer required." : `${requirementName} no longer required`}
      {timestamp}
    </Layout>
  );
};
ApplicationNoLongerRequired.propTypes = EventLogPropTypes;

export default ApplicationNoLongerRequired;
