import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Icon from "components/Icon";

import styles from "./SubHeader.scss";

const SubHeader = ({ businessName, id, requirementName }) => {
  const projectLink = `/projects/${id}/apply`;
  return (
    <div className={styles.container}>
      <div className={styles.gutters}>
        <div className={styles.subHeaderContainer}>
          <div className={styles.businessName}>
            <Link to={projectLink}>{businessName}</Link>
          </div>
          <Icon icon="caret-right" />
          <div className={styles.requirementName}>{requirementName}</div>
        </div>
      </div>
    </div>
  );
};
SubHeader.propTypes = {
  businessName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  requirementName: PropTypes.string.isRequired,
};

export default SubHeader;
