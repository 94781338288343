import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { branch, renderComponent } from "recompose";

import { FullScreenLoader } from "components/Loader";
import { RequirementSummaryPage } from "components/projects/requirement/Summary";
import { withAnswerContextConsumer, withMappedAnswerContext } from "containers/withAnswerContext";
import withRouterParams from "containers/withRouterParams";
import { selectFeesForRequirement } from "reducers/fees";
import {
  getRequirementApplicationByRequirementID,
  selectCurrentFees,
  selectProjectRequirementSections,
} from "reducers/projects";
import { getCurrentState, getSubmittedAt } from "reducers/requirementApplications";
import { getRequirementStepCount, selectRequirementBySlug } from "reducers/requirements";
import { selectFullName } from "reducers/tenant";
import { selectIsMobile } from "reducers/ui";
import { isBlank } from "utils/func";

const mapStateToProps = (state, { answerContext: { record: project }, requirementSlug }) => {
  const requirement = selectRequirementBySlug(state, requirementSlug);
  const requirementApplication =
    project && getRequirementApplicationByRequirementID(project, requirement.id);

  if (isBlank(requirementApplication)) {
    return { loading: true, projectID: project.id, requirement };
  }

  const allFees = selectCurrentFees(state, project);
  const fees = selectFeesForRequirement(allFees, requirement);
  const sections = selectProjectRequirementSections(state, project, requirement, {
    withFeeFields: true,
  });

  const submittedAt = getSubmittedAt(requirementApplication);

  return {
    requirement,
    requirementApplicationID: R.prop("id", requirementApplication || {}),
    state: getCurrentState(requirementApplication || {}),
    submittedAt,
    sections,
    project,
    projectID: project.id,
    fees,
    loading: false,
    path: window.location.pathname,
    isMobile: selectIsMobile(state),
    tenantFullName: selectFullName(state),
    steps: getRequirementStepCount(requirement),
  };
};

const branched = branch(
  ({ loading }) => loading,
  renderComponent(() => <FullScreenLoader label="Summary" />),
);

export default R.compose(
  withAnswerContextConsumer,
  withRouterParams(["requirementSlug"]),
  connect(mapStateToProps),
  branched,
  withMappedAnswerContext(R.prop("requirement")),
)(RequirementSummaryPage);
