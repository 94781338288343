import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

import clientInstanceId from "services/clientInstanceId";
import { instance as errorReporter } from "services/errorReporter";

const site = "datadoghq.com";

const ignoredResourceUrlPattern = /^https?:\/\/((cdn|r).lr-ingest.io)\//;

export const initDatadogRUM = ({
  applicationId,
  clientToken,
  env,
  service,
  version,
  sessionSampleRate,
  allowedTracingUrls,
}) => {
  datadogRum.init({
    applicationId,
    clientToken,
    site,
    service,
    env,
    version,
    sessionSampleRate,
    sessionReplaySampleRate: 100, // 100% of sampled sessions
    allowedTracingUrls,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    beforeSend: (event, _context) => {
      const { type, resource } = event;
      if (type === "resource" && ignoredResourceUrlPattern.test(resource.url)) return false;
      return event;
    },
  });

  // TODO: is this providing any thing useful on top of RUM?
  datadogLogs.init({
    clientToken,
    site,
    service,
    env,
    version,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });

  datadogRum.setGlobalContextProperty("clientInstanceId", clientInstanceId);

  errorReporter.onError((error, context) => {
    // console.warn("ErrorReporter.onError[Datadog]");
    datadogRum.addError(error, context);
  });
};
