import React, { useMemo } from "react";
import * as R from "ramda";

import ExternalLink from "components/ExternalLink";
import { MaybeTable, TableGroup } from "components/admin/utilities/Table";
import Table, { columnHelper, useRowFileOpen } from "components/utilities/DataTable";
import { useListConfigContext, withFilteredList } from "contexts/listConfig";

import styles from "./DocumentsTable.scss";

const ctaCellRenderer = ({ row }) => (
  <div className={styles.cta}>
    <ExternalLink className="btn-sm" href={row.url} title={row.filename}>
      View
    </ExternalLink>
  </div>
);

// const defaultSort = {
//   sortDirection: SortDirection.DESC,
//   sortBy: "created_at",
// };

const getDocumentLink = R.prop("url");

const Documents = withFilteredList(({ documents, scope }) => {
  const onRowClick = useRowFileOpen(getDocumentLink);
  const applicationScope = scope === "application";
  const { config: { sort = [] } = {}, onSortChange } = useListConfigContext();
  const columns = useMemo(
    () => [
      columnHelper.accessor(applicationScope ? "label" : "filename", {
        header: "Document",
        meta: { size: "1fr" },
      }),
      columnHelper.accessor(applicationScope ? "requirementName" : "message", {
        header: applicationScope ? "Requirement" : "Message",
        meta: { size: applicationScope ? 200 : "2fr" },
      }),
      ...(scope === "message"
        ? [
            columnHelper.accessor("sent_by", {
              header: "Sent By",
              meta: { size: 200 },
            }),
          ]
        : []),
      columnHelper.display({
        id: "created_at",
        cell: ctaCellRenderer,
        meta: { size: 100 },
      }),
    ],
    [applicationScope, scope],
  );

  return (
    <Table
      columns={columns}
      data={documents}
      sorting={sort}
      onSortingChange={onSortChange}
      manualSorting={false}
      onRowClick={onRowClick}
    />
  );
});

/**
 * @component DocumentsTable
 * tabular display of documents
 *
 * @param {Object} params
 * @param {Object[]} params.documents
 * @param {Boolean} params.isLoading
 * @returns <DocumentsTable />
 */
const DocumentsTable = ({ documents, isLoading = false }) => (
  <TableGroup>
    <MaybeTable isLoading={isLoading} listCount={documents.length}>
      <Documents documents={documents} scope="application" />
    </MaybeTable>
  </TableGroup>
);

export const AttachmentsTable = ({ documents, isLoading = false }) => (
  <TableGroup>
    <MaybeTable isLoading={isLoading} listCount={documents.length}>
      <Documents documents={documents} scope="message" />
    </MaybeTable>
  </TableGroup>
);
export default DocumentsTable;
