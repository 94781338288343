import React from "react";
import * as R from "ramda";

import MaybeApplicationLink from "./MaybeApplicationLink";
import EventLogPropTypes from "./propTypes";

/**
 * ApplicationProcessed log entry component
 * @component
 * @param {Object} params
 * @returns <ApplicationProcessed />
 */
const ApplicationProcessed = ({ Layout, event, applicantContext, applicationView, timestamp }) => {
  const by = R.path(["payload", "data", "by"], event);

  return (
    <Layout icon="check-circle">
      {applicationView ? (
        <>Processed {applicantContext ? "by staff" : by && `by ${by}`}</>
      ) : (
        <>
          <MaybeApplicationLink event={event} /> application processed
          {applicantContext ? "by staff" : by && `by ${by}`}
        </>
      )}
      {timestamp}
    </Layout>
  );
};
ApplicationProcessed.propTypes = EventLogPropTypes;

export default ApplicationProcessed;
