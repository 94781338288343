import { useCallback, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { downloadError, downloadReady } from "actions";
import * as actions from "actions";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { useSession } from "contexts/session";
import { selectTenantID } from "reducers/tenant";
import { PusherContext } from "services/pusher";
import { capitalize } from "utils/format";

export const usePusher = () => useContext(PusherContext);

export const usePusherChannel = (channelName) => {
  const { pusher, connected } = usePusher();
  const queryKey = ["pusher", pusher?.sessionID, "channel", channelName];
  const result = useQuery({
    queryKey,
    enabled: connected && !!channelName,
    queryFn({ signal }) {
      const channel = pusher.subscribe(channelName);

      // cleanup subscriptions when the query goes out of scope
      signal.addEventListener("abort", () => {
        console.debug("PSHR unsub", channelName);
        pusher.unsubscribe(channelName);
      });

      if (channel.subscribed) return channel;
      return new Promise((resolve, reject) => {
        channel.bind("pusher:subscription_succeeded", () => {
          console.debug("PSHR channel subscribed", { channelName });
          resolve(channel);
        });

        channel.bind("pusher:subscription_error", (error) => {
          console.debug("PSHR channel subscription_error", { channelName, error });
          channel.unbind_all(); // unbind these handlers so when this query retries (and pusher.subscribe() returns the *same object*), we don't stack another set of these bindings on top
          reject(error);
        });
      });
    },
    staleTime: Infinity,
  });
  return {
    ...result,
    channel: result.data,
    queryKey,
  };
};

export const usePusherSubscription = (channelName, event, handler) => {
  const { channel } = usePusherChannel(channelName);
  return useEffect(() => {
    if (!channel) return undefined;
    console.debug("PSHR", channel.name, "bind", event);
    channel.bind(event, handler);

    return () => {
      console.debug("PSHR", channel.name, "unbind", event);
      channel.unbind(event, handler);
    };
  }, [channel, event, handler]);
};

export const usePusherSessionChannelSubcription = (event, handler) => {
  const { pusher_channel: sessionPusherChannel } = useSession();
  return usePusherSubscription(sessionPusherChannel, event, handler);
};

export const usePusherUserChannelSubscription = (event, handler) => {
  const {
    user: { pusher_channel: userPusherChannel },
  } = useSession();
  return usePusherSubscription(userPusherChannel, event, handler);
};

export const usePusherAdminChannelSubscription = (event, handler) => {
  const tenantID = useSelector(selectTenantID);
  const { canAdmin } = useSession();
  const channelName = canAdmin && tenantID ? `private-admin-${tenantID}` : null;
  usePusherSubscription(channelName, event, handler);
};

export const usePusherConfigChannelSubscription = (event, handler) => {
  const { id: tenantID } = useCurrentTenantInfo();
  const channelName = tenantID ? `private-config-${tenantID}` : null;
  return usePusherSubscription(channelName, event, handler);
};

export const usePusherSessionSubscriptions = () => {
  const {
    logout,
    query: { invalidate, setData },
  } = useSession();

  const pullSession = useCallback(() => invalidate("Pusher:pull-session"), [invalidate]);

  const dispatch = useDispatch();
  const dispatchDownloadReady = useCallback((url) => dispatch(downloadReady({ url })), [dispatch]);
  const dispatchDownloadError = useCallback(
    (error) => dispatch(downloadError({ error })),
    [dispatch],
  );
  const dispatchStateChange = useCallback(
    // eslint-disable-next-line import/namespace
    ({ type, data }) => dispatch(actions["fetch" + capitalize(type)](data)),
    [dispatch],
  );

  usePusherSessionChannelSubcription("pull-session", pullSession);
  usePusherSessionChannelSubcription("session", setData);

  usePusherUserChannelSubscription("logout", logout);
  usePusherUserChannelSubscription("download-ready", dispatchDownloadReady);
  usePusherUserChannelSubscription("download-error", dispatchDownloadError);
  usePusherUserChannelSubscription("state-change", dispatchStateChange);
};
