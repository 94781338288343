import React, { useEffect } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom-v5-compat";

import { FullScreenLoader } from "components/Loader";
import { useAnswerContext } from "containers/withAnswerContext";
import { getGuideType, getRequirementIDs, isInProgress, selectFirstPage } from "reducers/projects";
import { selectRequirementSlugByID } from "reducers/requirements";

const useProjectResumePath = () => {
  const { record: project } = useAnswerContext();
  const { id: projectID } = project;

  return useSelector((state) => {
    if (!isInProgress(project)) {
      // this project is post scoping
      if (Object.keys(project.requirement_applications).length === 1) {
        const requirementID = Object.values(project.requirement_applications)[0].requirement_id;

        return `/projects/${projectID}/application/${requirementID}`;
      }
      return `/projects/${projectID}/apply`;
    }

    if (getGuideType(project) === "direct_application") {
      const requirementID = R.head(getRequirementIDs(project));
      const requirementSlug = selectRequirementSlugByID(state, requirementID);
      return `/projects/${projectID}/direct/${requirementSlug}`;
    }

    const nextPage = selectFirstPage(state, project);
    return `/projects/${projectID}/guide/${nextPage.slug}`;
  });
};

const ResumeProjectRedirect = () => {
  const next = useProjectResumePath();

  const navigate = useNavigate();
  useEffect(() => {
    navigate(next, { replace: true });
  }, [navigate, next]);

  return <FullScreenLoader label="ResumeProjectRedirect" />;
};
export default ResumeProjectRedirect;
