import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom-v5-compat";

import { fetchProject } from "actions";
import { FullScreenLoader } from "components/Loader";
import { useAnswerContext } from "containers/withAnswerContext";
import useAbortableEffect from "hooks/useAbortableEffect";
import useParam from "hooks/useParam";
import { useValidateProject } from "hooks/useValidateProject";
import { useSubmitApplication } from "queries/requirementApplications";
import { getRequirementApplicationByRequirementID } from "reducers/projects";
import { getInvoiceID, wasSubmitted } from "reducers/requirementApplications";
import { selectRequirementBySlug } from "reducers/requirements";
import { isPresent } from "utils/func";

import useIsDirectApply from "../useIsDirectApply";
import useValidationFieldsForRequirement from "./useValidationFieldsForRequirement";

const SubmitApplication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const answerContext = useAnswerContext();
  const { record: project, pending: isPendingSave } = answerContext;

  // HACK: inject the requirement into answerContext as `context`, as if we're inside a mapped answerContext
  const requirementSlug = useParam("requirementSlug");
  const requirement = useSelector((state) => selectRequirementBySlug(state, requirementSlug));
  // const mappedAnswerContext = R.assoc("context", requirement, answerContext);
  // TODO: build the mapped answerContext component for the /projects/:projectID/requirements/:requirementSlug route tree and then the above becomes just:
  // const { record: project, context: requirement } = useAnswerContext()

  // TODO: handle errors?
  //  can handle any 422 error on the submit request in a maybe somewhat clunky way, since it should be pretty unlikely.

  const { requirementOrDirect } = useIsDirectApply();

  const { fieldIDs, contextuallyRequiredFieldIDs } = useValidationFieldsForRequirement(
    project,
    requirement,
  );
  const { mutateAsync: validateProject } = useValidateProject(project.id, {
    fieldIDs,
    contextuallyRequiredFieldIDs,
  });
  const { mutateAsync: submitApplication } = useSubmitApplication();

  const application = getRequirementApplicationByRequirementID(project, requirement.id);
  if (!application) {
    const error = new Error("Application non-existent");
    error.context = { projectID: project.id, requirementSlug, requirementID: requirement.id };
    throw error;
  }

  useAbortableEffect(
    ({ aborted }) =>
      (async () => {
        if (isPendingSave) return;

        const isValid = await validateProject();
        if (!isValid) {
          navigate(`/projects/${project.id}/${requirementOrDirect}/${requirementSlug}`);
          return;
        }

        let app = application;
        if (!wasSubmitted(app)) {
          if (aborted) return;
          const { data: updatedApplication } = await submitApplication(app.id);
          app = updatedApplication;

          // nb(rtlong): This fetchProject triggers refetching by sagas, which also record in-flight network requests in the store. The two routes below are wrapped in Guards which will await those pending requests for the relevant project, before they erroneously redirect based on stale data.
          dispatch(fetchProject({ id: app.project_id }, { from: __filename }));
        }

        const invoiceID = getInvoiceID(app);
        const nextURL = isPresent(invoiceID)
          ? `/projects/${project.id}/requirements/${requirementSlug}/payment/${invoiceID}`
          : `/projects/${project.id}/requirements/${requirementSlug}/summary`;

        navigate(nextURL);
      })(),
    [dispatch, isPendingSave, application, submitApplication, navigate],
  );

  return <FullScreenLoader label="SubmitApplication" />;
};

export default SubmitApplication;
