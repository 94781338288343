import { useCallback, useState } from "react";
import * as R from "ramda";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom-v5-compat";

import { fetchProject } from "actions";
import { useParams } from "hooks/useParam";
import useWaitForReduxStateChange from "hooks/useWaitForReduxStateChange";
import { useUpdateProjectInvoice } from "queries/invoices";
import keys from "queries/keys";
import { selectIsRequestPendingFor } from "reducers/network";
import { reportError } from "services/errorReporter";

const selectIsRequestPendingForProject = (projectID) =>
  R.compose(R.not, (state) => selectIsRequestPendingFor(state, { id: projectID, type: "Project" }));

const useOnNext = ({ paymentType, afterAction, shouldSubmit = false, shouldNavigate = true }) => {
  const [isLoading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const { requirementSlug, projectID, invoiceID } = useParams();
  const invoiceMutation = useUpdateProjectInvoice(projectID, invoiceID);
  const navigate = useNavigate();
  const waitForReduxProjectFetch = useWaitForReduxStateChange(
    selectIsRequestPendingForProject(projectID),
  );
  const dispatch = useDispatch();

  const onNext = useCallback(async () => {
    try {
      setLoading(true);
      await invoiceMutation.mutateAsync({ payment_type: paymentType, submit: shouldSubmit });
      let success = true;
      if (afterAction) success = await afterAction();

      queryClient.invalidateQueries(keys.projects(projectID));

      // this dispatch isn't relevant since we will get a pusher fetch request on state change
      // and the state transition code probably won't be complete anyway at this point (which is weird)
      dispatch(fetchProject({ id: projectID }));
      if (!success) return;

      // this wait seems strange since we display stale data everwhere and the rendering page should be
      // responsible for if it needs to avoid doing that.
      await waitForReduxProjectFetch;

      if (shouldNavigate)
        navigate(`/projects/${projectID}/requirements/${requirementSlug}/summary`);
    } catch (e) {
      reportError(e, { paymentType, params: { invoiceID, projectID, requirementSlug } });
    } finally {
      setLoading(false);
    }
  }, [
    afterAction,
    dispatch,
    invoiceID,
    invoiceMutation,
    navigate,
    paymentType,
    projectID,
    queryClient,
    requirementSlug,
    setLoading,
    shouldNavigate,
    shouldSubmit,
    waitForReduxProjectFetch,
  ]);

  return { onNext, isLoading };
};

export default useOnNext;
