import * as R from "ramda";

import {
  ApplicationACASubmissionCompleted,
  ApplicationACASubmissionError,
  ApplicationACASubmissionStarted,
} from "./ApplicationACASubmission";
import ApplicationApproved from "./ApplicationApproved";
import ApplicationChangesRequired from "./ApplicationChangesRequired";
import ApplicationIssued from "./ApplicationIssued";
import ApplicationNoLongerRequired from "./ApplicationNoLongerRequired";
import ApplicationProcessed from "./ApplicationProcessed";
import ApplicationRejected from "./ApplicationRejected";
import ApplicationRenewalCreated from "./ApplicationRenewalCreated";
import ApplicationRenewalCreatedFrom from "./ApplicationRenewalCreatedFrom";
import ApplicationRenewalStatusChanged from "./ApplicationRenewalStatusChanged";
import ApplicationRevoked from "./ApplicationRevoked";
import ApplicationSubmitted from "./ApplicationSubmitted";
import ChangesetApplied from "./ChangesetApplied";
import InquiryProcessed from "./InquiryProcessed";
import InquirySent from "./InquirySent";
import NoteAdded from "./NoteAdded";
import PaymentSubmitted from "./PaymentSubmitted";
import ProjectBuilt from "./ProjectBuilt";
import ProjectStarted from "./ProjectStarted";
import ProjectUserChanged from "./ProjectUserChanged";
import RefundIssued from "./RefundIssued";
import RequirementApplicationAssigned from "./RequirementApplicationAssigned";
import RequirementApplicationReviewUndone from "./RequirementApplicationReviewUndone";

export const logTypes = {
  changeset_applied: ChangesetApplied,
  inquiry_processed: InquiryProcessed,
  inquiry_sent: InquirySent,
  note_added: NoteAdded,
  payment_submitted: PaymentSubmitted,
  project_started: ProjectStarted,
  project_built: ProjectBuilt,
  project_user_changed: ProjectUserChanged,
  refund_issued: RefundIssued,
  requirement_application_approved: ApplicationApproved,
  requirement_application_assigned: RequirementApplicationAssigned,
  requirement_application_changes_required: ApplicationChangesRequired,
  requirement_application_review_undone: RequirementApplicationReviewUndone,
  requirement_application_issued: ApplicationIssued,
  requirement_application_no_longer_required: ApplicationNoLongerRequired,
  requirement_application_processed: ApplicationProcessed,
  requirement_application_rejected: ApplicationRejected,
  requirement_application_renewal_created: ApplicationRenewalCreated,
  requirement_application_renewal_created_from: ApplicationRenewalCreatedFrom,
  requirement_application_renewal_status_changed: ApplicationRenewalStatusChanged,
  requirement_application_aca_submission_started: ApplicationACASubmissionStarted,
  requirement_application_aca_submission_completed: ApplicationACASubmissionCompleted,
  requirement_application_aca_submission_error: ApplicationACASubmissionError,
  requirement_application_revoked: ApplicationRevoked,
  requirement_application_submitted: ApplicationSubmitted,
};

const lookup = ({ log_type }) => logTypes[log_type];

export default lookup;

export const hasRenderableEntries = R.any(lookup);
