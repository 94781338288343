import React from "react";
import { Link } from "react-router-dom";

import Icon from "components/Icon";
import { ButtonDiv, MenuDiv } from "components/accessibility/Div";

import styles from "./AdminTools.scss";

const AdminTools = () => {
  const Label = (
    <ButtonDiv className={styles.label}>
      <div>
        <Icon fw icon="folder-plus" />
        <span>Admin Tools</span>
      </div>

      <Icon icon="caret-down" size="sm" />
    </ButtonDiv>
  );

  return (
    <MenuDiv className={styles.container} label={Label}>
      <ul className={styles.dropdown}>
        <li>
          <Link to="/projects/build">
            <Icon fw icon="folder-plus" />
            <span>Create new project</span>
          </Link>
        </li>
        <li>
          <Link to="/admin/projects">
            <Icon fw icon="folder" />
            <span>Projects</span>
          </Link>
        </li>
        <li>
          <Link to="/admin/applications">
            <Icon fw icon="file-alt" />
            <span>Applications</span>
          </Link>
        </li>
        <li>
          <Link to="/admin/messages">
            <Icon fw icon="comments" />
            <span>Messages</span>
          </Link>
        </li>
        <li>
          <Link to="/admin/transactions">
            <Icon fw icon="square-dollar" />
            <span>Transactions</span>
          </Link>
        </li>
        <li>
          <Link to="/admin/analytics">
            <Icon fw icon="chart-column" />
            <span>Analytics</span>
          </Link>
        </li>
      </ul>
    </MenuDiv>
  );
};

export default AdminTools;
