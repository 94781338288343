import * as R from "ramda";
import { useParams as useParamsV5 } from "react-router-dom";
import { useParams as useParamsV6 } from "react-router-dom-v5-compat";

export const useParams = () => {
  const params = R.mergeLeft(useParamsV6(), useParamsV5());
  return params;
};

export default (name, cast = R.identity) => {
  const params = useParams();
  const param = R.prop(name, params);
  return param ? cast(param) : null;
};

export const useProjectIDParam = () => useParams().projectID;
