import React from "react";
import classnames from "classnames";

import Icon from "components/Icon";
import { maybeMoney } from "utils/format";

import styles from "./FeeChange.scss";

const FeeChange = ({ change }) => {
  let changeDirection;
  let icon;

  if (change === 0) {
    changeDirection = "none";
  } else if (change > 0) {
    changeDirection = "up";
    icon = "plus-circle";
  } else {
    changeDirection = "down";
    icon = "minus-circle";
  }
  return (
    <div className={classnames(styles.feeChange, styles[changeDirection])}>
      <Icon faStyle="solid" icon={icon} />
      <span>{maybeMoney(change)}</span>
    </div>
  );
};

export default FeeChange;
