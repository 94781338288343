import { useCallback, useDebugValue } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom-v5-compat";

import { fetchProject } from "actions";
import { useAnswerContext } from "containers/withAnswerContext";
import { selectNextPage } from "reducers/projects";
import routes from "utils/routes";
import { urlFrom } from "utils/urls";

import { useValidateProject } from "./useValidateProject";

const maybeFullPathFromRelative = (relativePath) =>
  relativePath ? urlFrom(relativePath).pathname : null;

const skipToHandoffPage = (project, nextPage, nextAction) => {
  // if (nextPage?.reason === "prohibited") return false;
  if (nextAction === "apply" && nextPage.slug === "fees") return true;
  if (nextPage.slug === "summary")
    // && !R.pathEq(["guide", "guide_type"], "zoning", project))
    return true;
  return false;
};

/**
 * useOnNext
 * @param {Object} params
 * @returns {onNext}
 */
export function useOnNext({
  page: currentPage,
  nextPage,
  nextPageType,
  fieldIDs = [],
  contextuallyRequiredFieldIDs = [],
}) {
  const navigate = useNavigate();
  const { record: project, nextAction } = useAnswerContext();

  const { mutateAsync: validateProject } = useValidateProject(project.id, {
    page: currentPage,
    fieldIDs,
    contextuallyRequiredFieldIDs,
  });

  const nextPath = useSelector((state) => {
    if (nextPageType === "CalculatedGuidePage") {
      nextPage = selectNextPage(state, project, currentPage.slug);
    }
    switch (nextPageType) {
      case "CalculatedGuidePage":
      case "GuidePage": {
        if (skipToHandoffPage(project, nextPage, nextAction)) {
          return routes.projectHandoff(project.id);
        }
        return nextPage.slug;
      }
      case "ProhibitedSummaryPage": {
        return nextPage.slug;
      }
      case "PATH":
        return nextPage;

      case "NONE":
        return false;

      default: {
        console.error(`nextPageType:${nextPageType} not handled by onNext`);
        return false;
      }
    }
  });

  useDebugValue({
    nextPath,
  });

  return useCallback(async () => {
    const valid = await validateProject();
    if (!valid) return;
    if (!nextPath) return;
    navigate(maybeFullPathFromRelative(nextPath));
  }, [validateProject, navigate, nextPath]);
}

export function useOnBack({ previousPage: { slug } = {} } = {}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { record: project } = useAnswerContext();
  return useCallback(() => {
    dispatch(fetchProject({ id: project.id }, { from: "useOnBack" }));
    navigate(maybeFullPathFromRelative(slug) || -1);
  }, [navigate, slug, dispatch, project.id]);
}
