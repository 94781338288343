import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { childrenPropType } from "utils/sharedPropTypes";

export default (style, Component = "div", defaultProps = {}) => {
  const StyledComponent = ({ children, classNames = [], ...props }) => (
    <Component className={classnames(style, classNames)} {...defaultProps} {...props}>
      {children}
    </Component>
  );

  StyledComponent.propTypes = {
    children: childrenPropType,
    classNames: PropTypes.arrayOf(PropTypes.string),
  };

  return StyledComponent;
};
