import React, { useContext, useMemo, useState } from "react";

const InspectorContext = React.createContext({});
export const useInspectorContext = () => useContext(InspectorContext);

const InspectorProvider = ({ children }) => {
  const [inspectedEntity, setInspectedEntity] = useState();

  const context = useMemo(
    () => ({
      inspectedEntity,
      inspecting: true,
      onSelect: setInspectedEntity,
    }),
    [inspectedEntity, setInspectedEntity],
  );

  return <InspectorContext.Provider value={context}>{children}</InspectorContext.Provider>;
};

export default InspectorProvider;
