import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { GoogleMap, Marker, Polyline, withGoogleMap } from "react-google-maps";

import withProps from "components/utilities/withProps";
import { withGoogleMapsUrl } from "services/withGoogleMapsUrl";
import { getAddress } from "utils/location";
import { addressToPosition } from "utils/map";
import { getPolyline } from "utils/route";
import { eventLocationType, eventRoutesType } from "utils/sharedPropTypes";

import styles from "./EventMap.scss";

const MAP_HEIGHT = "18.2em";

const Map = R.compose(
  withProps({
    containerElement: <div style={{ height: MAP_HEIGHT, pointerEvents: "none" }} />,
    mapElement: <div style={{ height: MAP_HEIGHT }} />,
    defaultOptions: {
      disableDefaultUI: true,
      zoomControl: false,
      scrollwheel: false,
      clickableIcons: false,
      fullscreenControl: false,
    },
  }),
  withGoogleMapsUrl({ libraries: "geometry,drawing" }),
  withGoogleMap,
)((props) => (
  <GoogleMap ref={props.setRef} {...props}>
    {props.children}
  </GoogleMap>
));

const DASHED_LINE = {
  path: "M 0,-1 0,1",
  strokeOpacity: 1,
  strokeColor: "#666",
};

const EventMap = ({ routes, locations, center, zoom, setInitialZoom }) => (
  <div className={styles.container}>
    <Map setRef={setInitialZoom} center={center} zoom={zoom}>
      {R.map(
        (loc) => (
          <Marker
            key={`location-${getAddress(loc)}`}
            position={addressToPosition(getAddress(loc))}
          />
        ),
        locations,
      )}

      {R.addIndex(R.map)((route, i) => {
        const path = window.google.maps.geometry.encoding.decodePath(getPolyline(route));
        return (
          <div key={`route-${i}`}>
            <Polyline
              path={path}
              options={{
                strokeOpacity: 0,
                icons: [
                  {
                    icon: DASHED_LINE,
                    offset: "0",
                    repeat: "10px",
                  },
                ],
              }}
            />
          </div>
        );
      }, routes)}
    </Map>
  </div>
);

EventMap.propTypes = {
  routes: PropTypes.arrayOf(eventRoutesType).isRequired,
  locations: PropTypes.arrayOf(eventLocationType).isRequired,
  center: PropTypes.object,
  zoom: PropTypes.number,
  setInitialZoom: PropTypes.func.isRequired,
};
export default EventMap;
