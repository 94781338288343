import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { childrenPropType } from "utils/sharedPropTypes";
import { administrativeBackgroundColor } from "utils/sharedStyles";

import styles from "./SkeuomorphicPaper.scss";

const SkeuomorphicPaper = ({
  children,
  className = [],
  pageFoldBgColor = administrativeBackgroundColor,
  size = "large",
}) => (
  <div className={classnames(styles[size], styles.container, ...Array.of(className))}>
    <div className={classnames(styles[size], styles.paper)}>
      <div
        className={classnames(styles[size], styles.corner)}
        style={{ borderRightColor: pageFoldBgColor, borderTopColor: pageFoldBgColor }}
      />
      {children}
    </div>
  </div>
);

SkeuomorphicPaper.propTypes = {
  children: childrenPropType,
  pageFoldBgColor: PropTypes.string,
  size: PropTypes.string,
};

export default SkeuomorphicPaper;
