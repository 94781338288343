import React from "react";
import PropTypes from "prop-types";

import Markdown from "components/Markdown";
import Text from "containers/Text";

import styles from "./Step.scss";

const Step = ({ header, headerKey, instructions, instructionsKey, step, steps = null }) => (
  <div className={styles.container}>
    <div className={styles.heading}>
      <div className={styles.step}>
        {steps != null && (
          <>
            <Text t="guides.header.step" /> {step}/{steps}
          </>
        )}
      </div>
      <h1>{header || (headerKey && <Text t={headerKey} />)}</h1>
    </div>
    <div className={styles.seperator} />
    {instructions ? (
      <div className={styles.instructions}>
        <Markdown source={instructions} />
      </div>
    ) : (
      instructionsKey && <Text t={instructionsKey} />
    )}
  </div>
);
Step.propTypes = {
  header: PropTypes.string,
  headerKey: PropTypes.string,
  instructions: PropTypes.string,
  instructionsKey: PropTypes.string,
  step: PropTypes.number.isRequired,
  steps: PropTypes.number,
};

export default Step;
