import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Loader from "components/Loader";
import Text from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import { useIsInAdminSection } from "contexts/applicationSection";
import { useRequirementCosts } from "queries/costs";
import { useProjectRequirementTransactions } from "queries/transactions";
import { getFeesForRequirement } from "reducers/fees";
import { selectRequirementFees } from "reducers/projects";
import { selectRequirementByID } from "reducers/requirements";
import {
  getCharge,
  getPaidTransactions,
  getPaymentMethodSummary,
  getTotalPaid,
} from "reducers/transactions";
import { getAmount, getCostForFeeID, sumCosts } from "selectors/costs";
import { formatMoneyFromCents } from "utils/format";
import { transactionPropType } from "utils/sharedPropTypes";
import { formatShortDateFdSlash } from "utils/time";

import styles from "./Fees.scss";

const MaybeMoney = ({ isLoading, cents }) =>
  isLoading ? <Loader /> : formatMoneyFromCents(cents, true);
MaybeMoney.propTypes = {
  isLoading: PropTypes.bool,
  cents: PropTypes.number,
};

export const FeeLineItem = ({ name, cost, isLoading = false }) => (
  <div className={styles.feeContainer} data-fee-line-item={name}>
    <div className={styles.feeName}>{name}</div>
    <div className={styles.cost} data-line-item-amount>
      <MaybeMoney isLoading={isLoading} cents={cost} />
    </div>
  </div>
);
FeeLineItem.propTypes = {
  name: PropTypes.string.isRequired,
  cost: PropTypes.number,
  isLoading: PropTypes.bool,
};

export const TotalLineItem = ({ cost, isLoading = false }) => (
  <div className={styles.totalContainer}>
    <div className={styles.totalLabel}>
      <Text t="projects.requirement.payment.total" />
    </div>
    <div className={styles.total} data-fees-total>
      <MaybeMoney isLoading={isLoading} cents={cost} />
    </div>
  </div>
);
TotalLineItem.propTypes = {
  cost: PropTypes.number,
  isLoading: PropTypes.bool,
};

const useRequirementFees = (record, requirementID) => {
  const requirement = useSelector((state) => selectRequirementByID(state, requirementID));
  const requirementFees = useSelector((state) => selectRequirementFees(state, record));

  return getFeesForRequirement(requirementFees, requirement);
};

const Fees = ({ requirementID, children }) => {
  const { record } = useAnswerContext();
  const fees = useRequirementFees(record, requirementID);
  const { data: costs = [], isLoading } = useRequirementCosts(record.id, requirementID);

  const total = sumCosts(costs);

  return (
    <div>
      {fees.map((fee) => {
        const cost = getCostForFeeID(costs, fee.id);
        return (
          <FeeLineItem
            key={`fee-${fee.id}`}
            name={fee.name}
            cost={getAmount(cost)}
            isLoading={isLoading}
          />
        );
      })}
      <TotalLineItem cost={total} isLoading={isLoading} />
      <PaidTransactions projectID={record.id} requirementID={requirementID} total={total} />
      {children}
    </div>
  );
};

const PaidTransactions = ({ projectID, requirementID, total }) => {
  const { data: transactions = [] } = useProjectRequirementTransactions(projectID, requirementID, {
    select: getPaidTransactions,
  });

  if (R.isEmpty(transactions)) {
    return null;
  }

  const totalDue = R.pipe(R.map(getTotalPaid), R.append(total), R.sum)(transactions);

  return (
    <div className={styles.transactions} data-transactions>
      {R.map(
        (transaction) => (
          <div key={`transaction-${transaction.id}`}>
            <TransactionCharge transaction={transaction} projectID={projectID} />
            {R.map(
              (refund) => (
                <RefundLineItem
                  key={`refund-${refund.id}`}
                  transaction={transaction}
                  projectID={projectID}
                  refund={refund}
                />
              ),
              R.prop("refunds", transaction),
            )}
          </div>
        ),
        transactions,
      )}
      <div className={styles.totalContainer}>
        <div className={styles.totalLabel}>
          <Text t="projects.requirement.payment.due" />
        </div>
        <div className={styles.total} data-total-due>
          {formatMoneyFromCents(totalDue, true)}
        </div>
      </div>
    </div>
  );
};
PaidTransactions.propTypes = {
  total: PropTypes.number,
};

export const ChargeText = ({ transaction, charge }) => (
  <Text
    t="projects.requirement.payment.total_paid"
    paymentMethod={getPaymentMethodSummary(transaction)}
    createdAt={formatShortDateFdSlash(charge.time)}
  />
);

const TransactionCharge = ({ transaction, projectID }) => {
  const charge = getCharge(transaction);
  const isAdmin = useIsInAdminSection();
  if (!charge) return null;

  return (
    <div
      className={classnames(styles.feeContainer, styles.transactionDetail)}
      data-transaction-charge
    >
      <div className={styles.transactionDetailName} data-charge-text>
        {isAdmin ? (
          <Link to={`/admin/projects/${projectID}/transactions/${transaction.id}`}>
            Paid on {formatShortDateFdSlash(charge.time)}:
          </Link>
        ) : (
          <ChargeText transaction={transaction} charge={charge} />
        )}
      </div>
      <div className={styles.transactionDetailCost} data-transaction-charge-amount>
        -{formatMoneyFromCents(Math.abs(charge.amount), true)}
      </div>
    </div>
  );
};
TransactionCharge.propTypes = {
  transaction: transactionPropType.isRequired,
};

const RefundLineItem = ({ transaction, refund, projectID }) => {
  const isAdmin = useIsInAdminSection();
  const refundText = getCharge(transaction) ? (
    <Text
      t="projects.requirement.payment.refund_issued"
      paymentMethod={getPaymentMethodSummary(transaction)}
      createdAt={formatShortDateFdSlash(refund.time)}
    />
  ) : (
    <Text
      t="projects.requirement.payment.refund_issued_auto"
      createdAt={formatShortDateFdSlash(refund.time)}
    />
  );

  return (
    <div className={classnames(styles.feeContainer, styles.transactionDetail)} data-refund>
      <div className={styles.transactionDetailName}>
        {isAdmin ? (
          <Link to={`/admin/projects/${projectID}/transactions/${transaction.id}`}>
            {refundText}
          </Link>
        ) : (
          refundText
        )}
      </div>
      <div className={styles.transactionDetailCost} data-refund-amount>
        {formatMoneyFromCents(refund.amount, true)}
      </div>
    </div>
  );
};
RefundLineItem.propTypes = {
  transaction: transactionPropType.isRequired,
  refund: PropTypes.shape({}),
};

export default Fees;
