import React, { useEffect, useState } from "react";
import classnames from "classnames";
import mailcheck from "mailcheck";
import PropTypes from "prop-types";

import { TextButton } from "components/Button";
import Text from "containers/Text";

import styles from "./DidYouMean.scss";

const useEmailSuggestion = (givenAddress) => {
  const [suggestion, setSuggestion] = useState(null);
  useEffect(() => {
    mailcheck.run({
      email: givenAddress,
      suggested: (suggestion) => setSuggestion(suggestion.full),
      empty: () => setSuggestion(null),
    });
  }, [givenAddress, setSuggestion]);

  return suggestion;
};

const DidYouMean = ({ email, onClick, className }) => {
  const suggestion = useEmailSuggestion(email);
  if (!suggestion) {
    return null;
  }

  return (
    <div className={classnames(styles.container, className)}>
      <Text t="did_you_mean" />:{" "}
      <TextButton onClick={() => onClick(suggestion)}>{suggestion}</TextButton>?
    </div>
  );
};

DidYouMean.propTypes = {
  email: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default DidYouMean;
