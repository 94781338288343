import React from "react";
import classnames from "classnames";

import Icon from "components/Icon";

const Layout = ({ icon, styles, containerStyles = [], additionalContent, children }) => (
  <div data-log-entry>
    <div className={classnames(styles.container, ...containerStyles)}>
      <div className={styles.header}>
        <div className={styles.icon}>
          <Icon icon={icon} size="lg" />
        </div>
        <div className={styles.title}>{children}</div>
      </div>
      {additionalContent && <div className={styles.content}>{additionalContent}</div>}
    </div>
  </div>
);

export default Layout;
