import resourceOperations from "./resourceOperations";
import { Cost } from "types/entities";

export const useRequirementCosts = (
  projectID: number,
  requirementID: number,
  opts = {}
) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/costs`,
    baseKey: "costs",
  }).useRead<Cost[]>(requirementID, { staleTime: 0, ...opts });
