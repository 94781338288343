// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-Forbidden__buttons--WBPZj {\n  display: flex;\n  gap: 1em;\n  justify-content: center;\n}", "",{"version":3,"sources":["webpack://./ui/components/Forbidden.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,uBAAA;AACF","sourcesContent":[".buttons{\n  display: flex;\n  gap: 1em;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "ui-components-Forbidden__buttons--WBPZj"
};
export default ___CSS_LOADER_EXPORT___;
