import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { branch, renderComponent } from "recompose";

import { TextButton } from "components/Button";
import { ButtonDiv } from "components/accessibility/Div";
import { RadioButton } from "components/project_form/RadioButtonField";
import ModalBrowser from "components/project_form/use_code/ModalBrowser";
import withActiveToggle from "components/utilities/withActiveToggle";

import styles from "./BrowserToggle.scss";

export const UseCodeToggleComponent = ({ value, activate }) => {
  const useName = R.prop("full_name", value || {});

  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        If you don’t see a relevant option, please{" "}
        <TextButton className={styles.browseLink} onClick={activate} data-use-code-button>
          browse the use code list.
        </TextButton>
        {useName ? (
          <ButtonDiv onClick={activate} className={styles.selectedUse}>
            <RadioButton selected />
            <span className={styles.selectedUseName}>{useName}</span>
          </ButtonDiv>
        ) : null}
      </div>
    </div>
  );
};

UseCodeToggleComponent.propTypes = {
  activate: PropTypes.func.isRequired,
  value: PropTypes.object,
};

export default R.compose(
  withActiveToggle,
  branch(({ active }) => active, renderComponent(ModalBrowser)),
)(UseCodeToggleComponent);
