import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import Icon from "components/Icon";
import { ButtonDiv } from "components/accessibility/Div";
import { useAnswerContext } from "containers/withAnswerContext";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { useSession } from "contexts/session";
import { isInProgress } from "reducers/projects";
import routes from "utils/routes";

import styles from "./SendMessageBadge.scss";

const SendMessageBadge = ({ application }) => {
  const tenant = useCurrentTenantInfo();
  const { record: project } = useAnswerContext();
  const navigate = useNavigate();
  const onClick = useCallback(
    () =>
      navigate(routes.projectMessages(project.id), {
        state: { linkApplication: application?.id },
      }),
    [project.id, navigate, application?.id],
  );

  const { isLoggedIn } = useSession();
  if (!isLoggedIn) return null;
  if (isInProgress(project)) return null;
  if (tenant.messaging_feature === "disabled") return null;

  return (
    <ButtonDiv className={styles.container} onClick={onClick} data-contact-link>
      <div className={styles.textContainer}>
        <div className={styles.title}>Have a question?</div>
        <div>Send a message to staff.</div>
      </div>
      <div className={styles.iconContainer}>
        <Icon icon="message-question" />
      </div>
    </ButtonDiv>
  );
};

export default SendMessageBadge;
