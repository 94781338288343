import * as R from "ramda";
import { connect } from "react-redux";

import ZoningMap from "components/guides/summary/ZoningMap";
import requireProps from "containers/requireProps";
import { withAnswerContextConsumer } from "containers/withAnswerContext";
import { getAnswerForField } from "reducers/answerContexts";
import { selectFieldBySlug } from "reducers/fields";

const mapStateToProps = (state, { answerContext: { record } }) => {
  const addressField = selectFieldBySlug(state, "address");
  const address = getAnswerForField(record, addressField) || {};

  return { address };
};

export default R.compose(
  withAnswerContextConsumer,
  connect(mapStateToProps),
  requireProps(["address"]),
)(ZoningMap);
