import { AxiosError } from "axios";
import { useVersionedClientRef } from "contexts/versionedClient";
import * as R from "ramda";
import {
  UseQueryOptions,
  UseMutationOptions,
  UseQueryResult,
  UseMutationResult,
  useQuery,
  useMutation,
  useQueryClient,
  QueryKey,
} from "react-query";
import ocClient from "services/ocClient";
import { errorHandler } from "utils/form";
import { compact, arrayWrap } from "utils/func";

export type ChangeQueryResultData<
  T extends UseQueryResult<any, any>,
  NewData
> = T extends UseQueryResult<any, infer E> ? UseQueryResult<NewData, E> : never;

type Path = string | number | (string | number)[];

interface Options {
  basePath?: string;
  baseKey: QueryKey;
  dataKey?: string;
  versioned?: boolean;
}

interface ResourceOptions {
  basePath?: string;
  dataKey?: string;
}

export interface ReadOptions
  extends Omit<UseQueryOptions, "queryKey" | "queryFn"> {
  basePath?: string;
  key?: QueryKey;
  params?: Record<string, any>;
}

export interface UpdateOptions extends UseMutationOptions, ResourceOptions {
  transformData?: (data: any) => any;
  setError?: (error: any) => void;
  onSuccess?: (data: any, variables?: any, context?: any) => void;

  invalidates?: QueryKey;
}

interface CreateOptions extends UseMutationOptions, ResourceOptions {
  onSuccess?: (data: any, variables?: any, context?: any) => void;
}

export interface ResourceOperations {
  useCreate: <TData = unknown>(
    path: Path,
    opts?: CreateOptions,
    cb?: (data: TData) => TData
  ) => UseMutationResult<TData>;

  useRead: <TData = unknown>(
    path: Path,
    opts?: ReadOptions,
    reshapeData?: (data: any) => TData
  ) => UseQueryResult<TData, AxiosError>;

  useUpdate: <TData = unknown>(
    path: Path,
    opts?: UpdateOptions,
    cb?: (data: TData) => TData
  ) => UseMutationResult<TData>;

  useDestroy: (opts?: UpdateOptions) => UseMutationResult<any>;

  queryKey: (key?: Path) => QueryKey;
  pathFor: (path: Path, basePath?: string) => string;
}

type VersionedOptions = {
  meta?: {
    versionedEndpoint?: boolean;
    [key: string]: any;
  };
  [key: string]: any;
};

export default function resourceOperations({
  basePath: defaultBasePath,
  baseKey,
  dataKey: defaultDataKey,
  versioned = true,
}: Options): ResourceOperations {
  const pathFor: ResourceOperations["pathFor"] = (path, basePath) =>
    compact([basePath || defaultBasePath, ...arrayWrap(path)]).join("/");

  const queryKey: ResourceOperations["queryKey"] = (key = []) =>
    compact([...arrayWrap(baseKey), ...arrayWrap(key)]);

  const annotateVersioned: (options: VersionedOptions) => VersionedOptions =
    versioned
      ? R.over(
          R.lensProp("meta"),
          R.compose(R.assoc("versionedEndpoint", true), R.defaultTo({}))
        )
      : R.identity;

  const useClient = versioned
    ? useVersionedClientRef
    : () => ({ current: ocClient });

  const shapePostData = (
    data: any,
    { dataKey = defaultDataKey }: { dataKey?: string } = {}
  ): any => (dataKey ? { [dataKey]: data } : data);

  const useCreate: ResourceOperations["useCreate"] = (
    path,
    { onSuccess, dataKey, basePath, ...opts } = {},
    cb = R.identity
  ) => {
    const queryClient = useQueryClient();
    const client = useClient();

    return useMutation({
      mutationFn: async (record) => {
        const postData = shapePostData(record, { dataKey });

        const { data } = await client.current.post(
          pathFor(path, basePath),
          postData
        );
        return cb(data);
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(queryKey());
        if (onSuccess) {
          onSuccess(data, variables, context);
        }
      },
      ...annotateVersioned(opts),
    }) as UseMutationResult<ReturnType<typeof cb>>;
  };

  const useRead: ResourceOperations["useRead"] = (
    path,
    { basePath, params, ...opts } = {},
    reshapeData = R.identity
  ) => {
    const client = useClient();

    return useQuery<any, AxiosError, any>({
      queryKey: opts.key || queryKey(path),
      queryFn: async () => {
        const getParams = params ? { params } : {};
        const { data } = await client.current.get(
          pathFor(path, basePath),
          getParams
        );
        return reshapeData(data);
      },
      ...annotateVersioned(opts),
    });
  };

  const useUpdate: ResourceOperations["useUpdate"] = (
    path,
    {
      basePath,
      transformData = R.identity,
      onSuccess,
      invalidates,
      setError,
      ...opts
    } = {},
    cb = R.identity
  ) => {
    const queryClient = useQueryClient();
    const client = useClient();

    return useMutation({
      mutationFn: async (record) => {
        const postData = shapePostData(
          transformData ? transformData(record) : record,
          { dataKey: opts.dataKey }
        );
        const { data } = await client.current.put(
          pathFor(path, basePath),
          postData
        );
        return cb(data);
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(queryKey());
        queryClient.setQueryData(queryKey(path), data);
        if (invalidates) {
          queryClient.invalidateQueries(invalidates);
        }
        if (onSuccess) onSuccess(data, variables, context);
      },
      onError: setError
        ? (error, variables, context) =>
            errorHandler(setError)(error, variables, context)
        : undefined,
      ...annotateVersioned(opts),
    }) as UseMutationResult<ReturnType<typeof cb>>;
  };

  const useDestroy: ResourceOperations["useDestroy"] = ({
    basePath,
    onSuccess,
    ...opts
  } = {}) => {
    const queryClient = useQueryClient();
    const client = useVersionedClientRef();

    return useMutation<any, any, any>({
      mutationFn: async (id: string) => {
        await client.current.delete(pathFor(id, basePath));
        return id;
      },
      onSuccess: (data) => {
        queryClient.removeQueries(queryKey());
        if (onSuccess) onSuccess(data);
      },
      ...annotateVersioned(opts),
    }) as UseMutationResult<string>;
  };

  return { useCreate, useRead, useUpdate, useDestroy, queryKey, pathFor };
}
