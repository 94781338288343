import * as R from "ramda";
import { connect } from "react-redux";

import { downloadIssuedRequirementPDF, downloadRequirementApplicationPDF } from "actions";
import HelpBar from "components/guides/HelpBar";
import { withAnswerContextConsumer } from "containers/withAnswerContext";
import { getExternalHelpURL, selectGuideByID } from "reducers/guides";
import { selectMunicipalityType } from "reducers/tenant";
import { selectDownloadInProgress } from "reducers/ui";

const mapStateToProps = (state, { answerContext: { record } }) => {
  const guide = selectGuideByID(state, R.prop("guide_id", record));
  return {
    externalHelpURL: getExternalHelpURL(guide),
    municipalityType: selectMunicipalityType(state),
    isDownloadInProgress: selectDownloadInProgress(state),
    showPDFDownload: true,
    showPrint: true,
  };
};

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...stateProps,
  ...ownProps,
  onDownloadPDF: () => {
    const func =
      ownProps.state === "issued"
        ? downloadIssuedRequirementPDF
        : downloadRequirementApplicationPDF;

    dispatch(func({ requirementApplicationID: ownProps.requirementApplicationID }));
  },
});

export default R.compose(
  withAnswerContextConsumer,
  connect(mapStateToProps, null, mergeProps),
)(HelpBar);
