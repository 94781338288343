import resourceOperations from "../resourceOperations";
import * as R from "ramda";
import { Changeset } from "types/adminEntities";

const operation = resourceOperations({
  basePath: "/api/admin/changesets",
  baseKey: ["changesets"],
  dataKey: "changeset",
});

export const useChangeset = (id: number, opts = {}) =>
  operation.useRead<Changeset>(
    id,
    R.assoc("basePath", "/api/changesets", opts)
  );

export const useProjectChangesets = (projectID: number, opts = {}) =>
  operation.useRead<Changeset[]>(`?project_id=${projectID}`, {
    ...opts,
    key: operation.queryKey(["forProject", projectID]),
  });

export const useUpdateChangeset = (id: number, opts = {}) =>
  operation.useUpdate<Changeset>(id, opts);

export const useRevertChangeset = (id: number, opts = {}) =>
  operation.useUpdate<Changeset>([id, "revert"], opts);
