import React from "react";
import classnames from "classnames";

import flex from "sharedStyles/flex.scss";

/**
 * This component is a wrapper around some shared flex styles with
 * props to enable each css class
 */
export default function Flex({
  children,
  direction,
  role,
  fullHeight,
  exactHeightContent,
  minHeightContent,
  className,
  tagName = "div",
  ...props
}) {
  return React.createElement(
    tagName,
    {
      role,
      className: classnames(
        flex.container,
        {
          [flex.column]: direction === "column",
          [flex.row]: direction === "row",
          [flex.fullHeight]: fullHeight,
          [flex.exactHeightContent]: exactHeightContent,
          [flex.minHeightContent]: minHeightContent,
        },
        className,
      ),
      ...props,
    },
    children,
  );
}

export const FlexRow = (props) => <Flex direction="row" {...props} />;
export const FlexColumn = (props) => <Flex direction="column" {...props} />;
