import React from "react";

import { Button } from "components/Button";
import ExternalLink from "components/ExternalLink";
import Logo from "components/Logo";
import InlineModal from "components/shared/InlineModal";
import Text from "containers/Text";

import styles from "./TermsAndConditions.scss";

const TermsAndConditions = ({ onAccept }) => (
  <InlineModal
    data-non-routed-modal-name="TermsAndConditions"
    heading={<Logo linkTo={null} className={styles.logo} />}
  >
    <p className={styles.description}>
      <Text t="pages.terms_and_conditions.message" />{" "}
      <ExternalLink href="https://opencounter.com/terms_of_service">
        <Text t="pages.terms_and_conditions.terms_and_conditions" />
      </ExternalLink>{" "}
      <Text t="pages.terms_and_conditions.and" />{" "}
      <ExternalLink href="https://opencounter.com/privacy">
        <Text t="pages.terms_and_conditions.privacy_policy" />
      </ExternalLink>
      .
    </p>

    <div className={styles.actions}>
      <Button
        data-agree
        onClick={onAccept}
        label={<Text t="pages.terms_and_conditions.agree" />}
        withSpinner
      />
    </div>
  </InlineModal>
);

export default TermsAndConditions;
