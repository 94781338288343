import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import Icon from "components/Icon";
import { EventLocationsInline } from "components/guides/EventLocationsLegacy";
import { getFullAddress } from "utils/address";
import { formatFullAddress } from "utils/format";
import { getAddress } from "utils/location";
import { eventLocationPropType } from "utils/sharedPropTypes";

import styles from "./EventLocationsFieldLegacy.scss";
import UnansweredField from "./UnansweredField";

export const EventLocationsField = EventLocationsInline;

const formatLocation = R.pipe(getAddress, getFullAddress, formatFullAddress);

export const DisabledEventLocationsFieldLegacy = ({ value = [] }) => (
  <div className={styles.container}>
    {R.isEmpty(value) && <UnansweredField />}
    {R.map(
      (location) => (
        <div key={`location-${formatLocation(location)}`} className={styles.locationContainer}>
          <div className={styles.icon}>
            <Icon size="lg" icon="map-marker-alt" />
          </div>
          {formatLocation(location)}
        </div>
      ),
      value,
    )}
  </div>
);
DisabledEventLocationsFieldLegacy.propTypes = {
  value: PropTypes.arrayOf(eventLocationPropType),
};
export const disabled = DisabledEventLocationsFieldLegacy;

export const IssuedEventLocationsFieldLegacy = ({ value = [] }) => {
  if (R.isEmpty(value)) {
    return (
      <div className={styles.container}>
        <UnansweredField />
      </div>
    );
  }

  const location = R.head(value);

  return (
    <div className={styles.issued}>
      <div className={styles.locationContainer}>
        <div className={styles.icon}>
          <Icon size="lg" icon="map-marker-alt" />
        </div>
        {formatLocation(location)}
      </div>
      {R.length(value) > 1 && (
        <div className={styles.locationContainer}>
          <div className={styles.icon}>
            <Icon size="lg" icon="map-marker-alt" />
          </div>
          {R.length(value) - 1} other locations
        </div>
      )}
    </div>
  );
};
IssuedEventLocationsFieldLegacy.propTypes = {
  value: PropTypes.arrayOf(eventLocationPropType),
};
export const issues = IssuedEventLocationsFieldLegacy;

export default EventLocationsField;
