import * as R from "ramda";

import { getGeocoderName } from "reducers/tenant";

import geocoders from "./geocoders";

const buildGeocoder = (tenant, field, projectID = null) => {
  let name = getGeocoderName(tenant);
  if (window.geocoder) {
    console.warn(`Overriding tenant geocoder configuration to use ${window.geocoder} geocoder.`);
    name = window.geocoder;
  }

  const Geocoder = R.pipe(
    R.toPairs,
    R.map(R.adjust(0, R.toLower)),
    R.fromPairs,
    R.prop(name),
  )(geocoders);

  if (!Geocoder) throw new Error(`Geocoder with name ${name} is not configured`);
  return new Geocoder(tenant, field, projectID);
};

export { buildGeocoder };
