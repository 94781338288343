import React from "react";

import Markdown from "components/Markdown";
import { getTermsAndConditions } from "reducers/fields";

import { CheckboxFieldComponent } from "./CheckboxField";
import styles from "./TermsAndConditionsField.scss";
import { checkedInput } from "./behaviors";

export const TermsAndConditionFieldComponent = (props) => {
  const { field } = props;
  return (
    <CheckboxFieldComponent {...props}>
      <div className={styles.container}>
        <Markdown source={getTermsAndConditions(field)} />
      </div>
    </CheckboxFieldComponent>
  );
};

export const DisabledTermsAndConditionFieldComponent = (props) => (
  <TermsAndConditionFieldComponent disabled {...props} />
);
export const disabled = DisabledTermsAndConditionFieldComponent;

export default checkedInput(TermsAndConditionFieldComponent);
