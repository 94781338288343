import * as R from "ramda";

import { parseTime } from "utils/time";

export const getCharge = R.pipe(R.propOr([], "charges"), R.nth(0));

export const getTotalPaid = R.pipe(
  R.props(["charges", "refunds"]),
  R.flatten,
  R.pluck("amount"),
  R.sum,
);

export const getPaidTransactions = R.reject(
  R.allPass([R.propEq("status", "unpaid"), R.propEq("charges", []), R.propEq("refunds", [])]),
);

export const getPaymentMethodSummary = R.prop("payment_method_summary");
export const getCreatedAt = R.pipe(R.prop("created_at"), parseTime);
export const getPaymentType = R.prop("payment_type");
