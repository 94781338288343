import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import queryString from "query-string";
import * as R from "ramda";

import Icon from "components/Icon";
import { childrenPropType, classNamePropType } from "utils/sharedPropTypes";

import styles from "./MailToLink.scss";

const buildMailtoURI = (email, params) =>
  R.compose(
    R.join("?"),
    R.filter(R.complement(R.isEmpty)),
  )([`mailto:${email}`, queryString.stringify(params)]);

const MailToLink = ({ email, subject, body, children, icon = "at", inline = false, className }) => {
  if (!email) return null;
  const uri = buildMailtoURI(email, { subject, body });
  const [username, suffix] = email.split("@");
  return (
    <a
      href={uri}
      target="_blank"
      rel="noreferrer noopener"
      className={classnames(
        styles.mailto,
        { [styles.inline]: inline, [styles.block]: !inline },
        className,
      )}
    >
      {children || (
        <>
          {icon && <Icon icon={icon} />}
          <span className={styles.defaultContentText}>
            {inline ? (
              email
            ) : (
              <>
                <span>{username}</span>
                <wbr />
                <span>@{suffix}</span>
              </>
            )}
          </span>
        </>
      )}
    </a>
  );
};
MailToLink.propTypes = {
  children: childrenPropType,
  className: classNamePropType,
  email: PropTypes.string.isRequired,
  subject: PropTypes.string,
  body: PropTypes.string,
};

export default MailToLink;
