import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { TextButton } from "components/Button";
import ExternalLink from "components/ExternalLink";
import Icon from "components/Icon";
import Text from "containers/Text";

import styles from "./HelpBar.scss";

const HelpBar = ({
  externalHelpURL,
  isDownloadInProgress,
  municipalityType,
  noPadding,
  onDownloadPDF,
  showPDFDownload,
  showPrint,
}) => {
  const linkContent = (
    <>
      <Icon size="lg" icon="comment" />
      <span>
        <Text t="guides.help_bar.need_help" />{" "}
      </span>
      <em>
        <Text t="guides.help_bar.contact" municipalityType={municipalityType.toLowerCase()} />
      </em>
    </>
  );
  return (
    <div className={classnames(styles.wrapper)}>
      <div
        className={classnames(styles.container, {
          [styles.noPadding]: noPadding,
        })}
      >
        {showPrint && (
          <span className={styles.print}>
            <TextButton onClick={window.print}>
              <Icon size="lg" icon="print" />
              <span>
                <Text t="guides.help_bar.print" />
              </span>
            </TextButton>
          </span>
        )}

        {showPDFDownload && (
          <span className={styles.download}>
            <TextButton
              data-download-pdf-button
              isLoading={isDownloadInProgress}
              onClick={onDownloadPDF}
            >
              <Icon size="lg" icon="download" />
              <span>
                <Text t="guides.summary.download_pdf" />
              </span>
            </TextButton>
          </span>
        )}

        <span className={styles.help} data-help>
          {externalHelpURL && <ExternalLink href={externalHelpURL}>{linkContent}</ExternalLink>}
        </span>
      </div>
    </div>
  );
};
HelpBar.propTypes = {
  municipalityType: PropTypes.string.isRequired,
  onDownloadPDF: PropTypes.func,
  isDownloadInProgress: PropTypes.bool,
  showPDFDownload: PropTypes.bool,
  showPrint: PropTypes.bool,
  noPadding: PropTypes.bool,
  externalHelpURL: PropTypes.string,
};

export default HelpBar;
