import * as R from "ramda";

import toGetParams from "../../../utils/objectToQueryString";
import Address from "../address";
import Base from "./base";

export default class Mapbox extends Base {
  suggestionURL(input) {
    const params = {
      types: "address",
      country: "us",
      access_token: this.options.token,
      proximity: this.center.longitude.toFixed(4) + "," + this.center.latitude.toFixed(4),
    };

    if (this.field.constrain_address_to_city) {
      const b = this.bounds;
      params.bbox = [b.min_lon, b.min_lat, b.max_lon, b.max_lat].map((i) => i.toFixed(4)).join(",");
    }

    input = encodeURIComponent(input);
    return `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?${toGetParams(params)}`;
  }

  _removeUnitedStatesSuffix(s) {
    const index = s.indexOf(", United States");
    return index >= 0 ? s.substr(0, index) : s;
  }

  jsonToSuggestions(json) {
    const suggestions = R.map((feature) => {
      const [longitude, latitude] = feature.geometry.coordinates;

      return {
        text: this._removeUnitedStatesSuffix(feature.place_name),
        score: feature.relevance * 100,
        longitude,
        latitude,
      };
    }, json.features);

    return suggestions;
  }

  async suggestionToAddress(s) {
    return new Address(s.latitude, s.longitude, s.text);
  }

  reverseGeocodeURL(lat, lng) {
    const params = toGetParams({
      types: "address",
      country: "us",
      access_token: this.options.token,
    });
    return `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?${params}`;
  }

  jsonToAddress(json, lat, lng) {
    const a = json.features[0];
    if (a) return new Address(lat, lng, this._removeUnitedStatesSuffix(a.place_name));
    return undefined;
  }
}
