import * as R from "ramda";

import { isBlank } from "./func";

export const maybeMoney = (cost, formatter = formatMoney) =>
  Number.isNaN(cost) ? "…" : `$${formatter(cost === null ? 0 : cost)}`;

export const maybeMoneyFromCents = (cost) => maybeMoney(cost, formatMoneyFromCents);

export const formatInteger = (v) => {
  if (isBlank(v)) return null;
  let intStr = v;
  if (typeof intStr === "number") intStr = Math.round(v).toString();

  return intStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Takes a number as string or number type and formats it for display
 * @param {string|number} n number to format
 * @returns {string} formatted number
 */
export function formatNumber(n) {
  if (n == null) return 0;
  if (typeof n === "number") n = Number(n);

  return n.toLocaleString("en-US");
}

export const formatTwoDecimalPlaces = (n) =>
  parseFloat(n)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");

export const formatMoney = (cost = 0, includeDollarSign = false) => {
  const value = formatTwoDecimalPlaces(cost);

  if (includeDollarSign) return cost < 0 ? `-$${value.replace(/^-/, "")}` : `$${value}`;

  return value;
};

export const formatMoneyFromCents = (cost = 0, includeDollarSign = false) =>
  formatMoney(cost / 100, includeDollarSign);

export const formatMetersAsMiles = (meters) => (meters * 0.00062137).toFixed(1);

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const slugToString = (slug) => capitalize(slug.replace(/_/g, " "));
export const humanize = (str) => {
  const frags = str.split("_");
  for (let i = 0; i < frags.length; i += 1) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
};

export const formatFullAddress = (address) => {
  if (R.isNil(address)) return "";
  return address.replace(/, USA$/, "");
};

export const formatAddress = (address) => {
  if (R.isNil(address)) return "";
  if (!address.includes(",")) return address;

  const addressParts = R.split(",", address);
  if (addressParts.length < 3) return address;

  return addressParts.slice(0, addressParts.length - 2).join(", ");
};

export const formatMultiLineAddress = (address) => {
  if (!address) return [""];
  const firstCommaIndex = address.indexOf(",");
  if (firstCommaIndex < 0) return [address];

  return R.pipe(
    R.splitAt(firstCommaIndex),
    R.map((line) => line.replace(/^,\s*/, "").trim()),
  )(address);
};

export const formatId = (input) => input.replace(/\W/g, "").trim();

export const formatAsInlineList = (parts) => {
  if (parts.length <= 1) return R.last(parts);
  return [R.init(parts).join(", "), R.last(parts)].join(" and ");
};

export const safeFormatValue = (value) => {
  switch (typeof value) {
    case "string":
    case "number":
      return value;
    default:
      return JSON.stringify(value);
  }
};
export const formatBytes = (bytes, decimals = 0) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / k ** i).toFixed(decimals)) + " " + sizes[i];
};
