// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-guides-EventLocations-Map__container--C2ekz {\n  flex: 1;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./ui/components/guides/EventLocations/Map.scss"],"names":[],"mappings":"AAAA;EACE,OAAA;EACA,YAAA;AACF","sourcesContent":[".container {\n  flex: 1;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ui-components-guides-EventLocations-Map__container--C2ekz"
};
export default ___CSS_LOADER_EXPORT___;
