import React, { useCallback, useMemo } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import { withGetText } from "containers/Text";
import { isBlank } from "utils/func";
import { momentablePropType } from "utils/sharedPropTypes";
import { formatEventTime, parseTime } from "utils/time";

import { BareCalendarField } from "./CalendarField";
import styles from "./DateTime.scss";
import textStyles from "./TextField.scss";
import UnansweredField from "./UnansweredField";

const TimeField = ({ value, onChange, onSave, placeholder }) => {
  const time = parseTime(value);
  const memoKey = time.startOf("day").toArray();
  const times = useMemo(() => {
    const times = [];
    let d = time.startOf("day");
    while (d.dayOfYear() === time.dayOfYear()) {
      times.push({
        value: d.toISOString(),
        label: d.format("hh:mm a"),
      });
      d = d.add(30, "minutes");
    }
    return times;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoKey]);

  const onChange_ = useCallback(
    (event) => {
      onChange(event.target.value);
      onSave();
    },
    [onChange, onSave],
  );
  return (
    <div className={styles.timeFieldContainer}>
      <select required onChange={onChange_} value={value ? time.toISOString() : ""}>
        <option disabled value="">
          {placeholder}
        </option>
        {times.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};
TimeField.propTypes = {
  value: momentablePropType,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const onChangeFn =
  (attrs) =>
  ({ value, onChange, defaultDate }) =>
  (newValue) => {
    let newTime = parseTime(newValue);
    if (!newTime.isValid()) return null;
    if (attrs[0] === "year") newTime = newTime.startOf("day"); // use start of day as default time when day is selected
    if (!value && !defaultDate) return onChange(newTime);

    const time = R.reduce(
      (t, attr) => t.set(attr, newTime.get(attr)),
      parseTime(value || defaultDate),
      attrs,
    );
    return onChange(time);
  };
const onDateChange = onChangeFn(["year", "month", "date"]);
const onTimeChange = onChangeFn(["hour", "minute", "second"]);

const DateTimeField = withGetText(
  ({
    getText,
    reverse,
    value,
    onChange,
    onSave,
    placeholders,
    minDate,
    maxDate,
    defaultDate,
    allowPastDates,
    initiallyVisibleMonth,
  }) => (
    <div className={classnames(styles.container, { [styles.reverse]: reverse })}>
      <div data-date-field>
        <BareCalendarField
          value={value}
          placeholder={R.prop("date", placeholders) || getText("fields.date_time.date")}
          onChange={onDateChange({ value, onChange, defaultDate })}
          initiallyVisibleMonth={initiallyVisibleMonth}
          onSave={onSave}
          minDate={minDate}
          maxDate={maxDate}
          allowPastDates={allowPastDates}
        />
      </div>
      <div data-time-field>
        <TimeField
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onTimeChange({ value, onChange, defaultDate })}
          onSave={onSave}
          placeholder={R.prop("time", placeholders) || getText("fields.date_time.time")}
        />
      </div>
    </div>
  ),
);
DateTimeField.propTypes = {
  value: momentablePropType,
  minDate: momentablePropType,
  maxDate: momentablePropType,
  defaultDate: momentablePropType,
  placeholders: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.string,
  }),
  reverse: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export const DisabledDateTimeField = ({ value }) => (
  <div className={textStyles.disabled}>
    {isBlank(value) ? <UnansweredField /> : formatEventTime(value)}
  </div>
);
DisabledDateTimeField.propTypes = {
  value: momentablePropType,
};
export const disabled = DisabledDateTimeField;

export const IssuedDateTimeField = ({ value }) => (
  <div className={textStyles.issued}>
    {isBlank(value) ? <UnansweredField /> : formatEventTime(value)}
  </div>
);
IssuedDateTimeField.propTypes = {
  value: momentablePropType,
};
export const issued = IssuedDateTimeField;

export default DateTimeField;
