import React, { useContext, useMemo } from "react";
import Color from "color";
import * as R from "ramda";

import styles from "./colors.scss";

const ColorsContext = React.createContext({});

export const useColors = () => useContext(ColorsContext);

const defaultDomWrapper = ({ style, children }) => (
  <div className={styles.wrapper} data-component="ColorsProvider" style={{ ...style }}>
    {children}
  </div>
);

const validateColorWithDefault = R.curry((defaultColor, color) => {
  try {
    const c = Color(color);
    return c.hex();
  } catch {
    return defaultColor;
  }
});

export const ColorsProvider = ({
  primary: primaryRaw,
  secondary: secondaryRaw,
  children,
  domWrapper = defaultDomWrapper,
}) => {
  const [primary, secondary] = useMemo(
    () => R.map(validateColorWithDefault("#99bb99"), [primaryRaw, secondaryRaw]),
    [primaryRaw, secondaryRaw],
  );
  const { style, colors } = useMemo(
    () => ({
      colors: { primary, secondary },
      style: {
        // nb: make sure any vars used here are also defined in ui/sharedStyles/customProperties.css
        "--primary-color": primary,
        "--secondary-color": secondary,
      },
    }),
    [primary, secondary],
  );

  // if domWrapper is falsy, don't add a wrapping container (which means only the React context is provided, not the CSS custom property overrides)
  if (!domWrapper) domWrapper = ({ children }) => children;

  return (
    <ColorsContext.Provider value={colors}>
      {domWrapper({ style, children })}
    </ColorsContext.Provider>
  );
};
