import { useSelector } from "react-redux";

import { useAnswerContext } from "containers/withAnswerContext";
import { selectGuideByID } from "reducers/guides";

const useCurrentGuide = () => {
  const { record } = useAnswerContext();

  return useSelector((state) => selectGuideByID(state, record.guide_id));
};

export default useCurrentGuide;
