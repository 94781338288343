import React from "react";

import Text from "containers/Text";
import { isPayable } from "reducers/requirementApplications";
import { getCallToAction, isAccelaCitizenAccess, isIntegrated } from "reducers/requirements";
import { requirementApplicationPropType, requirementPropType } from "utils/sharedPropTypes";

import styles from "./StartButton.scss";

const StartButton = ({ requirement, requirementApplication }) => {
  if (isIntegrated(requirement)) {
    const startText =
      requirement.type === "Upload" ? (
        <Text t="projects.handoff.upload" />
      ) : (
        <Text t="projects.handoff.apply_online" />
      );

    return (
      <div data-requirement-start className={styles.btn}>
        {isPayable(requirementApplication) ? <Text t="projects.handoff.pay_now" /> : startText}
      </div>
    );
  }

  if (isAccelaCitizenAccess(requirement))
    return (
      <div className={styles.btn}>
        <Text t="projects.handoff.apply_via_aca" />
      </div>
    );

  // new behavior

  const callToActionKey = getCallToAction(requirement);
  return (
    <div className={styles.btn}>
      <Text t={`projects.handoff.call_to_action.${callToActionKey}`} />
    </div>
  );
};
StartButton.propTypes = {
  requirement: requirementPropType.isRequired,
  requirementApplication: requirementApplicationPropType.isRequired,
};

export default StartButton;
