import React from "react";

import Modal from "components/LegacyModal";
import withHandlers from "components/utilities/withHandlers";
import { administrativeBackgroundColor } from "utils/sharedStyles";

import Browser from "./Browser";
import styles from "./ModalBrowser.scss";

const ModalBrowser = ({ deactivate, ...props }) => (
  <Modal
    closeColor={administrativeBackgroundColor}
    onClose={deactivate}
    contentClassName={styles.modal}
  >
    <div className={styles.container}>
      <Browser {...props} />
    </div>
  </Modal>
);

const closeOnSelect = withHandlers({
  onUseCodeSelect: (props) => (useCode) => {
    props.onUseCodeSelect(useCode);
    props.deactivate();
  },
});

export default closeOnSelect(ModalBrowser);
