import React, { useCallback, useMemo } from "react";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import * as R from "ramda";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import Text from "containers/Text";
import { selectStripeKey } from "reducers/tenant";
import { selectLocale } from "reducers/ui";
import { reportError } from "services/errorReporter";

import IntegratedPayment from "./IntegratedPayment";
import styles from "./IntegratedPayment.scss";

const StripePayment = ({ paymentType, setPaymentType }) => {
  const stripe = useStripe();
  const elements = useElements();

  const createToken = async (formMethods) => {
    if (!stripe || !elements) {
      reportError(new Error("Error initializing stripe"));
      throw new Error("We're unable to accept payments right now. Please try again.");
    }

    const cardElement = elements.getElement(CardElement);
    const formValues = formMethods.getValues();
    formValues.name = [formValues.first_name, formValues.last_name].join(" ");

    const { token, error } = await stripe.createToken(
      cardElement,
      R.omit(["token", "first_name", "last_name"], formValues),
    );

    if (token) {
      return token;
    }
    throw new Error(R.propOr("Payment failed", "message", error));
  };

  return (
    <IntegratedPayment
      paymentType={paymentType}
      setPaymentType={setPaymentType}
      createToken={createToken}
      processor="stripe"
    >
      <label htmlFor="cardNumber">
        <Text t="projects.requirement.payment.credit_card.number" />
        <div className={styles.stripeInput}>
          <StripeCardElement />
        </div>
      </label>
    </IntegratedPayment>
  );
};

const StripeCardElement = () => {
  const formMethods = useFormContext();

  const onChangePayment = useCallback(() => {
    formMethods.clearErrors("payment");
  }, [formMethods]);

  return (
    <CardElement
      className="StripePayment"
      onChange={onChangePayment}
      options={{
        hidePostalCode: true,
        style: {
          base: {
            fontSize: "16px",
            fontFamily: "Circular, 'Helvetica Neue', Helvetica, Arial, sans-serif",
          },
        },
      }}
    />
  );
};

const StripePaymentContainer = (props) => {
  const stripePublicKey = useSelector(selectStripeKey);
  const stripePromise = useMemo(() => loadStripe(stripePublicKey), [stripePublicKey]);
  const locale = useSelector(selectLocale);

  if (!stripePublicKey) {
    return null;
  }

  return (
    <Elements stripe={stripePromise} locale={locale}>
      <StripePayment {...props} />
    </Elements>
  );
};

export default StripePaymentContainer;
