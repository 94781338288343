/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, lazy, useEffect } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { useLocation } from "react-router-dom-v5-compat";

import Home from "components/Home";
import Hotkeys from "components/HotKeys";
import { FullScreenLoader } from "components/Loader";
import LogoutPage from "components/LogoutPage";
import { ReduxNetworkErrorHandler } from "components/NetworkErrorBoundary";
import NotFound from "components/NotFound";
import LoginController from "components/auth/LoginController";
import ProjectsRoutes from "components/projects";
import SetupAccelaCitizenAccess from "components/projects/SetupAccelaCitizenAccess";
import {
  RequirePrivilegedUser,
  RequireRealUser,
  RequireUserSession,
} from "components/utilities/guards";
import { RootAnswerContextProvider } from "containers/withAnswerContext";
import ApplicationSectionContext from "contexts/applicationSection";
import { useAuthnState } from "contexts/auth";
import { ColorsProvider } from "contexts/colors";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { useSession } from "contexts/session";
import { usePusherSessionSubscriptions } from "hooks/pusher";
import { selectGuides } from "reducers/guides";
import { getColors } from "reducers/tenant";
import { selectInitialStateLoaded } from "reducers/ui";
import analytics from "services/analytics";

import styles from "./App.scss";
import BuildAProject from "./build-a-project";
import WithHeader from "./withHeader";

const Admin = lazy(() => import(/* webpackChunkName: "admin" */ "components/admin"));
const Config = lazy(() => import(/* webpackChunkName: "config" */ "components/config"));
const MyProjectsPage = lazy(() =>
  import(
    /* webpackChunkName: "projects" */
    /* webpackPrefetch: true */
    "components/ProjectsPage"
  ),
);
const DirectApplicationRedirect = lazy(() =>
  import(
    /* webpackChunkName: "directApplication" */
    /* webpackPrefetch: true */
    "containers/projects/DirectApplicationRedirect"
  ),
);
const Profile = lazy(() =>
  import(
    /* webpackChunkName: "profile" */
    /* webpackPrefetch: true */
    "components/Profile"
  ),
);
const AuthPage = lazy(() =>
  import(
    /* webpackChunkName: "auth" */
    /* webpackPrefetch: true */
    "components/auth/AuthPage"
  ),
);
const PrivacyPolicyPage = lazy(() =>
  import(
    /* webpackChunkName: "privacyPolicy" */
    /* webpackPrefetch: true */
    "./legal/PrivacyPolicyPage"
  ),
);
const TermsOfServicePage = lazy(() =>
  import(
    /* webpackChunkName: "termsOfService" */
    /* webpackPrefetch: true */
    "./legal/TermsOfServicePage"
  ),
);
const DentakuDocs = lazy(() =>
  /* webpackChunkName: "dentakuDocs" */ import("components/config/DentakuDocs"),
);

const AwaitSessionLoaded = ({ children }) => {
  const { isLoaded: hasSession } = useSession();
  if (!hasSession) return <FullScreenLoader label="AwaitSessionLoaded" />;
  return children;
};
const AwaitInitialState = ({ children }) => {
  const initialStateLoaded = useSelector(selectInitialStateLoaded);
  if (!initialStateLoaded) return <FullScreenLoader label="AwaitInitialState" />;
  return children;
};

const useScrollToTopOnLocationChange = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
};

const App = () => {
  useEffect(() => {
    analytics.page(window.location.pathname);
  }, []);
  useScrollToTopOnLocationChange();
  usePusherSessionSubscriptions();

  const guides = useSelector(selectGuides);
  const tenant = useCurrentTenantInfo();
  const colors = getColors(tenant);
  const { loginModalActive, closeLoginModal } = useAuthnState();

  return (
    <div className={styles.wrapper}>
      <ApplicationSectionContext.Provider value="applicant">
        <ColorsProvider primary={colors.primary} secondary={colors.secondary}>
          <Hotkeys>
            <div id="modal" />
            <Switch>
              <Route path="/logout" component={LogoutPage} />
              <Route>
                <ReduxNetworkErrorHandler>
                  <Suspense fallback={<FullScreenLoader label="App/Suspense" />}>
                    {loginModalActive && <LoginController onClose={closeLoginModal} />}
                    <Switch>
                      <Route exact path="/config/dentaku/docs" component={DentakuDocs} />
                      <Route>
                        <AwaitSessionLoaded>
                          <Switch>
                            <Route path="/config">
                              <RequirePrivilegedUser requireConfig>
                                <Config />
                              </RequirePrivilegedUser>
                            </Route>
                            <Route>
                              <AwaitInitialState>
                                <Switch>
                                  <Route exact path="/" component={Home} />

                                  <Route path="/admin">
                                    <RequirePrivilegedUser>
                                      <Admin />
                                    </RequirePrivilegedUser>
                                  </Route>

                                  <Route exact path="/profile">
                                    <RequireRealUser>
                                      <WithHeader>
                                        <Profile />
                                      </WithHeader>
                                    </RequireRealUser>
                                  </Route>

                                  <Route exact path="/projects">
                                    <RequireUserSession>
                                      <WithHeader>
                                        <MyProjectsPage />
                                      </WithHeader>
                                    </RequireUserSession>
                                  </Route>

                                  <Route path="/projects/build" component={BuildAProject} />

                                  <Route path="/projects/:projectID">
                                    <RequireUserSession>
                                      <RootAnswerContextProvider>
                                        <ProjectsRoutes />
                                      </RootAnswerContextProvider>
                                    </RequireUserSession>
                                  </Route>

                                  <Route exact path="/permits/:guideType?/:requirementSlug">
                                    <DirectApplicationRedirect />
                                  </Route>

                                  <Route exact path="/setup_accela_citizen_access">
                                    <SetupAccelaCitizenAccess />
                                  </Route>

                                  <Route
                                    exact
                                    path="/privacy-policy"
                                    component={PrivacyPolicyPage}
                                  />

                                  <Route exact path="/terms" component={TermsOfServicePage} />

                                  <Route
                                    path={[
                                      "/signin",
                                      "/signup",
                                      "/forgot",
                                      "/reset",
                                      "/accept-invite",
                                    ]}
                                    component={AuthPage}
                                  />

                                  {guides.map((guide) => (
                                    <Route
                                      key={`guide-portal-${guide.id}`}
                                      exact
                                      path={`/${(guide.slug || "").replace(/-portal/, "")}`}
                                      render={(props) => <Home fixedGuide={guide} {...props} />}
                                    />
                                  ))}

                                  {guides.map((guide) => (
                                    <Route
                                      key={`guide-${guide.id}`}
                                      exact
                                      path={`/${guide.slug}`}
                                      render={(props) => <Home fixedGuide={guide} {...props} />}
                                    />
                                  ))}

                                  <Route component={NotFound} />
                                </Switch>
                              </AwaitInitialState>
                            </Route>
                          </Switch>
                        </AwaitSessionLoaded>
                      </Route>
                    </Switch>
                  </Suspense>
                </ReduxNetworkErrorHandler>
              </Route>
            </Switch>
          </Hotkeys>
        </ColorsProvider>
      </ApplicationSectionContext.Provider>

      <ReactQueryDevtools
        position="bottom-right"
        toggleButtonProps={{ className: styles.noPrint }}
        initialIsOpen={false}
      />
    </div>
  );
};

export default App;
