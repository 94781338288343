import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useAnswerContext } from "containers/withAnswerContext";
import { selectTenant } from "reducers/tenant";
import { buildGeocoder } from "services/geocoding";

const useGeocoder = (field) => {
  const tenant = useSelector(selectTenant);
  const { record: project } = useAnswerContext();
  const geocoder = useMemo(
    () => buildGeocoder(tenant, field, project.id),
    [tenant, field, project.id],
  );
  return geocoder;
};

export default useGeocoder;
