import { useCallback, useEffect } from "react";
import debounce from "lodash.debounce";

// eslint-disable-next-line react-hooks/exhaustive-deps
const useDebounce = (fn, wait) => useCallback(debounce(fn, wait), [fn, wait]);

export default useDebounce;

// NB: the caller is expected to properly `useCallback` on the function here
export const useDebouncedEffect = (fn, wait) => {
  const callback = useDebounce(fn, wait);

  useEffect(() => {
    callback();

    // the return function here will be called whenever the dependencies
    // to useEffect change, specifically when the identity of `fn` here changes.
    // this will happen fairly often (e.g. when the `value` of the form field
    // changes on onChange) so we have to make sure a *new* debounce is started
    // each time.
    return callback.cancel;
  }, [callback]);

  // return a function that performs the effect immediately.
  return callback.flush;
};
