import React, { useContext } from "react";

export const ServerVariablesContext = React.createContext({});

/**
 * @typedef ServerVariablesContextValue
 * @type {object}
 * @property {string} deliveryStage
 * @property {string} recaptchaSiteKey
 * @property {string} googleMapsKey
 * @property {string} mapboxToken
 */

/**
 * Description
 * @param {any} children
 * @param {ServerVariablesContextValue} value
 * @returns {any}
 */
export const ServerVariablesProvider = ({ children, value }) => (
  <ServerVariablesContext.Provider value={value}>{children}</ServerVariablesContext.Provider>
);

/**
 * Return the current value of the ServerVariablesContext
 * @returns {ServerVariablesContextValue}
 */
export const useServerVariables = () => useContext(ServerVariablesContext);
