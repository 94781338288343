import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: 1000 * 20,
      // trying out relying on webhooks to make data stale
      staleTime: Infinity,
    },
  },
});

const QueryProvider = ({ children }) => (
  <QueryClientProvider client={queryClient} children={children} />
);

export default QueryProvider;
