/* eslint-disable no-undef */
import * as R from "ramda";

import { reportError } from "services/errorReporter";
import toGetParams from "utils/objectToQueryString";

import Address from "../address";
import Base from "./base";

export default class Google extends Base {
  fetchSuggestions(input) {
    const b = this.bounds;
    const options = {
      input,
      offset: 4,
      // types: ["address"],
      componentRestrictions: { country: "us" },
      key: this.options.token,
      bounds: new google.maps.LatLngBounds(
        new google.maps.LatLng(b.min_lat, b.min_lon),
        new google.maps.LatLng(b.max_lat, b.max_lon),
      ),
    };

    if (this.field.constrain_address_to_city) options.strictBounds = true;

    const suggestionPromise = new Promise((resolve, reject) => {
      const service = new google.maps.places.AutocompleteService();

      service.getPlacePredictions(options, (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) reject(status);
        else resolve(this.placeToSuggestions(predictions));
      });
    });

    return suggestionPromise;
  }

  _removeUnitedStatesSuffix(s) {
    let index = s.indexOf(", USA");
    s = index >= 0 ? s.substr(0, index) : s;

    index = s.indexOf(", United States");
    return index >= 0 ? s.substr(0, index) : s;
  }

  placeToSuggestions(predictions) {
    return R.map(
      (prediction) => ({
        text: this._removeUnitedStatesSuffix(prediction.description),
        placeID: prediction.place_id,
        score: 100,
      }),
      predictions,
    );
  }

  async suggestionToAddress(s) {
    const params = {
      place_id: s.placeID,
      key: this.options.token,
    };

    try {
      const { data: results } = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?${toGetParams(params)}`,
      );
      const r = R.head(results);
      const { lat, lng } = R.path(["geometry", "location"], r);

      return new Address(lat, lng, r.formatted_address);
    } catch (e) {
      reportError(e);
      throw e;
    }
  }

  reverseGeocode(lat, lng) {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          if (R.isEmpty(results)) resolve(null);

          resolve({
            latitude: lat,
            longitude: lng,
            text: R.path([0, "formatted_address"], results),
          });
        } else reject(new Error(`Geocoder failed due to: ${status}`));
      });
    });
  }

  jsonToAddress(json, lat, lng) {
    const a = json.results[0];
    if (a) return new Address(lat, lng, this._removeUnitedStatesSuffix(a.formatted_address));
    return null;
  }
}
