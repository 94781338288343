import React from "react";
import * as R from "ramda";

import MaybeApplicationLink from "./MaybeApplicationLink";
import EventLogPropTypes from "./propTypes";

/**
 * ApplicationChangesRequired log entry component
 *
 * @component
 * @param {Object} params
 * @returns <ApplicationChangesRequired />
 */
const ApplicationChangesRequired = ({
  Layout,
  event,
  timestamp,
  applicantContext,
  applicationView,
}) => {
  const by = R.path(["payload", "data", "by"], event);
  const reviewName = R.path(["payload", "data", "review_name"], event);

  return (
    <Layout icon="exchange">
      {applicantContext || applicationView ? (
        <>
          {reviewName}: Changes requested{applicantContext ? " by staff" : by && ` by ${by}`}.
        </>
      ) : (
        <>
          <MaybeApplicationLink event={event} />: Changes requested
          {applicantContext ? " by staff" : by && ` by ${by}`}
        </>
      )}
      {timestamp}
    </Layout>
  );
};
ApplicationChangesRequired.propTypes = EventLogPropTypes;

export default ApplicationChangesRequired;
