import React, { useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import { TextButton } from "components/Button";
import ExternalLink from "components/ExternalLink";
import Icon from "components/Icon";
import LanguagePicker from "components/LanguagePicker";
import TelephoneLink from "components/TelephoneLink";
import { ButtonDiv, PresentationDiv } from "components/accessibility/Div";
import Swipeable from "components/utilities/Swipeable";
import Text from "containers/Text";
import { useAuthnState } from "contexts/auth";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { useIsLoginEnabled } from "contexts/featureFlags";
import { useSession } from "contexts/session";
import useToggleState from "hooks/useToggleState";
import {
  getAddress,
  getIsMultilingual,
  getName,
  getPhone,
  getStateAbbreviation,
  getWebsiteURL,
  getZipcode,
} from "reducers/tenant";
import { toDisplayURL } from "utils/urls";

import styles from "./MobileMenu.scss";

export const MobileMenuSidebar = ({ active, setActive }) => {
  const [moved, setMoved] = useState(0);

  const tenant = useCurrentTenantInfo();
  const {
    isLoggedIn,
    canAdmin: hasAdminAccess,
    // permissions: {},
  } = useSession();

  const loginEnabled = useIsLoginEnabled();
  const { activateLoginModal } = useAuthnState();

  const address = getAddress(tenant);
  const tenantName = getName(tenant);
  const cityLocation = `${tenantName}, ${getStateAbbreviation(tenant)} ${getZipcode(tenant)}`;
  const isMultilingual = getIsMultilingual(tenant);
  const phone = getPhone(tenant);
  const websiteURL = getWebsiteURL(tenant);

  const className = classnames(styles.overlay, {
    [styles.activeOverlay]: active,
  });

  const close = () => {
    setActive(false);
    setTimeout(() => setMoved(0), 1);
  };

  const containerStyle = {};
  if (moved > 0) {
    containerStyle.right = -moved;
  }

  return (
    <Swipeable direction="horizontal" onSwipeRight={close} onMoveRight={setMoved}>
      <PresentationDiv className={className} onClick={close}>
        <div className={styles.container} style={containerStyle}>
          <ButtonDiv className={styles.close} onClick={close}>
            ✕
          </ButtonDiv>
          {isLoggedIn ? (
            <div className={styles.primaryLinks}>
              <Link to="/">
                <Icon fw inverse icon="home" size="lg" />
                <span>
                  <Text t="pages.home.title" />
                </span>
              </Link>
              {hasAdminAccess && (
                <Link to="/admin">
                  <Icon fw inverse icon="tools" size="lg" />
                  <span>
                    <Text t="nav.admin_link" />
                  </span>
                </Link>
              )}
              <Link to="/projects">
                <Icon fw inverse icon="folder-open" size="lg" />
                <span>
                  <Text t="projects.my_projects" />
                </span>
              </Link>
              <Link to="/profile">
                <Icon fw inverse icon="user" size="lg" />
                <span>
                  <Text t="nav.profile_link" />
                </span>
              </Link>
              <Link to="/logout" data-sign-out>
                <Icon fw inverse icon="sign-out" size="lg" />
                <span>
                  <Text t="sign_out" />
                </span>
              </Link>
            </div>
          ) : (
            <div className={styles.primaryLinks}>
              <Link to="/">
                <Icon fw inverse icon="home" size="lg" />
                <span>
                  <Text t="pages.home.title" />
                </span>
              </Link>

              {loginEnabled && (
                <ButtonDiv onClick={activateLoginModal}>
                  <Icon fw inverse icon="sign-in" size="lg" />
                  <Text t="pages.login.link" />
                </ButtonDiv>
              )}
            </div>
          )}

          <div className={styles.spacer} />

          <div className={styles.tenantName}>
            {tenantName} <Text t="city_hall" />
          </div>

          <address className={styles.cityDetailsContainer}>
            <div className={styles.address}>
              <div>
                <Icon inverse icon="map-marker-alt" size="lg" />
              </div>
              <div>
                <div>{address}</div>
                <div>{cityLocation}</div>
              </div>
            </div>
            {phone && (
              <div className={styles.phone}>
                <div>
                  <Icon inverse icon="phone" size="lg" />
                </div>
                <div>
                  <TelephoneLink number={phone} />
                </div>
              </div>
            )}
            {websiteURL && (
              <div className={styles.cityWebsite}>
                <div>
                  <Icon inverse icon="mouse-pointer" size="lg" />
                </div>
                <div>
                  <ExternalLink href={websiteURL}>{toDisplayURL(websiteURL)}</ExternalLink>
                </div>
              </div>
            )}
          </address>

          <div className={styles.spacer} />

          {isMultilingual && (
            <>
              <LanguagePicker />
              <div className={styles.spacer} />
            </>
          )}

          <nav className={classnames(styles.tosLinks, styles.links)}>
            <a href="/terms">
              <Text t="pages.terms.title" />
            </a>
            <a href="/privacy-policy">
              <Text t="pages.privacy.title" />
            </a>
          </nav>

          <div className={styles.footer}>
            <div className={styles.poweredByContainer}>
              <div className={styles.poweredBy} style={containerStyle}>
                <Text t="powered_by" />{" "}
                <ExternalLink
                  className={styles.linksOpencounter}
                  href="https://www.opencounter.com"
                  hideIcon
                >
                  OpenCounter
                </ExternalLink>
              </div>
            </div>
          </div>
        </div>
      </PresentationDiv>
    </Swipeable>
  );
};

const MobileMenuToggle = ({ onClick }) => (
  <span className={styles.toggle}>
    <TextButton label={<Text t="menu" />} onClick={onClick} />
  </span>
);

const MobileMenu = () => {
  const { value: active, set: setActive, toggle: toggleActive } = useToggleState(false);
  return (
    <div>
      <MobileMenuToggle onClick={toggleActive} />
      <MobileMenuSidebar setActive={setActive} active={active} />
    </div>
  );
};

export default MobileMenu;
