/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import Markdown from "components/Markdown";
import SVG from "components/SVG";
import ReadMore from "components/utilities/ReadMore";
import { getEnumLabelByCode } from "reducers/fields";
import {
  classNamePropType,
  errorPropType,
  fieldPropType,
  selectOptionPropType,
} from "utils/sharedPropTypes";

import styles from "./RadioButtonField.scss";
import { DisabledTextField, IssuedTextField } from "./TextField";
import { immediateInput } from "./behaviors";

export const RadioButton = ({ selected }) => (
  <div className={styles.focusOutline}>
    <div
      className={classnames(styles.radioButton, selected ? styles.selected : styles.notSelected)}
    >
      <i className={styles.radioIcon} />
    </div>
  </div>
);

RadioButton.propTypes = {
  selected: PropTypes.bool,
};

export const RadioButtonOption = ({
  fieldKey,
  subLabel,
  selectedValue,
  selectOption: { code, label, description, icon_key },
  onChange,
  asHeading,
  hasSubLabels,
  iconURL,
  error,
  labelId,
  isSelected,
  visibleLines = 3,
  classes = {},
}) => {
  const selected = isSelected ? isSelected() : code === selectedValue;
  const iconKey = icon_key;
  if (!subLabel) subLabel = description;

  return (
    <div className={classnames(styles.container)}>
      <label
        className={classnames(styles.labelWrapper, classes.label, {
          [styles.labelWrapperWithIcon]: iconKey || iconURL,
        })}
      >
        <input
          type="radio"
          className={styles.actualRadio}
          name={fieldKey}
          value={code}
          checked={selected}
          onChange={onChange}
          aria-describedby={labelId}
          aria-selected={selected}
          aria-invalid={!!error}
          aria-labelledby={labelId}
        />
        <RadioButton selected={selected} />
        {iconURL && (
          <div className={styles.iconURL}>
            <img src={iconURL} alt="" />
          </div>
        )}
        {iconKey && (
          <div className={styles.icon}>
            <SVG iconKey={iconKey} alt={iconKey} />
          </div>
        )}
        <div className={styles.label}>
          <div className={hasSubLabels || asHeading ? styles.heading : styles.simple}>{label}</div>
          {subLabel && (
            <div className={styles.subLabel}>
              <ReadMore lines={visibleLines}>
                <Markdown source={subLabel} />
              </ReadMore>
            </div>
          )}
        </div>
        <span className={iconURL || iconKey ? styles.focusBorderCentered : styles.focusBorder} />
      </label>
    </div>
  );
};
RadioButtonOption.propTypes = {
  fieldKey: PropTypes.string,
  subLabel: PropTypes.string,
  selectedValue: PropTypes.any,
  selectOption: selectOptionPropType,
  onChange: PropTypes.func,
  asHeading: PropTypes.bool,
  hasSubLabels: PropTypes.bool,
  iconURL: PropTypes.string,
  labelId: PropTypes.string,
  visibleLines: PropTypes.number,
  error: errorPropType,
  isSelected: PropTypes.func,
  classes: PropTypes.shape({
    label: classNamePropType,
  }),
};

export const RadioButtonFieldComponent = ({
  value,
  field: { enum: fieldEnum = [], key: fieldKey },
  onChange,
  labelId,
  error,
  smaller,
  isSelected,
  classes,
}) => {
  const hasSubLabels = R.any((e) => e.description, fieldEnum);
  const buttons = fieldEnum.map((e) => (
    <RadioButtonOption
      key={e.code}
      fieldKey={fieldKey}
      labelId={labelId}
      selectOption={e}
      selectedValue={value}
      onChange={onChange}
      hasSubLabels={hasSubLabels}
      error={error}
      isSelected={isSelected}
      classes={classes}
    />
  ));

  return (
    <div
      className={classnames(styles.container, {
        [styles.smaller]: smaller,
        [styles.withSublabels]: hasSubLabels,
      })}
    >
      {buttons}
    </div>
  );
};

export const DisabledRadioButtonField = (props) => {
  const { field, value } = props;
  const iconKey = R.prop(
    "icon_key",
    field.enum.find((e) => e.code === value),
  );

  return (
    <div className={styles.disabled}>
      <DisabledTextField {...props} value={value && getEnumLabelByCode(field, value)} />
      {iconKey && (
        <div className={styles.icon}>
          <SVG iconKey={iconKey} alt={iconKey} />
        </div>
      )}
    </div>
  );
};
DisabledRadioButtonField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  field: fieldPropType,
};
export const disabled = DisabledRadioButtonField;

export const IssuedRadioButtonField = (props) => {
  const { field, value } = props;
  return <IssuedTextField {...props} value={value && getEnumLabelByCode(field, value)} />;
};
IssuedRadioButtonField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  field: fieldPropType,
};
export const issued = IssuedRadioButtonField;

export default immediateInput(RadioButtonFieldComponent);
