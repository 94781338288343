import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { AdminBar, AdminBarHandle } from "components/AdminBar";
import Menu from "components/Menu";
import ReadOnlyBadge from "components/projects/ReadOnlyBadge";
import Seal from "containers/Seal";
import Text from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { selectGuideByID } from "reducers/guides";
import { getName } from "reducers/tenant";
import { formatDateStringAsDayFullMonth, now } from "utils/time";

import styles from "./Nav.scss";

const Title = ({ guide }) => {
  const tenant = useCurrentTenantInfo();
  const tenantName = getName(tenant);
  let text;
  if (guide) {
    text = guide.header_title;
  } else {
    text = `${tenantName} Portal`;
  }
  return <div>{text}</div>;
};

const Nav = ({ hideTitle = false }) => {
  const { record: { id: projectID, guide_id: guideID = -1 } = {}, readOnly } = useAnswerContext();
  const guide = useSelector((s) => selectGuideByID(s, guideID));

  return (
    <nav className={styles.nav}>
      <AdminBar />
      <div className={styles.navBar}>
        <Link className={styles.navTitle} to="/" data-home-link>
          <span className={styles.tenantSeal}>
            <Seal type="light" />
          </span>
          <span className={styles.printedTenantSeal}>
            <Seal style={{ maxHeight: "50px" }} type="dark" />
          </span>
          {!hideTitle && <Title guide={guide} />}
        </Link>
        {readOnly && <ReadOnlyBadge />}
        <div className={styles.adminBarHandle}>
          <AdminBarHandle />
        </div>
        <div className={styles.detailsContainer}>
          {guide && (
            <div>
              <Text t="guides.header.nav.generated" /> {formatDateStringAsDayFullMonth(now())}
            </div>
          )}
          {projectID && (
            <div>
              <Text t="guides.header.nav.project_id" /> #{projectID}
            </div>
          )}
        </div>
        <Menu />
      </div>
    </nav>
  );
};

Nav.propTypes = {
  hideTitle: PropTypes.bool,
};

export default Nav;
