import React from "react";
import * as R from "ramda";

import MaybeApplicationLink from "./MaybeApplicationLink";
import EventLogPropTypes from "./propTypes";
import sharedStyles from "./sharedStyles.scss";

/**
 * ApplicationRejected log entry component
 *
 * @component
 * @param {Object} params
 * @returns <ApplicationRejected />
 */
const ApplicationRejected = ({
  Layout,
  event,
  timestamp,
  applicantContext,
  applicationView,
  context,
}) => {
  const note = R.path(["payload", "data", "note"], event);
  const reviewName = R.path(["payload", "data", "review_name"], event);
  const by = R.path(["payload", "data", "by"], event);

  const additionalContent = applicationView ? null : (
    <div className={sharedStyles.noteContent}>
      {reviewName}: {note}
    </div>
  );
  return (
    <Layout
      icon="times-hexagon"
      additionalContent={additionalContent}
      containerStyles={[sharedStyles.error]}
    >
      {applicationView ? (
        <>Rejected{context !== "handoff" && `: ${note}`}</>
      ) : (
        <>
          <MaybeApplicationLink event={event} /> application rejected
          {applicantContext ? " by staff" : by && ` by ${by}`}
        </>
      )}
      {timestamp}
    </Layout>
  );
};
ApplicationRejected.propTypes = EventLogPropTypes;

export default ApplicationRejected;
