import React from "react";

import { Icon, Message } from "components/guides/ZoningDetails/Permission";
import { permissionPropType } from "utils/sharedPropTypes";

import styles from "./Header.scss";

export const Header = ({ permission }) => (
  <div className={styles.container}>
    <Icon permission={permission} />
    <Message permission={permission} />
  </div>
);

Header.propTypes = {
  permission: permissionPropType.isRequired,
};
export default Header;
