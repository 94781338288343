import React from "react";
import { useForm } from "react-hook-form";

import { SubmitButton } from "components/Button";
import * as Inputs from "components/forms/BareFields";
import {
  ApplicantLabeledInput,
  Form,
  FormActions,
  FormRow,
  HorizontalLabeledInput,
} from "components/forms/Layout";
import InlineModal from "components/shared/InlineModal";
import Text from "containers/Text";
import { useOnAuthnSuccess } from "contexts/auth";
import { useSession } from "contexts/session";
import { useUpdateApplicant } from "queries/auth";
import { errorHandler, swallowErrors } from "utils/form";

import { matches_password } from "./validations";

const InvitedSignupForm = ({ onClose }) => {
  const {
    user: { email, id: userID },
  } = useSession();
  const onSuccess = useOnAuthnSuccess();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm({
    defaultValues: { email },
    mode: "onTouched",
    progressive: true,
  });

  const { mutateAsync } = useUpdateApplicant(userID, {
    onSuccess({ after_sign_in_path }) {
      onSuccess(after_sign_in_path);
    },
    onError: errorHandler(setError),
  });

  return (
    <InlineModal
      onClose={onClose}
      heading="Finish creating your account"
      subheading="YOU WERE INVITED"
    >
      <Form
        onSubmit={handleSubmit(swallowErrors(mutateAsync))}
        name="create-applicant"
        data-create-account-form
      >
        <FormRow>
          <ApplicantLabeledInput label={<Text t="pages.signup.email" />} error={errors?.email}>
            <Inputs.Text
              type="email"
              register={register("email", { required: "is required" })}
              disabled
            />
          </ApplicantLabeledInput>
          <ApplicantLabeledInput
            label={<Text t="pages.signup.phone" />}
            error={errors?.phone_number}
          >
            <Inputs.Text
              register={register("phone_number")}
              type="text"
              autoComplete="tel-national"
            />
          </ApplicantLabeledInput>
        </FormRow>
        <FormRow>
          <ApplicantLabeledInput
            label={<Text t="pages.signup.password" />}
            error={errors?.password}
          >
            <Inputs.Text
              register={register("password", {
                minLength: { value: 8, message: "must be at least 8 characters in length" },
                maxLength: { value: 128, message: "must be no more than 128 characters in length" },
                required: "is required",
              })}
              type="password"
              autoComplete="new-password"
            />
          </ApplicantLabeledInput>
          <ApplicantLabeledInput
            label={<Text t="pages.signup.password_confirmation" />}
            error={errors?.password_confirmation}
          >
            <Inputs.Text
              register={register("password_confirmation", {
                required: "is required",
                validate: { matches_password },
              })}
              type="password"
              autoComplete="new-password"
              data-1p-ignore
            />
          </ApplicantLabeledInput>
        </FormRow>
        <FormRow>
          <HorizontalLabeledInput
            reversed
            label={<Text markdown t="pages.signup.terms_and_conditions" />}
          >
            <Inputs.Checkbox register={register("terms_and_conditions", { required: true })} />
          </HorizontalLabeledInput>
        </FormRow>
        <FormActions>
          <div>
            <SubmitButton data-create-account formControl={control}>
              <Text t="pages.signup.create_account" />
            </SubmitButton>
          </div>
        </FormActions>
      </Form>
    </InlineModal>
  );
};

export default InvitedSignupForm;
