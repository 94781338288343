import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import logoImage from "images/oc-logo.svg";

import styles from "./Logo.scss";

const Logo = ({ linkTo, className = [] }) => (
  <Link to={linkTo} className={classnames(styles.containerLink, ...Array.of(className))}>
    <img alt="OpenCounter" src={logoImage} />
  </Link>
);
export default Logo;
