import { select } from "redux-saga/effects";

import { selectTenantVersionNumber } from "reducers/tenant";
import versionedAPI from "services/api";

export default function* getAPIContext(version = undefined) {
  if (version) return versionedAPI(version);

  const versionNumber = yield select(selectTenantVersionNumber);
  return versionedAPI(versionNumber);
}
