import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import MapGL, { NavigationControl, Popup, ScaleControl } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import WebMercatorViewport from "viewport-mercator-project";

import { clearValidationError } from "actions";
import { SmallButton } from "components/Button";
import Icon from "components/Icon";
import Loader from "components/Loader";
import Markdown from "components/Markdown";
import { Tabs } from "components/Tabs";
import { ButtonDiv } from "components/accessibility/Div";
import ErrorMessage from "components/project_form/ErrorMessage";
import { useAnswerContext } from "containers/withAnswerContext";
import { useServerVariables } from "contexts/serverVariables";
import useGeocoder from "hooks/useGeocoder";
import useIsAppleTouch from "hooks/useIsAppleTouch";
import { useGeomsList } from "queries/geoms";
import { getAnswerForField, getErrorForField } from "reducers/answerContexts";
import { selectFieldBySlug } from "reducers/fields";
import { selectTenant } from "reducers/tenant";
import { selectIsMobile } from "reducers/ui";
import { selectInitialAddressViewport } from "selectors/addressViewport";
import { useEventMapStyle } from "services/MapStyleBuilder";
import ocClient from "services/ocClient";
import { formatAddress } from "utils/format";

import "mapbox-gl/dist/mapbox-gl.css";
import styles from "./EventGeomsField.scss";
import UnansweredField from "./UnansweredField";

/**
 * onSave callback for selecting a location
 *
 * @callback
 * @private
 * @param {Object} params
 */
const addLocationFromGeoms = ({
  addressesField,
  geomIDs,
  geomsField,
  location,
  onChange,
  onSave,
  project,
  setCurrentLocation,
}) => {
  let field;
  let newValue;
  if (geomIDs.length === 0) {
    field = addressesField;
    newValue = [location];
  } else {
    field = geomsField;
    newValue = geomIDs;
  }

  const currentAnswer = getAnswerForField(project, field) || [];
  onChange(field, R.concat(newValue, currentAnswer));
  onSave(field);

  setTimeout(() => {
    setCurrentLocation({ reset: true });
  }, 300);
};

// DisabledEventGeomsField state is also used for guide presentation of answers in its modifiable mode
export const DisabledEventGeomsField = ({
  field,
  value = [],
  modifiable = false,
  setCurrentLocation,
  setHighlighted,
}) => {
  const { data: geoms = null } = useGeomsList();
  const geomIDs = value;
  const { record: project, onChange, onSave } = useAnswerContext();
  const addressesField = useSelector((state) => selectFieldBySlug(state, "event-addresses"));
  const addressesValue = useMemo(
    () => getAnswerForField(project, addressesField) || [],
    [project, addressesField],
  );

  const onDeleteGeom = useCallback(
    (e) => {
      onChange(field, R.without([getClosestGeomID(e)], geomIDs));
      onSave(field);
    },
    [onChange, onSave, field, geomIDs],
  );
  const onDeleteAddress = useCallback(
    (e) => {
      const addressIndex = Number(e.target.closest("[data-address-index]").dataset.addressIndex);
      onChange(addressesField, R.remove(addressIndex, 1, addressesValue));
      onSave(addressesField);
    },
    [onChange, onSave, addressesField, addressesValue],
  );
  const onClick = useCallback(
    (e) => setCurrentLocation({ geomIDs: [getClosestGeomID(e)] }),
    [setCurrentLocation],
  );
  const onMouseOver = useCallback((e) => setHighlighted(getClosestGeomID(e)), [setHighlighted]);
  const onMouseOut = useCallback(() => setHighlighted(null), [setHighlighted]);

  if (geoms === null) return <Loader />;

  const isEmpty = R.isEmpty(value) && R.isEmpty(addressesValue);

  return (
    <div className={styles.disabledContainer}>
      {!modifiable && isEmpty && <UnansweredField />}
      {modifiable && !isEmpty && <h3>Selected locations:</h3>}
      <div className={styles.locationsContainer}>
        {value.map((geomID) => {
          const geom = geoms.find((geom) => geom.id === geomID);
          const iconType = geom.geom_type === "route" ? "route" : "map-marker-check";

          return (
            <div key={geomID} className={styles.locationContainer} data-geom-id={geomID}>
              {modifiable && (
                <ButtonDiv
                  onClick={onClick}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                  onFocus={onMouseOver}
                  onBlur={onMouseOut}
                  data-selected-geom
                >
                  <Icon size="lg" icon={iconType} />
                  <span>{geom.name}</span>
                </ButtonDiv>
              )}
              {!modifiable && (
                <>
                  <Icon size="lg" icon={iconType} />
                  <span>{geom.name}</span>
                </>
              )}
              {modifiable && (
                <ButtonDiv onClick={onDeleteGeom} className={styles.delete}>
                  <Icon icon="trash-alt" aria-hidden="false" />
                </ButtonDiv>
              )}
            </div>
          );
        })}
        {modifiable && (
          <AddressList
            value={addressesValue}
            modifiable={modifiable}
            onDeleteAddress={onDeleteAddress}
          />
        )}
      </div>
      {modifiable && !isEmpty && (
        <div className={styles.addAnotherMsg}>
          <div className={styles.inlineContent}>Add another location above, or continue below.</div>
          <div className={styles.guideContent}>Add another location, or click next below.</div>
        </div>
      )}
    </div>
  );
};
DisabledEventGeomsField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
};
export const disabled = DisabledEventGeomsField;

export function AddressList({ value, onDeleteAddress, modifiable }) {
  return value.map((a, i) => (
    <div key={a.full_address} className={styles.locationContainer}>
      <Icon size="lg" icon="map-marker-check" />
      <span>{formatAddress(a.full_address)}</span>
      {modifiable && (
        <ButtonDiv onClick={onDeleteAddress} data-address-index={i} className={styles.delete}>
          <Icon icon="trash-alt" aria-hidden="false" />
        </ButtonDiv>
      )}
    </div>
  ));
}

function getClosestGeomID(e) {
  let element;
  if (Object.prototype.hasOwnProperty.call(e.target.dataset, "geomId")) element = e.target;
  else element = e.target.closest("[data-geom-id]");
  return Number(element.dataset.geomId);
}

/**
 * return Geoms component
 *
 * @component
 * @private
 * @param {Object} params
 * @returns <Geoms />
 */
const Geoms = ({ geoms, setCurrentLocation, setHighlighted, inline = false }) => {
  const [selectedTab, selectTab] = useState("location");

  const onClick = useCallback(
    (e) => {
      setCurrentLocation({ geomIDs: [getClosestGeomID(e)], center: true });
    },
    [setCurrentLocation],
  );

  const onMouseOver = useCallback((e) => setHighlighted(getClosestGeomID(e)), [setHighlighted]);
  const onMouseOut = useCallback(() => setHighlighted(null), [setHighlighted]);

  /** we need to detect if this is an apple device and suppress hover-related props accordingly because of the double-tap issue: */
  /** https://css-tricks.com/annoying-mobile-double-tap-link-issue/ */
  const isAppleTouch = useIsAppleTouch();

  const hoverProps = isAppleTouch
    ? {}
    : {
        onMouseOver,
        onMouseOut,
        onFocus: onMouseOver,
        onBlur: onMouseOut,
      };

  return (
    <div className={styles.geomsContainer}>
      <Tabs
        onSelect={selectTab}
        selected={selectedTab}
        config={{
          location: "guides.event_locations.select_a_location",
          route: "guides.event_locations.select_a_route",
        }}
        invertColors={inline}
      />
      <ul>
        {geoms
          .filter((g) =>
            selectedTab === "location" ? g.geom_type !== "route" : g.geom_type === "route",
          )
          .map((geom) => (
            <li
              key={`geom-${geom.id}`}
              className={styles.geomContainer}
              data-geom-id={geom.id}
              {...hoverProps}
            >
              <ButtonDiv onClick={onClick} data-geom>
                <span className={styles.heading}>{geom.name}</span>
              </ButtonDiv>
              {selectedTab === "location" && <div className={styles.address}>{geom.address}</div>}
              <Markdown source={geom.description} />
            </li>
          ))}
      </ul>
    </div>
  );
};

function getGeomGroup(input, allGeoms) {
  let geoms = allGeoms;
  if (input) {
    const searchStr = input.toLowerCase();
    geoms = allGeoms.filter((g) => (g.name + g.description).toLowerCase().includes(searchStr));
  }

  return {
    label: "Place/Route Results",
    options: geoms.map((g) => ({
      label: g.name,
      value: { id: g.id },
    })),
  };
}

const Search = ({ geoms, setCurrentLocation, error, autoFocus = false }) => {
  const geocoder = useGeocoder({ constrain_address_to_city: true });

  const loadLocationOptions = useCallback(
    async (input) => {
      if (input.length < 5) return [getGeomGroup(input, geoms)];

      const suggestions = await geocoder.fetchSuggestions(input);
      const suggestionGroup = {
        label: "Address Results",
        options: suggestions.map((s) => ({
          value: s,
          label: formatAddress(s.text),
        })),
      };
      return [getGeomGroup(input, geoms), suggestionGroup];
    },
    [geocoder, geoms],
  );

  const onChange = useCallback(
    (option) => {
      if (!option) {
        setCurrentLocation({ reset: true });
      } else if (!Object.prototype.hasOwnProperty.call(option.value, "id")) {
        geocoder
          .suggestionToAddress(option.value)
          .then((address) => {
            if (!address) return; // esri will return suggestions that don't exist

            // TODO: check if this point has geoms at it and use them instead if so
            setCurrentLocation({
              location: { ...address, full_address: address.text },
              center: true,
            });
          })
          // eslint-disable-next-line no-alert
          .catch(() => alert("Sorry, this address was not found."));
      } else setCurrentLocation({ geomIDs: [option.value.id], center: true });
    },
    [setCurrentLocation, geocoder],
  );

  return (
    <div className={styles.searchContainer}>
      <AsyncSelect
        placeholder="Search Locations"
        cacheOptions
        defaultOptions
        loadOptions={loadLocationOptions}
        isClearable
        autoFocus={autoFocus}
        onChange={onChange}
      />
      {error && (
        <div className={styles.errorContainer}>
          <ErrorMessage error={error} />
        </div>
      )}
    </div>
  );
};

export function Sidebar({
  geom,
  geoms,
  geomsAnswer,
  geomsField,
  geomIDs,
  setCurrentLocation,
  tenant,
  setHighlighted,
  error,
  onSaveCurrent,
  location,
  isMobile,
}) {
  if (!geoms) return <Loader />;

  return (
    <>
      <div className={styles.header}>
        <h1>Event Location</h1>
      </div>
      {tenant.event_location_help && (
        <div className={styles.help}>
          <Markdown source={tenant.event_location_help} />
        </div>
      )}
      <DisabledEventGeomsField
        value={geomsAnswer}
        modifiable
        field={geomsField}
        setCurrentLocation={setCurrentLocation}
        setHighlighted={setHighlighted}
      />
      <>
        <Search geoms={geoms} setCurrentLocation={setCurrentLocation} error={error} autoFocus />
        {(geom || location) && isMobile && (
          <div className={styles.mapPopupContent}>
            <MapPopupContent geom={geom} location={location} onSaveCurrent={onSaveCurrent} />
          </div>
        )}
        <Geoms
          geoms={geoms}
          geomIDs={geomIDs}
          setCurrentLocation={setCurrentLocation}
          setHighlighted={setHighlighted}
        />
      </>
    </>
  );
}

const MapPopup = ({ setCurrentLocation, location, geom, onSaveCurrent }) => {
  const onClose = useCallback(() => {
    setCurrentLocation({ reset: true });
  }, [setCurrentLocation]);

  return (
    <Popup
      anchor="bottom"
      latitude={location.latitude}
      longitude={location.longitude}
      closeOnClick={false}
      onClose={onClose}
      maxWidth="500px"
    >
      <div className={styles.popupContainer}>
        <MapPopupContent geom={geom} location={location} onSaveCurrent={onSaveCurrent} />
      </div>
    </Popup>
  );
};

const MapPopupContent = ({ geom, location, onSaveCurrent }) => {
  const buttonRef = createRef(null);
  const geomNameRef = createRef(null);

  useEffect(() => {
    buttonRef?.current?.focus();
    geomNameRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geom, location]);

  return (
    <>
      {geom && (
        <>
          <h3 ref={geomNameRef}>{geom.name}</h3>
          <div>{geom.address}</div>
          <Markdown source={geom.description} />
        </>
      )}
      {!geom && <h3>{formatAddress(location.full_address)}</h3>}
      <br />
      <div>
        <SmallButton onClick={onSaveCurrent} data-select-geom ref={buttonRef}>
          {geom && geom.geom_type === "route" ? "Select this route" : "Select this location"}
        </SmallButton>
      </div>
    </>
  );
};

export function Map({
  tenant,
  location,
  setCurrentLocation,
  geomIDs,
  viewport,
  setViewport,
  addressesField,
  geomsField,
  mapRef,
  geoms,
  highlighted,
  geomsAnswer,
  onSaveCurrent,
}) {
  const { record: project } = useAnswerContext();
  const addressesAnswer = getAnswerForField(project, addressesField) || [];
  const geocoder = useGeocoder({ constrain_address_to_city: true });

  const onClick = useCallback(
    (e) => {
      const { lng: longitude, lat: latitude } = e.lngLat;
      const geomIDs = mapRef.current
        .queryRenderedFeatures(e.point, { layers: ["geoms", "paths"] })
        .filter((f) => f.layer.source === "geoms")
        .map((f) => f.properties.id);

      if (geomIDs.length > 0) {
        setCurrentLocation({
          geomIDs: [geomIDs[0]], // only use one id for now (special events)
          location: { latitude, longitude },
        });
        return;
      }

      geocoder.reverseGeocode(latitude, longitude).then((address) => {
        setCurrentLocation({
          location: { latitude, longitude, full_address: address.text },
        });
      });
    },
    [mapRef, geocoder, setCurrentLocation],
  );
  const geom = geomIDs.length > 0 && geoms.find((g) => g.id === geomIDs[0]);
  const { mapboxToken } = useServerVariables();
  const mapStyle = useEventMapStyle({
    tenantID: tenant.id,
    location,
    geomIDs,
    geomsAnswer,
    addressesAnswer,
    highlightedID: highlighted,
  });

  return (
    <MapGL
      {...viewport}
      onMove={(evt) => setViewport(evt.viewState)}
      mapStyle={mapStyle}
      ref={mapRef}
      mapboxAccessToken={mapboxToken}
      onClick={onClick}
      clickRadius={0}
      touchZoom
      touchRotate={false}
      transitionDuration={0}
    >
      {location && (
        <MapPopup
          geom={geom}
          geomIDs={geomIDs}
          location={location}
          setCurrentLocation={setCurrentLocation}
          geomsField={geomsField}
          addressesField={addressesField}
          onSaveCurrent={onSaveCurrent}
        />
      )}
      <NavigationControl />
      <ScaleControl />
    </MapGL>
  );
}

function useStates(geomsField) {
  const defaultViewport = useSelector(selectInitialAddressViewport);
  const dispatch = useDispatch();
  const answerContext = useAnswerContext();
  const tenant = useSelector(selectTenant);
  const [viewport, setViewport] = useState(defaultViewport);
  const [highlighted, setHighlighted] = useState(null);
  const [geomIDs, setGeomIDs] = useState([]);
  const [location, setLocation] = useState(null);
  const { data: geoms } = useGeomsList();
  const mapRef = useRef();

  const setCurrentLocation = useCallback(
    async ({ geomIDs, location, center, reset }) => {
      if (reset) {
        setGeomIDs([]);
        setLocation(null);
        setViewport({
          ...defaultViewport,
          transitionDuration: "auto",
        });
        return;
      }

      if (location) {
        dispatch(clearValidationError({ answerContext, field: geomsField }));
        setGeomIDs(geomIDs || []);
        setLocation(location);
        if (center)
          setViewport({
            longitude: location.longitude,
            latitude: location.latitude,
            zoom: 14,
            transitionDuration: "auto",
          });
        return;
      }

      if (geomIDs) {
        setGeomIDs(geomIDs);
        const { data } = await ocClient.get(`/api/geoms/${geomIDs[0]}/bounds_and_center`);
        const {
          center: [longitude, latitude],
          bounds,
        } = data;
        setLocation({ longitude, latitude });

        if (!mapRef?.current) return;

        // HACK: docs / exammples say just passing the vp should work but fitToBounds isn't working unless I give it a width / height, test again after update
        const { offsetHeight: height, offsetWidth: width } = mapRef.current.getMap().getContainer();
        const wmvp = new WebMercatorViewport({ width, height });
        setViewport({
          ...wmvp.fitBounds(bounds, { padding: 20 }),
          transitionDuration: "auto",
        });
      }
    },
    [answerContext, defaultViewport, geomsField, setLocation, setGeomIDs, setViewport, dispatch],
  );

  return {
    tenant,
    geomIDs,
    geoms,
    location,
    setCurrentLocation,
    viewport,
    setViewport,
    mapRef,
    highlighted,
    setHighlighted,
  };
}

export function GuideEventGeomsField({ geomsField, addressesField }) {
  const {
    tenant,
    geomIDs,
    location,
    setCurrentLocation,
    viewport,
    setViewport,
    mapRef,
    geoms,
    highlighted,
    setHighlighted,
  } = useStates(geomsField);

  const { record: project, onChange, onSave } = useAnswerContext();
  const geomsAnswer = getAnswerForField(project, geomsField) || [];
  const error = getErrorForField(project, geomsField);
  const isMobile = useSelector(selectIsMobile);

  const geom = geomIDs.length > 0 && geoms.find((g) => g.id === geomIDs[0]);

  const onSaveCurrent = useCallback(
    (e) => {
      e.preventDefault();
      addLocationFromGeoms({
        addressesField,
        geomsField,
        geomIDs,
        location,
        project,
        onChange,
        onSave,
        setCurrentLocation,
      });
    },
    [location, addressesField, geomsField, project, setCurrentLocation, onChange, onSave, geomIDs],
  );

  return (
    <div className={styles.container}>
      <div className={styles.sidebarContainer}>
        <Sidebar
          geom={geom}
          geoms={geoms}
          geomsField={geomsField}
          tenant={tenant}
          setCurrentLocation={setCurrentLocation}
          geomsAnswer={geomsAnswer}
          setHighlighted={setHighlighted}
          error={error}
          isMobile={isMobile}
          onSaveCurrent={onSaveCurrent}
          location={location}
        />
      </div>

      {!isMobile && (
        <div className={styles.mapContainer} data-testid="map-container">
          <Map
            tenant={tenant}
            geomIDs={geomIDs}
            location={location}
            setCurrentLocation={setCurrentLocation}
            setViewport={setViewport}
            viewport={viewport}
            geomsField={geomsField}
            addressesField={addressesField}
            mapRef={mapRef}
            geoms={geoms}
            highlighted={highlighted}
            geomsAnswer={geomsAnswer}
            onSaveCurrent={onSaveCurrent}
          />
        </div>
      )}
    </div>
  );
}
export const guide = GuideEventGeomsField;

export function InlineEventGeomsField({ field: geomsField, value: geomsAnswer, error }) {
  const {
    geoms,
    tenant,
    geomIDs,
    location,
    setCurrentLocation,
    setViewport,
    viewport,
    mapRef,
    highlighted,
    setHighlighted,
  } = useStates(geomsField);

  const { record: project, onChange, onSave } = useAnswerContext();
  const addressesField = useSelector((state) => selectFieldBySlug(state, "event-addresses"));

  const onSaveCurrent = useCallback(
    (e) => {
      e.preventDefault();
      addLocationFromGeoms({
        addressesField,
        geomsField,
        geomIDs,
        location,
        project,
        onChange,
        onSave,
        setCurrentLocation,
      });
    },
    [addressesField, geomIDs, geomsField, location, onChange, onSave, project, setCurrentLocation],
  );

  if (!geoms) return <Loader />;

  return (
    <div className={styles.inlineContainer}>
      <div>
        <Search geoms={geoms} setCurrentLocation={setCurrentLocation} error={error} />
        <Geoms
          geoms={geoms}
          geomIDs={geomIDs}
          setCurrentLocation={setCurrentLocation}
          setHighlighted={setHighlighted}
          inline
        />
      </div>
      <div className={styles.mapContainer}>
        <Map
          geoms={geoms}
          tenant={tenant}
          geomIDs={geomIDs}
          location={location}
          setCurrentLocation={setCurrentLocation}
          setViewport={setViewport}
          viewport={viewport}
          geomsField={geomsField}
          addressesField={addressesField}
          mapRef={mapRef}
          highlighted={highlighted}
          geomsAnswer={geomsAnswer}
          onSaveCurrent={onSaveCurrent}
        />
      </div>
      <DisabledEventGeomsField
        value={geomsAnswer}
        modifiable
        field={geomsField}
        setCurrentLocation={setCurrentLocation}
        setHighlighted={setHighlighted}
      />
    </div>
  );
}

export default InlineEventGeomsField;
