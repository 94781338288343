import { useMemo } from "react";
import Immutable from "immutable";
import * as R from "ramda";

import mapStyleConfig from "sharedStyles/map-config.json";
import mapStyleDefault from "sharedStyles/map-default.json";
import mapStyleEvent from "sharedStyles/map-event.json";
import mapStyleSatellite from "sharedStyles/map-satellite.json";
import mapStyleZoneless from "sharedStyles/map-zoneless.json";

const mapStyles = {
  default: Immutable.fromJS(mapStyleDefault),
  zoneless: Immutable.fromJS(mapStyleZoneless),
  event: Immutable.fromJS(mapStyleEvent),
  satellite: Immutable.fromJS(mapStyleSatellite),
  config: Immutable.fromJS(mapStyleConfig),
};

const findLayerById = (layers, id) => layers.findEntry((layer) => layer.get("id") === id) || [];

const addZoneLayers = (style, clearances) => {
  const layers = style.get("layers");

  R.forEachObjIndexed((ids, permission) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const type of ["zones", "overlays"]) {
      const [index, layer] = findLayerById(layers, `${type}-${permission}`);
      if (!layer) return;
      style = style.setIn(
        ["layers", index],
        layer.set(
          "filter",
          Immutable.List.of(
            "all",
            Immutable.List.of("in", "id", ...ids),
            Immutable.List.of(
              "any",
              Immutable.List.of("==", "overlay", type === "overlays"),
              Immutable.List.of("==", "overlay", Number(type === "overlays")),
            ),
          ),
        ),
      );
    }
  }, clearances);

  return style;
};

const addAllZones = (style, overlays = false) => {
  const type = overlays ? "overlays" : "zones";
  const layers = style.get("layers");

  const [index, layer] = findLayerById(layers, type);
  style = style.setIn(
    ["layers", index],
    layer.set("filter", Immutable.List.of("==", "overlay", overlays)),
  );

  return style;
};

const addRadiusRuleLayers = (style, radiusRules) => {
  const layers = style.get("layers");
  R.forEachObjIndexed((ids, permission) => {
    const [index, layer] = findLayerById(layers, `radius-rules-buffer-${permission}`);
    if (!layer) return;
    const newFilter = Immutable.List.of("all", Immutable.List.of("in", "id", ...ids));
    style = style.setIn(["layers", index], layer.set("filter", newFilter));
  }, radiusRules);

  return style;
};

const addAllParcels = (style) => {
  const layers = style.get("layers");
  const [index, parcelsLayer] = findLayerById(layers, "parcels");
  if (!parcelsLayer) return style;

  return style.setIn(["layers", index], parcelsLayer);
};

const addCurrentParcels = (style, zoning) => {
  const { parcels } = zoning;

  if (!parcels || R.isEmpty(parcels)) return style;

  const layers = style.get("layers");
  const [index, parcelLayer] = findLayerById(layers, "parcel");
  if (!parcelLayer) return style;
  const parcelNumbers = R.pipe(R.map(R.prop("value")), R.flatten)(parcels);

  return style.setIn(
    ["layers", index],
    parcelLayer.set(
      "filter",
      Immutable.List.of("all", Immutable.List.of("in", "parcel_number", ...parcelNumbers)),
    ),
  );
};

const addMVTSources = (style, tenantID, tileVersion) =>
  ["zones", "parcels", "tenants", "rules", "geoms", "zone_display_geoms"].reduce((style, type) => {
    const path = ["sources", type, "tiles"];
    if (!style.getIn(path)) return style;

    const urlRoot = window.location.protocol + "//" + window.location.host;
    if (type === "geoms")
      return style.setIn(
        ["sources", "geoms", "tiles"],
        Immutable.fromJS([
          `${urlRoot}/api/mvt/${tenantID}/{z}/{x}/{y}.pbf?tileVersion=${tileVersion}`,
        ]),
      );

    return style.setIn(
      path,
      Immutable.fromJS([
        `${urlRoot}/api/mvt/${tenantID}/${type}/{z}/{x}/{y}.pbf?tileVersion=${tileVersion}`,
      ]),
    );
  }, style);

const addBoundary = (style) => {
  const layers = style.get("layers");
  const [_index, boundaryLayer] = findLayerById(layers, "boundary");

  if (!boundaryLayer) return style;
  return style;
};

function addIDsToLayer(style, layerName, ids) {
  const [index, layer] = findLayerById(style.get("layers"), layerName);
  let filter;
  if (layer.getIn(["filter", 0]) === "all")
    // some layers have nested combined filters
    filter = layer.setIn(["filter", 1], Immutable.List.of("in", "id", ...ids));
  else filter = layer.set("filter", Immutable.List.of("in", "id", ...ids));
  return style.setIn(["layers", index], filter);
}

function hideLayer(style, layerName) {
  const [index, layer] = findLayerById(style.get("layers"), layerName);
  return style.setIn(
    ["layers", index],
    layer.set("layout", Immutable.fromJS({ visibility: "none" })),
  );
}

const addPoints = (style, locations = [], source = "current-point") => {
  if (locations.length === 0) return style;
  const points = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: locations.map((l) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [l.longitude, l.latitude],
        },
      })),
    },
  };
  return style.setIn(["sources", source], Immutable.fromJS(points));
};

export const useMapStyle = ({
  tenantID,
  tileVersion,
  clearances,
  radiusRules,
  address: addressValue,
  zoning = false,
  type,
}) =>
  useMemo(() => {
    let style = mapStyles[type];
    style = addMVTSources(style, tenantID, tileVersion);
    style = addZoneLayers(style, clearances);
    style = addRadiusRuleLayers(style, radiusRules);
    style = addAllParcels(style);
    style = addBoundary(style);
    if (addressValue) style = addPoints(style, [addressValue]);
    if (zoning) style = addCurrentParcels(style, zoning);
    return style;
  }, [tenantID, type, clearances, radiusRules, addressValue, zoning, tileVersion]);

export const useEventMapStyle = ({
  tenantID,
  tileVersion,
  location,
  geomIDs = [],
  geomsAnswer = [],
  addressesAnswer = [],
  highlightedID,
  hideGeoms = false,
}) =>
  useMemo(() => {
    let style = mapStyles.event;
    style = addMVTSources(style, tenantID, tileVersion);

    if (location) style = addPoints(style, [location]);
    style = addPoints(style, addressesAnswer, "saved-points");

    if (highlightedID) style = addIDsToLayer(style, "highlighted-geoms", [highlightedID]);
    style = addIDsToLayer(style, "selected-geoms", geomIDs);
    style = addIDsToLayer(style, "selected-paths", geomIDs);

    style = addIDsToLayer(style, "saved-geoms", geomsAnswer);
    style = addIDsToLayer(style, "saved-paths", geomsAnswer);

    if (hideGeoms) style = hideLayer(style, "geoms");

    return style;
  }, [
    tenantID,
    location,
    addressesAnswer,
    geomIDs,
    highlightedID,
    hideGeoms,
    geomsAnswer,
    tileVersion,
  ]);

export const useConfigMapStyle = ({ tenantID, tileVersion, mode }) =>
  useMemo(() => {
    let style = mapStyles.config;
    style = addMVTSources(style, tenantID, tileVersion);
    style = addBoundary(style);

    style = addAllZones(style);
    style = addAllZones(style, true);

    style = addAllParcels(style);

    const LAYERS = ["zones", "geoms", "overlays", "parcels", "boundary", "zone_display_geoms"];
    if (mode !== "all")
      LAYERS.forEach((type) => {
        if (type !== mode) style = hideLayer(style, type);
      });
    if (mode === "all") style = hideLayer(style, "zone_display_geoms");

    return style;
  }, [tenantID, mode, tileVersion]);
