import { useCallback, useState } from "react";

export default (initialValue = false) => {
  const [toggleValue, setToggleValue] = useState(initialValue);

  const toggle = useCallback(() => setToggleValue(!toggleValue), [toggleValue, setToggleValue]);
  const setToggleOn = useCallback(() => setToggleValue(true), [setToggleValue]);
  const setToggleOff = useCallback(() => setToggleValue(false), [setToggleValue]);

  return {
    value: toggleValue,
    toggle,
    set: setToggleValue,
    setOn: setToggleOn,
    setOff: setToggleOff,
  };
};
