import { all, call, put, take, takeLatest } from "redux-saga/effects";

import { SET_CURRENT_USER, UPDATE_PASSWORD, fetchSession } from "actions";
import analytics from "services/analytics";
import { unversionedAPI } from "services/api";

import apiCall from "./apiCall";
import formSaga from "./formSaga";

export function* updatePassword({ id, oldPassword, password }) {
  yield call(
    apiCall,
    { type: "Mutation", entityType: "User", entityID: id },
    unversionedAPI.updatePassword,
    {
      id,
      oldPassword,
      password,
    },
  );
}
export function* submitPasswordUpdate({ payload, meta }) {
  yield call(formSaga, "update-password", updatePassword, payload, meta);
}

export function* waitForSessionFetch(reason) {
  yield put(fetchSession(reason));
  yield take(SET_CURRENT_USER); // nb: SessionProvider will dispatch this
}

function* identifyUser({ payload: user }) {
  yield call(analytics.alias, user.id);
  yield call(analytics.identify);
}

export function* authSaga() {
  yield all([
    takeLatest(UPDATE_PASSWORD, submitPasswordUpdate),
    takeLatest(SET_CURRENT_USER, identifyUser),
  ]);
}
