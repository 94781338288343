// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-messaging-SendMessageFooter__footer-container--z45ED {\n  width: 100%;\n  min-height: 8.375em;\n  box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.1);\n  padding-top: 1.5em;\n}\n\n.ui-components-messaging-SendMessageFooter__footer-content--N19Ke {\n  margin: 0 auto;\n  max-width: 1024px;\n}\n.ui-components-messaging-SendMessageFooter__footer-content--N19Ke label {\n  display: block;\n  font-size: 1.125rem;\n  font-weight: 700;\n  padding-bottom: 1em;\n}\n\n.ui-components-messaging-SendMessageFooter__admin-footer-container--gerKD .ui-components-messaging-SendMessageFooter__footer-content--N19Ke {\n  margin: 0;\n  padding: 0 2em;\n  max-width: none;\n}", "",{"version":3,"sources":["webpack://./ui/components/messaging/SendMessageFooter.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,2CAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;AACF;AACE;EACE,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AAIE;EACE,SAAA;EACA,cAAA;EACA,eAAA;AADJ","sourcesContent":[".footer-container {\n  width: 100%;\n  min-height: 8.375em;\n  box-shadow: 0 -2px 3px 0 rgb(0 0 0 / 10%);\n  padding-top: 1.5em;\n}\n\n.footer-content {\n  margin: 0 auto;\n  max-width: 1024px;\n\n  label {\n    display: block;\n    font-size: 1.125rem;\n    font-weight: 700;\n    padding-bottom: 1em;\n  }\n}\n\n.admin-footer-container {\n  .footer-content {\n    margin: 0;\n    padding: 0 2em;\n    max-width: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer-container": "ui-components-messaging-SendMessageFooter__footer-container--z45ED",
	"footerContainer": "ui-components-messaging-SendMessageFooter__footer-container--z45ED",
	"footer-content": "ui-components-messaging-SendMessageFooter__footer-content--N19Ke",
	"footerContent": "ui-components-messaging-SendMessageFooter__footer-content--N19Ke",
	"admin-footer-container": "ui-components-messaging-SendMessageFooter__admin-footer-container--gerKD",
	"adminFooterContainer": "ui-components-messaging-SendMessageFooter__admin-footer-container--gerKD"
};
export default ___CSS_LOADER_EXPORT___;
