import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";

import { DefaultLine } from "components/Line";
import { Section } from "components/Section";
import Fields from "components/guides/Fields";
import Footer from "components/guides/ZoningDetails/Footer";
import { Header } from "components/guides/ZoningDetails/Header";
import ZoningDetails from "components/guides/ZoningDetails/ZoningDetails";
import Text from "containers/Text";
import ZoningMap from "containers/guides/summary/ZoningMap";
import { useAnswerContext } from "containers/withAnswerContext";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { selectCurrentActiveFieldsForProjectSlug } from "reducers/answerContexts";
import { filterUnansweredFields, getIsZoningEnabled, selectZoning } from "reducers/projects";
import { getDisclaimer, getMunicipalityType } from "reducers/tenant";
import { isBlank } from "utils/func";
import { getPermission } from "utils/zoning";

import styles from "./Summary.scss";

const ZoningSummary = () => {
  const { record: project } = useAnswerContext();
  const tenant = useCurrentTenantInfo();
  const zoning = useSelector((state) => selectZoning(state, project) || {});
  const activeFields = useSelector((state) =>
    selectCurrentActiveFieldsForProjectSlug(state, project, "summary"),
  );

  if (isBlank(zoning)) return null;

  const guideFields = filterUnansweredFields(project, activeFields);

  const isZoningEnabled = getIsZoningEnabled(project);
  const permission = getPermission(zoning);
  const disclaimer = getDisclaimer(tenant);
  const municipalityType = getMunicipalityType(tenant);

  return (
    <main role="main" data-zoning-summary>
      <Header permission={permission} />
      <div className={styles.mapContainer}>
        <ZoningMap />
      </div>

      <div className={styles.sectionContainer}>
        <ZoningDetails fromSummary />
      </div>

      {!R.isEmpty(guideFields) && (
        <Section heading={<Text t="guides.summary.project_details" />}>
          <Fields fields={guideFields} behaviorType="disabled" className={styles.fieldsSection} />
        </Section>
      )}

      <DefaultLine />

      {isZoningEnabled && (
        <Footer
          permission={permission}
          disclaimer={disclaimer}
          municipalityType={municipalityType}
        />
      )}
    </main>
  );
};

export default ZoningSummary;
