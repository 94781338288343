import React, { useState } from "react";

import Icon from "components/Icon";
import { ButtonDiv } from "components/accessibility/Div";

import styles from "./FlashMessage.scss";

const FlashMessage = ({ heading, hideClose = false, children }) => {
  const [closed, setClosed] = useState(false);
  const close = () => setClosed(true);

  if (closed) return null;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.heading}>{heading}</div>
        {hideClose || (
          <ButtonDiv>
            <Icon icon="times" onClick={close} />
          </ButtonDiv>
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default FlashMessage;
