import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import TextareaAutosize from "react-textarea-autosize";

import errorStyles from "sharedStyles/errors.scss";

import { errorPropType } from "../../utils/sharedPropTypes";
import styles from "./TextAreaField.scss";
import UnansweredField from "./UnansweredField";
import { textInput } from "./behaviors";

export const TextAreaFieldComponent = ({
  value,
  onChange,
  onBlur,
  field,
  name,
  error,
  required,
  fullWidth,
  rows,
  disabled,
}) => (
  <div className={classnames(styles.container, { [styles.fullWidth]: fullWidth })}>
    <TextareaAutosize
      className={classnames({
        [errorStyles.errorBorder]: error,
      })}
      value={value || ""}
      placeholder={field.placeholder}
      onChange={onChange}
      onBlur={onBlur}
      minRows={rows || 2}
      name={name}
      aria-invalid={!!error}
      aria-required={!!required}
      disabled={disabled}
    />
  </div>
);
TextAreaFieldComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  field: PropTypes.shape({
    placeholder: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
  error: errorPropType,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
};

export const StaticSizedTextAreaFieldComponent = ({
  value,
  onChange,
  onBlur,
  field,
  name,
  error,
  required,
  fullWidth,
  rows,
  disabled,
}) => (
  <div className={classnames(styles.container, { [styles.fullWidth]: fullWidth })}>
    <textarea
      className={classnames({
        [errorStyles.errorBorder]: error,
      })}
      value={value || ""}
      placeholder={field.placeholder}
      onChange={onChange}
      onBlur={onBlur}
      rows={rows || 2}
      name={name}
      aria-invalid={!!error}
      aria-required={!!required}
      disabled={disabled}
    />
  </div>
);
StaticSizedTextAreaFieldComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  field: PropTypes.shape({
    placeholder: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
  error: errorPropType,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
};

export const DisabledTextAreaField = ({ value }) => (
  <div className={styles.disabled}>{value || <UnansweredField />}</div>
);
DisabledTextAreaField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export const disabled = DisabledTextAreaField;

export default textInput(TextAreaFieldComponent);
