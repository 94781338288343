import { useEffect, useRef, useState } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

/**
 *
 * @param {Object} params
 * @param {Function} params.selector - redux selector that grabs the bit of state to watch for changes
//  * @param {Function} params.shouldResolve - fn that receives the value selected by selector and returns truthy if the promise should resolve. Default is always resolve when the value changes.
 * @returns {Promise<void>} - promise that resolves when the value pulled by the selector becomes truthy
 */
const useWaitForReduxStateChange = (selector) => {
  const value = Boolean(useSelector(selector));

  const resolve = useRef(R.T);
  const [promise, setPromise] = useState(() =>
    R.assoc("debugID", [selector.name, "INIT"], Promise.resolve()),
  );

  useEffect(() => {
    // shouldResolve(value) is truthy -- not building a new promise,
    if (value) return;

    const debugID = [selector.name, uuid(), value];
    const newPromise = new Promise((newResolve, _reject) => {
      resolve.current = newResolve;
    });
    newPromise.debugID = debugID;
    setPromise(newPromise);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolve, setPromise, value]);

  useEffect(() => {
    if (value) {
      resolve.current();
      return undefined;
    }
    return resolve.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolve, value]);

  return promise;
};

export default useWaitForReduxStateChange;
