import React from "react";
import PropTypes from "prop-types";

import Text from "containers/Text";
import { isPresent } from "utils/func";

import styles from "./Footer.scss";

const Footer = ({ municipalityType, disclaimer, permission: { category } = {} }) => {
  const prohibited = category === "prohibited";

  return (
    <div className={styles.footer}>
      {prohibited ? (
        <div>
          <Text t="guides.zoning.prohibited_message" municipalityType={municipalityType} />
        </div>
      ) : (
        <div>{isPresent(disclaimer) ? disclaimer : <Text t="disclaimer" />}</div>
      )}
    </div>
  );
};
Footer.propTypes = {
  permission: PropTypes.object.isRequired,
  municipalityType: PropTypes.string.isRequired,
  disclaimer: PropTypes.string.isRequired,
};

export default Footer;
