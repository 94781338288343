import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Markdown from "components/Markdown";
import Field from "containers/Field";
import { useRenewal } from "queries/renewals";
import { useIssuedRequirementAssets } from "queries/requirementApplications";
import { getDescriptiveID, getIssuedAt, isRevoked } from "reducers/requirementApplications";
import {
  getDisclaimer,
  getIssuedRequirementSealURL,
  getIssuerName,
  getIssuerTitle,
  getPostingInstructions,
  getName as getRequirementName,
  getSignatureURL,
  hideDepartmentNameOnIssuedRequirement,
  hideTenantNameOnIssuedRequirement,
} from "reducers/requirements";
import { getSealURL, getFullName as getTenantName } from "reducers/tenant";
import { fieldPropType } from "utils/sharedPropTypes";
import { parseTime } from "utils/time";

import styles from "./IssuedRequirementApplication.scss";

const defaultAssets = {
  disclaimer: "",
  issuer_name: "",
  issuer_title: "",
  issuer_signature_url: "",
  posting_instructions: "",
};

const IssuedRequirementApplication = ({
  tenant,
  requirement,
  departmentName,
  fields,
  application,
  embedded,
}) => {
  const preview = !application.id;
  const defaultReqAssets = preview ? requirement : defaultAssets;
  const { data: requirementAssets = defaultReqAssets } = useIssuedRequirementAssets(
    application.project_id,
    application.id,
    { enabled: !preview },
  );
  const requirementName = getRequirementName(requirement);
  const { data: renewal = {} } = useRenewal(application.project_id, application.id, {
    enabled: !!application.id,
  });
  const hideTenantName = hideTenantNameOnIssuedRequirement(requirement);
  const hideDepartmentName = hideDepartmentNameOnIssuedRequirement(requirement);
  const disclaimer = getDisclaimer(requirementAssets);
  const postingInstructions = getPostingInstructions(requirementAssets);
  const signatureURL = getSignatureURL(requirementAssets);
  const issuerName = getIssuerName(requirementAssets);
  const issuerTitle = getIssuerTitle(requirementAssets);
  const requirementApplicationDescriptiveID = String(getDescriptiveID(application));
  const sealURL = getIssuedRequirementSealURL(requirement) || getSealURL(tenant, "color");
  const watermarkURL = getSealURL(tenant, "watermark");
  const tenantName = getTenantName(tenant);

  return (
    <div className={classnames(styles.container, { [styles.embedded]: embedded })}>
      <Header
        application={application}
        sealURL={sealURL}
        tenantName={tenantName}
        departmentName={departmentName}
        requirementName={requirementName}
        hideTenantName={hideTenantName}
        hideDepartmentName={hideDepartmentName}
        descriptiveID={requirementApplicationDescriptiveID}
        expires={renewal.expires}
      />
      <Content fields={fields} />
      <Footer
        disclaimer={disclaimer}
        signatureURL={signatureURL}
        issuerName={issuerName}
        issuerTitle={issuerTitle}
      />

      <div className={styles.notices}>
        <div className={styles.instructions}>
          <Markdown source={postingInstructions} />
        </div>
        <div className={styles.issuedAt}>
          Issued {getIssuedAt(application).format("MMMM D, YYYY")}
        </div>
        <div className={styles.watermark}>
          <img src={watermarkURL} alt="" />
        </div>
      </div>
    </div>
  );
};

IssuedRequirementApplication.propTypes = {
  embedded: PropTypes.bool,
  departmentName: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(fieldPropType),
  application: PropTypes.shape({}),
};

const Line = () => <div className={styles.line} />;

const Header = ({
  sealURL,
  tenantName,
  hideTenantName,
  departmentName,
  hideDepartmentName,
  requirementName,
  descriptiveID,
  application,
  expires,
}) => (
  <div className={styles.header}>
    <div className={styles.seal}>
      <img src={sealURL} alt={tenantName} className={styles.seal} />
    </div>
    <div className={styles.headerInfo}>
      {!hideTenantName && <div className={styles.heading}>{tenantName}</div>}
      {!hideDepartmentName && <div className={styles.heading}>{departmentName}</div>}
      <div className={styles.title}>{requirementName}</div>
      <div className={styles.id}>#{descriptiveID}</div>
      {expires && (
        <div className={styles.expires}>Expires: {parseTime(expires).format("MMMM D, YYYY")}</div>
      )}
      {isRevoked(application) && (
        <div className={styles.revokedContainer}>
          <div className={styles.revokedStamp}>revoked</div>
        </div>
      )}
    </div>
  </div>
);
Header.propTypes = {
  sealURL: PropTypes.string.isRequired,
  tenantName: PropTypes.string.isRequired,
  departmentName: PropTypes.string.isRequired,
  requirementName: PropTypes.string.isRequired,
  hideTenantName: PropTypes.bool,
  hideDepartmentName: PropTypes.bool,
  descriptiveID: PropTypes.string.isRequired,
  application: PropTypes.shape({}),
};

const Content = ({ fields, renewalDiff = {} }) => (
  <div className={styles.content}>
    {fields.map((field) => (
      <Field
        field={field}
        key={field.id}
        className={styles.field}
        behaviorType="issued"
        hideIfUnanswered
        hideRequired
        hideHelp
        changeType={renewalDiff[field.id]}
      />
    ))}
  </div>
);
Content.propTypes = {
  fields: PropTypes.arrayOf(fieldPropType),
};

const Footer = ({ disclaimer, signatureURL, issuerName, issuerTitle }) => (
  <div className={styles.footer}>
    <div className={styles.disclaimer}>
      <Markdown source={disclaimer} />
    </div>
    <div className={styles.issuer}>
      {signatureURL && (
        <div className={styles.signature}>
          <img src={signatureURL} alt={issuerName} />
          <Line />
        </div>
      )}
      <div className={styles.issuerName}>{issuerName}</div>
      <div className={styles.issuerTitle}>{issuerTitle}</div>
    </div>
  </div>
);
Footer.propTypes = {
  disclaimer: PropTypes.string.isRequired,
  signatureURL: PropTypes.string.isRequired,
  issuerName: PropTypes.string.isRequired,
  issuerTitle: PropTypes.string.isRequired,
};

export default IssuedRequirementApplication;
