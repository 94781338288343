/*
These don't seem like selectors and the parms make calling these in general cases hard by expecting 
the ruby attr format (js format would be hard too in the inverse cases).

getApplicationAdminURL({ project_id: projectID, id: requirementApplicationID })
  vs
routes.admin(projectID, requirementApplication)

If this works for everyone I will replace these call sites with utils/routes
*/

import routes from "utils/routes";

export const getProjectAdminURL = ({ id }) => routes.admin.project(id);

export const getApplicationAdminURL = ({ project_id, id }) =>
  routes.admin.requirementApplication(project_id, id);

export const getApplicationURL = ({ project_id, requirement_id }) =>
  `/projects/${project_id}/application/${requirement_id}`;

export const getTransactionAdminURL = ({ project_id, id }) =>
  `/admin/projects/${project_id}/transactions/${id}`;

export const getChangesetAdminURL = ({ id, project_id }) =>
  `/admin/projects/${project_id}/changesets/${id}`;

export const getChangesetURL = ({ id, project_id }) => `/projects/${project_id}/changesets/${id}`;
