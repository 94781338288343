import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Tooltip from "components/Tooltip";
import { fieldPropType } from "utils/sharedPropTypes";

import styles from "./Label.scss";

const Label = ({ field, required, hideHelp, horizontalLabel, behaviorType, disabled }) => {
  const helpText = !hideHelp && field.help_info;
  // FIXME: this id prop doesn't work well with a list-type field, as each has the same ID which violates DOM expectations
  return (
    <div
      id={field.key}
      className={classnames("field-label", styles[`behavior-${behaviorType}`], {
        [styles.horizontalLabel]: horizontalLabel,
        [styles.disable]: disabled,
      })}
    >
      <span className={styles.inner}>
        {field.field_label}
        {required && "\u00A0*"}
        {helpText && "\u00A0"}
        {helpText && <Tooltip message={helpText} />}
      </span>
    </div>
  );
};

Label.propTypes = {
  field: fieldPropType.isRequired,
  required: PropTypes.bool,
  hideHelp: PropTypes.bool,
  horizontalLabel: PropTypes.bool,
  behaviorType: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Label;
