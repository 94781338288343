import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import Footer from "components/projects/Footer";
import { useAnswerContext } from "containers/withAnswerContext";
import routes from "utils/routes";

const PaymentFooter = ({ nextTextKey = "guides.footer.pay", ...props }) => {
  const navigate = useNavigate();
  const { record: project } = useAnswerContext();
  const onNav = useCallback(
    () => navigate(routes.projectHandoff(project.id)),
    [navigate, project.id],
  );

  return (
    <Footer {...props} nextTextKey={nextTextKey} backTextKey="guides.footer.exit" onBack={onNav} />
  );
};

export default PaymentFooter;
