import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import { childrenPropType } from "../../../utils/sharedPropTypes";
import Icon from "../../Icon";
import { ButtonDiv } from "../../accessibility/Div";
import withActiveToggle from "../../utilities/withActiveToggle";
import styles from "./Collapsible.scss";

export const CollapsibleComponent = ({ active, toggleActive, title, children }) => {
  const hasContent = !R.all(
    R.identity,
    R.map((row) => R.isNil(row) || (row.trim && !row.trim()), children),
  );

  const Container = hasContent ? ButtonDiv : "div";

  return (
    <div className={classnames(styles.container)}>
      <Container
        className={styles.headline}
        onClick={() => toggleActive(!active)}
        aria-expanded={active}
      >
        <h4>{title}</h4>
        {hasContent && <Icon icon={active ? "angle-up" : "angle-down"} />}
      </Container>
      <div className={classnames(styles.children, { [styles.active]: active })}>{children}</div>
    </div>
  );
};

CollapsibleComponent.propTypes = {
  active: PropTypes.bool,
  toggleActive: PropTypes.func,
  title: PropTypes.string,
  children: childrenPropType,
};

export default withActiveToggle(CollapsibleComponent);
