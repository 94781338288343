import * as R from "ramda";

import { humanize } from "utils/format";

export const listToOptions = R.map((item) => ({ label: item, value: item }));
export const listToHumanizedOptions = R.map((item) => ({ label: humanize(item), value: item }));
export const recordsToOptions = R.curry((records, label = "name", value = "id") =>
  R.map(R.applySpec({ label: R.prop(label), value: R.prop(value) }), records),
);
export const objectToOptions = R.pipe(
  R.toPairs,
  R.map(([value, label]) => ({ label, value })),
);
