// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-messaging-NoMessages__no-messages--wpjps {\n  margin-top: 6em;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n.ui-components-messaging-NoMessages__no-messages--wpjps h3 {\n  font-size: 1.5em;\n}", "",{"version":3,"sources":["webpack://./ui/components/messaging/NoMessages.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;AACF;AACE;EACE,gBAAA;AACJ","sourcesContent":[".no-messages {\n  margin-top: 6em;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n\n  h3 {\n    font-size: 1.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no-messages": "ui-components-messaging-NoMessages__no-messages--wpjps",
	"noMessages": "ui-components-messaging-NoMessages__no-messages--wpjps"
};
export default ___CSS_LOADER_EXPORT___;
