import { setDeviceType } from "../actions";

const configure = (store) => {
  deviceTypeToState(store);
};

const deviceTypeToState = (store) => {
  const tabletSizes = { min: 767, max: 1023 };
  dispatchDeviceTypeToState(store, tabletSizes);

  const mediaQuery = window.matchMedia(
    `(min-width: ${tabletSizes.min}px) and (max-width: ${tabletSizes.max}px)`,
  );
  mediaQuery.addListener(() => dispatchDeviceTypeToState(store, tabletSizes));
};

const dispatchDeviceTypeToState = (store, sizes) => {
  const w = document.documentElement.clientWidth;
  let type;

  if (w <= sizes.min) type = "mobile";
  else if (w > sizes.min && w < sizes.max) type = "tablet";
  else type = "desktop";

  store.dispatch(setDeviceType(type));
};

export default { configure };
