import React from "react";
import classnames from "classnames";

import Text from "containers/Text";

import styles from "./Section.scss";

const Section = ({ headerTextKey, children }) => (
  <div className={styles.sectionContainer}>
    <div className={styles.box}>
      <div className={styles.heading}>{headerTextKey && <Text t={headerTextKey} />}</div>
      <div data-private className={classnames("fs-hide", styles.content)}>
        {children}
      </div>
    </div>
  </div>
);

export default Section;
