import React from "react";

import Header from "components/Header";
import { childrenPropType } from "utils/sharedPropTypes";

const WithHeader = ({ children }) => (
  <div>
    <Header />
    <div>{children}</div>
  </div>
);

WithHeader.propTypes = {
  children: childrenPropType.isRequired,
};

export default WithHeader;
