import PropTypes from "prop-types";

import { logEntryPropType } from "utils/sharedPropTypes";

export default {
  event: logEntryPropType.isRequired,
  Layout: PropTypes.elementType.isRequired,
  applicantContext: PropTypes.bool,
  applicationView: PropTypes.bool,
  projectView: PropTypes.bool,
  context: PropTypes.string,
  timestamp: PropTypes.object.isRequired,
};
