import React, { useCallback } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useSelector } from "react-redux";

import useCurrentGuide from "hooks/useCurrentGuide";
import { getUseCodeFilters } from "reducers/guides";
import { selectUseCodes } from "reducers/tenant";
import filterUseCodes from "utils/filterUseCodes";

import { RadioButtonOption } from "./RadioButtonField";
import { immediateInput } from "./behaviors";

// TODO: Why is this it's own component, but not an actual ui type?
// it's rendered directly from BuildingTypePage
const BuildingTypeField = ({ value, field, onChange, onSave }) => {
  const guide = useCurrentGuide();
  const useCodes = useSelector((state) => {
    const featuredUseCodes = R.filter(R.propEq("featured", true), selectUseCodes(state));
    const useCodeFilter = getUseCodeFilters(guide);
    if (useCodeFilter.length > 0) {
      return filterUseCodes(featuredUseCodes, useCodeFilter);
    }

    return featuredUseCodes.sort((a, b) =>
      (a.icon_label || a.name).localeCompare(b.icon_label || b.name),
    );
  });

  const onChangeUseCode = useCallback(
    (selectedValue) => {
      const useCode = R.find(R.propEq("slug", selectedValue), useCodes);
      return onChange({ use: useCode });
    },
    [useCodes, onChange],
  );

  return (
    <BuildingTypeOptions
      value={value}
      field={field}
      onChange={onChangeUseCode}
      onSave={onSave}
      useCodes={useCodes}
    />
  );
};

// HACK - this lets us replace use featured + attached icon and thus paperclip.
// this should work like special event types on config side in future
function guessIconKey(label) {
  const l = label.toLowerCase();

  if (l.match(/mobile/) || l.match(/manufacture/)) return "mobile-home";
  if (l.match(/guest/) || l.match(/accessory/)) return "adu";
  if (l.match(/apartment/) || l.match(/multi/)) return "apartment";
  if (l.match(/three/) || l.match(/town/) || l.match(/triple/)) return "three-family-home";
  if (l.match(/two/) || l.match(/duplex/)) return "two-family-home";
  if (l.match(/single/) || l.match(/one/) || l.match(/group/)) return "single-family-home";

  return "other";
}

const BuildingTypeOptions = immediateInput(
  Object.assign(
    ({ value, field, onChange, useCodes }) => {
      const selectedValue = R.pathOr("", ["use", "slug"], value);

      return (
        <div data-building-type-field>
          {R.map(
            (u) => (
              <div data-use-code-results key={u.full_name}>
                <RadioButtonOption
                  fieldKey={field.key}
                  selectOption={{
                    ...u,
                    code: u.slug,
                    label: u.icon_label || u.name,
                    icon_key: guessIconKey(u.icon_label || u.name),
                  }}
                  selectedValue={selectedValue}
                  iconURL={u.icon_url}
                  onChange={onChange}
                  asHeading
                />
              </div>
            ),
            useCodes,
          )}
        </div>
      );
    },
    {
      displayName: "BuildingTypeOptions",
    },
  ),
);

BuildingTypeField.propTypes = {
  field: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default BuildingTypeField;
