import "./utils/whyDidYouRender";
// IMPORTANT: the whyDidYouRender import must be very first

import React from "react";
import objectFitImages from "object-fit-images";
import { createRoot } from "react-dom/client";

import "url-search-params-polyfill";
import { isProductionBuild } from "env";
import { fetchInitialState, lockToGuide, setLocale } from "actions";
import Root from "containers/Root";
import { configAnalytics } from "services/analytics";
import { initDatadogRUM } from "services/datadog";
import detectFontAwesome from "services/detectFontAwesome";
import { wrap as reportErrors } from "services/errorReporter";
import zoningApi from "services/zoningApi";
import configureStore from "stores/configureStore";

require("normalize.css");
require("./sharedStyles/global.scss");
require("./fonts/index.pure.css");

const startApp = () => {
  const rootEl = document.getElementById("container");
  const configurationEl = document.getElementById("configuration");

  const {
    initialReduxState,
    fixedGuideId,
    locale,
    featureFlags,
    deliveryStage,

    datadog,
    google_maps: { token: googleMapsKey },
    mapbox: { token: mapboxToken },
    pusher: pusherConfig,
    recaptcha: { site_key: recaptchaSiteKey },
  } = JSON.parse(configurationEl.innerHTML);

  const store = configureStore({
    initialState: initialReduxState,
  });

  if (datadog) initDatadogRUM(datadog);
  if (fixedGuideId) store.dispatch(lockToGuide(fixedGuideId));
  if (locale) store.dispatch(setLocale(locale));

  const configVariables = {
    recaptchaSiteKey,
    googleMapsKey,
    mapboxToken,
    deliveryStage,
  };

  detectFontAwesome();
  configAnalytics(store);
  store.dispatch(fetchInitialState());
  zoningApi.configure({
    baseURL: window.location.protocol + "//" + window.location.host,
    locale,
    version: initialReduxState.tenant.version_number,
  });

  const root = createRoot(rootEl);
  root.render(
    <Root
      store={store}
      featureFlags={featureFlags}
      pusherConfig={pusherConfig}
      configVariables={configVariables}
    />,
  );

  if (!isProductionBuild) {
    window.store = store;
  }
};

// Polyfill object-fit/object-position on <img>: IE9, IE10, IE11, Edge, Safari, ...
objectFitImages();

reportErrors(startApp, { component: "startApp" })();
