import * as R from "ramda";

import { humanize } from "utils/format";

/** @typedef {Object} FieldDefinition -- ultimately is consumed by LabeledField and then by useFieldProps() -- extra attributes are allowed and are passed through useFieldProps to the Component
 * @param {Object} name - Field name
 * @param {Object} Component - The component used to render the form field
 * @param {Object} [Container] - Forces the component to render in this Container instead of the default
 * @param {Object} [dependentOn] - Only display this field if dependencies are met. @see useDependentOn
 * @param {Object} [label] - Override the derived label
 * @param {Object} [helpText] - Help text in markdown displayed in popover next to field
 * @param {Object} [header] - Text to use when displaying the field in a table
 * @param {?(Function|Bool)} [disabled] - Boolean (or function returning Boolean, which gets the args `[{ record, newRecord }]` from the FormContext) which will disable the field
 * @param {Bool} [required] - Boolean which will indicate the field must have a value
 */

/**
 * Define a field for use in Form and BatchCreateForm
 * @param {String} name - Name of the field used to derive the label and field attribute name
 * @param {FieldDefinition} options - Takes a field definiion and derives some defaults
 * @return {FieldDefinition}
 */
export const defineField = (name, { Component, label, header, fieldName, ...opts }) => {
  label ||= humanize(name);
  header ||= label;

  if (!Component) {
    throw new Error(`defineField ${name} requires a Component`);
  }

  return { type: "FieldDefinition", name: fieldName || name, Component, label, header, ...opts };
};

/**
 * Define a set of fields for use in Form and BatchCreateForm @see defineField
 * @param {Array.<FieldDefinition>} - Defines a list of fields indexed by their key
 */
export const defineFields = R.mapObjIndexed((config, name) => defineField(name, config));

const fieldsWithContainer = (fields, container) => {
  if (!container) {
    return fields;
  }

  return R.map(R.assoc("Container", container), fields);
};

/**
 * Define a group of fields. Usually rendered as a "row"
 * @param {Array.<FieldDefinition>} fields - List of field defintions
 * @param {Object} options
 * @param {Object} [options.Container] - A container to wrap the group in
 * @param {Object} [options.FieldContainer] - Override each fields Container
 */
export const defineGroup = (fields, { Container, FieldContainer } = {}) => ({
  type: "Group",
  children: fieldsWithContainer(fields, FieldContainer),
  Container,
});

/**
 * Define a collapsible form Section
 * @param {String} label - Heading label
 * @param {Array} fields - List containing FieldDefinition's, FieldGroup's, React components
 * @param {Object} [options]
 * @param {Object} [options.FieldContainer] - Override each fields Container
 * @param {Object} [options.dependentOn] - Only display this section if dependencies are met. @see useDependentOn
 * @param {Object} [options.collpased=false] - Render the section as collapsed until open
 * @param {Object} [options.helpText]
 */
export const defineSection = (label, fields = [], { FieldContainer, ...opts } = {}) => ({
  label,
  children: fieldsWithContainer(fields, FieldContainer),
  type: "Section",
  ...opts,
});

/**
 * Define a form Tab
 * @param {String} name - Tab name
 * @param {Array} config - List containing FieldDefinition's, FieldGroup's, FieldSection's, React components
 */
const defineTab = (name, config) => {
  if (Array.isArray(config)) {
    return {
      type: "Tab",
      name,
      label: humanize(name),
      children: config,
    };
  }

  return { name, label: humanize(name), children: [], ...config, type: "Tab" };
};

/**
 * Define a tabbed form
 * @param {Object} config - Keys are tab names, values are their configuration @see defineTab
 */
export const defineTabbedForm = (tabConfig) => ({
  type: "TabbedForm",
  children: R.mapObjIndexed((config, name) => defineTab(name, config), tabConfig),
});

/**
 * Define a form
 * @param {Array} config - List containing FieldDefinition's, FieldGroup's, FieldSection's, React components
 */
export const defineForm = (config) => ({
  type: "Form",
  children: config,
});

/**
 * Flattens any kind of form definition into it's extractFieldDefinitions
 */
export const extractFieldDefinitions = (fieldsConfig) => {
  if (
    typeof fieldsConfig === "function" ||
    (R.is(Object, fieldsConfig) && fieldsConfig.$$typeof === Symbol.for("react.forward_ref"))
  ) {
    return {
      type: "Component",
      Component: fieldsConfig,
    };
  }

  if (Array.isArray(fieldsConfig)) {
    return R.chain(extractFieldDefinitions, fieldsConfig);
  }

  if (R.is(Object, fieldsConfig)) {
    switch (fieldsConfig.type) {
      case "TabbedForm": {
        return extractFieldDefinitions(R.flatten(R.values(fieldsConfig.children)));
      }

      case "Form":
      case "Tab":
      case "Section":
      case "Group": {
        return extractFieldDefinitions(fieldsConfig.children);
      }

      case "FieldDefinition": {
        return fieldsConfig;
      }

      default:
        return null;
    }
  }

  return null;
};

/**
 * Finds the tab a field is defined in
 */
export const tabForField = (config, fieldName) => {
  if (config.type !== "TabbedForm") return null;

  return R.find(
    (t) => R.any(R.propEq("name", fieldName), extractFieldDefinitions(t)),
    R.values(config.children),
  );
};
