import * as R from "ramda";

import { useFieldsList } from "queries/entities";
import { useAttachments } from "queries/messages";
import * as AnswerContext from "reducers/answerContexts";
import { arrayWrap, indexByID } from "utils/func";

/**
 * selector for surfacing documents from a project
 *
 * @param {Object} project
 * @param {*} fields
 * @returns {Object[]}
 */
export const getDocuments = (project, fields) => {
  if (!fields.length > 0 || !project) return [];

  const answersByFieldID = AnswerContext.getAnswersForFieldsIndexByID(
    project,
    fields.filter((field) => field.field_type === "attachment"),
  );

  const fieldsByID = indexByID(fields);

  const requirementApplications =
    project?.requirement_applications &&
    Object.values(project?.requirement_applications).filter(
      (requirementApplication) => requirementApplication.requirement_type !== "Upload",
    );

  const documents = requirementApplications?.flatMap((requirementApplication) => {
    const { requirement_field_ids: requirementFieldIDs, requirement_name: requirementName } =
      requirementApplication;

    const fieldIDs = requirementFieldIDs.filter((fieldID) => answersByFieldID[fieldID]);

    return fieldIDs.flatMap((fieldID) => {
      const answers = arrayWrap(answersByFieldID[fieldID]);

      return answers.map((answer) => ({
        filename: answer.filename,
        url: answer.url,
        label: fieldsByID[fieldID].field_label,
        requirementName,
      }));
    });
  });

  return [...new Set(documents)];
};

/**
 * selector for surfacing "upload" requirements from a project
 *
 * @param {Object} project
 * @param {Object[]} fields
 * @param {Number} requirementApplicationID
 * @returns {Object[]}
 */
export const getUploadRequirements = (project, fields, requirementApplicationID = null) => {
  if (!fields.length > 0 || !project) return [];

  const attachmentFields = fields.filter((field) => field.field_type === "attachment");
  const answersByFieldID = AnswerContext.getAnswersForFieldsIndexByID(project, attachmentFields);

  const requirementApplications =
    typeof project?.requirement_applications === "object"
      ? Object.values(project?.requirement_applications).filter(
          (requirementApplication) =>
            requirementApplication.requirement_type === "Upload" &&
            requirementApplication.id !== requirementApplicationID,
        )
      : [];

  const uploadRequirements =
    requirementApplications?.length > 0
      ? requirementApplications?.flatMap((requirementApplication) => {
          const {
            id: raID,
            state_history,
            submitted_at: submittedAt,
            requirement_id: requirementID,
            requirement_field_ids: requirementFieldIDs,
            requirement_name: requirementName,
            requirement_review_type: requirementReviewType,
          } = requirementApplication;

          const currentState =
            Object.keys(state_history).length > 0
              ? Object.values(state_history)[Object.keys(state_history).length - 1]
              : null;

          const toState = currentState?.to_state || null;
          const status =
            requirementReviewType === "Approved" && toState === "issued" ? "approved" : toState;

          const cta = ["in_progress", null].includes(status) ? "Upload" : "View"; // @TODO i18n

          const fieldID = requirementFieldIDs.find((fieldID) =>
            attachmentFields.find((field) => field.id === fieldID),
          );

          const answers = answersByFieldID[fieldID];

          const uploadRequirement = {
            cta: {
              label: cta,
              pID: parseInt(project.id, 10),
              raID: parseInt(raID, 10),
              rID: parseInt(requirementID, 10),
            },
            status,
            submittedAt,
          };

          // if the answer is a list, only return an entry for the
          // first item to avoid displaying duplicate requirements
          return arrayWrap(answers).map((answer) => ({
            ...uploadRequirement,
            answerID: answer?.id,
            document: {
              name: requirementName,
              url: answer?.url,
            },
          }))[0];
        })
      : [];

  return [...new Set(uploadRequirements)];
};

// selector for surfacing all documents from a project
//
// @param {Object} project
export const useProjectDocuments = (project) => {
  const { data: fields = [] } = useFieldsList({
    select: R.filter(R.propEq("field_type", "attachment")),
  });
  const { data: attachments = [] } = useAttachments(project?.id, { enabled: !!project?.id });

  if (project) {
    const uploadRequirements = getUploadRequirements(project, fields);
    const documents = getDocuments(project, fields);
    const totalCount = uploadRequirements.length + documents.length + attachments.length;
    return { documents, attachments, uploadRequirements, total: totalCount };
  }

  return { documents: [], attachments, uploadRequirements: [], total: 0 };
};
