import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./Line.scss";

export const DefaultLine = ({ noMargin }) => (
  <div className={classnames(styles.defaultLine, { [styles.noMargin]: noMargin })} />
);

DefaultLine.propTypes = {
  noMargin: PropTypes.bool,
};

const Line = ({ containerWidth, fullWidth, noMargin }) => (
  <div
    className={classnames(styles.line, {
      [styles.forceFullWidth]: fullWidth,
      [styles.forceContainerWidth]: containerWidth,
      [styles.noMargin]: noMargin,
    })}
  />
);

Line.propTypes = {
  fullWidth: PropTypes.bool,
  containerWidth: PropTypes.bool,
  noMargin: PropTypes.bool,
};

export default Line;
