import React, { useCallback, useState } from "react";
import classnames from "classnames";
import * as R from "ramda";
import { useSelector } from "react-redux";

import Icon from "components/Icon";
import { ButtonDiv } from "components/accessibility/Div";
import { useFindEntities } from "queries/entities";
import { selectFeesWithTotalsForRequirementID } from "reducers/fees";
import { selectRequirementByKey } from "reducers/requirements";
import { selectRequirementMutationsOfType } from "selectors/changesets";
import { getAmountChanged, getNewValue, getTotalAmountChanged } from "selectors/mutations";
import { maybeMoney } from "utils/format";

import FeeChange from "./FeeChange";
import styles from "./RequirementChanges.scss";

const RequirementChanges = ({ changeset, requirementsHeading }) => {
  const addedRequirementMutations = selectRequirementMutationsOfType(changeset.mutations, "added");
  const removedRequirementMutations = selectRequirementMutationsOfType(
    changeset.mutations,
    "removed",
  );
  const changedRequirementMutations = selectRequirementMutationsOfType(
    changeset.mutations,
    "changed",
  );

  const requirementMutations = [
    ...addedRequirementMutations,
    ...removedRequirementMutations,
    ...changedRequirementMutations,
  ];

  const changedRequirementMutationsWithFeeUpdates = R.reject(
    R.propSatisfies(R.isEmpty, "fee_mutations"),
    changedRequirementMutations,
  );

  const feeMutations = R.chain(R.prop("fee_mutations"), requirementMutations);

  const noChanges =
    [
      ...addedRequirementMutations,
      ...removedRequirementMutations,
      ...changedRequirementMutationsWithFeeUpdates,
      ...feeMutations,
    ].length === 0;

  if (noChanges) return null;

  const feeTotals = R.propOr({}, "fees", changeset);

  return (
    <>
      {requirementsHeading && <h2>{requirementsHeading}</h2>}
      <div className={styles.container}>
        {addedRequirementMutations.length > 0 && (
          <RequirementMutationsGroup
            mutations={addedRequirementMutations}
            name="New requirements"
            feeTotals={feeTotals}
          />
        )}
        {removedRequirementMutations.length > 0 && (
          <RequirementMutationsGroup
            mutations={removedRequirementMutations}
            name="No longer required"
            feeTotals={feeTotals}
          />
        )}
        {changedRequirementMutationsWithFeeUpdates.length > 0 && (
          <RequirementMutationsGroup
            mutations={changedRequirementMutationsWithFeeUpdates}
            name="Fee updates"
            feeTotals={feeTotals}
          />
        )}
      </div>
    </>
  );
};
const RequirementMutationsGroup = ({ mutations, name, feeTotals }) => (
  <div className={styles.requirementGroup}>
    <div className={styles.groupHeader}>{name}</div>
    <div className={styles.requirementMutations}>
      {mutations.map((mutation) => (
        <RequirementMutation mutation={mutation} feeTotals={feeTotals} key={mutation.key} />
      ))}
    </div>
  </div>
);

const RequirementMutation = ({ mutation, feeTotals }) => {
  const requirement = useSelector((state) => selectRequirementByKey(state, mutation.key));
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded]);

  const requirementFees = useSelector((state) =>
    selectFeesWithTotalsForRequirementID(state, feeTotals, requirement.id),
  );
  const requirementTotal = R.sum(R.map(R.propOr(0, "cost"), requirementFees));

  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <ButtonDiv
      className={classnames(styles.requirementMutation, {
        [styles.expanded]: expanded,
        [styles.removed]: mutation.type === "removed",
      })}
      onClick={toggleExpanded}
      aria-expanded={expanded}
      role="listitem"
    >
      <div className={styles.requirementMutationRow}>
        <div className={styles.requirementName}>{requirement.name}</div>
        <div className={styles.summaryContainer}>
          {mutation.fee_mutations.length > 0 && (
            <FeeMutationsSummary
              mutations={mutation.fee_mutations}
              total={requirementTotal}
              expanded={expanded}
            />
          )}
          <div className={styles.openItem}>
            <Icon size="lg" icon={expanded ? "angle-up" : "angle-down"} />
          </div>
        </div>
      </div>
      {expanded && mutation.fee_mutations.length > 0 && (
        <div className={styles.expandedContent}>
          <div className={styles.requirementDescription}>{requirement.description}</div>
          <FeeMutations
            fees={requirementFees}
            mutations={mutation.fee_mutations}
            total={requirementTotal}
          />
        </div>
      )}
    </ButtonDiv>
  );
};

const FeeMutationsSummary = ({ mutations, total }) => {
  const feeChange = R.reduce((acc, mutation) => acc + getAmountChanged(mutation), 0, mutations);

  return (
    <div className={styles.feeChangeSummary}>
      <FeeChange change={feeChange} />
      <div className={styles.newFee}>{maybeMoney(total)}</div>
    </div>
  );
};

const FeeMutations = ({ mutations, fees, total }) => {
  const changedFees = R.map(R.prop("identifier"), mutations);
  const existingFees = fees.filter((fee) => !changedFees.includes(fee.identifier));

  return (
    <>
      {mutations.map((mutation) => (
        <FeeMutation mutation={mutation} key={mutation.identifier} />
      ))}
      {existingFees.map((fee) => (
        <FeeChangeDetails
          key={fee.id}
          name={fee && fee.name}
          amountChanged={0}
          totalAmount={fee.cost}
        />
      ))}
      <FeeChangeDetails
        name="Total fees"
        amountChanged={getTotalAmountChanged(mutations)}
        totalAmount={total}
      />
    </>
  );
};

const FeeMutation = ({ mutation }) => {
  const { data: fees = [] } = useFindEntities([mutation.identifier]);
  const fee = fees[0];

  return (
    <FeeChangeDetails
      name={fee && fee.name}
      amountChanged={getAmountChanged(mutation)}
      totalAmount={getNewValue(mutation)}
    />
  );
};

const FeeChangeDetails = ({ name, amountChanged, totalAmount }) => (
  <div className={styles.feeChangeDetails}>
    <div className={styles.feeName}>{name}</div>
    <div className={styles.fees}>
      <FeeChange change={amountChanged} />
      <div className={styles.newFee}>{maybeMoney(totalAmount)}</div>
    </div>
  </div>
);

export default RequirementChanges;
