// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-sharedStyles-errors__error-border--l8NZ3 {\n  border: 2px solid #d0021b;\n}", "",{"version":3,"sources":["webpack://./ui/sharedStyles/errors.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;AADF","sourcesContent":["@use \"./variables.scss\" as *;\n\n.error-border {\n  border: 2px solid $error-color;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-border": "ui-sharedStyles-errors__error-border--l8NZ3",
	"errorBorder": "ui-sharedStyles-errors__error-border--l8NZ3"
};
export default ___CSS_LOADER_EXPORT___;
