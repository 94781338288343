import { create } from "axios";
import { equals, pathSatisfies } from "ramda";

import clientInstanceId from "services/clientInstanceId";

// This is an unversioned client, use the versionedClient for anything versioned
const ocClient = create({
  headers: {
    "X-Client-Instance-ID": clientInstanceId,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const isUnauthorizedError = pathSatisfies(equals(401), ["response", "status"]);
export const isForbiddenError = pathSatisfies(equals(403), ["response", "status"]);
export const isNotFoundError = pathSatisfies(equals(404), ["response", "status"]);
export const isUnprocessableError = pathSatisfies(equals(422), ["response", "status"]);
export const isCSRFError = pathSatisfies(equals(488), ["response", "status"]);
export const isTermsAndConditionsError = pathSatisfies(equals(489), ["response", "status"]);

export default ocClient;
