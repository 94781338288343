import React from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";

import Icon from "components/Icon";
import Text from "containers/Text";
import { selectFormattedText } from "selectors/translations";
import { getDescription } from "utils/permission";

import styles from "./Permission.scss";

const categoryIcons = {
  permitted: "check-circle",
  conditional: "do-not-enter",
  other: "do-not-enter",
  prohibited: "times-hexagon",
};

const Permission = ({
  isZoningEnabled,
  permission,
  category,
  unansweredFieldCount,
  withoutUnansweredQuestions = false,
}) => {
  const translatedCategoryName = useSelector(
    (state) => category && selectFormattedText(state, `guides.location.${category}`),
  );

  if (!isZoningEnabled || !category) return null;

  return (
    <div>
      <div className={classnames(styles[category], styles.title)}>
        <Icon icon={categoryIcons[category]} />
        <span>{translatedCategoryName}</span>
      </div>
      <div className={styles.content}>
        {(withoutUnansweredQuestions || unansweredFieldCount === 0) && getDescription(permission)}

        <div className={styles.disclaimer}>
          <Text t="fields.zoning.disclaimer" />
        </div>

        {!withoutUnansweredQuestions && unansweredFieldCount > 0 && (
          <Text
            t="fields.zoning.unanswered_questions"
            category={translatedCategoryName}
            unansweredFieldCount={unansweredFieldCount}
          />
        )}
      </div>
    </div>
  );
};

export default Permission;
