import { useMutation } from "react-query";

import { useVersionedClient } from "contexts/versionedClient";

export const useEphemeralProject = (options = {}) => {
  const client = useVersionedClient();

  return useMutation(async (postData) => {
    const { data } = await client.post("/api/admin/ephemeral_projects", {
      ...postData,
    });
    return data;
  }, options);
};

export const useCreateEphemeralProject = (options = {}) => {
  const client = useVersionedClient();

  return useMutation(async (postData) => {
    const { data } = await client.post(`/api/admin/ephemeral_projects/apply`, {
      ...postData,
    });
    return data;
  }, options);
};

export const useEphemeralChangeset = (projectID, options = {}) => {
  const client = useVersionedClient();

  return useMutation(async (postData) => {
    const { data } = await client.post(`/api/admin/projects/${projectID}/ephemeral_changesets`, {
      ...postData,
    });
    return data;
  }, options);
};

export const useApplyEphemeralChangeset = (projectID) => {
  const client = useVersionedClient();

  return useMutation(async (postData) => {
    const { data } = await client.post(
      `/api/admin/projects/${projectID}/ephemeral_changesets/apply`,
      {
        ...postData,
      },
    );
    return data;
  });
};
