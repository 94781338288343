/* eslint no-constant-condition: ["error", { "checkLoops": false }] */
/* eslint-disable no-alert */

import * as R from "ramda";
import { channel } from "redux-saga";
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";

import * as actions from "actions";
import { push, replace } from "components/Router";
import {
  getActiveFieldIDs,
  getAnswersForFields,
  getContextType,
  getErrors,
} from "reducers/answerContexts";
import { selectFieldKeysForIDs } from "reducers/fields";
import { isUnauthorizedError } from "reducers/network";
import { selectFirstPage, selectMissingFieldIDs, selectProjectByID } from "reducers/projects";
import { selectRequirementApplication } from "reducers/requirementApplications";
import { getSlug as getRequirementSlug, selectRequirementByID } from "reducers/requirements";
import { getTimezone, selectTenantID, selectTenantVersionNumber } from "reducers/tenant";
import { selectDownloadInProgress } from "reducers/ui";
import { selectInitialStateURLByVersionNumber } from "reducers/versions";
import debounceWithJitter from "sagas/debounceWithJitter";
import { selectCurrentUser, selectFullName } from "selectors/users";
import analytics from "services/analytics";
import { fetchInitialState as apiFetchInitialState, unversionedAPI } from "services/api";
import { reportError } from "services/errorReporter";
import { PDF_DOWNLOADED } from "services/tracking";
import NetworkError from "utils/NetworkError";
import appendToQueryString from "utils/appendToQueryString";
import dayjs from "utils/dayjs";
import { isBlank } from "utils/func";
import { isConfigURL } from "utils/urls";

import AnswerBuffer from "./AnswerBuffer";
import apiCall from "./apiCall";
import { authSaga, waitForSessionFetch } from "./auth";
import formSaga from "./formSaga";
import getAPIContext from "./getAPIContext";

export function* fetchInitialState({ payload: { versionNumber } = {} }) {
  const tenantId = yield select(selectTenantID);

  if (!versionNumber) {
    versionNumber = yield select(selectTenantVersionNumber);
  }

  let initial_state_url = yield select(selectInitialStateURLByVersionNumber, versionNumber);

  if (!initial_state_url) {
    yield put(actions.fetchVersions());
    yield take(actions.SET_VERSIONS);
    initial_state_url = yield select(selectInitialStateURLByVersionNumber, versionNumber);
  }

  const initialState = yield call(
    apiCall,
    {
      type: "Request",
      entityType: "InitialState",
      entityID: tenantId,
    },
    apiFetchInitialState,
    initial_state_url,
  );
  if (initialState instanceof NetworkError) return;

  yield put(actions.updateTenant(initialState.tenant));
  dayjs.tz.setDefault(getTimezone(initialState.tenant));

  if (!isConfigURL()) {
    yield put(actions.setFees(initialState.fees));
    yield put(actions.setGuides(initialState.guides));
    yield put(actions.setPages(initialState.pages));
    yield put(actions.setRequirements(initialState.requirements));
    yield put(actions.setDepartments(initialState.departments));
    yield put(actions.addFields({ fields: R.values(initialState.fields) }));
  }

  yield put(actions.initializeState());
}

export function* createProject({ payload }) {
  const api = yield call(getAPIContext);
  const json = yield call(
    apiCall,
    {
      type: "Mutation",
      entityType: "Project",
      entityID: 0,
      guideID: payload.guideID,
    },
    api.createProject,
    { guide_id: payload.guideID },
  );
  if (json instanceof NetworkError) return;

  yield* waitForSessionFetch("sagas.createProject");
  const { project } = json;
  yield put(actions.addProject(project));
  yield call(projectVersionGuard, project);

  const firstPage = yield select(selectFirstPage, project);

  yield put(push(`/projects/${project.id}/guide/${firstPage.slug}`));
}

// TODO: Extract common code w/ createProject
// coming from DirectApplicationRedirect
export function* createDirectApplicationProject({ payload }) {
  const api = yield call(getAPIContext);
  const json = yield call(
    apiCall,
    {
      type: "Mutation",
      entityType: "Project",
      entityID: 0,
      guideID: payload.guideID,
    },
    api.createProject,
    {
      guide_id: payload.guideID,
      requirement_ids: [payload.requirementID],
    },
  );
  if (json instanceof NetworkError) return;
  yield* waitForSessionFetch("sagas.createDirectApplicationProject");
  const { project } = json;
  yield put(actions.addProject(project));
  yield call(projectVersionGuard, project);

  const requirement = yield select(selectRequirementByID, payload.requirementID);

  if (requirement.direct_to_handoff) {
    yield put(replace(`/projects/${project.id}/apply`));
  } else {
    yield put(replace(`/projects/${project.id}/direct/${requirement.slug}`));
  }
}

export function* createRenewal({ payload }) {
  const json = yield call(
    apiCall,
    {
      type: "Mutation",
      entityType: "Project",
      entityID: 0,
      guideID: 0,
    },
    unversionedAPI.project.createRenewal,
    payload.projectID,
    payload.requirement.id,
  );
  if (json instanceof NetworkError) return;
  yield* waitForSessionFetch("sagas.createRenewal");
  const { project } = json;
  yield put(actions.addProject(project));
  yield call(projectVersionGuard, project);

  yield put(push(`/projects/${project.id}/direct/${getRequirementSlug(payload.requirement)}`));
}

export function* downloadPDF({ payload: { record: project } }) {
  yield call(analytics.track, PDF_DOWNLOADED, { pdf: "project" });
  yield call(
    apiCall,
    {
      type: "Mutation",
      entityType: "PDF",
      entityID: project.id,
    },
    unversionedAPI.project.fetchPDF,
    project.id,
  );
}

export function* downloadRequirementApplicationPDF({ payload: { requirementApplicationID } }) {
  yield call(analytics.track, PDF_DOWNLOADED, {
    pdf: "requirementApplication",
  });
  yield call(
    apiCall,
    {
      type: "Mutation",
      entityType: "PDF",
      entityID: requirementApplicationID,
    },
    unversionedAPI.downloadRequirementApplicationPDF,
    requirementApplicationID,
  );
}

export function* downloadIssuedRequirementPDF({ payload: { requirementApplicationID } }) {
  yield call(analytics.track, PDF_DOWNLOADED, { pdf: "issuedRequirement" });
  yield call(
    apiCall,
    {
      type: "Mutation",
      entityType: "PDF",
      entityID: requirementApplicationID,
    },
    unversionedAPI.downloadIssuedRequirementPDF,
    requirementApplicationID,
  );
}

export function* downloadReady({ payload: { url } }) {
  const downloadInProgress = yield select(selectDownloadInProgress);

  if (!downloadInProgress) return;

  yield put(actions.downloadSuccess());
  window.location.assign(url);
}

export function downloadError({ payload: { error } }) {
  reportError(new Error(error), { context: { component: "downloadError" } });
}

export function* reloadRecord(answerContext) {
  const record = yield select(selectProjectByID, answerContext.record.id);

  return R.assoc("record", record, answerContext);
}

export function* fetchMissingFieldDefinitions(context) {
  const api = yield call(getAPIContext);
  const unloadedFieldIDs = yield select(selectMissingFieldIDs, context);
  if (R.isEmpty(unloadedFieldIDs)) return;

  const response = yield call(
    apiCall,
    { type: "Request", entityType: "Fields" },
    api.fetchFields,
    unloadedFieldIDs,
  );
  if (response instanceof NetworkError) return;

  yield put(actions.addFields({ fields: response }));
}

export function* fetchProject({ payload: { id } }) {
  if (isBlank(id)) {
    reportError(new Error(`Project fetch called without a project`), {
      context: { location: window.location.href },
    });
    return;
  }

  const project = yield call(
    apiCall,
    {
      type: "Request",
      entityType: "Project",
      entityID: id,
    },
    unversionedAPI.project.fetch,
    id,
  );
  if (project instanceof NetworkError) return;

  yield call(projectVersionGuard, project);
  yield* fetchMissingFieldDefinitions(project);

  yield put(actions.updateContext(R.assoc("meta", { from: "fetchProject" }, project)));
}

export function* submitUpdateUser({ payload: { user, form } }) {
  yield call(formSaga, form, updateUser, user, { skipReset: true });
}

export function* updateUser(user) {
  const response = yield call(
    apiCall,
    {
      type: "Mutation",
      entityType: "User",
      entityID: user.id,
    },
    unversionedAPI.updateUser,
    user,
  );
  if (response instanceof NetworkError) return;

  const { user: newUser } = response;
  yield put(actions.updateUserSuccess({ user: newUser }));
}

export function* updateUserSuccess({ payload: { user } }) {
  const currentUser = yield select(selectCurrentUser);
  if (R.eqProps("id", user, currentUser)) {
    yield* waitForSessionFetch("sagas.updateUserSuccess");
  } else {
    yield put(actions.updateUser(user));
  }
}

function* submitAssignRequirementApplication({
  payload: {
    payload: { requirementApplication, note },
    form,
    redirectURL,
  },
  meta,
}) {
  if (note) {
    const userName = yield select(selectFullName);
    yield call(addRequirementApplicationNote, {
      payload: {
        note: { text: note, by: userName, created_at: new Date().toString() },
        requirementApplicationID: requirementApplication.id,
      },
    });
  }

  yield call(formSaga, form, updateRequirementApplication, { requirementApplication }, meta);
  yield put(push(redirectURL));
}

export function* claimRequirementApplication({ payload: { requirementApplication } }) {
  const user = yield select(selectCurrentUser);
  yield call(assignRequirementApplication, { requirementApplication, user });
}

function* assignRequirementApplication({ requirementApplication, user }) {
  const newApp = R.evolve(
    {
      assigned_user_ids: R.pipe(R.append(user.id), R.uniq),
    },
    requirementApplication,
  );
  yield call(updateRequirementApplication, {
    requirementApplication: R.pick(["id", "assigned_user_ids"], newApp),
  });
}

export function* updateRequirementApplication({ requirementApplication }) {
  const api = yield call(getAPIContext);
  yield put(actions.mergeRequirementApplication(requirementApplication));
  yield call(
    apiCall,
    {
      type: "Mutation",
      entityType: "RequirementApplication",
      entityID: requirementApplication.id,
    },
    api.admin.updateRequirementApplication,
    requirementApplication,
  );
}

export function* saveAnswer({ payload: { field, answerContext } }) {
  yield call(saveAnswers, { payload: { fields: [field], answerContext } });
}

export function* saveAnswers({ payload: { fields, answerContext } }) {
  const { record } = yield call(reloadRecord, answerContext);

  const answers = getAnswersForFields(record, fields);
  const currentErrors = getErrors(record);

  let json;
  try {
    json = yield call(
      apiCall,
      {
        type: "Mutation",
        entityType: getContextType(record),
        entityID: record.id,
        entityAttrs: R.keys(answers),
      },
      unversionedAPI.project.saveAnswers,
      record,
      answers,
    );
  } catch (errors) {
    if (!R.is(Object, errors)) throw errors;

    yield put(
      actions.updateValidationError({
        answerContext,
        errors: { ...currentErrors, ...errors.errors },
      }),
    );

    return;
  }
  if (json instanceof NetworkError) return;

  const fieldIDs = R.map(R.prop("id"), fields);
  const updatedRecord = json;
  updatedRecord.errors = R.omit(fieldIDs, currentErrors);

  yield* fetchMissingFieldDefinitions(updatedRecord);

  const activeFieldIDs = getActiveFieldIDs(record);
  const activeFieldKeys = yield select(selectFieldKeysForIDs, activeFieldIDs);
  activeFieldKeys.push("project_name");

  yield put(actions.removeInactiveAnswers({ answerContext, activeFieldKeys }));

  yield put(actions.updateContext(updatedRecord));
}

const defaultActionMap = {
  [actions.SAVE_ANSWER]: (action) => call(saveAnswer, action),
  [actions.SAVE_ANSWERS]: (action) => call(saveAnswers, action),
};

function* handleAnswerSaving(chan, buffer) {
  while (true) {
    const action = yield take(chan);

    yield defaultActionMap[action.type](action);

    if (buffer.isEmpty()) {
      yield put(actions.setProjectSavePending(false));
    }
  }
}

function* orderAnswerSaving() {
  const contextBuffer = new AnswerBuffer();
  const contextChan = yield call(channel, contextBuffer);
  yield fork(handleAnswerSaving, contextChan, contextBuffer);

  while (true) {
    const action = yield take([actions.SAVE_ANSWERS, actions.SAVE_ANSWER]);

    yield put(actions.setProjectSavePending(true));
    yield put(contextChan, action);
  }
}

function changeLocale({ payload: locale }) {
  // TODO: use initial state reloading
  window.location.href = appendToQueryString(window.location.href, "locale", locale);
}

function* fetchRequirementApplications({ payload }) {
  const api = yield call(getAPIContext);
  const requirementApplications = yield call(
    apiCall,
    { type: "Request", entityType: "RequirementApplications" },
    api.fetchRequirementApplications,
    payload,
  );
  if (requirementApplications instanceof NetworkError) return;

  yield put(actions.updateRequirementApplications(requirementApplications));
}

function* fetchTenantSummary() {
  const api = yield call(getAPIContext);
  const summary = yield call(
    apiCall,
    { type: "Request", entityType: "TenantSummary" },
    api.fetchTenantSummary,
  );
  if (summary instanceof NetworkError) return;

  yield put(actions.updateTenant(summary));
}

function* fetchRequirementApplication({ payload: id }) {
  const api = yield call(getAPIContext);
  const requirementApplication = yield call(
    apiCall,
    { type: "Request", entityType: "RequirementApplication", entityID: id },
    api.fetchRequirementApplication,
    id,
  );

  if (requirementApplication instanceof NetworkError) return undefined;
  yield put(actions.updateRequirementApplication(requirementApplication));
  return requirementApplication;
}

function* processRequirementApplication({ payload: { id } }) {
  const api = yield call(getAPIContext);
  const requirementApplication = yield select(selectRequirementApplication, id);
  yield call(claimRequirementApplication, {
    payload: { requirementApplication },
  });

  yield call(
    apiCall,
    { type: "Mutation", entityType: "RequirementApplication", entityID: id },
    api.admin.processRequirementApplication,
    id,
  );
  yield call(fetchRequirementApplication, { payload: id });
  yield call(fetchProject, {
    payload: { id: requirementApplication.project_id },
  });
}

export function* submitReviewRequirementApplication({
  payload: { form, redirectURL, id, values },
}) {
  yield call(formSaga, form, reviewRequirementApplication, {
    form,
    id,
    values,
  });
  yield put(push(redirectURL));
}

function* reviewRequirementApplication({ form, id, values }) {
  const api = yield call(getAPIContext);
  const requirementApplication = yield select(selectRequirementApplication, id);
  yield call(claimRequirementApplication, {
    payload: { requirementApplication },
  });

  yield call(
    apiCall,
    { type: "Mutation", entityType: "RequirementApplication", entityID: id },
    api.admin[form],
    id,
    values,
  );
}

export function* submitRevokeRequirementApplication({ payload: { form, redirectURL, id, note } }) {
  yield call(formSaga, form, revokeRequirementApplication, { id, note });
  yield put(push(redirectURL));
}

function* revokeRequirementApplication({ id, note }) {
  const api = yield call(getAPIContext);
  const requirementApplication = yield select(selectRequirementApplication, id);
  yield call(claimRequirementApplication, {
    payload: { requirementApplication },
  });

  yield call(
    apiCall,
    { type: "Mutation", entityType: "RequirementApplication", entityID: id },
    api.admin.revokeRequirementApplication,
    id,
    note,
  );
}

function* addRequirementApplicationNote({
  payload: {
    requirementApplicationID,
    projectID,
    note: { text },
  },
}) {
  const api = yield call(getAPIContext);
  yield call(
    apiCall,
    {
      type: "Mutation",
      entityType: "RequirementApplicationNote",
    },
    api.addRequirementApplicationNote,
    requirementApplicationID,
    text,
  );

  yield call(fetchProject, { payload: { id: projectID } });
}

function* createRefund({ payload: { form, ...payload }, meta }) {
  yield call(formSaga, form, submitRefund, payload, meta);
}

function* submitRefund(payload) {
  const api = yield call(getAPIContext);
  yield call(
    apiCall,
    {
      type: "Mutation",
      entityType: "Refund",
    },
    api.admin.submitRefund,
    payload,
  );
}

function* fetchUsers() {
  const api = yield call(getAPIContext);
  const users = yield call(
    apiCall,
    {
      type: "Request",
      entityType: "Users",
    },
    api.admin.fetchUsers,
  );
  if (users instanceof NetworkError) return;
  yield put(actions.updateUsers(users));
}

function* fetchUser({ payload: id }) {
  const api = yield call(getAPIContext);
  const response = yield call(
    apiCall,
    {
      type: "Request",
      entityType: "User",
      entityID: id,
    },
    api.admin.fetchUser,
    id,
  );
  if (response instanceof NetworkError) return;
  const { user, departments } = response;
  yield put(actions.setDepartments(departments));
  yield put(actions.updateUser(user));
}

function* submitAdminUser({ payload: { user, form } }) {
  yield call(formSaga, form, saveAdminUser, user);
}

function* saveAdminUser(attrs) {
  const api = yield call(getAPIContext);
  const { id } = attrs;
  const apiMethod = id ? api.admin.updateUser : api.admin.createUser;
  const adminUser = yield call(
    apiCall,
    {
      type: "Mutation",
      entityType: "User",
      entityID: id,
    },
    apiMethod,
    attrs,
  );
  if (adminUser instanceof NetworkError) return;

  yield put(actions.updateUserSuccess({ user: adminUser }));
  yield put(push(`/admin/users/${adminUser.id}`));
}

function* fetchRequirement({ payload: id }) {
  const api = yield call(getAPIContext);
  const requirement = yield call(
    apiCall,
    { type: "Request", entityType: "Requirement", entityID: id },
    api.fetchRequirement,
    id,
  );
  if (requirement instanceof NetworkError) return;

  yield put(actions.updateRequirement(requirement));
}

function* downloadTransactions({ payload: { params } }) {
  const api = yield call(getAPIContext);
  yield call(
    apiCall,
    { type: "Request", entityType: "Transaction" },
    api.admin.downloadTransactions,
    params,
  );
}

function* fetchVersions() {
  const api = yield call(getAPIContext);
  const versions = yield call(
    apiCall,
    { type: "Request", entityType: "Versions" },
    api.config.fetchVersions,
  );
  if (versions instanceof NetworkError) return;
  yield put(actions.setVersions(versions));
}

function* submitChangeVersion({ payload: { version_number, form } }) {
  yield call(formSaga, form, changeVersion, version_number);
}

function* changeVersion(versionNumber) {
  yield put(actions.fetchInitialState({ versionNumber }));
}

export function* projectVersionGuard(project) {
  const currentVersion = yield select(selectTenantVersionNumber);

  if (project.version_number && project.version_number !== currentVersion)
    yield call(changeVersion, project.version_number);
}

function* handleNetworkError({ payload: error }) {
  if (isUnauthorizedError(error)) {
    yield* waitForSessionFetch("sagas.handleNetworkError"); // TODO: this actually *triggers* a session invalidation, but we also have that occurring on the same trigger (401 response) within SessionRelatedNetworkErrorHandler. Do we see double invalidations if this fires (only would in the case of the apiCall saga)? Is that an issue (extra /session request, etc), or does it happen faster than the Query can react?
  }
}

export default function* rootSaga() {
  yield all([
    authSaga(),

    orderAnswerSaving(),
    takeLeading(actions.CREATE_PROJECT, createProject),
    takeLeading(actions.ADD_REQUIREMENT_APPLICATION_NOTE, addRequirementApplicationNote),
    takeLatest(actions.FETCH_PROJECT, fetchProject),

    takeLeading(actions.FETCH_INITIAL_STATE, fetchInitialState),
    takeLatest(actions.FETCH_REQUIREMENT_APPLICATIONS, fetchRequirementApplications),
    takeLatest(actions.FETCH_REQUIREMENT_APPLICATION, fetchRequirementApplication),
    takeLatest(actions.FETCH_TENANT_SUMMARY, fetchTenantSummary),
    takeLatest(actions.FETCH_USERS, fetchUsers),
    takeLatest(actions.FETCH_USER, fetchUser),
    takeLatest(actions.FETCH_REQUIREMENT, fetchRequirement),
    takeLatest(actions.FETCH_VERSIONS, fetchVersions),

    takeLatest(actions.CREATE_DIRECT_APPLICATION_PROJECT, createDirectApplicationProject),
    takeLatest(actions.CREATE_RENEWAL, createRenewal),
    takeLatest(actions.DOWNLOAD_PDF, downloadPDF),
    takeLatest(actions.DOWNLOAD_REQUIREMENT_APPLICATION_PDF, downloadRequirementApplicationPDF),
    takeLatest(actions.DOWNLOAD_ISSUED_REQUIREMENT_PDF, downloadIssuedRequirementPDF),
    takeLatest(actions.DOWNLOAD_READY, downloadReady),
    takeLatest(actions.DOWNLOAD_ERROR, downloadError),
    takeLatest(actions.CHANGE_LOCALE, changeLocale),
    takeLatest(actions.PROCESS_REQUIREMENT_APPLICATION, processRequirementApplication),
    takeLatest(actions.REVIEW_REQUIREMENT_APPLICATION, submitReviewRequirementApplication),
    takeLatest(actions.REVOKE_REQUIREMENT_APPLICATION, submitRevokeRequirementApplication),
    takeLatest(actions.ASSIGN_REQUIREMENT_APPLICATION, submitAssignRequirementApplication),
    takeLatest(actions.CLAIM_REQUIREMENT_APPLICATION, claimRequirementApplication),
    takeLatest(actions.UPDATE_USER_SUCCESS, updateUserSuccess),
    takeLatest(actions.SUBMIT_REFUND, createRefund),
    takeLatest(actions.SAVE_ADMIN_USER, submitAdminUser),
    takeLatest(actions.DOWNLOAD_TRANSACTIONS, downloadTransactions),
    takeLatest(actions.CHANGE_VERSION, submitChangeVersion),

    takeEvery(actions.NETWORK_REQUEST_ERROR, handleNetworkError),

    debounceWithJitter(2000, 40000, actions.REFRESH_TENANT_SUMMARY, fetchTenantSummary),
  ]);
}
