import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";

import errorStyles from "sharedStyles/errors.scss";
import { errorPropType } from "utils/sharedPropTypes";

import Icon from "../Icon";
import styles from "./TelephoneField.scss";
import { textInput } from "./behaviors";

export const TelephoneFieldComponent = ({ name, value, onChange, onBlur, error, required }) => (
  <div className={styles.container}>
    <MaskedInput
      className={classnames(styles.telephoneInput, {
        [errorStyles.errorBorder]: error,
      })}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      type="tel"
      mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
      aria-invalid={!!error}
      aria-required={!!required}
      data-testid={`${name}-input`} // TODO - make a helper for this to standardize format of name to kabob case
      guide
    />
    {error ? <Icon icon="exclamation" /> : null}
  </div>
);

TelephoneFieldComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: errorPropType,
  required: PropTypes.bool,
};

export default textInput(TelephoneFieldComponent);
