import React, { useContext, useMemo } from "react";
import { FormProvider as HookFormProvider } from "react-hook-form";

import useIsPreviousVersion from "hooks/useIsPreviousVersion";

const FormContext = React.createContext();

const FormProvider = ({ children, record, newRecord, disabled = false, ...formMethods }) => {
  const value = useMemo(
    () => ({
      disabled,
      record,
      newRecord,
    }),
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [disabled, record],
  );

  return (
    <HookFormProvider {...formMethods}>
      <FormContext.Provider value={value} children={children} />
    </HookFormProvider>
  );
};

export const FormProviderWithDisabled = (props) => {
  const disabled = useIsPreviousVersion();

  return <FormProvider {...props} disabled={disabled} />;
};
export const useFormRecordContext = () => useContext(FormContext) || {};

export default FormProvider;
