import { useLayoutEffect, useState } from "react";
import * as R from "ramda";
import ReactDOM from "react-dom";

const SafePortal = ({ targetID, children }) => {
  const [domReady, setDomReady] = useState(false);
  const el = document.getElementById(targetID);

  useLayoutEffect(() => {
    if (el) {
      setDomReady(true);
      return R.T;
    }

    const observer = new MutationObserver((_, observer) => {
      const divElement = document.getElementById(targetID);
      if (divElement) {
        setDomReady(true);
        observer.disconnect();
      }
    });
    observer.observe(document, { subtree: true, childList: true });
    return () => observer.disconnect();
  }, [targetID, el]);

  if (domReady) {
    try {
      return ReactDOM.createPortal(children, el);
    } catch (e) {
      return null;
    }
  }
  return null;
};

export default SafePortal;
