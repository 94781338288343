import React from "react";
import PropTypes from "prop-types";

import styles from "./CircularProgressBar.scss";

const CircularProgressbar = ({ percentage, strokeWidth, color, trailColor }) => {
  const radius = 50 - strokeWidth / 2;
  const diameter = Math.PI * 2 * radius;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  return (
    <svg className={styles.container} viewBox="0 0 100 100">
      <path
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={{ stroke: trailColor }}
      />

      <path
        className={styles.path}
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={{
          stroke: color,
          strokeDasharray: `${diameter}px ${diameter}px`,
          strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
        }}
      />
    </svg>
  );
};

CircularProgressbar.propTypes = {
  percentage: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
  trailColor: PropTypes.string,
};

CircularProgressbar.defaultProps = {
  strokeWidth: 12,
  color: "#5cb85c",
  trailColor: "#888",
};

export default CircularProgressbar;
