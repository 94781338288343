import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useSelector } from "react-redux";

import { Button, TextButton } from "components/Button";
import Popover from "components/Popover";
import FixAnswersTooltip from "components/projects/FixAnswersTooltip";
import Text from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import useAsyncCallbackPendingState from "hooks/useAsyncCallbackPendingState";
import useDefferedButtonHandler from "hooks/useDefferedButtonHandler";
import { useOnBack, useOnNext } from "hooks/useOnNext";
import { getAnswerForField, selectCurrentFieldsForProjectSlug } from "reducers/answerContexts";
import { getKey, selectFieldsByID, selectPrimaryFieldByType } from "reducers/fields";
import { selectGuideByID } from "reducers/guides";
import { selectIsProjectSavePending } from "reducers/network";
import { selectOrderedInvalidFieldsByID, selectPreviousPage } from "reducers/projects";

import FooterShell from "../FooterShell";
import styles from "./Footer.scss";

const useIsEmptyUseCode = () => {
  const useCodeField = useSelector((s) => selectPrimaryFieldByType(s, "use_code"));
  const { record: project = {}, context: { slug: pageSlug } = {} } = useAnswerContext();
  const useCodeAnswer =
    useCodeField && R.path(["use", "slug"], getAnswerForField(project, useCodeField));
  const isBusinessPage = pageSlug === "business-type";
  return isBusinessPage && !useCodeAnswer;
};

export const useErrors = ({ fieldIDs }) => {
  const { record: project = {}, context: { slug: pageSlug } = {} } = useAnswerContext();

  const fields = useSelector((s) =>
    fieldIDs
      ? selectFieldsByID(s, fieldIDs)
      : selectCurrentFieldsForProjectSlug(s, project, pageSlug),
  );
  const invalidFields = useSelector((s) =>
    selectOrderedInvalidFieldsByID(s, project, R.pluck("id", fields)),
  );
  const firstInvalidField = R.head(invalidFields);

  const numErrors = invalidFields.length;
  const scrollToFirstError = useCallback(() => {
    if (firstInvalidField)
      document
        .getElementById(getKey(firstInvalidField))
        .scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }, [firstInvalidField]);

  return {
    numErrors,
    scrollToFirstError,
  };
};

// nb: this only really works for contexts with a guide page
export const ConnectedFooter = ({
  validate,
  nextPage,
  nextPageType = "CalculatedGuidePage",
  fieldIDs,
  contextuallyRequiredFieldIDs,
  backTextKey,
  nextTextKey,
  ...extraProps
}) => {
  const [error, setError] = useState();
  const answerContext = useAnswerContext();

  // IDK if we can assume that the answerContext.context is a page in all cases. Sometimes it's a Requirement. It would be better if we had specific keys for each of those things or at least a robust way to detect which it is, since we would never have both (it's either a guide page or a requirment form filling context). See RequirementFooter, where we expect the context to be a requirement... how does that work when this code gets it?
  const { record: project = {}, context: page = {} } = answerContext;
  const { slug: pageSlug } = page;
  const { guide_id } = project;

  const guide = useSelector((s) => selectGuideByID(s, guide_id));
  const previousPage = useSelector((s) => selectPreviousPage(s, project, pageSlug));

  const disableNext = useIsEmptyUseCode(); // [jb] why are we special casing this? is it to handle cases where the system field isn't required? If so we should just have the page itself override the requiredness (like we do for req scoping)

  backTextKey ||=
    guide.type === "zoning" && pageSlug === "business-type"
      ? "guides.footer.cancel"
      : "guides.footer.previous";

  nextTextKey ||= pageSlug === "fees" ? "guides.summary.start_button" : "guides.footer.next";

  const onNextBehavior = useOnNext({
    page,
    nextPage,
    nextPageType,
    fieldIDs,
    contextuallyRequiredFieldIDs,
  });
  const onNext = useCallback(async () => {
    if (validate) {
      const error = await validate();
      if (error) {
        setError(error);
        return;
      }
    }
    await onNextBehavior();
  }, [onNextBehavior, validate, setError]);

  const { numErrors, scrollToFirstError } = useErrors({ fieldIDs });

  const onBack = useOnBack({ previousPage });

  const props = {
    backTextKey,
    nextTextKey,
    disableNext,
    error,
    onBack,
    onNext,
    numErrors,
    scrollToFirstError,
    ...extraProps,
  };
  return <Footer {...props} />;
};

const Footer = ({
  backTextKey = "guides.footer.previous",
  disableBack = false,
  disableNext = false,
  error,
  isLoading = false,
  nextTextKey = "guides.footer.next",
  onBack,
  onNext,
  numErrors,
  scrollToFirstError,
}) => {
  const buttonRef = useRef();

  const pending = useSelector(selectIsProjectSavePending);
  const [bufferedOnNext, projectSavePending] = useDefferedButtonHandler(onNext, pending);

  // tracking onNext here to unrender the FixAnswersTooltip while validations are pending, in order to ensure it shows again each time the Next button is clicked (resetting its internal close-button hiding state)
  const [onNextWithLoading, isOnNextPending] = useAsyncCallbackPendingState(bufferedOnNext);

  const showErrors = !isOnNextPending && (error || numErrors > 0);

  return (
    <>
      {showErrors &&
        (error ? (
          <Popover
            placement="top-end"
            referenceElement={buttonRef.current}
            className={styles.errorBox}
          >
            <Text t={error.textKey} {...error.textProps} />
          </Popover>
        ) : (
          <FixAnswersTooltip
            referenceElement={buttonRef.current}
            numErrors={numErrors}
            scrollToFirstError={scrollToFirstError}
          />
        ))}
      <FooterShell className={styles.footer}>
        <div className={styles.footerNextContainer}>
          <Button
            data-save-button
            onClick={onNextWithLoading}
            ref={buttonRef}
            label={<Text t={nextTextKey} />}
            isLoading={projectSavePending || isLoading}
            disabled={disableNext}
          />
        </div>
        {!disableBack && (
          <TextButton
            data-back-button
            disabled={projectSavePending || isLoading}
            onClick={onBack}
            label={<Text t={backTextKey} />}
            withSpinner
          />
        )}
      </FooterShell>
    </>
  );
};
Footer.propTypes = {
  nextTextKey: PropTypes.string,
  disableBack: PropTypes.bool,
};

export default Footer;
