import React, { useMemo } from "react";
import hexrgb from "hex-rgb";
import * as R from "ramda";

import { useColors } from "contexts/colors";
import useMapboxStaticImageUrl from "hooks/useMapboxStaticImageUrl";
import { calculateZoomLevelForBounds, centerOfBounds } from "utils/map";

const hexToRGBA = (hex, alpha) => {
  const rgba = hexrgb(hex, { format: "array" });
  const rgb = R.pipe(R.dropLast(1), R.join(","))(rgba);

  return `rgba(${rgb}, ${alpha})`;
};

const OverviewMapBackground = ({ bounds, children }) => {
  const width = 1280;
  const height = 780;
  const [longitude, latitude] = centerOfBounds(bounds);
  const imageUrl = useMapboxStaticImageUrl({
    username: "opencounter",
    style_id: "cj4hmjvq61okd2sqy7lediynl",
    lat: latitude,
    lon: longitude,
    zoom: calculateZoomLevelForBounds(bounds, { height, width }),
    height,
    width,
  });
  const colors = useColors();

  const style = useMemo(
    () => ({
      background: [
        `linear-gradient(130deg, ${hexToRGBA(colors.primary, 1)} 0%, ${hexToRGBA(
          colors.primary,
          0.5,
        )} 100%)`,
        `url("${imageUrl}")`,
      ].join(", "),
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    }),
    [imageUrl, colors.primary],
  );

  return <div style={style}> {children} </div>;
};

export default OverviewMapBackground;
