import React from "react";
import PropTypes from "prop-types";

const Seal = ({ seal_name, seal_url, style }) => (
  <img src={seal_url} alt={seal_name} style={style} />
);

Seal.propTypes = {
  seal_url: PropTypes.string.isRequired,
  seal_name: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default Seal;
