import React from "react";

import ExternalLink from "components/ExternalLink";
import TelephoneLink from "components/TelephoneLink";
import LinkLine from "components/utilities/LinkLine";
import Text from "containers/Text";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import logoImage from "images/oc-logo.svg";

import styles from "./Footer.scss";

const Footer = () => {
  const {
    address,
    phone,
    zipcode,
    website_url: websiteURL,
    full_name: tenantFullName,
    name: tenantName,
    state_abbreviation: stateAbbreviation,
  } = useCurrentTenantInfo();

  return (
    <div>
      <div className={styles.content}>
        <div className={styles.linksContainer}>
          <div className={styles.linksGroup}>
            <LinkLine />
            <ul>
              <li className={styles.cityHall}>{tenantFullName}</li>
              <li>
                <address>
                  <div>{address}</div>
                  <div>{`${tenantName}, ${stateAbbreviation} ${zipcode}`}</div>
                </address>
              </li>
              <li>
                <TelephoneLink number={phone} />
              </li>
              <li>
                <ExternalLink className={styles.cityLink} href={websiteURL} />
              </li>
            </ul>
          </div>

          <div className={styles.linksGroup}>
            <LinkLine />
            <ul>
              <li>
                <a href="/terms">
                  <Text t="pages.terms.title" />
                </a>
              </li>
              <li>
                <a href="/privacy-policy">
                  <Text t="pages.privacy.title" />
                </a>
              </li>

              <li className={styles.linksOpencounterContainer}>
                <Text t="powered_by" />{" "}
                <ExternalLink
                  className={styles.linksOpencounter}
                  href="https://www.opencounter.com"
                  hideIcon
                >
                  <img alt="OpenCounter" className={styles.logo} src={logoImage} />
                  <span className={styles.companyName}>OpenCounter</span>
                </ExternalLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
