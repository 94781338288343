import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { FullScreenLoader } from "components/Loader";
import navigateHard from "utils/navigateHard";
import { buildURL } from "utils/urls";

const LoginFactorOkta = ({ href }) => {
  const { pathname } = useLocation();
  const url = buildURL(href);
  url.searchParams.set("return_to", pathname);
  useEffect(() => {
    navigateHard(url.toString());
  }, [url]);
  return <FullScreenLoader label="LoginFactorOkta" />;
};
export default LoginFactorOkta;
