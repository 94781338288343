import React from "react";
import classnames from "classnames";
import * as R from "ramda";

import Icon from "components/Icon";
import Text from "containers/Text";
import { useRenewal } from "queries/renewals";
import { useRequirementApplication } from "queries/requirementApplications";
import {
  EXPIRED,
  EXPIRES,
  RENEWAL_DUE,
  RENEWAL_OPEN,
  getDaysTillRenewalDue,
} from "reducers/renewals";
import { SUBMITTED, getDisplayID } from "reducers/requirementApplications";

import styles from "./RenewalValidity.scss";

function RenewalValidity({ projectID, requirementApplicationID: raID }) {
  const { data: renewal, isLoading: isRenewalLoading } = useRenewal(projectID, raID);
  const { data: requirementApplication, isLoading: isRALoading } = useRequirementApplication(
    projectID,
    raID,
  );

  if (isRenewalLoading || isRALoading || !renewal.showRenewalValidity) return null;

  const daysTillDue = getDaysTillRenewalDue(renewal);
  const wasSubmitted = !!renewal.derivedSubmittedAt;

  const isValid = renewal.renewalState !== EXPIRED;
  let validityMessage;
  if (wasSubmitted) validityMessage = SUBMITTED;
  else if (R.includes(renewal.renewalState, [RENEWAL_DUE, RENEWAL_OPEN, EXPIRES]))
    validityMessage = renewal.renewalState + (!daysTillDue ? "_without_days" : "");

  return (
    <div
      className={classnames(styles.validityContainer, {
        [styles.valid]: isValid,
      })}
    >
      LICENSE #{getDisplayID(requirementApplication)}
      <div className={styles.title}>
        <Icon faStyle="regular" icon={isValid ? "check-circle" : "times-hexagon"} />
        <span>
          <Text t={`requirement_application.${isValid ? "valid" : "expired"}`} />
        </span>
      </div>
      <div data-validity-message>
        {validityMessage && (
          <Text
            t={`requirement_application.validity_description.${validityMessage}`}
            days={daysTillDue}
          />
        )}
      </div>
    </div>
  );
}

export default RenewalValidity;
