import dayjs from "utils/dayjs";

const buildParams = (prefix, obj, add) => {
  const rbracket = /\[\]$/;

  if (Array.isArray(obj)) {
    obj.forEach((value, index) => {
      if (rbracket.test(prefix)) {
        add(prefix, value);
      } else {
        buildParams(`${prefix}[${typeof value === "object" ? index : ""}]`, value, add);
      }
    });
  } else if (obj instanceof Date || obj instanceof dayjs) {
    add(prefix, obj.toISOString());
  } else if (obj && typeof obj === "object") {
    Object.entries(obj).forEach(([key, value]) => {
      buildParams(`${prefix}[${key}]`, value, add);
    });
  } else {
    add(prefix, obj);
  }
};

export default (a) => {
  const s = [];
  const r20 = /%20/g;
  const add = (key, value) => {
    if (typeof value === "function") {
      value = value();
    } else if (value == null) {
      value = "";
    }
    s.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
  };

  if (Array.isArray(a)) {
    a.forEach((value, index) => {
      add(index, value);
    });
  } else {
    Object.entries(a).forEach(([key, value]) => {
      buildParams(key, value, add); // Ensure buildParams is defined elsewhere in your code
    });
  }

  return s.join("&").replace(r20, "+");
};
