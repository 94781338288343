import React from "react";
import classnames from "classnames";

import { ModalCloseButton, useCloseModalBehavior } from "components/LegacyModal";

import styles from "./Modal.scss";

const Modal = ({
  classNames: { contentContainer: contentContainerClassNames, content: contentClassNames } = {},
  header,
  hideClose,
  children,
}) => {
  const onClose = useCloseModalBehavior();
  return (
    <main role="main" className={styles.window}>
      <div className={styles.modalContainer}>
        {!hideClose && (
          <div className={styles.close}>
            <ModalCloseButton onClick={onClose} color="#fff" />
          </div>
        )}
        <div className={classnames(styles.contentContainer, contentContainerClassNames)}>
          {header && <div className={styles.header}>{header}</div>}
          <div className={classnames(styles.content, contentClassNames)}>{children}</div>
        </div>
      </div>
    </main>
  );
};

export default Modal;
