import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import { TextButton } from "components/Button";
import Icon from "components/Icon";
import Markdown from "components/Markdown";
import DateTimeField from "components/project_form/DateTime";
import ErrorMessage from "components/project_form/ErrorMessage";
import Text, { useGetText, withGetText } from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import useCurrentGuide from "hooks/useCurrentGuide";
import { getSubmissionTimeframeMarkdown } from "reducers/guides";
import { isChangeset } from "selectors/changesets";
import { isBlank, mapIndexed, setAtIndex } from "utils/func";
import { eventTimesPropType } from "utils/sharedPropTypes";
import { formatEventTime, now, parseTime } from "utils/time";

import styles from "./EventTimesField.scss";

const formatTimeNBSp = R.compose(R.replace(" ", "\u00A0"), formatEventTime); // prevent line-wrapping between date and time
const getStartTime = R.prop("start_time");
const getEndTime = R.prop("end_time");
const getEventTimeType = R.prop("event_time_type");

const defaultValue = [{ event_time_type: "event" }];

const ICONS = {
  setup: { icon: "person-dolly" },
  event: { icon: "calendar" },
  breakdown: { icon: "person-dolly", className: "fa-flip-horizontal" },
};

const Separator = ({ small = false }) => (
  <span className={classnames(styles.seperator, { [styles.small]: small })}>&mdash;</span>
);
Separator.propTypes = { small: PropTypes.bool };

const AddAnotherRow = ({ value, onChange }) => {
  const onAddAnother = (type) => () => {
    const items = isBlank(value) ? defaultValue : value;
    onChange(R.append({ event_time_type: type }, items));
  };

  return (
    <div className={styles.addAnotherRow}>
      {R.map(
        (type) => (
          <TextButton key={type} onClick={onAddAnother(type)} data-add-another={type}>
            <Icon {...ICONS[type]} size="lg" />
            <span>
              <Text t={`guides.event_times.add_${type}`} />
            </span>
          </TextButton>
        ),
        ["setup", "breakdown", "event"],
      )}
    </div>
  );
};
AddAnotherRow.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(eventTimesPropType),
};

const TimePeriodField = withGetText(
  ({ startTime, endTime, index, onItemChange, onRemoveItem, getText, allowPastDates }) => {
    const { timezone } = useCurrentTenantInfo();
    return (
      <div className={styles.timePeriodField} data-timezone={timezone}>
        <div data-field-key="start_time">
          <DateTimeField
            value={startTime}
            onChange={onItemChange(index, "start_time")}
            onSave={R.T}
            allowPastDates={allowPastDates}
            maxDate={endTime}
            initiallyVisibleMonth={() => now().add(2, "months")}
            placeholders={{
              date: getText("guides.event_times.placeholders.start_date"),
              time: getText("guides.event_times.placeholders.start_time"),
            }}
          />
        </div>

        <Separator />

        <div data-field-key="end_time">
          <DateTimeField
            value={endTime}
            onChange={onItemChange(index, "end_time")}
            onSave={R.T}
            allowPastDates={allowPastDates}
            minDate={startTime}
            defaultDate={startTime}
            initiallyVisibleMonth={() => now().add(2, "months")}
            placeholders={{
              date: getText("guides.event_times.placeholders.end_date"),
              time: getText("guides.event_times.placeholders.end_time"),
            }}
            reverse
          />
        </div>

        {index > 0 && (
          <div className={styles.remove}>
            <TextButton onClick={onRemoveItem(index)} data-remove-btn>
              <Icon icon="trash-alt" />
            </TextButton>
          </div>
        )}
      </div>
    );
  },
);

const EventTimesField = ({ value, onChange, onSave, error, inline }) => {
  const { record } = useAnswerContext();
  const guide = useCurrentGuide();
  const allowPastDates = isChangeset(record);
  const onItemChange = (index, childKey) => (itemValue) => {
    const componentValue = R.prop(index, value || defaultValue);
    const updatedComponentValue = R.assoc(childKey, itemValue.format(), componentValue);
    const newValue = setAtIndex(index, updatedComponentValue, value || defaultValue);

    onChange(newValue);
    if (getStartTime(updatedComponentValue) && getEndTime(updatedComponentValue)) onSave();
  };

  const onRemoveItem = (index) => () => {
    onChange(R.remove(index, 1, value || []));
    onSave();
  };

  return (
    <div className={classnames(styles.container, { [styles.inline]: inline })}>
      {mapIndexed(
        (itemValue, index) => {
          const startTime = getStartTime(itemValue);
          const endTime = getEndTime(itemValue);
          const timeType = getEventTimeType(itemValue);
          return (
            <div
              key={`period-${index}`}
              className={styles.row}
              data-time-period={timeType}
              data-index={index}
            >
              <h4 className={classnames(styles.label, styles[timeType])}>
                <Icon {...ICONS[timeType]} size="lg" />
                <span>
                  <Text t={`guides.event_times.labels.${getEventTimeType(itemValue)}`} />
                </span>
              </h4>
              <TimePeriodField
                startTime={startTime}
                endTime={endTime}
                timeType={timeType}
                index={index}
                onItemChange={onItemChange}
                onRemoveItem={onRemoveItem}
                allowPastDates={allowPastDates}
              />
              {error && (
                <div className={styles.errorContainer}>
                  <ErrorMessage error={error[index]} />
                </div>
              )}
            </div>
          );
        },
        isBlank(value) ? defaultValue : value,
      )}

      <AddAnotherRow value={value} onChange={onChange} />

      <div className={styles.note}>
        <div className={styles.noteEmphasis}>
          <Text t="guides.event_times.note_title" />
        </div>
        <Markdown source={getSubmissionTimeframeMarkdown(guide)} />
      </div>
    </div>
  );
};
EventTimesField.propTypes = {
  guide: PropTypes.shape({ submission_timeframe_markdown: PropTypes.string }),
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(eventTimesPropType),
  error: PropTypes.arrayOf(PropTypes.string),
};

export const InlineEventTimesField = (props) => <EventTimesField {...props} inline />;
export const inline = InlineEventTimesField;

export const DisabledEventTimesField = ({ value = [], issued }) => (
  <ul
    className={classnames(styles.container, styles.disabled, {
      [styles.issued]: issued,
    })}
  >
    {mapIndexed((itemValue, index) => {
      const type = getEventTimeType(itemValue);
      return (
        <li key={`type-${index}`} className={classnames(styles[type])}>
          <div className={classnames(styles.label, styles[type])}>
            <Icon {...ICONS[type]} size="lg" />
            <span>
              <Text t={`guides.event_times.disabled.${type}`} />
            </span>
          </div>
          <div>
            {formatTimeNBSp(getStartTime(itemValue))}
            <Separator small />
            {formatTimeNBSp(getEndTime(itemValue))}
          </div>
        </li>
      );
    }, value || [])}
  </ul>
);
DisabledEventTimesField.propTypes = {
  value: PropTypes.arrayOf(eventTimesPropType),
  issued: PropTypes.bool,
};
export const disabled = DisabledEventTimesField;

const sortMomentTimes = R.sortBy(R.invoker(0, "valueOf"));
const mapParseTime = R.map(parseTime);
const latestEndTime = R.compose(R.last, sortMomentTimes, mapParseTime, R.map(getEndTime));
const earliestStartTime = R.compose(R.head, sortMomentTimes, mapParseTime, R.map(getStartTime));
const joinAsSentence = R.compose(
  R.join(", "),
  R.flatten,
  R.converge(Array.of, [R.head, R.pipe(R.last, R.join(", and "))]),
  R.splitAt(-2),
);

const SummarizedTimeRanges = ({ setup, breakdown, event }) => {
  const getText = useGetText();
  const periods = [];
  if (setup > 0) {
    periods.push(getText("guides.event_times.counts.setup", { count: setup }));
  }
  if (event > 0) {
    periods.push(getText("guides.event_times.counts.event", { count: event }));
  }
  if (breakdown > 0) {
    periods.push(getText("guides.event_times.counts.breakdown", { count: breakdown }));
  }
  if (periods.length === 0) return null;
  return (
    <>
      (
      <Text t="guides.event_times.summarized_counts" timeRangeCounts={joinAsSentence(periods)} />)
    </>
  );
};

const summarizeTimeRanges = R.countBy(getEventTimeType);

export const IssuedEventTimesField = ({ value = [] }) => {
  if (R.isEmpty(value)) return null;
  const startTime = earliestStartTime(value);
  const endTime = latestEndTime(value);
  const { setup, breakdown, event } = summarizeTimeRanges(value);

  return (
    <div className={styles.issued}>
      <div className={styles.label}>
        <Icon icon="calendar" />
        <span>Event time range:</span>
      </div>
      <div className={styles.range}>
        {formatTimeNBSp(startTime)}
        {"\u00a0"}
        <Separator small /> {formatTimeNBSp(endTime)}
      </div>
      <div className={styles.summary}>
        <SummarizedTimeRanges setup={setup} event={event} breakdown={breakdown} />
      </div>
    </div>
  );
};
IssuedEventTimesField.propTypes = {
  value: PropTypes.arrayOf(eventTimesPropType),
};
export const issued = IssuedEventTimesField;

export default EventTimesField;
