import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import ExternalLink from "components/ExternalLink";
import Markdown from "components/Markdown";
import { Section } from "components/Section";
import Text from "containers/Text";
import { isPresent } from "utils/func";
import { zoningPropType } from "utils/sharedPropTypes";
import { getBaseZone, getPermission, getZonesWithDevelopmentStandards } from "utils/zoning";

import Collapsible from "./Collapsible";
import styles from "./ZoneDetails.scss";

const UseCodeSection = ({ useCode }) => {
  if (R.isEmpty(useCode)) return null;
  const { name, description, reference_url } = useCode;
  if (R.none(isPresent, [name, description, reference_url])) return null;

  return (
    <div className={styles.section}>
      <h3>
        <Text t="guides.zoning.use_code_header" />
      </h3>
      <h4>{name}</h4>
      {description && <Markdown source={description.trim()} />}
      <LearnMore link={reference_url} />
    </div>
  );
};

const OverlaySection = ({ zoning }) => {
  const overlays = R.filter(R.prop("is_overlay"), zoning.zones);
  if (R.isEmpty(overlays)) return null;

  return (
    <div className={styles.section}>
      <h3>
        <Text t="guides.zoning.overlay_heading" />
      </h3>
      {renderZones(overlays, Collapsible)}
    </div>
  );
};

const DevelopmentStandardsSection = ({ zoning }) => {
  const withStandards = getZonesWithDevelopmentStandards(zoning);
  if (R.isEmpty(withStandards)) return null;

  return (
    <div className={styles.section}>
      <h3>
        <Text t="guides.zoning.development_standards_heading" />
      </h3>
      <ul className={styles.developmentStandards}>
        {R.map(
          (z) => (
            <li key={z.display_name}>
              <span className={styles.standardName}>
                {z.is_overlay ? z.display_name : `${z.display_name} (${z.code})`}
              </span>
              <LearnMore link={z.development_standards_url} />
            </li>
          ),
          withStandards,
        )}
      </ul>
    </div>
  );
};

const Zone = ({ title, children, ...props }) => (
  <div {...props}>
    <h4>{title}</h4>
    {children}
  </div>
);

const LearnMore = ({ link }) => {
  if (!link) return null;

  return (
    <ExternalLink className={styles.learnMore} href={link}>
      <Text t="guides.zoning.learn_more" />
    </ExternalLink>
  );
};
LearnMore.propTypes = { link: PropTypes.string };

const renderZones = (zones, Wrapper = Zone) =>
  R.map(
    (z) => (
      <div className={styles.zone} key={`${z.display_name} ${z.description} ${z.code}`}>
        <Wrapper title={z.is_overlay ? `${z.display_name}` : `${z.display_name} (${z.code})`}>
          {z.description && <Markdown source={z.description.trim()} />}
          {z.municipal_code_url && <LearnMore link={z.municipal_code_url} />}
        </Wrapper>
      </div>
    ),
    zones,
  );

const isPermissionPresent = R.compose(
  R.any(isPresent),
  R.props(["display_name", "description", "municipal_code_url"]),
  R.defaultTo({}),
);

const ZoneDetails = ({ isZoningEnabled, zoningClearanceHeader, zoning, useCode }) => {
  const zone = getBaseZone(zoning);
  const permission = getPermission(zoning);

  return (
    <Section heading={<Text t="guides.zoning.zoning_details_header" />}>
      {isZoningEnabled && isPermissionPresent(permission) && (
        <div className={styles.section}>
          <h3>{zoningClearanceHeader}</h3>
          <h4>{permission.display_name}</h4>
          <p>{permission.description}</p>
          {permission.municipal_code_url && (
            <div>
              <LearnMore link={permission.municipal_code_url} />
            </div>
          )}
        </div>
      )}

      <div className={styles.section}>
        <h3>
          <Text t="guides.zoning.zoning_district_header" />
        </h3>
        {renderZones([zone])}
      </div>

      <UseCodeSection useCode={useCode} />
      <OverlaySection zoning={zoning} />
      <DevelopmentStandardsSection zoning={zoning} />
    </Section>
  );
};

ZoneDetails.propTypes = {
  isZoningEnabled: PropTypes.bool.isRequired,
  zoningClearanceHeader: PropTypes.string.isRequired,
  zoning: zoningPropType.isRequired,
  useCode: PropTypes.shape({}).isRequired,
};

export default ZoneDetails;
