// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-projects-DueDate__container--Se29T {\n  display: flex;\n  font-size: 0.875em;\n}\n.ui-components-projects-DueDate__container--Se29T .icon {\n  min-width: 1.3em;\n}", "",{"version":3,"sources":["webpack://./ui/components/projects/DueDate.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF;AACE;EACE,gBAAA;AACJ","sourcesContent":[".container {\n  display: flex;\n  font-size: 0.875em;\n\n  :global(.icon) {\n    min-width: 1.3em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ui-components-projects-DueDate__container--Se29T"
};
export default ___CSS_LOADER_EXPORT___;
