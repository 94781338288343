import { delay, fork, race, take } from "redux-saga/effects";

// used to avoid thundering-herd issues with fetches triggered by Pusher broadcasts
const debounceWithJitter = (minDelayMs, maxDelayMs, pattern, task, ...taskArgs) =>
  fork(function* debounceSaga() {
    while (true) {
      let action = yield take(pattern);

      const ms = Math.floor(Math.random() * (maxDelayMs - minDelayMs)) + minDelayMs;
      while (true) {
        const { debounced, latestAction } = yield race({
          debounced: delay(ms),
          latestAction: take(pattern),
        });

        if (debounced) {
          yield fork(task, ...taskArgs, action);
          break;
        }

        action = latestAction;
      }
    }
  });

export default debounceWithJitter;
