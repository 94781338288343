import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useSelector } from "react-redux";

import Header from "components/Header";
import Peekbar from "components/guides/Peekbar";
import SendMessageBadge from "components/messaging/SendMessageBadge";
import SummaryTable from "components/projects/SummaryTable";
import * as Layout from "components/projects/pages/Layout";
import RequirementFooter from "components/projects/requirement/RequirementFooter";
import Section from "components/projects/requirement/Section";
import ZoningBar from "components/zoning/ZoningBar";
import HelpBar from "containers/guides/HelpBar";
import SubHeader from "containers/projects/requirement/SubHeader";
import { AnswerContextProvider, useAnswerContext } from "containers/withAnswerContext";
import useParam from "hooks/useParam";
import {
  getLockedFieldIDs,
  getRequirementApplicationByRequirementID,
  getUnansweredCountForFields,
  selectProjectRequirementSections,
} from "reducers/projects";
import { getRequirementStepCount, selectRequirementBySlug } from "reducers/requirements";

import styles from "./RequirementFormFillingPage.scss";
import Step from "./requirement/Step";
import useIsDirectApply from "./useIsDirectApply";

const RequirementFormFillingPage = () => {
  const answerContext = useAnswerContext();
  const { record: project } = answerContext;
  const requirementSlug = useParam("requirementSlug");
  const { isDirectApply } = useIsDirectApply();

  const requirement = useSelector((state) => selectRequirementBySlug(state, requirementSlug));
  const application = getRequirementApplicationByRequirementID(project, requirement.id);
  const mappedAnswerContext = R.assoc("context", requirement, answerContext);

  const sections = useSelector((state) =>
    selectProjectRequirementSections(state, project, requirement, {
      withFeeFields: true,
    }),
  );
  const steps = getRequirementStepCount(requirement);
  const lockedFieldIDs = getLockedFieldIDs(project);

  const tableProps = {
    sticky: true,
    requirementID: requirement.id,
  };

  if (isDirectApply) {
    const feeSection = R.find(R.propEq("key", "fee_scoping"))(sections);
    const feeFields = R.propOr([], "fields", feeSection);
    tableProps.unansweredFeeFieldsCount = getUnansweredCountForFields(project, feeFields);
  }

  return (
    <AnswerContextProvider value={mappedAnswerContext}>
      <Layout.Page name="requirement-form">
        <Header hideTitle />
        {isDirectApply || <SubHeader requirement={requirement} />}
        <Layout.Main>
          <Step
            header={requirement.name}
            instructions={requirement.form_intro}
            instructionsKey="projects.requirement.apply.instructions"
            step={1}
            steps={steps}
          />

          <Layout.ContentWithSidebar>
            <Layout.Content>
              {sections.map((section) => (
                <Section
                  record={project}
                  section={section}
                  key={section.name}
                  lockedFieldIDs={lockedFieldIDs}
                />
              ))}
            </Layout.Content>
            <Layout.Sidebar>
              <SummaryTable {...tableProps} />
              <div className={styles.sendMessageBadge}>
                <SendMessageBadge application={application} />
              </div>
            </Layout.Sidebar>
          </Layout.ContentWithSidebar>
          <HelpBar />
        </Layout.Main>
        <ZoningBar />
        <Peekbar showFees requirementID={requirement.id} />
        <RequirementFooter
          requirement={requirement}
          disableBack={isDirectApply}
          nextTextKey="submit_application"
        />
      </Layout.Page>
    </AnswerContextProvider>
  );
};
RequirementFormFillingPage.propTypes = {
  directApply: PropTypes.bool,
};

export default RequirementFormFillingPage;
