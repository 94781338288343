import { create as createAxios } from "axios";
import * as R from "ramda";

import { X_TENANT_VERSION } from "utils/httpHeaderNames";

const responseToClearances = (r) =>
  R.reduceBy(
    (acc, c) => {
      const permission = relationshipForType(c, "permission", r);
      const rule = relationshipForType(c, "rule", r);
      return acc.concat({ permission, rule });
    },
    [],
    (c) => c.relationships.zone.data.id,
    r.data,
  );

const responseToRadiusRules = (r) => {
  if (!r.included) return {};

  const permissionForRuleID = (id) => {
    const clearance = R.find(R.pathEq(["relationships", "rule", "data", "id"], String(id)), r.data);
    const permissionID = R.path(["relationships", "permission", "data", "id"], clearance);
    const permission = R.find(
      R.and(R.propEq("type", "permissions"), R.propEq("id", permissionID)),
      r.included,
    );
    const category = R.path(["attributes", "category"], permission);
    if (category) return category.toLowerCase().replace("permission::", "");
    return null;
  };

  const isRadiusRule = (r) => {
    R.and(R.propEq("type", "rules", r), R.equals(R.props(["rule_type"], r.attributes), ["Radius"]));
  };

  return R.pipe(
    R.filter(isRadiusRule),
    R.map((id) => Number(R.prop("id", id))),
    R.groupBy(permissionForRuleID),
  )(r.included);
};

const relationshipForType = (obj, type, response) => {
  const id = R.path(["relationships", type, "data", "id"], obj);
  if (!id) return null;

  const relationship = R.find(
    R.and(R.propEq("type", `${type}s`), R.propEq("id", id)),
    response.included,
  ).attributes;

  return R.merge(relationship, { id: Number(id) });
};

export class ZoningApi {
  constructor() {
    this.locale = null;
  }

  configure({ locale, version, baseURL = null }) {
    this.locale = locale;
    this.client = createAxios({
      baseURL,
      headers: {
        Accept: "application/vnd.api+json",
        [X_TENANT_VERSION]: version,
      },
    });
  }

  async getSnapSuggestions({ latitude, longitude }) {
    const { data } = await this.client.get(`/api/zoning/snap_suggestions.json`, {
      params: this.withDefaultParams({
        latitude,
        longitude,
      }),
    });
    return data;
  }

  async getClearances(useCodeSlug) {
    const { data } = await this.client.get("/api/zoning/clearances", {
      params: this.withDefaultParams({
        "filter[use.slug]": useCodeSlug,
        include: "permission,rule,zone",
      }),
    });
    const clearances = responseToClearances(data);
    const radiusRules = responseToRadiusRules(data);
    return { clearances, radiusRules };
  }

  withDefaultParams(params) {
    params.locale ||= this.locale;
    return params;
  }
}

export default new ZoningApi();
