import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { branch, lifecycle, renderComponent } from "recompose";

import { fetchProject } from "actions";
import IssuedRequirementApplication from "components/IssuedRequirementApplication";
import Loader from "components/Loader";
import { withMappedAnswerContext } from "containers/withAnswerContext";
import withRouterParams from "containers/withRouterParams";
import { getName as getDepartmentName, selectDepartmentForRequirement } from "reducers/departments";
import { selectFieldsByID } from "reducers/fields";
import { getRequirementApplication, selectProjectByID } from "reducers/projects";
import { getRequirementID } from "reducers/requirementApplications";
import {
  getIssuedRequirementFieldIDs,
  getIssuedRequirementSealURL,
  selectRequirementByID,
} from "reducers/requirements";
import { getSealURL, selectTenant } from "reducers/tenant";

const withLifecycle = lifecycle({
  componentDidMount() {
    this.props.fetchProject({ id: this.props.projectID });
  },
});

const mapStateToProps = (state, { requirementApplicationID, projectID }) => {
  const project = selectProjectByID(state, projectID);
  const ra = getRequirementApplication(project, requirementApplicationID);
  const requirement = selectRequirementByID(state, getRequirementID(ra));

  const tenant = selectTenant(state);
  const fieldIDs = getIssuedRequirementFieldIDs(requirement);
  if (!requirement || !ra) return { isLoading: true };

  return {
    tenant,
    application: ra,
    requirementApplicationID,
    requirement,
    fieldIDs,
    sealURL: getIssuedRequirementSealURL(requirement) || getSealURL(tenant, "color"),
    departmentName: getDepartmentName(selectDepartmentForRequirement(state, requirement)),
    fields: selectFieldsByID(state, fieldIDs),
  };
};

const enhance = R.compose(
  withRouter,
  withRouterParams(["projectID", "requirementID", "requirementApplicationID"], Number),
  connect(mapStateToProps, { fetchProject }),
  branch(({ isLoading }) => isLoading, renderComponent(Loader)),
  withLifecycle,
  withMappedAnswerContext(R.prop("requirement")),
);

export const IssuedRequirementApplicationComponent = R.compose(
  connect(mapStateToProps),
  withMappedAnswerContext(R.prop("requirement")),
)(IssuedRequirementApplication);

export default enhance(IssuedRequirementApplication);
