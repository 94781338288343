import React from "react";

import Icon from "components/Icon";
import Text from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import { useZoningData } from "hooks/useZoningField";
import { getDescription } from "utils/permission";

import styles from "./ZoningBar.scss";

const ZoningBar = () => {
  const answerContext = useAnswerContext();
  const { permission, category, isZoningEnabled } = useZoningData(answerContext);

  if (category !== "prohibited" || !isZoningEnabled) return null;

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.title}>
          <Icon icon="exclamation-triangle" className={styles.icon} />
          <span>
            <Text t="zoning.permission.prohibited.title" />
          </span>
        </div>
        <div className={styles.content}>{getDescription(permission)}</div>
      </div>
    </div>
  );
};

export default ZoningBar;
