import React from "react";
import PropTypes from "prop-types";

import ExternalLink from "components/ExternalLink";
import Text from "containers/Text";

import styles from "./TopLine.scss";

const TopLine = ({ tenantFullName, tenantURL }) => {
  const tenantLink = tenantURL ? (
    <ExternalLink href={tenantURL}>{tenantFullName}</ExternalLink>
  ) : (
    tenantFullName
  );

  return (
    <div className={styles.container}>
      <span>
        <Text t="topline" /> {tenantLink}
      </span>
      <span>{tenantURL}</span>
    </div>
  );
};

TopLine.propTypes = {
  tenantFullName: PropTypes.string.isRequired,
  tenantURL: PropTypes.string,
};

export default TopLine;
