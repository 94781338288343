import React from "react";
import PropTypes from "prop-types";

import { isProductionBuild } from "env";
import { Button } from "components/Button";

import styles from "./ErrorBoundary.scss";

export const ErrorBanner = ({ error }) => (
  <div className={styles.banner}>
    <h3>Something went wrong (refresh to continue)</h3>
    {error && <div className={styles.error}>{error.toString()}</div>}
  </div>
);

const ErrorPage = ({ error }) => (
  <div data-non-routed-modal-name="ErrorPage" className={styles.swwContainer} data-error-page>
    <div className={styles.contentContainer}>
      <h1>Something went wrong.</h1>

      <div className={styles.description}>We’re sorry, but an error has occurred.</div>

      <div className={styles.description}>
        To continue, please refresh the page. If issues persist, please start over, then try
        clearing your browser cache and restart the browser.
      </div>

      <div className={styles.buttons}>
        <Button onClick={() => window.location.reload()}>Refresh</Button>
        <a className="btn" href="/">
          Start over
        </a>
      </div>

      {!isProductionBuild && error && (
        <div className={styles.error} data-error-message>
          {error.toString()}
        </div>
      )}
    </div>
  </div>
);

ErrorPage.propTypes = {
  error: PropTypes.shape({
    toString: PropTypes.func,
  }),
};

export default ErrorPage;
