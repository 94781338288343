import { compose } from "ramda";

import withHandlers from "components/utilities/withHandlers";
import withState from "components/utilities/withState";

export default compose(
  withState("active", "setActive", false),
  withHandlers({
    toggleActive:
      ({ setActive, active }) =>
      () =>
        setActive(!active),
    activate:
      ({ setActive }) =>
      () => {
        document.querySelector("body").style.overflow = "hidden";
        setActive(true);
      },
    deactivate:
      ({ setActive }) =>
      () => {
        setActive(false);
        document.querySelector("body").style.overflow = "initial";
      },
  }),
);
