import React, { useCallback, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useSelector } from "react-redux";

import Icon from "components/Icon";
import Loader from "components/Loader";
import Tooltip from "components/Tooltip";
import { ButtonDiv } from "components/accessibility/Div";
import Text from "containers/Text";
import { useIsPermittedTo } from "contexts/session";
import { selectTenant } from "reducers/tenant";
import { selectVaultAddr } from "reducers/vault";
import { reportError } from "services/errorReporter";
import { fetchVaultToken } from "services/sensitiveData";
import * as Vault from "services/vault";
import errorStyles from "sharedStyles/errors.scss";
import { errorPropType } from "utils/sharedPropTypes";

import styles from "./SensitiveField.scss";
import textStyles from "./TextField.scss";

const answerType = PropTypes.shape({
  ciphertext: PropTypes.string,
  key_name: PropTypes.string,
  context: PropTypes.string.isRequired,
});

const useVaultEncryption = ({
  answerValue: { context, key_name, ciphertext },
  setError,
  clearError,
  onValue,
}) => {
  const canDecrypt = useIsPermittedTo("sensitive_data");
  const tenant = useSelector(selectTenant);
  const vaultAddr = useSelector(selectVaultAddr);
  const [isLoading, setLoading] = useState(false);
  const [plaintext, setPlaintext] = useState(null);

  const encrypt = useCallback(
    async (string) => {
      try {
        setLoading(true);
        setPlaintext(null);
        clearError();

        const { token, entityId } = await fetchVaultToken({ addr: vaultAddr });
        const key_name = `sd-${entityId}`;
        const response = await Vault.encrypt({
          addr: vaultAddr,
          keyName: key_name,
          plaintext: string,
          token,
          backendPath: Vault.backendPath(tenant),
          context,
        });
        const {
          data: { ciphertext },
        } = response;

        await onValue({
          context,
          key_name,
          ciphertext,
        });
      } catch (e) {
        setError("Error encrypting sensitive data");
        reportError(e);
      } finally {
        setLoading(false);
      }
    },
    [context, onValue, setError, clearError, setLoading, vaultAddr, tenant],
  );

  const decrypt = useCallback(async () => {
    try {
      setLoading(true);
      clearError();

      const { token } = await fetchVaultToken({ addr: vaultAddr });
      const response = await Vault.decrypt({
        addr: vaultAddr,
        token,
        backendPath: Vault.backendPath(tenant),
        keyName: key_name,
        ciphertext,
        context,
      });
      const {
        data: { plaintext: plaintext64 },
      } = response;
      setPlaintext(atob(plaintext64));
    } catch (e) {
      setError("Error decrypting sensitive data");
      reportError(e);
    } finally {
      setLoading(false);
    }
  }, [context, key_name, ciphertext, setError, clearError, setLoading, vaultAddr, tenant]);

  const undecrypt = useCallback(() => {
    setPlaintext(null);
    setLoading(false);
  }, [setLoading, setPlaintext]);

  return {
    encrypt,
    decrypt: ciphertext && canDecrypt && decrypt,
    plaintext,
    undecrypt,
    isLoading,
    hasValue: !!ciphertext,
    isShown: !!plaintext,
  };
};

const MASKED_VALUE = "00000000000000000000000000000000000000000000000";

const SensitiveField = ({
  value: answerValue = {},
  field,
  name,
  labelId,
  error,
  required,
  answerContext,
  onSave,
  onChange,
  setError,
  clearError,
}) => {
  const { record } = answerContext;
  const [isDirty, setIsDirty] = useState(false);

  const { id: fieldId } = field;
  const encryptionContext = JSON.stringify([record.id, fieldId]);
  const { encrypt, isLoading, hasValue } = useVaultEncryption({
    answerValue: R.assoc("context", encryptionContext)(answerValue),
    setError,
    clearError,
    onValue(value) {
      onChange(value);
      onSave();
    },
  });

  const [inputValue, setInputValue] = useState(hasValue ? MASKED_VALUE : "");
  const [isInputVisible, setInputVisible] = useState(false);

  const handleBlur = useCallback(() => {
    if (inputValue === "") {
      if (hasValue) setInputValue(MASKED_VALUE);

      setIsDirty(false);
      return;
    }
    setInputValue(MASKED_VALUE);
    encrypt(inputValue);
    setIsDirty(false);
  }, [inputValue, setInputValue, hasValue, encrypt]);

  const handleChange = ({ target: { value } }) => {
    setInputValue(value);
    setIsDirty(true);
  };
  const handleFocus = () => setInputValue("");
  const toggleInputVisibility = () => setInputVisible(!isInputVisible);

  return (
    <div className={styles.container} data-sensitive-encrypted={hasValue && !isDirty && !isLoading}>
      <div className={styles.inputContainer}>
        <div className={textStyles.container}>
          <input
            className={classnames({ [errorStyles.errorBorder]: error })}
            placeholder={field.placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            type={isInputVisible ? "text" : "password"}
            name={name}
            value={inputValue}
            disabled={!answerValue || isLoading}
            autoComplete="off"
            aria-labelledby={labelId}
            aria-invalid={!!error}
            aria-required={!!required}
            data-private
            data-dd-privacy="hidden"
          />
          {error && <Icon icon="exclamation" />}
        </div>
        <ShieldIcon encrypted={hasValue && !isDirty} />
      </div>

      <div className={styles.visibilityContainer}>
        <ButtonDiv onClick={toggleInputVisibility}>
          <Icon icon={isInputVisible ? "eye-slash" : "eye"} />
          <span>
            <Text t={`fields.sensitive.${isInputVisible ? "hide" : "show"}`} />
          </span>
        </ButtonDiv>
      </div>
    </div>
  );
};
SensitiveField.propTypes = {
  value: answerType,
  field: PropTypes.shape({
    id: PropTypes.number,
    placeholder: PropTypes.string,
  }).isRequired,
  error: errorPropType,
  name: PropTypes.string,
  labelId: PropTypes.string,
  required: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ShieldIcon = ({ encrypted }) => (
  <div className={classnames(styles.shieldContainer, { [styles.encrypted]: encrypted })}>
    <Tooltip
      icon={encrypted ? "shield-check" : "shield-alt"}
      message={<Text t="fields.sensitive.explanation" />}
    />
  </div>
);
ShieldIcon.propTypes = {
  encrypted: PropTypes.bool,
};

export const DisabledSensitiveField = ({ value: answerValue = {}, setError, clearError }) => {
  const { decrypt, undecrypt, isLoading, plaintext, hasValue } = useVaultEncryption({
    answerValue,
    setError,
    clearError,
  });

  if (hasValue) {
    if (isLoading)
      return (
        <div className={classnames(textStyles.disabled, styles.inputContainer)}>
          <Loader />
          <ShieldIcon />
        </div>
      );

    if (plaintext)
      return (
        <div
          data-private
          data-dd-privacy="hidden"
          className={classnames(textStyles.disabled, styles.inputContainer)}
        >
          <span className={styles.revealed}>{plaintext}</span>
          <ShieldIcon />
          <ButtonDiv onClick={undecrypt} data-reset-sensitive className={styles.showButton}>
            hide
          </ButtonDiv>
        </div>
      );

    return (
      <div
        className={classnames(textStyles.disabled, styles.inputContainer)}
        data-sensitive-encrypted
      >
        ***********
        <ShieldIcon encrypted />
        {decrypt && (
          <ButtonDiv onClick={decrypt} data-show-sensitive className={styles.showButton}>
            show
          </ButtonDiv>
        )}
      </div>
    );
  }

  return (
    <div className={textStyles.disabled}>
      <span className={styles.unanswered}>
        <Text t="forms.not_provided" />
      </span>
    </div>
  );
};
DisabledSensitiveField.propTypes = {
  value: answerType,
};

export default SensitiveField;
export const disabled = DisabledSensitiveField;
