import * as R from "ramda";

import { unversionedAPI } from "services/api";

import * as Vault from "./vault";

const getWrappingToken = R.path(["wrap_info", "token"]);
const getToken = R.path(["auth", "client_token"]);

export async function fetchVaultToken({ addr }) {
  const wrappedTokenResponse = await unversionedAPI.fetchWrappedVaultToken();
  const wrappingToken = getWrappingToken(wrappedTokenResponse);

  const unwrappedTokenResponse = await Vault.unwrap({
    token: wrappingToken,
    addr,
  });
  const token = getToken(unwrappedTokenResponse);

  const lookupSelfResponse = await Vault.tokenLookupSelf({ token, addr });
  const {
    data: { entity_id: entityId },
  } = lookupSelfResponse;

  return { token, entityId };
}
