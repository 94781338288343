import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { useHotKeyContext } from "contexts/hotKeys";

export default (key, callback, { alwaysEnabled = false, dependencies = [], ...config }) => {
  const { register, unregister, enabled, setEnabled, setOverlay, setContainer, close } =
    useHotKeyContext();

  const hotKeyConfig = { key, ...config };

  useEffect(() => {
    register(hotKeyConfig);
    return () => unregister(hotKeyConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  useHotkeys(
    key,
    (event) => callback({ setOverlay, setContainer, setEnabled, close, event }),
    {
      enabled: enabled || alwaysEnabled,
    },
    { dependencies },
  );
};
