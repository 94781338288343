import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";

import Header from "components/Header";
import styles from "components/pages/Requirements.scss";
import Footer from "components/projects/Footer";
import Section from "components/projects/requirement/Section";
import Field from "containers/Field";
import HelpBar from "containers/guides/HelpBar";
import { useAnswerContext } from "containers/withAnswerContext";
import { selectFieldBySlug, selectFields } from "reducers/fields";
import { selectGuideByID } from "reducers/guides";
import { selectRequirementByID } from "reducers/requirements";
import AnswerContext from "utils/AnswerContext";

import Step from "../requirement/Step";
import * as Layout from "./Layout";

// TODO: Next button submits application and takes applicant to a explination page
const ZoningVerificationPage = () => {
  const { record: project } = useAnswerContext();
  const requirement = useSelector((state) =>
    selectRequirementByID(state, project.requirement_ids[0]),
  );
  const { sections } = requirement;

  const projectType = useSelector((state) => selectFieldBySlug(state, "project-type"));
  const address = useSelector((state) => selectFieldBySlug(state, "address"));
  const requirementFieldIDs = sections.reduce((ids, s) => ids.concat(s.field_ids), []);
  const fields = useSelector(selectFields);
  const entryPoint = useSelector((state) => selectGuideByID(state, project.guide_id));

  const allFieldIDs = [projectType.id, address.id, ...requirementFieldIDs];
  sections.forEach((s) => {
    s.fields = Object.values(R.pick(s.field_ids, fields));
  });

  return (
    <Layout.Page name="zoning-verification">
      <Header hideTitle />
      <Layout.Main>
        <Layout.Content>
          <div className={styles.sectionsContainer}>
            <Step header={entryPoint.header_title} instructions={entryPoint.message} />

            <Field field={projectType} behaviorType="inline" />
            <Field field={address} behaviorType="inline" withoutUseCodeConditions />
            <div className={styles.sectionsContainer}>
              {sections.map((section) => (
                <Section
                  answerContext={AnswerContext(project)}
                  section={section}
                  key={section.name}
                />
              ))}
            </div>
          </div>
        </Layout.Content>
        <HelpBar />
      </Layout.Main>
      <Footer
        contextuallyRequiredFieldIDs={[projectType, address]}
        requirement={requirement}
        fieldIDs={allFieldIDs}
        disableNext={false}
        nextTextKey="submit_application"
      />
    </Layout.Page>
  );
};

export default ZoningVerificationPage;
