// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-messaging-Attachment__attachment--b1BvK {\n  display: flex;\n  flex-direction: row;\n  align-items: baseline;\n  gap: 0.5em;\n  margin-bottom: 0.25em;\n}\n.ui-components-messaging-Attachment__attachment--b1BvK:last-child {\n  margin-bottom: 0;\n}\n.ui-components-messaging-Attachment__attachment--b1BvK a {\n  text-decoration: none;\n  color: transparent;\n  color: var(--primary-color, #333);\n}\n\n.ui-components-messaging-Attachment__attachment-size--g1whx {\n  color: #888;\n  color: var(--dark-grey-88, #888);\n}", "",{"version":3,"sources":["webpack://./ui/components/messaging/Attachment.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,UAAA;EACA,qBAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,qBAAA;EACA,kBAAA;EAAA,iCAAA;AAAJ;;AAIA;EACE,WAAA;EAAA,gCAAA;AADF","sourcesContent":[".attachment {\n  display: flex;\n  flex-direction: row;\n  align-items: baseline;\n  gap: 0.5em;\n  margin-bottom: 0.25em;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  a {\n    text-decoration: none;\n    color: var(--primary-color, #333);\n  }\n}\n\n.attachment-size {\n  color: var(--dark-grey-88, #888);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attachment": "ui-components-messaging-Attachment__attachment--b1BvK",
	"attachment-size": "ui-components-messaging-Attachment__attachment-size--g1whx",
	"attachmentSize": "ui-components-messaging-Attachment__attachment-size--g1whx"
};
export default ___CSS_LOADER_EXPORT___;
