import React from "react";
import * as R from "ramda";

import EventLogPropTypes from "./propTypes";

const messageContent = {
  business: "from the Business Portal",
  residential: "from the Residential Portal",
  special_events: "from the Special Events Portal",
  zoning: "from the Zoning Portal",
  requirement_catalog: "from the Permit Catalog",
  direct_application: "from a Direct Application",
};

const ProjectStarted = ({ Layout, event, timestamp }) => {
  const guideType = R.path(["payload", "data", "guide_type"], event);

  return (
    <Layout icon="star">
      New project started {R.prop(guideType, messageContent)}
      {timestamp}
    </Layout>
  );
};
ProjectStarted.propTypes = EventLogPropTypes;

export default ProjectStarted;
