import { connect } from "react-redux";

import Seal from "components/Seal";
import { getFullName, selectSealURL, selectTenant } from "reducers/tenant";
import { selectFormattedText } from "selectors/translations";

const selectSealName = (state) => {
  const tenant = selectTenant(state);
  return selectFormattedText(state, "seal_name", {
    tenantFullName: getFullName(tenant),
  });
};

const mapStateToProps = (state, ownProps) => ({
  seal_url: selectSealURL(state, ownProps.type),
  seal_name: selectSealName(state),
});

export default connect(mapStateToProps)(Seal);
