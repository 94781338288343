import React, { useEffect } from "react";

import { FullScreenLoader } from "components/Loader";
import { useSession } from "contexts/session";

function LogoutPage() {
  const { logout } = useSession();
  useEffect(logout);

  return <FullScreenLoader label="LogoutPage" />;
}

export default LogoutPage;
