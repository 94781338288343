/* global google */

import * as R from "ramda";

import { getAddress } from "utils/location";
import { getEndAddress, getStartAddress } from "utils/route";

export const locationToPosition = (l) => ({
  lat: getAddress(l).latitude,
  lng: getAddress(l).longitude,
});
export const addressToPosition = (address) => ({ lat: address.latitude, lng: address.longitude });
export const positionToAddress = (position, full_address) => ({
  latitude: position.lat(),
  longitude: position.lng(),
  full_address,
});

export const directionsResultToRoute = (result) => {
  const route = R.path(["routes", 0], result);
  const legs = R.prop("legs", route);
  const start = R.head(legs);
  const end = R.last(legs);

  return {
    start_address: positionToAddress(start.start_location, start.start_address),
    end_address: positionToAddress(end.end_location, end.end_address),
    distance: R.sum(R.map(R.path(["distance", "value"]), route.legs)),
    overview_polyline: route.overview_polyline,
    directions: result,
  };
};

export const fetchDirections = (route) => {
  const directionsPromise = new Promise((resolve, reject) => {
    const DirectionsService = new google.maps.DirectionsService();

    DirectionsService.route(
      {
        origin: addressToPosition(getStartAddress(route)),
        destination: addressToPosition(getEndAddress(route)),
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.WALKING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) resolve(directionsResultToRoute(result));
        else reject(new Error(`error fetching directions: ${status}`));
      },
    );
  });

  return directionsPromise;
};

export function tenantBoundsToArray(b) {
  const ne = { lng: b.max_lon, lat: b.max_lat };
  const sw = { lng: b.min_lon, lat: b.min_lat };
  return [sw, ne];
}

export const boundsToLngLatLike = (b) => [
  { lng: b.min_lon, lat: b.min_lat },
  { lng: b.max_lon, lat: b.max_lat },
];

export const centerOfBounds = (b) => [
  b.min_lon + (b.min_lon - b.max_lon) / 2,
  b.min_lat + (b.min_lat - b.max_lat) / 2,
];

// from: https://stackoverflow.com/questions/6048975/google-maps-v3-how-to-calculate-the-zoom-level-for-a-given-bounds/13274361#13274361
export const calculateZoomLevelForBounds = (bounds, mapDim) => {
  if (mapDim.width === 0 || mapDim.height === 0) return 10;
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  function latRad(lat) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const [sw, ne] = boundsToLngLatLike(bounds);

  const latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

  const lngDiff = ne.lng - sw.lng;
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
};
