import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import { AdminBar, AdminBarHandle } from "components/AdminBar";
import { Button } from "components/Button";
import Line from "components/Line";
import Markdown from "components/Markdown";
import Menu from "components/Menu";
import OverviewMapBackground from "components/OverviewMapBackground";
import { SuperAdminFeature } from "components/privilegedUserFeatures";
import LinkLine from "components/utilities/LinkLine";
import Seal from "containers/Seal";
import Text from "containers/Text";
import {
  getCallToAction,
  getDescription,
  getHighlightMessageMarkdown,
  getTitle,
  getType,
  isRequirementCatalog,
} from "reducers/guides";
import { guidePropType } from "utils/sharedPropTypes";

import AdminTools from "./AdminTools";
import styles from "./Guides.scss";

const GuideStartButton = ({ guide, callback }) => (
  <div className={styles.guide}>
    <h3>{getTitle(guide)}</h3>
    <div className={styles.linkLineContainer}>
      <LinkLine />
    </div>
    <div className={styles.guideMessage}>{getDescription(guide)}</div>
    <StartButton guide={guide} callback={callback} />
  </div>
);
GuideStartButton.propTypes = {
  guide: guidePropType,
  callback: PropTypes.func.isRequired,
};

const StartButton = ({ guide, callback }) => (
  <Button
    expandOnMobile
    data-guide={getType(guide)}
    data-role="get-started"
    onClick={() => callback(guide)}
    label={getCallToAction(guide)}
    withSpinner
  />
);
StartButton.propTypes = {
  guide: guidePropType,
  callback: PropTypes.func.isRequired,
};

const SingleGuideSplash = ({ getStarted, title, message, guide, bounds }) => (
  <div className={styles.splashContainer}>
    <OverviewMapBackground bounds={bounds}>
      <AdminBarHandle className={styles.adminBarHandle} />
      <div className={styles.menu}>
        <Menu />
      </div>
      <div className={styles.content}>
        <span className={styles.seal}>
          <Seal type="light" style={{ maxHeight: "100px", maxWidth: "200px" }} />
        </span>
        <h1>{guide.splash_title || title}</h1>
        <p>{message}</p>
        <div className={styles.guideButton}>
          <Button
            expandOnMobile
            data-guide={guide.guide_type}
            data-role="get-started"
            onClick={() => getStarted(guide)}
            label={getCallToAction(guide)}
            withSpinner
          />
        </div>
      </div>
    </OverviewMapBackground>
  </div>
);
SingleGuideSplash.propTypes = {
  getStarted: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  guide: guidePropType.isRequired,
};

const MultiGuideSplash = ({ getStarted, guides, bounds, tenantName, title }) => {
  const buttons = guides.map((guide) => (
    <GuideStartButton key={guide.id} withSpinner guide={guide} callback={getStarted} />
  ));

  return (
    <div className={styles.splashContainer}>
      <OverviewMapBackground bounds={bounds}>
        <AdminBarHandle className={styles.adminBarHandle} />
        <div className={styles.menu}>
          <Menu />
        </div>
        <div className={styles.content}>
          <span className={styles.seal}>
            <Seal type="light" style={{ maxHeight: "100px", maxWidth: "200px" }} />
          </span>
          <h1>{title || <Text t="pages.home.header" tenantName={tenantName} />}</h1>
          <SuperAdminFeature>
            <AdminTools />
          </SuperAdminFeature>
        </div>
      </OverviewMapBackground>
      <div className={styles.guideButtons}>{buttons}</div>
      <Line fullWidth noMargin />
    </div>
  );
};
MultiGuideSplash.propTypes = {
  getStarted: PropTypes.func.isRequired,
  guides: PropTypes.arrayOf(guidePropType).isRequired,
  tenantName: PropTypes.string,
  title: PropTypes.string,
};

const TopLevelGuides = ({ guides, ...props }) => {
  if (guides.length === 1) return <SingleGuideSplash guide={guides[0]} {...props} />;
  return <MultiGuideSplash guides={guides} {...props} />;
};
TopLevelGuides.propTypes = {
  guides: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const RequirementCatalogSplash = ({ guide, getStarted }) => (
  <div className={styles.requirementCatalogSplash}>
    <Markdown source={getHighlightMessageMarkdown(guide) || ""} />
    <p>
      <StartButton guide={guide} callback={getStarted} />
    </p>
  </div>
);
RequirementCatalogSplash.propTypes = {
  guide: guidePropType.isRequired,
  getStarted: PropTypes.func.isRequired,
};

const Guides = ({ guides, ...props }) => {
  const topLevelGuides = R.reject(isRequirementCatalog, guides);
  const requirementCatalogGuide = R.find(isRequirementCatalog, guides);

  return (
    <div className={styles.container}>
      <AdminBar useURLParamToSwitchVersions />
      {!R.isEmpty(topLevelGuides) && <TopLevelGuides guides={topLevelGuides} {...props} />}
      {!R.isEmpty(topLevelGuides) && requirementCatalogGuide && (
        <RequirementCatalogSplash guide={requirementCatalogGuide} {...props} />
      )}
      {R.isEmpty(topLevelGuides) && requirementCatalogGuide && (
        <TopLevelGuides guides={[requirementCatalogGuide]} {...props} />
      )}
    </div>
  );
};
Guides.propTypes = {
  guides: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Guides;
