import * as R from "ramda";

import { compact, isPresent } from "utils/func";

// given a specific Zone:

export const getZonePermissionCategory = (zone) => R.path(["permission", "category"], zone);
export const getZoneZoningDistrict = (zone) =>
  zone && zone.code ? `${zone.display_name} (${zone.code})` : undefined;

// given the full Zoning answer:

export const getZones = R.propOr([], "zones");
export const getParcelIDs = R.pipe(R.propOr([], "parcels"), R.pluck("value"), compact);

export const getZonesWithDevelopmentStandards = R.pipe(
  getZones,
  R.filter(R.prop("development_standards_url")),
);
export const getEffectiveZone = R.pipe(getZones, R.head);
export const getOverlays = R.pipe(getZones, R.filter(R.prop("is_overlay")));
export const getBaseZone = R.pipe(getZones, R.find(R.complement(R.prop("is_overlay"))));

export const getPermission = R.pipe(getEffectiveZone, R.defaultTo({}), R.prop("permission"));
export const getZoningPermissionCategory = R.pipe(
  getEffectiveZone,
  R.defaultTo({}),
  getZonePermissionCategory,
);
export const getIsProhibited = R.pipe(getZoningPermissionCategory, R.equals("prohibited"));

export const getZoningDistrict = R.pipe(getBaseZone, getZoneZoningDistrict);

export const getIsZoningPresent = R.converge(
  R.or,
  R.map(R.propSatisfies(isPresent, R.__), ["zones", "parcels"]),
);

export const shouldSnapToFeature = (tenant, { zones, parcels }) =>
  R.isEmpty(zones) || (R.isEmpty(parcels) && tenant.hasParcels);
