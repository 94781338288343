const defaultLogger = (error, context) => {
  console.error("ErrorReporter:", error, context);
};
class ErrorReporter {
  constructor() {
    this._callbacks = [];
  }

  onError(cb) {
    this._callbacks.push(cb);
  }

  get callbacks() {
    if (this._callbacks.length === 0) {
      return [defaultLogger];
    }
    return this._callbacks;
  }

  reportError(error, context = {}) {
    return Promise.all(
      this.callbacks.map((fn) => {
        try {
          return fn(error, context);
        } catch (e) {
          console.error("[ErrorReporter] error in callback:", e);
          return true;
        }
      }),
    );
  }

  wrap(fn, context = {}) {
    return (...args) => {
      try {
        return fn(...args);
      } catch (er) {
        this.reportError(er, context);
        throw er;
      }
    };
  }
}

export const instance = new ErrorReporter();
export const reportError = (error, context) => instance.reportError(error, context);
export const wrap = (fn, context) => instance.wrap(fn, context);
