import * as R from "ramda";

import { FETCH_SESSION, SET_CURRENT_USER } from "actions";

const initialState = {
  loaded: false,
  user_id: null,
  permissions: {
    routes: {},
    flags: {},
  },
  uuid: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SESSION: {
      // HACK(rtlong): This is expressly to allow the sagas to signal to SessionProvider that the session needs to be refetched.
      const {
        payload: { uuid },
      } = action;
      return R.assoc("uuid", uuid)(state);
    }
    case SET_CURRENT_USER: {
      const {
        payload: { id, permissions, accela_connected },
      } = action;
      return R.compose(
        R.assoc("loaded", true),
        R.assoc("user_id", id),
        R.assoc("accela_connected", accela_connected),
        permissions ? R.assoc("permissions", permissions) : R.identity,
      )(state);
    }
    default:
      return state;
  }
};

const getUserId = R.prop("user_id");
const getIsSessionLoaded = R.propOr(false, "loaded");
const getIsAccelaConnected = R.prop("accela_connected");

export const selectSession = R.prop("session");
export const selectSessionCacheBustingKey = R.compose(R.prop("uuid"), selectSession);
export const selectCurrentUserId = R.compose(getUserId, selectSession);
export const selectSessionIsLoaded = R.compose(getIsSessionLoaded, selectSession); // has the /session request come back yet
export const selectSessionHasUserId = R.compose(R.not, R.isNil, selectCurrentUserId); // does the /session response include a user ID; if so we're a guest user, and if not the user is a NullUser
export const selectIsAccelaConnected = R.compose(getIsAccelaConnected, selectSession);

export const selectHasPermission = R.curry((permissionPath, state) =>
  R.compose(R.path(["permissions", ...permissionPath]), selectSession)(state),
);
