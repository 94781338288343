import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";

import Field from "containers/Field";
import { AnswerContextProvider } from "containers/withAnswerContext";
import { selectFieldByKey } from "reducers/fields";
import { getAsPreviousRecord, selectAnswerMutations } from "selectors/changesets";

import styles from "./AnswerChanges.scss";

const AnswerChanges = ({ changeset }) => {
  const [overrideMutations, mutations] = R.partition(
    R.propEq("key", "overrides"),
    selectAnswerMutations(changeset.mutations),
  );

  const previousRecord = getAsPreviousRecord(changeset);

  if (mutations.length === 0 && overrideMutations.length === 0) {
    return null;
  }

  return (
    <AnswerContextProvider value={{ type: "REDUX", record: changeset }}>
      <div className={styles.container}>
        <table>
          <thead>
            <tr>
              <th>Question</th>
              <th>New answer</th>
              <th>Old answer</th>
            </tr>
          </thead>
          <tbody>
            {overrideMutations.map((mutation) => (
              <OverrideMutation
                mutation={mutation}
                key={mutation.identifier}
                previousRecord={previousRecord}
              />
            ))}
            {mutations.map((mutation) => (
              <AnswerMutation
                mutation={mutation}
                key={mutation.identifier}
                previousRecord={previousRecord}
              />
            ))}
          </tbody>
        </table>
      </div>
    </AnswerContextProvider>
  );
};

const AnswerMutation = ({ mutation, previousRecord, fieldProps = {} }) => {
  const field = useSelector((state) => selectFieldByKey(state, mutation.key));

  return (
    <tr data-answer-mutation={field.key}>
      <td>{field.field_label}</td>
      <td data-current-answer>
        <Field field={field} behaviorType="disabled" hideLabel disabled {...fieldProps} />
      </td>
      <td data-previous-answer>
        <AnswerContextProvider value={{ type: "REDUX", record: previousRecord }}>
          <Field field={field} behaviorType="disabled" hideLabel disabled {...fieldProps} />
        </AnswerContextProvider>
      </td>
    </tr>
  );
};

const OverrideMutation = ({ mutation, previousRecord }) => {
  const newValue = mutation.change.new_value || {};
  const previousValue = mutation.change.previous_value || {};

  // Filter out previous overrides from display
  const dupes = [];
  Object.keys(newValue).forEach((key) => {
    if (newValue[key] === previousValue[key]) {
      dupes.push(key);
    }
  });

  const filter = (entity) => !dupes.includes(entity.identifier);

  return (
    <AnswerMutation mutation={mutation} previousRecord={previousRecord} fieldProps={{ filter }} />
  );
};

export default AnswerChanges;
