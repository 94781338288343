import React, { useState } from "react";
import { useSelector } from "react-redux";

import { PAYMENT_PROCESSORS, selectPaymentProcessor } from "reducers/tenant";

import CityBasePayment from "./CityBasePayment";
import InPersonPayment from "./InPersonPayment";
import SpreedlyPayment from "./SpreedlyPayment";
import StripePayment from "./StripePayment";

const PaymentPage = () => {
  const paymentProcessor = useSelector(selectPaymentProcessor);
  const [paymentType, setPaymentType] = useState("card");

  if (paymentType === "in_person") {
    return <InPersonPayment setPaymentType={setPaymentType} paymentType={paymentType} />;
  }

  switch (paymentProcessor) {
    case PAYMENT_PROCESSORS.citybase:
      return <CityBasePayment setPaymentType={setPaymentType} paymentType={paymentType} />;
    case PAYMENT_PROCESSORS.spreedly:
      return <SpreedlyPayment setPaymentType={setPaymentType} paymentType={paymentType} />;
    case PAYMENT_PROCESSORS.stripe:
      return <StripePayment setPaymentType={setPaymentType} paymentType={paymentType} />;
    default:
      throw new Error(`unsupported payment processor: ${paymentProcessor}`);
  }
};

export default PaymentPage;
