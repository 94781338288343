import React from "react";

import { TableGroup } from "components/admin/utilities/Table";
import Text from "containers/Text";

import DocumentsTable, { AttachmentsTable } from "./DocumentsTable";

const { default: UploadRequirementsTable } = require("./UploadRequirementsTable");

const applicationsWithStatusCount = (applications, statuses) =>
  applications.filter((application) => statuses.includes(application.status)).length;

const UploadRequirementsAdminHeading = ({ uploadRequirements }) => {
  const approvedCount = applicationsWithStatusCount(uploadRequirements, ["approved", "issued"]);
  const awaitingReviewCount = applicationsWithStatusCount(uploadRequirements, ["submitted"]);
  const documentsAreKey =
    awaitingReviewCount > 1 ? "projects.documents.documents_are" : "projects.documents.document_is";

  return (
    <h3>
      <span>
        {awaitingReviewCount} <Text t={documentsAreKey} />{" "}
        <Text t="projects.documents.awaiting_review" />{" "}
      </span>
      <Text
        t="projects.documents.documents_approved"
        count={approvedCount}
        total={uploadRequirements?.length}
      />
    </h3>
  );
};

const UploadRequirementsHeading = ({ uploadRequirements }) => {
  const awaitingUploadCount = applicationsWithStatusCount(uploadRequirements, [
    "unsubmitted",
    "in_progress",
    null,
  ]);

  const documentsAreKey =
    awaitingUploadCount > 1 ? "projects.documents.documents_are" : "projects.documents.document_is";

  return (
    <>
      <h3>
        <span>
          {awaitingUploadCount} <Text t={documentsAreKey} />{" "}
          <Text t="projects.documents.awaiting_upload" />{" "}
        </span>
      </h3>
      <p>
        <Text t="projects.documents.require_review" />
      </p>
    </>
  );
};

export const UploadRequirements = ({ uploadRequirements, isAdmin }) => {
  if (uploadRequirements.length === 0) return null;

  return (
    <section>
      {isAdmin ? (
        <UploadRequirementsAdminHeading uploadRequirements={uploadRequirements} />
      ) : (
        <UploadRequirementsHeading uploadRequirements={uploadRequirements} />
      )}
      <TableGroup>
        <UploadRequirementsTable isAdmin={isAdmin} uploadRequirements={uploadRequirements} />
      </TableGroup>
    </section>
  );
};

export const Documents = ({ documents, uploadRequirements }) => {
  if (documents.length === 0) return null;

  return (
    <section>
      <h3>
        {uploadRequirements?.length > 0 ? (
          <Text t="projects.documents.other_documents" />
        ) : (
          <Text t="projects.documents.documents" />
        )}
      </h3>
      <DocumentsTable documents={documents} />
    </section>
  );
};

export const MessageAttachments = ({ attachments }) => {
  if (attachments.length === 0) return null;

  return (
    <section>
      <h3>
        <Text t="projects.documents.message_documents" />
      </h3>
      <AttachmentsTable documents={attachments} />
    </section>
  );
};
