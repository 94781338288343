import React from "react";

export const hoc =
  (HocComponent, args = {}) =>
  (InnerComponent) => {
    let innerName = InnerComponent.displayName || InnerComponent.name;
    if (!innerName) {
      // console.warn("hoc() helper was given an InnerComponent that doesn't have a name", {
      //   InnerComponent,
      // });
      innerName = "unknown";
    }

    let hocName = HocComponent.displayName || HocComponent.name;
    if (!hocName) {
      // console.warn("hoc() helper was given an HocComponent that doesn't have a name", {
      //   HocComponent,
      // });
    }
    hocName ||= `HOC[${__filename}]`;

    const Hoc = React.forwardRef((props, ref) => (
      <HocComponent {...args} {...props} Component={InnerComponent} ref={ref} />
    ));
    Hoc.displayName = `${hocName}(${innerName})`;
    return Hoc;
  };
