import React from "react";
import PropTypes from "prop-types";

import { SmallButtonBordered } from "components/Button";
import Icon from "components/Icon";
import Modal from "components/LegacyModal";
import { DefaultLine } from "components/Line";

import styles from "./ChangesetModal.scss";

const CancelButton = ({ onClick }) => (
  <SmallButtonBordered className={styles.cancel} onClick={onClick}>
    <Icon icon="times" />
    <span>Close</span>
  </SmallButtonBordered>
);

const ChangesetModal = ({ header, headerInstructions, onClose, returnTo, children }) => (
  <Modal
    onClose={onClose}
    returnTo={returnTo}
    fullWidth
    nonCovering
    showCancel
    CancelButton={CancelButton}
  >
    <div className={styles.container}>
      <div>
        <h1>{header}</h1>
        {headerInstructions && <p className={styles.instructions}>{headerInstructions}</p>}
      </div>
      <div className={styles.fullWidth}>
        <DefaultLine noMargin />
      </div>
      {children}
    </div>
  </Modal>
);

ChangesetModal.propTypes = {
  header: PropTypes.string.isRequired,
  headerInstructions: PropTypes.string,
  onClose: PropTypes.func,
};

export default ChangesetModal;
