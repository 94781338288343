import * as R from "ramda";
import { connect } from "react-redux";

import SubHeader from "components/projects/requirement/SubHeader";
import { withAnswerContextConsumer } from "containers/withAnswerContext";
import { getName } from "reducers/projects";
import { getName as getRequiremnentName } from "reducers/requirements";

const mapStateToProps = (state, { requirement, answerContext: { record } }) => ({
  id: record.id,
  businessName: getName(record),
  requirementName: getRequiremnentName(requirement),
});

export default R.compose(withAnswerContextConsumer, connect(mapStateToProps))(SubHeader);
