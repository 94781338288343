import React, { useState } from "react";
import classnames from "classnames";
import { usePopper } from "react-popper";

import styles from "./Popover.scss";

const Popover = ({ children, referenceElement, placement, className }) => {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      { name: "offset", options: { offset: [0, 10] } },
    ],
  });

  return (
    <div
      ref={setPopperElement}
      className={classnames(styles.container, className)}
      style={popperStyles.popper}
      {...attributes.popper}
    >
      {children}
      <div className={styles.arrow} ref={setArrowElement} style={popperStyles.arrow} />
    </div>
  );
};

export default Popover;
