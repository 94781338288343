// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-utilities-CircularProgressBar__path--oodBP {\n  stroke-linecap: round;\n  transition: stroke-dashoffset 0.5s ease 0s;\n}", "",{"version":3,"sources":["webpack://./ui/components/utilities/CircularProgressBar.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,0CAAA;AACF","sourcesContent":[".path {\n  stroke-linecap: round;\n  transition: stroke-dashoffset 0.5s ease 0s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"path": "ui-components-utilities-CircularProgressBar__path--oodBP"
};
export default ___CSS_LOADER_EXPORT___;
