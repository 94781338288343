import React from "react";
import classNames from "classnames";

import { FlexColumn } from "components/FlexColumn";

import styles from "./Layout.scss";

/*
  These layouts are used primarily on project based pages and standardize the presentation of pages.
  They typically are nested in this hierachy:

  <Layout.Page name="name-of-page-used-by-tests">
    <!-- Typically Header location -->
    <Layout.Main>
      <!-- Often Steps -->
      <Layout.ContentWithSidebar>
        <Layout.Content> </Layout.Content>
        <Layout.Sidebar> <!-- typically SummaryTable --> </Layout.Sidebar>
      </Layout.ContentWithSidebar>
      <!-- Often where components like the helpbar is placed -->
    </Layout.Main>
    <!-- Often where elements that cover the page when enabled (ex: peekbar on mobile) -->
  </Layout.Page>
*/

/**
 * Top level page container
 * @param {} name - important for tests so we have a standard way to check the currently rendered page
 * @returns
 */
export function Page({ name, children }) {
  return (
    <FlexColumn fullHeight data-page-name={name}>
      {children}
    </FlexColumn>
  );
}

/**
 * Provides main element with our standard gutters inside a flex container that offupies the full height
 * @param {*} param0
 * @returns
 */
export function Main({ children }) {
  return (
    <FlexColumn exactHeightContent>
      <div className={styles.gutters}>
        <main role="main" className={styles.mainContainer}>
          {children}
        </main>
      </div>
    </FlexColumn>
  );
}

/**
 * Container that expects both a Content and Sidebar child.
 * @param {*} param0
 * @returns
 */
export function ContentWithSidebar({ children }) {
  return <div className={styles.contentWithSidebarContainer}>{children}</div>;
}

/**
 * Container for content, can be used inside a ContentWithSidebar our on its own.
 * @param {*} param0
 * @returns
 */
export function Content({ children }) {
  return <div className={styles.contentContainer}>{children}</div>;
}

/**
 * Sidebar to be nested under a ContentWithSidebar, Typically houses the SummaryTable
 * @param {*} param0
 * @returns
 */
export function Sidebar({ className, children }) {
  return <div className={classNames(styles.sidebarContainer, className)}>{children}</div>;
}

/**
 * Header of a given level
 * @param {*} param0
 * @returns
 */
export function Header({ children, level = 1 }) {
  const H = "h" + level;
  return <H className={styles.header}>{children}</H>;
}
