// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-Logo__container-link--M_JeH {\n  display: block;\n}\n.ui-components-Logo__container-link--M_JeH img {\n  width: 100%;\n  height: auto;\n}", "",{"version":3,"sources":["webpack://./ui/components/Logo.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ","sourcesContent":[".container-link {\n  display: block;\n\n  img {\n    width: 100%;\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container-link": "ui-components-Logo__container-link--M_JeH",
	"containerLink": "ui-components-Logo__container-link--M_JeH"
};
export default ___CSS_LOADER_EXPORT___;
