/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import Map, { NavigationControl, ScaleControl } from "react-map-gl";
import { useSelector } from "react-redux";

import Text from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { useServerVariables } from "contexts/serverVariables";
import { getAnswerForField } from "reducers/answerContexts";
import { selectUseCode } from "reducers/projects";
import { useMapStyle } from "services/MapStyleBuilder";
import zoningCache from "services/ZoningCache";
import { boundsToLngLatLike, calculateZoomLevelForBounds } from "utils/map";
import { getSlug } from "utils/useCode";

import "mapbox-gl/dist/mapbox-gl.css";
import MapPopup from "./MapPopup";
import styles from "./ZoningMap.scss";

const ZoningMap = ({
  addressField,
  onLocationChange,
  isFullScreen,
  isInCityBoundary,
  isZoningEnabled,
  hidePopup,
  compactControls,
  mapType = "default",
  setMapType,
  unansweredFieldCount,
  zoning,
}) => {
  const { record } = useAnswerContext();
  const address = getAnswerForField(record, addressField);
  const addressCenter = { longitude: address?.longitude, latitude: address?.latitude };
  const hasUseCode = useSelector((state) => !!getSlug(selectUseCode(state, record)));
  const { mapboxToken } = useServerVariables();
  const { id: tenantID, bounds, tile_version } = useCurrentTenantInfo();

  const onMapTypeChange = useCallback(() => {
    setMapType((prevType) => (prevType === "default" ? "satellite" : "default"));
  }, [setMapType]);

  const clearances = useSelector((state) => zoningCache.getZonesPerPermission(state, record));
  const radiusRules = useSelector((state) =>
    zoningCache.getPermissionsForRadiusRules(state, record),
  );

  const mapRef = useRef();
  const containerRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (!containerRef.current) return;
    const cur = containerRef.current;
    setDimensions({ width: cur.offsetWidth, height: cur.offsetHeight });
  }, []);

  useEffect(() => {
    mapRef.current?.resize(); // map needs to be resized or it will retain previous size
  }, [isFullScreen]);

  useEffect(() => {
    if (!mapRef.current || !address?.latitude) return;
    mapRef.current.flyTo({ center: [address.longitude, address.latitude], zoom: 16 });
  }, [address?.latitude, address?.longitude]);

  const mapStyle = useMapStyle({
    tenantID,
    tileVersion: tile_version,
    clearances,
    radiusRules,
    address,
    zoning,
    type: mapType,
  });
  let initialViewState; // use address center or tenant center and related zoom on first load
  if (address?.latitude) initialViewState = { ...addressCenter, zoom: 16 };
  else {
    const zoom = calculateZoomLevelForBounds(bounds, dimensions);
    const lngLatLike = boundsToLngLatLike(bounds);
    initialViewState = { bounds: lngLatLike, zoom };
  }
  const onClick = useCallback(
    ({ lngLat }) => onLocationChange({ latitude: lngLat.lat, longitude: lngLat.lng }, "click"),
    [onLocationChange],
  );

  return (
    <div ref={containerRef} className={styles.container}>
      <Map
        ref={mapRef}
        initialViewState={initialViewState}
        mapStyle={mapStyle}
        mapboxAccessToken={mapboxToken}
        dragRotate
        onClick={onClick}
        clickRadius={0}
        touchZoom
        touchRotate={false}
        transitionDuration={0}
      >
        {isZoningEnabled && !hidePopup && (
          <MapPopup
            addressField={addressField}
            address={address}
            zoning={zoning}
            unansweredFieldCount={unansweredFieldCount}
            isInCityBoundary={isInCityBoundary}
          />
        )}

        <ScaleControl />
        <NavigationControl showCompass={false} />
      </Map>

      <div
        className={classnames({
          [styles.mapNotFullScreen]: !isFullScreen,
        })}
      >
        <div
          className={classnames(styles.mapControlsContainer, {
            [styles.compact]: compactControls,
          })}
        >
          {isZoningEnabled && (
            <a className={styles.mapTypeToggle} onClick={onMapTypeChange}>
              {mapType === "default" ? (
                <Text t="guides.location.satellite_view" />
              ) : (
                <Text t="guides.location.map_view" />
              )}
            </a>
          )}
        </div>

        {isZoningEnabled && hasUseCode && (
          <ul className={classnames(styles.legend, { [styles.compact]: compactControls })}>
            <li>
              <div className={styles.permitted} />
              <Text t="guides.location.permitted" />
            </li>
            <li>
              <div className={styles.conditional} />
              <Text t="guides.location.conditional" />
            </li>
            <li>
              <div className={styles.prohibited} />
              <Text t="guides.location.prohibited" />
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default ZoningMap;
