import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import { Section } from "components/Section";
import StaticMap from "components/maps/StaticMap";
import Text from "containers/Text";
import { isBlank } from "utils/func";
import { zoningPropType } from "utils/sharedPropTypes";
import { getParcelIDs } from "utils/zoning";

import styles from "./ParcelDetails.scss";

const renderParcel = (id) => (
  <div key={id} className={styles.container}>
    <div className={styles.details}>
      <h3 className={styles.parcelId}>
        <Text t="guides.zoning.parcel_id" id={id} />
      </h3>
    </div>
  </div>
);

const ParcelDetails = ({ zoning, municipalityType }) => {
  const parcelIDs = getParcelIDs(zoning);
  if (isBlank(parcelIDs)) return null;

  return (
    <Section heading={<Text t="guides.zoning.parcel_details_heading" />}>
      <div className={styles.description}>
        <Text t="guides.zoning.parcel_details_description" municipalityType={municipalityType} />
      </div>
      <div className={styles.content}>
        <div className={styles.map}>
          <StaticMap />
        </div>
        <div className={styles.parcelNumbers}>{R.map(renderParcel, parcelIDs)}</div>
      </div>
    </Section>
  );
};

ParcelDetails.propTypes = {
  zoning: zoningPropType,
  municipalityType: PropTypes.string.isRequired,
};

export default ParcelDetails;
