import React, { useCallback } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import Text from "containers/Text";

import styles from "./Tabs.scss";
import { ButtonDiv } from "./accessibility/Div";

export const Tabs = ({ config, onSelect, selected, invertColors = false }) => {
  const selectTab = useCallback((name) => () => onSelect(name), [onSelect]);
  const tabs = R.pipe(
    R.toPairs,
    R.addIndex(R.map)(([name, label], _i) => (
      <ButtonDiv
        data-location-tab
        className={classnames(styles.tab, {
          [styles.selected]: selected === name,
        })}
        onClick={selectTab(name)}
        key={name}
      >
        <Text t={label} />{" "}
      </ButtonDiv>
    )),
  )(config);

  return (
    <div
      className={classnames(styles.container, {
        [styles.invert]: invertColors,
      })}
    >
      {tabs}
    </div>
  );
};
Tabs.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  config: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Tabs;
