import React from "react";
import PropTypes from "prop-types";

import Nav from "components/Nav";
import TopLine from "containers/TopLine";

import styles from "./Header.scss";

const Header = ({ hideTitle }) => (
  <div role="banner" className={styles.container}>
    <TopLine />
    <Nav hideTitle={hideTitle} />
  </div>
);

Header.propTypes = {
  hideTitle: PropTypes.bool,
};

export default Header;
