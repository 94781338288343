import resourceOperations, { ReadOptions } from "./resourceOperations";
import * as R from "ramda";
import { Requirement } from "types/entities";

const operation = resourceOperations({
  basePath: "/api/requirements",
  baseKey: ["requirements"],
});

export const useRequirement = (id: number, opts: ReadOptions = {}) =>
  operation.useRead<Requirement>(id, opts);

export const useRequirements = (opts: ReadOptions = {}) =>
  operation.useRead<Requirement[]>(null, opts);

export const useRequirementsByID = (
  ids: number[] = [],
  opts: ReadOptions = {}
) =>
  useRequirements({
    ...opts,
    select: R.filter((r: any) => ids.includes(r.id)),
  });
