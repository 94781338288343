import React from "react";

import useToggleState from "hooks/useToggleState";
import { hoc } from "utils/hoc";

const WithFocusToggle = ({ initialValue = false, Component, ...props }) => {
  const { value, set, setOn, setOff } = useToggleState(initialValue);
  const hookProps = {
    focused: value,
    setFocused: set,
    focus: setOn,
    unfocus: setOff,
  };
  return <Component {...props} {...hookProps} />;
};
export default hoc(WithFocusToggle);
