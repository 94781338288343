import React from "react";
import PropTypes from "prop-types";

import { TextButton } from "components/Button";
import Popover from "components/Popover";
import Text from "containers/Text";
import useToggleState from "hooks/useToggleState";

import styles from "./Footer.scss";

const FixAnswersTooltip = ({ referenceElement, numErrors, scrollToFirstError }) => {
  const { value: isHidden, setOn: onHide } = useToggleState(false);

  if (isHidden) return null;

  return (
    <Popover referenceElement={referenceElement} placement="top-end" className={styles.errorBox}>
      <div>
        <Text t="guides.footer.error_message" numErrors={numErrors} />
      </div>
      <div className={styles.actions}>
        <TextButton onClick={onHide}>
          <Text t="close" />
        </TextButton>
        <TextButton onClick={scrollToFirstError}>
          <Text t="guides.footer.fix_errors" numErrors={numErrors} />
        </TextButton>
      </div>
    </Popover>
  );
};
FixAnswersTooltip.propTypes = {
  numErrors: PropTypes.number,
  scrollToFirstError: PropTypes.func,
};

export default FixAnswersTooltip;
