// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-projects-Download__button--THBzq {\n  text-decoration: none !important;\n}", "",{"version":3,"sources":["webpack://./ui/components/projects/Download.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;AACF","sourcesContent":[".button {\n  text-decoration: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ui-components-projects-Download__button--THBzq"
};
export default ___CSS_LOADER_EXPORT___;
