import React from "react";

import { hoc } from "utils/hoc";

const withProps = (givenProps) => {
  const WithProps = ({ Component, ...props }) => {
    const finalProps = {
      ...props,
      ...(typeof givenProps === "function" ? givenProps(props) : givenProps),
    };
    return <Component {...finalProps} />;
  };
  return hoc(WithProps);
};

export default withProps;
