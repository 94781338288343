import * as R from "ramda";
import { connect } from "react-redux";

import { useAnswerContext, withAnswerContextConsumer } from "containers/withAnswerContext";
import { getLogEntriesByRequirementApplicationID } from "reducers/projects";

const mapStateToProps = (state, { requirementApplicationID, answerContext: { record } }) => {
  const history = getLogEntriesByRequirementApplicationID(record, requirementApplicationID);
  return { history };
};

export default (component) =>
  R.compose(withAnswerContextConsumer, connect(mapStateToProps))(component);

export const useApplicationHistory = (requirementApplicationID) => {
  const { record } = useAnswerContext();
  const history = getLogEntriesByRequirementApplicationID(record, requirementApplicationID);
  return history;
};
