// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-pages-Requirements__description--vsVwN {\n  margin-bottom: 1em;\n}\n\n.ui-components-pages-Requirements__search-container--plKqS {\n  max-width: 22em;\n  margin: 2em 0 1.5em;\n}", "",{"version":3,"sources":["webpack://./ui/components/pages/Requirements.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF","sourcesContent":[".description {\n  margin-bottom: 1em;\n}\n\n.search-container {\n  max-width: 22em;\n  margin: 2em 0 1.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "ui-components-pages-Requirements__description--vsVwN",
	"search-container": "ui-components-pages-Requirements__search-container--plKqS",
	"searchContainer": "ui-components-pages-Requirements__search-container--plKqS"
};
export default ___CSS_LOADER_EXPORT___;
