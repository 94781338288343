// implements the detection in
// https://stackoverflow.com/questions/54578395/how-can-i-check-if-font-awesome-is-blocked-from-a-clients-browser

const state = { available: true };

export const isFontAwesomeAvailable = () => state.available;

export default () => {
  // insert a hidden element with font awesome and measure its width.
  // if it does not differ from the width of a non-existent font,
  // then we add the class .font-awesome-blocked to the body, allowing us to
  // target this case in CSS.
  //
  // this handles the case where the browser has fetched the font, but
  // refuses to display it due to local security policy.
  const el = document.createElement("span");
  el.className = "fa fa-ambulance";
  el.style.position = "absolute";
  el.style.left = 0;
  el.style.top = 0;
  el.style.visibility = "hidden";
  document.body.appendChild(el);

  const measureElement = () => {
    const widthBefore = el.offsetWidth;
    el.style.fontFamily = "non-existent-font";
    const widthAfter = el.offsetWidth;
    if (widthBefore === widthAfter) {
      document.body.setAttribute("data-font-awesome", "blocked");
      state.available = false;
    }
    document.body.removeChild(el);
  };

  setTimeout(measureElement, 1000);
};
