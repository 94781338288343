import * as R from "ramda";
import { createSelector } from "reselect";

import { SET_FEES } from "actions";
import { getFeeIDs } from "reducers/requirements";
import { indexByID } from "utils/func";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_FEES:
      return action.payload;
    default:
      return state;
  }
};

export const selectFees = R.prop("fees");

export const selectRequirementFeeTypes = R.pipe(
  selectFees,
  R.filter(R.propEq("type", "RequirementFee")),
);

export const selectFeesForRequirement = (fees, requirement) =>
  R.filter(R.propEq("requirement_id", requirement.id), fees);

export const selectFeesForTransaction = (fees, transaction) => {
  if (!transaction) return [];

  const feeIDs = R.map(R.prop("fee_id"), transaction.line_items);
  return indexByID(R.props(feeIDs, fees));
};

export const selectFeesByID = (state, feeIDs) => R.props(feeIDs, selectFees(state));
export const selectFeeByID = (state, id) => R.prop(id, selectFees(state));
export const selectFeeByIdentifier = (state, identifier) =>
  R.find(R.propEq("identifier", identifier), R.values(selectFees(state)));

// TODO: remove these?
export const sumFees = (fees) => R.sum(R.map(parseFloat, R.pluck("cost", fees)));

export const sumFeesForRequirements = (fees, requirements) => {
  const requirementIDs = R.pluck("id", requirements);
  return R.pipe(
    R.filter((fee) => R.contains(fee.requirement_id, requirementIDs)),
    sumFees,
  )(fees);
};

export const getRequirementTotalFees = (requirement, fees) =>
  R.pipe(
    R.pickAll(getFeeIDs(requirement)),
    R.values,
    R.reject(R.isNil),
    R.map(R.prop("cost")),
    R.sum,
  )(fees);

export const getName = R.prop("name");
export const getSlug = R.prop("slug");
export const getIdentifier = (fee) => `fee:${getSlug(fee).replace(/-/g, "_")}`;
export const getRequirementID = R.prop("requirement_id");
export const getFeesForRequirement = (fees, requirement) =>
  R.filter(R.propEq("requirement_id", requirement.id), fees);
export const selectFeesWithCosts = createSelector(
  selectFees,
  (_, feeTotals) => feeTotals,
  (fees, feeTotals) => getFeesWithCosts(feeTotals, fees),
);

export const getFeesWithCosts = R.curry((feeTotals, fees) => {
  const addCostToFees = (activeFees, [feeID, feeTotal]) =>
    R.append(R.merge(fees[feeID], { cost: Number(feeTotal) }), activeFees);

  return R.pipe(
    R.pick(R.pluck("id", R.values(fees))),
    R.toPairs,
    R.reduce(addCostToFees, []),
  )(feeTotals);
});

export const getFeeCost = (record, fee) => R.path(["fees", fee.id], record);

export const selectFeesForRequirementID = (state, requirementID) =>
  selectFeesForRequirement(R.values(selectFees(state)), { id: requirementID });

export const selectFeesWithTotalsForRequirementID = (state, feeTotals, requirementID) =>
  R.pipe(
    selectFees,
    R.filter(R.propEq("requirement_id", requirementID)),
    getFeesWithCosts(feeTotals),
  )(state);
