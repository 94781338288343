import React, { useCallback } from "react";

import { RadioButtonInput } from "components/forms/BareFields";
import Text from "containers/Text";
import { useUpdateProjectInvoice } from "queries/invoices";

import Section from "./Section";

const PaymentTypeSelector = ({ paymentType, setPaymentType, invoiceID, projectID }) => {
  const invoiceMutation = useUpdateProjectInvoice(projectID, invoiceID);

  const onChange = useCallback(
    ({ target: { value } }) => {
      setPaymentType(value);
      invoiceMutation.mutateAsync({ payment_type: value });
    },
    [setPaymentType, invoiceMutation],
  );

  const paymentModeOptions = [
    { label: "Credit card", value: "card" },
    { label: "Check or cash", value: "in_person" },
  ];

  return (
    <Section headerTextKey="projects.requirement.payment.method.header">
      <h4>
        <Text t="projects.requirement.payment.method.label" />
      </h4>
      <RadioButtonInput
        name="payment_type"
        value={paymentType}
        options={paymentModeOptions}
        onChange={onChange}
      />
    </Section>
  );
};

export default PaymentTypeSelector;
