import React from "react";

import Icon from "components/Icon";
import Markdown from "components/Markdown";
import { getInstructions } from "reducers/fields";
import { isPresent } from "utils/func";

import styles from "./InstructionField.scss";

const InstructionField = ({ field }) => {
  const { text, title } = getInstructions(field);
  return (
    <div className={styles.container}>
      {isPresent(title) && (
        <h3 style={styles.h3}>
          <Icon icon="exclamation-circle" size="lg" />
          <span>{title}</span>
        </h3>
      )}
      {isPresent(text) && <Markdown source={text} />}
    </div>
  );
};

export const disabled = InstructionField;

export const IssuedInstructionField = ({ field }) => {
  const { text } = getInstructions(field);
  return <div className={styles.container}>{isPresent(text) && <Markdown source={text} />}</div>;
};
export const issued = IssuedInstructionField;

export default InstructionField;
