/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { compose } from "ramda";

import Icon, { CircularIcon } from "components/Icon";
import { ButtonDiv } from "components/accessibility/Div";
import SummaryTable from "components/projects/SummaryTable";
import Text from "containers/Text";
// TODO: inline this
import withRequirementsAndFees from "containers/guides/withRequirementsAndFees";
import withModalToggle from "containers/utils/withModalToggle";
import { formatMoney } from "utils/format";

import styles from "./Peekbar.scss";

const PermissionBar = ({ requirements, feesTotal, showFees, activate }) => (
  <ButtonDiv className={styles.container} onClick={activate} role="button" tabIndex="0">
    <div className={styles.open}>
      <Icon icon="angle-up" />
    </div>

    <div className={styles.group}>
      <div className={styles.requirementCount}>
        <Icon size="lg" icon="file-alt" />
        <span>
          <Text t="guides.peekbar.requirement_count" requirements={requirements.length} />
        </span>
      </div>
      {showFees && (
        <div className={styles.fees}>
          <Icon size="lg" icon="usd-circle" />
          <span>{formatMoney(feesTotal)}</span>
        </div>
      )}
    </div>
  </ButtonDiv>
);

PermissionBar.propTypes = {
  requirements: PropTypes.array.isRequired,
  feesTotal: PropTypes.number.isRequired,
  showFees: PropTypes.bool,
  activate: PropTypes.func.isRequired,
};

const PeekBar = ({ activate, deactivate, active, requirements, requirementID, ...props }) => (
  <div
    onClick={() => !active && activate()}
    className={classnames({ [styles.active]: active })}
    aria-hidden="true"
  >
    <PermissionBar activate={activate} requirements={requirements} {...props} />
    <div className={styles.window}>
      <a className={styles.close} onClick={deactivate}>
        ✕
      </a>
      <div className={styles.header}>
        <div className={styles.calculator}>
          <CircularIcon icon="calculator" />
        </div>
        <h1>
          <Text t="guides.peekbar.header" />
        </h1>
        {requirements.length > 0 || (
          <p>
            <Text t="guides.peekbar.instructions" />
          </p>
        )}
      </div>
      <SummaryTable
        showFees={props.showFees}
        isSidebar
        isAriaHidden
        requirementID={requirementID}
      />
    </div>
  </div>
);

PeekBar.propTypes = {
  requirements: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default compose(withRequirementsAndFees, withModalToggle)(PeekBar);
