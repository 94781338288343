import * as R from "ramda";
import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";

import * as actions from "actions";
import { compact, indexByID } from "utils/func";

export default (state = {}, action) => {
  const pl = action.payload;
  switch (action.type) {
    case actions.ADD_FIELDS:
      return R.merge(state, indexByID(pl.fields));
    default:
      return state;
  }
};

export const selectFields = R.propOr({}, "fields");

const fieldsByParentID = createSelector(
  R.prop("fields"),
  R.pipe(R.values, R.filter(R.has("parent_id")), R.groupBy(R.prop("parent_id"))),
);

const withChild = R.curry((fieldsByParent, field) =>
  R.assoc("children", R.map(withChild(fieldsByParent), fieldsByParent[field.id] || []), field),
);

export const rootFields = createSelector(
  (state) => state.fields,
  (fields) => R.pipe(R.values, R.filter(R.propSatisfies(R.isNil, "parent_id")))(fields),
);

const fieldsWithChildren = createSelector(
  R.prop("fields"),
  fieldsByParentID,
  (fields, parentIndex) => R.pipe(R.values, R.map(withChild(parentIndex)), indexByID)(fields),
);

export const selectFieldsIndexedByID = fieldsWithChildren;

export const orderFields = (fields) => R.sortBy(R.prop("sort_order"), fields);

export const selectOrderedFieldIDs = (state, fieldIDs) =>
  R.pipe(R.curry(selectFieldsByID)(state), orderFields, R.pluck("id"))(fieldIDs);

export const selectFieldIDs = createSelector(R.prop("fields"), (fields) =>
  R.map(Number, R.keys(fields)),
);

export const selectFieldsByID = R.curry((state, IDs) =>
  compact(R.props(R.map(String, IDs), fieldsWithChildren(state))),
);

export const selectFieldByID = (state, ID) => fieldsWithChildren(state)[String(ID)];

export const selectFieldBySlug = (state, slug) =>
  R.pipe(
    fieldsWithChildren,
    R.values,
    R.find((field) => getSlug(field) === slug),
  )(state);

export const selectFieldsByKey = (state, keys) =>
  R.pipe(
    fieldsWithChildren,
    R.values,
    R.filter((field) => R.includes(getKey(field), keys)),
  )(state);

export const selectFieldByKey = (state, key) =>
  R.pipe(
    fieldsWithChildren,
    R.values,
    R.find((field) => getKey(field) === key),
  )(state);

export const selectSystemFields = createSelector(fieldsWithChildren, (fields) =>
  R.filter(R.prop("system_created"), R.values(fields)),
);

export const selectUseCodeConditionFields = (state) =>
  R.filter(isUseCodeCondition, R.values(fieldsWithChildren(state)));

export const selectSystemFieldBySlug = (state, slug) =>
  R.find((f) => f.system_created && f.slug === slug, R.values(state.fields));

export const selectPrimaryUseCodeField = (state) => selectPrimaryFieldByType(state, "use_code");

export const selectPrimaryUseCodeSearchField = (state) =>
  selectPrimaryFieldByType(state, "use_code_search");

export const selectPrimaryFieldByType = createCachedSelector(
  fieldsWithChildren,
  (_, type) => type,
  (fields, type) =>
    R.find(R.where({ primary: R.equals(true), field_type: R.equals(type) }), R.values(fields)),
)((state, type) => type);

export const selectFieldsByType = createSelector(
  fieldsWithChildren,
  (_, type) => type,
  (fields, type) => R.filter(R.where({ field_type: R.equals(type) }), R.values(fields)),
);

export const selectFocusedField = createSelector(
  fieldsWithChildren,
  (state) => state.ui.focusedField,
  (fieldsLookup, key) => R.find(R.propEq("key", key), R.values(fieldsLookup)),
);

export const selectFieldKeysForIDs = (state, ids) =>
  R.pipe(
    R.filter((f) => R.contains(f.id, ids)),
    R.map(getKey),
    R.values,
  )(state.fields);

export const selectFilteredFieldIDs = (state, filter) =>
  R.pipe(R.prop("fields"), R.values, R.filter(filter), R.map(R.prop("id")))(state);

export const getKey = R.prop("key");
export const getIdentifier = R.prop("identifier");
export const getSlug = R.prop("slug");
export const getLabel = R.prop("field_label");
export const getType = R.prop("field_type");
export const getUIType = R.path(["ui", "form_input", "type"]);
export const getUIAttribute = (field, attr) => R.path(["ui", "form_input", attr], field);
export const getInstructions = R.pipe(R.path(["ui", "form_input"]), R.pick(["text", "title"]));
export const getChildByComponentKey = (field, key) =>
  R.pipe(R.prop("children"), R.find(R.propEq("component_key", key)))(field);
export const getTermsAndConditions = R.prop("terms_and_conditions_markdown");
export const isList = R.prop("is_list");
export const isPrimary = R.prop("primary");
export const isUseCodeCondition = R.prop("use_code_condition");
export const isCalculated = R.prop("calculated");
export const rejectCalculatedFields = R.reject(R.propEq("calculated", true));
export const isConditional = R.prop("conditional");
export const findFieldByType = (fields, type) => R.find((f) => R.equals(type, getType(f)), fields);

export const getFieldEnumByCode = R.curry((code, field) =>
  R.compose(
    R.find((e) => R.propEq("code", code, e)),
    R.propOr([], "enum"),
  )(field),
);

export const getEnumLabelByCode = (field, code) =>
  R.compose(R.prop("label"), getFieldEnumByCode(code))(field);
