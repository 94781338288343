import React, { useCallback } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { changeLocale } from "actions";
import Icon from "components/Icon";
import { ButtonDiv, MenuDiv } from "components/accessibility/Div";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { getActiveLanguages } from "reducers/tenant";
import { selectLanguage } from "selectors/translations";

import styles from "./LanguagePicker.scss";

const Language = ({ name, locale, isCurrent }) => {
  const dispatch = useDispatch();
  const handleChange = useCallback(() => {
    dispatch(changeLocale(locale));
  }, [dispatch, locale]);

  return (
    <ButtonDiv
      key={locale}
      role="menuitem"
      data-locale={locale}
      className={classnames(styles.language, {
        [styles.current]: isCurrent,
      })}
      onMouseDown={isCurrent ? null : handleChange}
    >
      {name}
    </ButtonDiv>
  );
};
Language.propTypes = {
  name: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool.isRequired,
};

const MobileLanguagePicker = ({ children }) => (
  <div className={styles.mobilePicker}>
    <div>
      <Icon icon="globe" size="lg" inverse />
    </div>
    <div className={styles.languages}>{children}</div>
  </div>
);

const DesktopLanguagePicker = ({ children, currentLanguageName }) => {
  const MenuLabel = (
    <div className={styles.menuLabel}>
      <Icon icon="globe" size="lg" inverse />
      <span className={styles.currentLanguage}>{currentLanguageName}</span>
      <Icon icon="caret-down" size="sm" inverse />
    </div>
  );

  return (
    <MenuDiv className={styles.desktopPicker} label={MenuLabel}>
      <div className={styles.dropdown}>
        <div className={styles.arrow} />
        {children}
      </div>
    </MenuDiv>
  );
};
DesktopLanguagePicker.propTypes = {
  currentLanguageName: PropTypes.string.isRequired,
};

const LanguagePicker = () => {
  const tenant = useCurrentTenantInfo();
  const languages = getActiveLanguages(tenant);
  const { locale: currentLocale, name: currentName } = useSelector(selectLanguage);
  if (languages.length <= 1) return null;

  const languageButtons = languages.map(({ locale, name }) => {
    const isCurrent = locale === currentLocale;
    return <Language key={locale} name={name} locale={locale} isCurrent={isCurrent} />;
  });
  return (
    <div className={styles.container} data-language-picker>
      <DesktopLanguagePicker currentLanguageName={currentName}>
        {languageButtons}
      </DesktopLanguagePicker>
      <MobileLanguagePicker>{languageButtons}</MobileLanguagePicker>
    </div>
  );
};

export default LanguagePicker;
