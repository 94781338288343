import React, { useContext, useMemo, useReducer, useState } from "react";
import * as R from "ramda";

const ListConfigContext = React.createContext();

const initialState = {
  dateRange: {
    startDate: undefined,
    endDate: undefined,
  },
};

export const useListConfigContext = () => useContext(ListConfigContext);

const listConfigReducer = (state, { type, facet, value }) => {
  switch (type) {
    case "setFilter":
      return R.assoc(facet, value, state);
    default:
      throw new Error();
  }
};

export const ListConfigProvider = ({ initial = initialState, children }) => {
  const [config, dispatch] = useReducer(listConfigReducer, initial);
  const [sort, setSort] = useState([]);

  const providerValue = useMemo(
    () => ({ config, dispatch, sort, setSort }),
    [config, dispatch, sort, setSort],
  );

  return <ListConfigContext.Provider value={providerValue}>{children}</ListConfigContext.Provider>;
};

export const withFilteredList = (Component, initial = {}) => {
  const initialConfig = { ...initialState, ...initial };
  const FilteredList = (props) => (
    <ListConfigProvider initial={initialConfig}>
      <Component {...props} />
    </ListConfigProvider>
  );
  return FilteredList;
};

const sortDirection = (sort) => {
  if (!sort) return "DESC";
  return sort.desc ? "DESC" : "ASC";
};

export const useFilteredList = (
  queryHook,
  { select = R.identity, multiSort = false, defaultSortColumn = "created_at", ...opts } = {},
) => {
  const { config, dispatch, sort, setSort } = useListConfigContext({
    multiSort,
  });

  const queryParams = useMemo(() => {
    const sortParam = {
      sortBy: sort[0]?.id || defaultSortColumn,
      sortDirection: sortDirection(sort[0]),
    };
    return { sort: sortParam, ...config };
  }, [sort, config, defaultSortColumn]);

  const queryOptions = useMemo(() => ({ placeholderData: [], select, ...opts }), [select, opts]);
  const query = queryHook(queryParams, queryOptions);

  return {
    ...query,
    queryParams,
    dispatch,
    sort,
    setSort,
    config,
    isLoading: query.isLoading || query.isPlaceholderData,
  };
};

export const useListFilterFacet = (facet) => {
  const { config } = useListConfigContext();
  return R.prop(facet, config);
};

export const useDateRangeFilter = () => R.defaultTo({}, useListFilterFacet("dateRange"));
