// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-project_form-EventLocationsFieldLegacy__container--IynoB {\n  display: flex;\n  flex-direction: column;\n  font-weight: normal;\n}\n\n.ui-components-project_form-EventLocationsFieldLegacy__location-container--Rkhxb {\n  display: flex;\n  align-items: center;\n  margin-top: 0.9em;\n  gap: 0.7em;\n}\n\n.ui-components-project_form-EventLocationsFieldLegacy__issued--lbsAE {\n  display: flex;\n  flex-direction: column;\n}\n.ui-components-project_form-EventLocationsFieldLegacy__issued--lbsAE .ui-components-project_form-EventLocationsFieldLegacy__icon--_iIB0 {\n  align-self: center;\n}\n.ui-components-project_form-EventLocationsFieldLegacy__issued--lbsAE .ui-components-project_form-EventLocationsFieldLegacy__location-container--Rkhxb:first-child {\n  margin-top: 0;\n}", "",{"version":3,"sources":["webpack://./ui/components/project_form/EventLocationsFieldLegacy.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,UAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAGI;EACE,aAAA;AADN","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  font-weight: normal;\n}\n\n.location-container {\n  display: flex;\n  align-items: center;\n  margin-top: 0.9em;\n  gap: 0.7em;\n}\n\n.issued {\n  display: flex;\n  flex-direction: column;\n\n  .icon {\n    align-self: center;\n  }\n\n  .location-container {\n    &:first-child {\n      margin-top: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ui-components-project_form-EventLocationsFieldLegacy__container--IynoB",
	"location-container": "ui-components-project_form-EventLocationsFieldLegacy__location-container--Rkhxb",
	"locationContainer": "ui-components-project_form-EventLocationsFieldLegacy__location-container--Rkhxb",
	"issued": "ui-components-project_form-EventLocationsFieldLegacy__issued--lbsAE",
	"icon": "ui-components-project_form-EventLocationsFieldLegacy__icon--_iIB0"
};
export default ___CSS_LOADER_EXPORT___;
