import PropTypes from "prop-types";

import dayjs from "utils/dayjs";

export const tenantPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
});

export const childrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const valuePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const IDPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const flatOptionsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: valuePropType.isRequired,
  }),
);

export const groupedOptionsPropType = PropTypes.arrayOf(PropTypes.objectOf(flatOptionsPropType));
export const optionsPropType = PropTypes.oneOfType([flatOptionsPropType, groupedOptionsPropType]);

export const filterOptionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    display: PropTypes.string.isRequired,
  }),
);

export const fieldPropType = PropTypes.shape({
  ui: PropTypes.shape({
    form_input: PropTypes.shape({
      type: PropTypes.string,
      includeBlank: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  key: PropTypes.string,
  field_label: PropTypes.string,
});

export const zoningPropType = PropTypes.shape({
  parcels: PropTypes.array,
  zones: PropTypes.array,
});

export const addressPropType = PropTypes.shape({
  text: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
});

export const feePropType = PropTypes.shape({
  cost: PropTypes.number.isRequired,
  requirement_id: PropTypes.number.isRequired,
  interval: PropTypes.string.isRequired,
});

export const costPropType = PropTypes.shape({
  amount: PropTypes.number.isRequired,
  requirement_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  fee_id: PropTypes.number,
  transaction_id: PropTypes.number,
});

export const requirementPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  application_method: PropTypes.string.isRequired,
  sections: PropTypes.array.isRequired,
  pdf_url: PropTypes.string,
});

export const pagePropType = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  title: PropTypes.string,
  field_ids: PropTypes.arrayOf(PropTypes.number),
});

export const historyPropType = PropTypes.shape({
  push: PropTypes.func,
});

export const permissionPropType = PropTypes.shape({
  category: PropTypes.string.isRequired,
  display_name: PropTypes.string.isRequired,
  display_verb: PropTypes.string.isRequired,
  description: PropTypes.string,
});

export const selectOptionPropType = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, childrenPropType]).isRequired,
  code: PropTypes.any.isRequired,
  description: PropTypes.string,
});

export const guidePropType = PropTypes.shape({
  guide_type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  description: PropTypes.string,
});

export const errorPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
  PropTypes.shape({
    id: PropTypes.string,
  }),
]);

export const languagePropType = PropTypes.shape({
  locale: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const notePropType = PropTypes.shape({
  text: PropTypes.string.isRequired,
  by: PropTypes.string,
  created_at: PropTypes.string.isRequired,
});

export const stateHistoryPropType = PropTypes.shape({
  id: valuePropType.isRequired,
  to_state: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
});

export const requirementApplicationPropType = PropTypes.shape({
  requirement_id: PropTypes.number.isRequired,
  project_id: PropTypes.number.isRequired,
  project_name: PropTypes.string,
  requirement_name: PropTypes.string,
  applicant_name: PropTypes.string,
  state_history: PropTypes.shape({}),
  updated_at: PropTypes.string,
  submitted_at: PropTypes.string,
  renewal_open: PropTypes.string,
  renewal_due: PropTypes.string,
  expires: PropTypes.string,
});

export const projectPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  requirementApplications: PropTypes.arrayOf(requirementApplicationPropType),
  requirement_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  active_field_ids: PropTypes.arrayOf(PropTypes.number),
});

export const departmentPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

export const projectSummaryPropTypes = {
  id: PropTypes.number,
  guide: PropTypes.shape({
    guide_type: PropTypes.string,
  }),
  created_at: PropTypes.string,
  status: PropTypes.string,
  project_name: PropTypes.string,
  business_address: PropTypes.string,
  use_code_name: PropTypes.string,
  requirement_count: PropTypes.number,
  user_full_name: PropTypes.string,
};

export const userPropType = PropTypes.shape({
  email: PropTypes.string.isRequired,
});

export const projectSummaryPropType = PropTypes.shape(projectSummaryPropTypes);

export const inquirySummaryPropTypes = {
  id: PropTypes.number,
  project_id: PropTypes.number,
  status: PropTypes.string,
  created_at: PropTypes.string,
  project_name: PropTypes.string,
  email: PropTypes.string,
  message: PropTypes.string,
};

export const payableAccountPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  transactions_amount: PropTypes.number,
});

export const transactionPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  project_id: PropTypes.number.isRequired,
  project_name: PropTypes.string,
  created_at: PropTypes.string,
  requirement_ids: PropTypes.arrayOf(PropTypes.number),
});

export const refundPropType = PropTypes.shape({
  amount: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  note: PropTypes.string,
  time: PropTypes.string.isRequired,
  user: userPropType.isRequired,
});

export const adminUserPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
});

export const disbursementPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  createdAt: PropTypes.string,
  account_name: PropTypes.string,
  account_number: PropTypes.string,
  amount: PropTypes.number,
});

export const refPropType = PropTypes.shape({ current: PropTypes.instanceOf(Element) });

// dayjs.js can parse a very wide range of input types; type checking is not useful here
export const momentablePropType = PropTypes.any;

export const eventTimesPropType = PropTypes.shape({
  start_time: momentablePropType,
  end_time: momentablePropType,
  event_time_type: PropTypes.string.isRequired,
});

export const eventRoutePropType = PropTypes.shape({
  distance: PropTypes.number.isRequired,
  overview_polyline: PropTypes.string.isRequired,
  start_address: addressPropType.isRequired,
  end_address: addressPropType.isRequired,
});

export const eventLocationPropType = PropTypes.shape({
  address: addressPropType.isRequired,
  place_type: PropTypes.string,
});

export const enumPropType = PropTypes.shape({
  code: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
});

export const eventRoutesType = PropTypes.shape({});

export const eventLocationType = PropTypes.shape({});

export const requirementReviewPropType = PropTypes.shape({
  id: IDPropType.isRequired,
  name: PropTypes.string.isRequired,
});

export const versionPropType = PropTypes.shape({
  number: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  committedAt: PropTypes.string,
  user: PropTypes.object,
  previous_version: PropTypes.number,
  next_version: PropTypes.number,
});

export const classNamePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])), // technically, this should be recursively arrayOf(classNamePropType), but that's not trivial to implement
]);

export const logEntryPropType = PropTypes.shape({
  context_id: PropTypes.number.isRequired,
  timestamp: PropTypes.instanceOf(dayjs).isRequired,
});
