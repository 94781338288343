import { reset, setSubmitSucceeded, startSubmit, stopSubmit } from "redux-form";
import { call, put } from "redux-saga/effects";

import { reportError } from "services/errorReporter";
import NetworkError from "utils/NetworkError";

export default function* formSaga(formId, apiSaga, apiSagaArgs, meta = {}) {
  const { reject, skipReset, resolve } = meta;

  yield put(startSubmit(formId));

  try {
    yield call(apiSaga, apiSagaArgs);

    if (resolve) resolve();
    if (!skipReset) {
      yield put(reset(formId));
    }
    yield put(stopSubmit(formId));
    yield put(setSubmitSucceeded(formId));
  } catch (error) {
    if (error instanceof NetworkError) return error;

    console.error("formSaga caught:", error);
    if (reject) {
      reject(error);
      reportError(error, { errors: error.errors, context: { component: "formSaga" } });
    } else {
      yield put(stopSubmit(formId, error.errors));
    }
  }

  return undefined;
}
