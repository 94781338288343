// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-projects-ReadOnlyBadge__read-only--B5S__ {\n  border-radius: 5px;\n  font-weight: bold;\n  font-size: 1.25em;\n  background: rgba(255, 255, 155, 0.15);\n  padding: 0.5em;\n  display: flex;\n  align-items: baseline;\n  gap: 0.25em;\n}", "",{"version":3,"sources":["webpack://./ui/components/projects/ReadOnlyBadge.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,qCAAA;EACA,cAAA;EACA,aAAA;EACA,qBAAA;EACA,WAAA;AACF","sourcesContent":[".read-only {\n  border-radius: 5px;\n  font-weight: bold;\n  font-size: 1.25em;\n  background: rgba(255, 255, 155, 0.15);\n  padding: 0.5em;\n  display: flex;\n  align-items: baseline;\n  gap: 0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"read-only": "ui-components-projects-ReadOnlyBadge__read-only--B5S__",
	"readOnly": "ui-components-projects-ReadOnlyBadge__read-only--B5S__"
};
export default ___CSS_LOADER_EXPORT___;
