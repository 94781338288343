import React from "react";
import * as R from "ramda";
import { Link } from "react-router-dom";

import Text from "containers/Text";
import { EXPIRED } from "reducers/renewals";
import { getApplicationAdminURL, getApplicationURL } from "selectors/routes";

const ICONS = {
  renewal_due: "hourglass-end",
  renewal_open: "arrows-rotate",
  expired: "times-hexagon",
};

const ApplicationRenewalStatusChanged = ({
  Layout,
  event,
  timestamp,
  applicantContext,
  projectView,
}) => {
  const {
    project_id,
    requirement_application_id,
    requirement_id,
    requirement_name,
    renewal_requirement_application_renewal_state: renewalState,
  } = R.path(["payload", "data"], event);

  const url = applicantContext
    ? getApplicationURL({ project_id, requirement_id })
    : getApplicationAdminURL({
        id: requirement_application_id,
        project_id,
        requirement_id,
      });

  return (
    <Layout icon={ICONS[renewalState]}>
      {projectView && renewalState === EXPIRED && <Link to={url}>{requirement_name}</Link>}
      <Text t={`requirement_application.${renewalState}`} />
      {timestamp}
    </Layout>
  );
};
export default ApplicationRenewalStatusChanged;
