import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./Section.scss";

export const SectionHeader = ({ heading, withPadding = true }) => (
  <h2 className={classnames(styles.sectionHeader, { [styles.padding]: withPadding })}>{heading}</h2>
);

SectionHeader.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  withPadding: PropTypes.bool,
};

export const Section = ({ heading, children }) => (
  <div className={styles.container}>
    {heading && <SectionHeader withPadding={false} heading={heading} />}
    <div className={styles.children}>{children}</div>
  </div>
);
