import React from "react";

import Icon from "components/Icon";

import styles from "./Attachment.scss";

const AttachmentIcon = ({ type }) => {
  let icon = "paperclip";
  if (type.includes("image")) {
    icon = "file-image";
  } else if (type.includes("pdf")) {
    icon = "file-pdf";
  } else if (type.includes("word")) {
    icon = "file-word";
  } else if (type.includes("excel")) {
    icon = "file-excel";
  }
  return <Icon icon={icon} />;
};

const Attachment = ({ attachment }) => (
  <div className={styles.attachment} data-attachment>
    <a href={attachment.url} target="_blank" rel="noreferrer">
      <AttachmentIcon type={attachment.content_type} />
      <span>{attachment.filename}</span>
    </a>
    <span className={styles.attachmentSize}>{attachment.size}</span>
  </div>
);

export default Attachment;
