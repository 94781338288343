import React from "react";
import { useSelector } from "react-redux";

import { FlexColumn } from "components/FlexColumn";
import Header from "components/Header";
import { FullScreenLoader } from "components/Loader";
import styles from "components/projects/requirement/Payment.scss";
import PaymentFooter from "components/projects/requirement/PaymentFooter";
import Receipt from "components/projects/requirement/Receipt";
import Step from "components/projects/requirement/Step";
import HelpBar from "containers/guides/HelpBar";
import SubHeader from "containers/projects/requirement/SubHeader";
import { useParams } from "hooks/useParam";
import { useProjectInvoice } from "queries/invoices";
import { isInPersonPayable, selectRequirementBySlug } from "reducers/requirements";
import { selectPaid } from "selectors/invoices";

import PaymentTypeSelector from "./PaymentTypeSelector";

const PaymentPageLayout = ({
  onNext,
  isLoading,
  disableNext,
  hideSubheader,
  paymentType,
  setPaymentType,
  children,
}) => {
  const { requirementSlug, projectID, invoiceID } = useParams();
  const requirement = useSelector((state) => selectRequirementBySlug(state, requirementSlug));
  const { data: invoice, isLoading: isInvoiceLoading } = useProjectInvoice(projectID, invoiceID);
  const allowInPersonPayments = isInPersonPayable(requirement);
  const alreadyPaid = !invoice || selectPaid(invoice);

  if (isInvoiceLoading) return <FullScreenLoader label="PaymentLayout/useProjectInvoice" />;

  return (
    <FlexColumn fullHeight>
      <Header hideTitle />
      {!hideSubheader && <SubHeader requirement={requirement} />}
      <FlexColumn exactHeightContent className={styles.container} key={window.location.pathname}>
        <div className={styles.gutters}>
          <main role="main">
            <div className={styles.contentContainer}>
              <Step
                headerKey="projects.requirement.payment.subheader"
                instructionsKey="projects.requirement.payment.instructions"
                step={2}
                steps={3}
              />

              <div className={styles.requirementContainer}>
                {invoice && <Receipt lineItems={invoice.line_items} requirement={requirement} />}
              </div>
            </div>

            {allowInPersonPayments && !alreadyPaid && (
              <PaymentTypeSelector
                paymentType={paymentType}
                setPaymentType={setPaymentType}
                invoiceID={invoiceID}
                projectID={projectID}
              />
            )}

            {!alreadyPaid && children}

            <HelpBar />
          </main>
        </div>
      </FlexColumn>
      <PaymentFooter
        requirementID={requirement.id}
        isLoading={isLoading}
        disableNext={disableNext || alreadyPaid}
        onNext={onNext}
      />
    </FlexColumn>
  );
};

export default PaymentPageLayout;
