import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { downloadPDF } from "actions";
import { TextButton } from "components/Button";
import Icon from "components/Icon";
import Text from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import { selectDownloadInProgress } from "reducers/ui";

import styles from "./Download.scss";

const Download = () => {
  const answerContext = useAnswerContext();
  const isDownloadInProgress = useSelector(selectDownloadInProgress);
  const dispatch = useDispatch();
  const onDownloadPDF = () => dispatch(downloadPDF(answerContext));

  return (
    <TextButton
      data-download-pdf-button
      isLoading={isDownloadInProgress}
      onClick={onDownloadPDF}
      className={styles.button}
      label={
        <>
          <Icon icon="download" size="lg" />
          <span>
            <Text t="projects.handoff.download_pdf" />
          </span>
        </>
      }
    />
  );
};

export default Download;
