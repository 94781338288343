import React from "react";
import PropTypes from "prop-types";

import { Section } from "components/Section";
import Fields from "components/guides/Fields";
import Text from "containers/Text";
import { fieldPropType } from "utils/sharedPropTypes";

import styles from "./EventDetails.scss";

const EventDetails = ({ answeredPositionFields, timesField }) => (
  <div className={styles.details}>
    <Section heading={<Text t="guides.summary.location" />}>
      <Fields hideLabel fields={answeredPositionFields} behaviorType="disabled" />
    </Section>

    <Section heading={<Text t="guides.summary.dates_and_times" />}>
      <Fields hideLabel fields={[timesField]} behaviorType="disabled" />
    </Section>
  </div>
);

EventDetails.propTypes = {
  answeredPositionFields: PropTypes.arrayOf(fieldPropType),
  timesField: fieldPropType.isRequired,
};

export default EventDetails;
