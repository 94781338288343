import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import { SmallButtonBordered } from "components/Button";
import { FlexColumn } from "components/FlexColumn";
import Header from "components/Header";
import Icon from "components/Icon";
import Markdown from "components/Markdown";
import { InPersonPaymentInstructions } from "components/payments/InPersonPayment";
import Fees from "components/projects/requirement/Fees";
import Footer from "components/projects/requirement/SummaryFooter";
import SkeuomorphicPaper from "components/utilities/SkeuomorphicPaper";
import { useActiveToggle } from "components/utilities/withActiveToggle";
import Field from "containers/Field";
import { IssuedRequirementApplicationComponent } from "containers/IssuedRequirementApplication";
import Text from "containers/Text";
import HelpBar from "containers/projects/requirement/HelpBar";
import SubHeader from "containers/projects/requirement/SubHeader";
import { getNextStepsMarkdown, isInPersonPayable } from "reducers/requirements";
import { formatDateStringAsDayFullMonth, now } from "utils/time";

import Step from "./Step";
import styles from "./Summary.scss";

const Section = ({ section, renewalDiff }) => (
  <div className={styles.section}>
    <div className={styles.sectionHeader}>
      <div className={styles.sectionInfo}>
        <div className={styles.sectionTitle}>{section.name}</div>
      </div>
    </div>
    <div className={styles.sectionContent}>
      {R.map(
        (field) => (
          <div className={styles.fieldContainer} key={field.id}>
            <div className={styles.field}>
              <Field
                field={field}
                key={field.id}
                changeType={renewalDiff[field.id]}
                behaviorType="disabled"
                hideRequired
                hideHelp
              />
            </div>
          </div>
        ),
        section.fields,
      )}
    </div>
  </div>
);
Section.propTypes = {
  section: PropTypes.object.isRequired,
};

const SubmittedRequirementApplication = ({
  children,
  requirementApplicationID,
  requirement,
  tenantFullName,
  sections,
  isMobile,
  pageFoldBgColor,
  submittedAt,
  renewalDiff = {},
}) => {
  const { active, toggleActive } = useActiveToggle();
  const disclosureSection = R.last(sections);
  sections = R.dropLast(1, sections);
  const sectionsByColumn = R.splitAt(Math.round(sections.length / 2), sections);

  return (
    <div>
      <SkeuomorphicPaper pageFoldBgColor={pageFoldBgColor}>
        <div className={styles.requirement}>
          <h3 className={styles.requirementHeader}>
            <div>{tenantFullName}</div>
            <Text
              t={`projects.requirement.summary.requirement_name${
                requirement.type === "Upload" ? "_upload" : ""
              }`}
              name={requirement.name}
            />
            <div className={styles.requirementId}>#{requirementApplicationID}</div>
          </h3>
          <div
            className={classnames(styles.requirementContent, {
              [styles.requirementContentDisabled]: isMobile && active,
            })}
          >
            {disclosureSection && (
              <div>
                <div className={styles.twoColumns}>
                  <div className={styles.sectionColumn}>
                    {R.map(
                      (section) => (
                        <Section
                          section={section}
                          key={section.id || section.key}
                          renewalDiff={renewalDiff}
                        />
                      ),
                      R.nth(0, sectionsByColumn),
                    )}
                  </div>
                  <div className={styles.sectionColumn}>
                    {R.map(
                      (section) => (
                        <Section
                          section={section}
                          key={section.id || section.key}
                          renewalDiff={renewalDiff}
                        />
                      ),
                      R.nth(1, sectionsByColumn),
                    )}
                  </div>
                </div>
                <div className={styles.disclosureSection}>
                  <Section
                    section={disclosureSection}
                    key={disclosureSection.id}
                    renewalDiff={renewalDiff}
                  />
                </div>
              </div>
            )}
            {children}
          </div>
          {isMobile && (
            <SmallButtonBordered onClick={toggleActive} className={styles.toggleButton}>
              <Icon icon={active ? "plus-square" : "minus-square"} />
              <span>
                <Text t={`projects.requirement.summary.${active ? "show" : "hide"}_content`} />
              </span>
            </SmallButtonBordered>
          )}
          <div className={styles.footerContainer}>
            {submittedAt && <SubmittedOn timestamp={submittedAt} />}
          </div>
        </div>
      </SkeuomorphicPaper>
    </div>
  );
};
SubmittedRequirementApplication.propTypes = {
  requirement: PropTypes.object.isRequired,
  requirementApplicationID: PropTypes.number,
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  tenantFullName: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  submittedAt: PropTypes.string,
};

const SubmittedOn = ({ timestamp }) => (
  <div className={styles.submittedOn}>
    <div className={styles.submittedOnLabel}>Submitted on</div>
    <div className={styles.submittedOnValue}>{formatDateStringAsDayFullMonth(timestamp)}</div>
  </div>
);
SubmittedOn.propTypes = { timestamp: PropTypes.string };

export const SubmittedSummary = (props) => {
  const { requirement, submittedAt } = props;
  const inPersonPayment = isInPersonPayable(requirement);

  return (
    <div>
      <div className={styles.printHeader}>Generated {formatDateStringAsDayFullMonth(now())}</div>
      <SubmittedRequirementApplication {...props}>
        <div className={styles.footerContainer}>
          {submittedAt && <SubmittedOn timestamp={submittedAt} />}
        </div>
        {requirement.payable && (
          <div className={styles.paymentsContainer}>
            <div className={styles.paymentsHeader}>
              <Text t="projects.requirement.summary.payments" />
            </div>
            <Fees requirementID={requirement.id} />
            {inPersonPayment && <InPersonPaymentInstructions requirement={requirement} />}
          </div>
        )}
      </SubmittedRequirementApplication>
    </div>
  );
};
SubmittedSummary.propTypes = {
  requirement: PropTypes.object.isRequired,
  submittedAt: PropTypes.string,
};

const IssuedSummary = ({ projectID, requirement, requirementApplicationID }) => (
  <div>
    <div className={styles.printHeader}>Generated {formatDateStringAsDayFullMonth(now())}</div>
    <IssuedRequirementApplicationComponent
      projectID={projectID}
      requirementID={requirement.id}
      requirementApplicationID={requirementApplicationID}
    />
  </div>
);
IssuedSummary.propTypes = {
  requirement: PropTypes.object.isRequired,
  requirementApplicationID: PropTypes.number.isRequired,
  projectID: PropTypes.number.isRequired,
};

const RequirementSummary = (props) => {
  const { requirement, state } = props;
  if (state === "issued" && !requirement.disable_issuance) return <IssuedSummary {...props} />;

  return <SubmittedSummary {...props} />;
};
RequirementSummary.propTypes = {
  state: PropTypes.string,
};

export const RequirementSummaryPage = (props) => {
  const { requirement, state, requirementApplicationID, steps } = props;
  const isIssued = state === "issued";

  const reviewType = requirement.review_type.toLowerCase();
  const verb = isIssued ? reviewType : "submitted";
  const suffix = requirement.type === "Upload" ? "_upload" : "";

  return (
    <FlexColumn fullHeight>
      <div className={styles.header}>
        <Header hideTitle />
        <SubHeader requirement={requirement} />
      </div>
      <FlexColumn exactHeightContent className={styles.container}>
        <div className={styles.gutters}>
          <main role="main" className={styles.contentContainer}>
            <Step
              headerKey={`projects.requirement.summary.${verb}_title${suffix}`}
              instructions={isIssued ? "" : requirement.nextSteps}
              step={steps}
              steps={steps}
            />
            {!isIssued && <Markdown source={getNextStepsMarkdown(requirement)} />}
            <div className={styles.requirementContainer}>
              <RequirementSummary {...props} />
            </div>
            <HelpBar state={state} requirementApplicationID={requirementApplicationID} />
          </main>
        </div>
      </FlexColumn>
      <Footer requirementID={requirement.id} />
    </FlexColumn>
  );
};
RequirementSummaryPage.propTypes = {
  requirement: PropTypes.object.isRequired,
  requirementApplicationID: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  steps: PropTypes.number.isRequired,
  state: PropTypes.string,
};

export default RequirementSummary;
