/**
 * a hook to check if the device type is apple and touch-enabled
 *
 * @returns {boolean}
 */
const useIsAppleTouch = () => {
  const isApple = Boolean(navigator.userAgent?.match(/iPhone|iPad|iPod|Safari/i));
  const isTouch = Boolean(
    "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0,
  );

  return Boolean(isApple && isTouch);
};
export default useIsAppleTouch;
