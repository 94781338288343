import React, { useEffect } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import Forbidden from "components/Forbidden";
import { FullScreenLoader } from "components/Loader";
import { useAnswerContext } from "containers/withAnswerContext";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { useSession } from "contexts/session";
import { useParams } from "hooks/useParam";
import { selectIsRequestPendingFor } from "reducers/network";
import {
  getRequirementApplication,
  getRequirementApplicationByRequirementID,
  isInProgress as projectIsInProgress,
  selectProjectByID,
} from "reducers/projects";
import {
  getTransactionID,
  isInProgress,
  isIssued,
  isPayable,
} from "reducers/requirementApplications";
import { selectRequirementBySlug } from "reducers/requirements";
import appendToQueryString from "utils/appendToQueryString";
import { isPresent } from "utils/func";
import isHeadless from "utils/isHeadless";
import navigateHard from "utils/navigateHard";

const NavigateAway = ({ href, hard, replace }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (hard) {
      navigateHard(href, { replace });
      return;
    }
    navigate(href, { replace });
  });

  return <FullScreenLoader label="NavigateAway" />;
};

const RedirectIfConditionNotMet = ({
  children,
  condition,
  redirectPath,
  isLoading = false,
  redirectQueryParamName = null,
  replace = false,
  hard = false,
  failInsteadOfRedirectIfHeadless = false,
}) => {
  const { pathname } = useLocation();

  const uri = redirectQueryParamName
    ? appendToQueryString(redirectPath, redirectQueryParamName, pathname)
    : redirectPath;

  if (isLoading) return <FullScreenLoader label="RedirectIfConditionNotMet/isLoading" />;
  if (condition) return children;

  if (failInsteadOfRedirectIfHeadless && isHeadless()) {
    throw new Error("Redirect attempted in headless context");
  }

  return <NavigateAway href={uri} hard={hard} replace={replace} />;
};

export const RequireApplicantLoginFeature = ({ children }) => {
  const { disable_login } = useCurrentTenantInfo();
  if (disable_login) return <Forbidden />;
  return children;
};

// asserts that the session request has loaded and we have at least a guest user present
export const RequireUserSession = ({ children }) => {
  const { isLoading, user } = useSession();
  const haveUserId = isPresent(user?.id);
  const haveInviteToken = user?.invited;
  const { disable_login } = useCurrentTenantInfo();

  if (disable_login && !isLoading && !haveUserId) {
    return <Forbidden />;
  }

  return (
    <RedirectIfConditionNotMet
      isLoading={isLoading}
      condition={haveUserId}
      redirectPath="/signin"
      redirectQueryParamName="redirect"
      replace
      failInsteadOfRedirectIfHeadless
    >
      <RedirectIfConditionNotMet
        condition={!haveInviteToken}
        redirectPath="/accept-invite"
        redirectQueryParamName="redirect"
        replace
      >
        {children}
      </RedirectIfConditionNotMet>
    </RedirectIfConditionNotMet>
  );
};

// asserts that the session request has loaded and we have a non-guest user present
export const RequireRealUser = ({ children }) => {
  const { isLoading, isLoggedIn } = useSession();
  const { disable_login } = useCurrentTenantInfo();
  if (disable_login) return <Forbidden />;
  return (
    <RedirectIfConditionNotMet
      isLoading={isLoading}
      condition={isLoggedIn}
      redirectPath="/signin"
      redirectQueryParamName="redirect"
      replace
      failInsteadOfRedirectIfHeadless
    >
      {children}
    </RedirectIfConditionNotMet>
  );
};

// asserts that the session request has loaded and the authenticated user has admin access (and config access if requireConfig)
export const RequirePrivilegedUser = ({ children, requireConfig = false }) => {
  const { isLoading, isLoggedIn, canAdmin, canConfig } = useSession();
  const cond = canAdmin && (canConfig || !requireConfig);
  return (
    <RedirectIfConditionNotMet
      isLoading={isLoading}
      condition={isLoggedIn}
      redirectQueryParamName="return_to"
      redirectPath="/oauth/authorize"
      hard
      failInsteadOfRedirectIfHeadless
    >
      {cond ? children : <Forbidden />}
    </RedirectIfConditionNotMet>
  );
};

export const ProjectIsScopeable = ({ children }) => {
  const { projectID } = useParams();
  const project = useSelector((state) => selectProjectByID(state, projectID));

  const cond = isHeadless() || R.isEmpty(project) || projectIsInProgress(project);

  return (
    <RedirectIfConditionNotMet
      condition={cond}
      replace
      redirectPath={`/projects/${projectID}/apply`}
    >
      {children}
    </RedirectIfConditionNotMet>
  );
};

export const RequirementIsIssued = ({ children }) => {
  const { projectID, requirementApplicationID } = useParams();
  const project = useSelector((state) => selectProjectByID(state, projectID));
  const requirementApplication = getRequirementApplication(project, requirementApplicationID);

  const cond = isHeadless() || isIssued(requirementApplication);

  return (
    <RedirectIfConditionNotMet
      condition={cond}
      replace
      redirectPath={`/projects/${projectID}/apply`}
    >
      {children}
    </RedirectIfConditionNotMet>
  );
};

const useProjectAndApplication = () => {
  const { record: project } = useAnswerContext();
  const { requirementSlug } = useParams();
  const requirement = useSelector((state) => selectRequirementBySlug(state, requirementSlug));
  const requirementID = R.prop("id", requirement);
  const requirementApplication = getRequirementApplicationByRequirementID(project, requirementID);

  const isLoading = useSelector(
    (s) =>
      selectIsRequestPendingFor(s, { id: project.id, type: "Project" }) ||
      R.isEmpty(requirementApplication) ||
      selectIsRequestPendingFor(s, {
        id: requirementApplication?.id,
        type: "RequirementApplications",
      }),
  );

  const redirectURI = isPayable(requirementApplication)
    ? `/projects/${project.id}/requirements/${requirementSlug}/payment/${getTransactionID(
        requirementApplication,
      )}`
    : `/projects/${project.id}/apply`;

  return { isLoading, requirementApplication, redirectURI };
};

export const RequirementApplicationIsInProgress = ({ children }) => {
  const { isLoading, requirementApplication, redirectURI } = useProjectAndApplication();
  const location = useLocation();

  const cond =
    isLoading || isInProgress(requirementApplication) || location.pathname.match(/summary$/);

  return (
    <RedirectIfConditionNotMet
      condition={cond}
      redirectPath={redirectURI}
      replace
      failInsteadOfRedirectIfHeadless
    >
      {children}
    </RedirectIfConditionNotMet>
  );
};

export const RequirementApplicationIsPayable = ({ children }) => {
  const { isLoading, requirementApplication, redirectURI } = useProjectAndApplication();

  const cond = isLoading || isPayable(requirementApplication);
  return (
    <RedirectIfConditionNotMet
      condition={cond}
      redirectPath={redirectURI}
      replace
      failInsteadOfRedirectIfHeadless
    >
      {children}
    </RedirectIfConditionNotMet>
  );
};
