import React, { useMemo } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import Icon, { IconStack } from "components/Icon";
import Label from "components/project_form/Label";
import { formatId } from "utils/format";
import { childrenPropType } from "utils/sharedPropTypes";

import styles from "./CheckboxField.scss";
import { checkedInput } from "./behaviors";

// eslint-disable-next-line no-alert
const confirmWithMessage = (message) => () => window.confirm(message);

export const CheckboxFieldComponent = ({
  children,
  confirmationMessage,
  descriptionId,
  disabled,
  error,
  field,
  labelId,
  labelProps,
  name,
  noMargin,
  onChange,
  partial,
  smaller,
  value,
}) => {
  const key = formatId(name || field.key);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useMemo(
    R.when(confirmationMessage ? confirmWithMessage(confirmationMessage) : R.T, onChange),
    [confirmationMessage, onChange],
  );

  let icon = "square";
  if (value) icon = "square-check";
  if (partial) icon = "square-minus";

  return (
    <div className={classnames({ [styles.container]: !noMargin })}>
      <div
        className={classnames(styles.inner, {
          [styles.horizontal]: labelProps && labelProps.horizontalLabel,
          [styles.disabled]: disabled,
        })}
      >
        <label data-terms-checkbox data-checkbox>
          <input
            className={styles.actualCheckbox}
            type="checkbox"
            id={key}
            name={key}
            onChange={handleChange}
            checked={!!value}
            aria-checked={!!value}
            aria-labelledby={labelId}
            aria-describedby={descriptionId}
            aria-invalid={!!error}
            value
            disabled={!!disabled}
          />
          <div className={styles.displayedCheckbox}>
            <IconStack size={smaller ? null : "xl"}>
              <Icon
                icon="square"
                faStyle="solid"
                className={classnames("fa-stack-1x", "fa-border", styles.iconBg)}
              />
              <Icon
                icon={icon}
                faStyle={value || partial ? "solid" : "thin"}
                className={classnames("fa-stack-1x", "fa-border", styles.icon, {
                  [styles.checked]: value || partial,
                })}
              />
            </IconStack>
          </div>
        </label>
        {children}
        {!children && R.propOr(false, "id", field) && (
          <div className={styles.label}>
            <Label disabled={disabled} {...labelProps} />
          </div>
        )}
      </div>
    </div>
  );
};
CheckboxFieldComponent.propTypes = {
  confirmationMessage: PropTypes.string,
  children: childrenPropType,
  descriptionId: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  field: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    key: PropTypes.string,
  }),
  labelId: PropTypes.string,
  labelProps: PropTypes.object,
  name: PropTypes.string,
  noMargin: PropTypes.bool,
  onChange: PropTypes.func,
  partial: PropTypes.bool,
  smaller: PropTypes.bool,
  value: PropTypes.bool,
};

export const DisabledCheckboxField = (props) => <CheckboxFieldComponent {...props} disabled />;
DisabledCheckboxField.propTypes = CheckboxFieldComponent.propTypes;
export const disabled = DisabledCheckboxField;

export default checkedInput(CheckboxFieldComponent);
