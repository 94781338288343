import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getRequirementApplication, getStatus, selectProjectByID } from "reducers/projects";
import { getApplicationAdminURL, getApplicationURL } from "selectors/routes";

const applicationIsLinkable = (application) => {
  const status = getStatus(application);
  return status !== "in_progress";
};

export const ApplicationLink = ({ application, requirementName, applicantContext }) => {
  if (!application || !applicationIsLinkable(application)) return requirementName;
  const url = applicantContext
    ? getApplicationURL(application)
    : getApplicationAdminURL(application);

  return <Link to={url}>{requirementName}</Link>;
};

const MaybeApplicationLinkFromEvent = ({ event, applicantContext = false }) => {
  const applicationID = R.prop("requirement_application_id", event);
  const projectID = R.prop("project_id", event);
  const requirementName = R.path(["payload", "data", "requirement_name"], event);

  const project = useSelector((state) => selectProjectByID(state, projectID));
  const application = getRequirementApplication(project, applicationID);

  return (
    <ApplicationLink
      application={application}
      requirementName={requirementName}
      applicantContext={applicantContext}
    />
  );
};

export default MaybeApplicationLinkFromEvent;
