import * as R from "ramda";
import { branch, renderComponent, renderNothing } from "recompose";

import Loader from "components/Loader";
import { isPresent } from "utils/func";

const requireProps = (requiredProps, fallback, comparitor = isPresent) =>
  branch(
    (props) => !R.all(comparitor, R.props(requiredProps, props)),
    fallback ? renderComponent(fallback) : renderNothing,
  );

export const loaderWithoutProps = R.curry(requireProps)(R.__, Loader);
export const requirePropsTrue = R.curry(requireProps)(R.__, R.__, Boolean);

export default requireProps;
