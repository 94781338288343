import * as R from "ramda";

export const getMutationIdentifiersFor = (mutations, type) =>
  R.map(R.prop("identifier"), R.filter(R.propEq("type", type), mutations));

const pathOrZero = R.pathOr(0);

export const getNewValue = pathOrZero(["change", "new_value"]);

export const getAmountChanged = (mutation) =>
  pathOrZero(["change", "new_value"], mutation) -
  pathOrZero(["change", "previous_value"], mutation);

export const getTotalAmountChanged = R.pipe(R.map(getAmountChanged), R.sum);
export const getTotalAmount = R.pipe(R.map(getNewValue), R.sum);
