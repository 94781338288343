import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import SignatureCanvas from "react-signature-canvas";

import { TextButton } from "components/Button";
import Text, { useText } from "containers/Text";

import styles from "./SignatureField.scss";

class SignatureField extends React.Component {
  componentDidMount() {
    window.addEventListener("resize", this.setCanvas);
    this.setCanvas();
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.setCanvas();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setCanvas);
  }

  onEnd = () => {
    const { onChange, onSave } = this.props;
    onChange(this.ref.getCanvas().toDataURL());
    onSave();
  };

  onClear = () => {
    this.ref.clear();
    const { onChange, onSave } = this.props;
    onChange(null);
    onSave();
  };

  setCanvas = () => {
    if (!this.ref) return;
    const { value } = this.props;
    if (!value) return;
    this.ref.fromDataURL(value);
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.signatureContainer}>
          <SignatureCanvas
            ref={(ref) => {
              this.ref = ref;
            }}
            canvasProps={{ className: styles.signaturePad }}
            onEnd={this.onEnd}
          />
          <div className={styles.signatureLine}>x</div>
        </div>
        <div className={styles.clear}>
          <TextButton onClick={this.onClear} label={<Text t="forms.clear" />} />
        </div>
      </div>
    );
  }
}

SignatureField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const DisabledSignatureField = ({ value }) => {
  const altText = useText("forms.applicant_signature");
  return (
    <div className={styles.signatureContainer}>
      {value ? (
        <img
          className={classnames(styles.signaturePad, { [styles.disabled]: disabled })}
          src={value}
          alt={altText}
        />
      ) : (
        <div className={styles.signaturePad} />
      )}
      <div className={classnames(styles.signatureLine, { [styles.disabledLine]: disabled })}>x</div>
    </div>
  );
};
DisabledSignatureField.propTypes = {
  value: PropTypes.string,
};
export const disabled = DisabledSignatureField;

export default SignatureField;
