import React from "react";
import PropTypes from "prop-types";
import Truncate from "react-truncate";

import { ButtonDiv } from "../accessibility/Div";
import styles from "./ReadMore.scss";
import { useActiveToggle } from "./withActiveToggle";

const stopBubbleAndCall = (func) => (e) => {
  e.stopPropagation();
  e.preventDefault();
  func(e);
};

const ReadMore = ({ children, readMoreText, readLessText, lines }) => {
  const { active, activate, deactivate } = useActiveToggle();
  return (
    <div>
      <Truncate
        lines={active ? 0 : lines}
        ellipsis={
          <span>
            ...
            <ButtonDiv
              className={styles.link}
              onClick={stopBubbleAndCall(activate)}
              aria-expanded="false"
            >
              {readMoreText}
            </ButtonDiv>
          </span>
        }
      >
        {children}
      </Truncate>
      {active && readLessText && (
        <span>
          <ButtonDiv
            className={styles.link}
            onClick={stopBubbleAndCall(deactivate)}
            aria-expanded="true"
          >
            {readLessText}
          </ButtonDiv>
        </span>
      )}
    </div>
  );
};

ReadMore.defaultProps = {
  lines: 1,
  readMoreText: "Read more",
  readLessText: "Read less",
};

ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
  readMoreText: PropTypes.node,
  readLessText: PropTypes.node,
  lines: PropTypes.number,
};

export default ReadMore;
