import React from "react";
import * as R from "ramda";

import MaybeApplicationLink from "./MaybeApplicationLink";
import EventLogPropTypes from "./propTypes";
import sharedStyles from "./sharedStyles.scss";

/**
 * ApplicationApproved log entry component
 *
 * @component
 * @param {Object} params
 * @returns <ApplicationApproved />
 */
const ApplicationApproved = ({ Layout, event, timestamp, applicantContext, applicationView }) => {
  const by = R.path(["payload", "data", "by"], event);
  const reviewName = R.path(["payload", "data", "review_name"], event);
  const additionalContent = applicationView ? null : (
    <div className={sharedStyles.noteContent}>{reviewName}</div>
  );

  return (
    <Layout icon="check-circle" additionalContent={additionalContent}>
      {applicationView ? (
        <>
          {reviewName} approved by {applicantContext ? "staff" : by}
        </>
      ) : (
        <>
          <MaybeApplicationLink event={event} /> application approved
          {applicantContext ? " by staff" : by && ` by ${by}`}
        </>
      )}
      {timestamp}
    </Layout>
  );
};
ApplicationApproved.propTypes = EventLogPropTypes;

export default ApplicationApproved;
