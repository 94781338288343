import { EventTypes, createTracker } from "redux-segment";

import { ADD_PROJECT, SET_ANSWER, SET_CURRENT_USER } from "actions";
import { LOCATION_CHANGE } from "components/Router";
import { selectIsLoggedIn } from "selectors/users";
import analytics from "services/analytics";
import { reportError } from "services/errorReporter";

const SCOPING_STARTED = "Scoping Started";
const ANSWER_GIVEN = "Answer Given";

// tracked in sagas
export const ADDRESS_CHOSEN = "Address Chosen";
export const ADDRESS_TYPEAHEAD = "Address Typeahead Selected";
export const ACCOUNT_CREATED = "Account Created";

// tracked in UseCodeField container
export const USE_CODE_SELECTED = "Use Code Selected";
export const USE_CODE_SEARCHED = "Use Code Searched";

// tracked in BusinessTypeBrowserPage component
export const USE_CODE_BROWSED = "Use Code Browsed";

// tracked in NotFound component
export const PAGE_NOT_FOUND = "Page Not Found";

// tracked in PermissionBubble component
export const ZONING_LOOKUP_CALCULATED = "Zoning Lookup Calculated";

// tracked in Summary component
export const PDF_DOWNLOADED = "PDF Summary Downloaded";

// tracked in SummaryTable component
export const REQUIREMENT_INFO_EXPANDED = "Requirement Info Expanded";

// tracked in guides/Footer component
export const PREVENTED_FROM_PROCEEDING = "Prevented From Proceeding";

// tracked in projects/Summary component
export const SUMMARY_PAGE_REACHED = "Summary Page Reached";

const stripProjectID = (url) => String(url).replace(/^(\/projects\/)\d+(\/.*$)/, "$10$2");

const locationChangeEvent = (state, path) => {
  const pageName = stripProjectID(path);

  return {
    eventType: EventTypes.page,
    eventPayload: { name: pageName },
  };
};

const identifyEvent = () => ({
  eventType: EventTypes.identify,
});

// TODO: undo this change, avoid the scoping started event for renewals
const scopingStartedEvent = (_, { payload: { guide } }) => {
  if (!guide) return undefined;

  return {
    eventType: EventTypes.track,
    eventPayload: {
      event: SCOPING_STARTED,
      properties: { portal: guide.guide_type },
    },
  };
};

const answerGivenEvent = (properties) => ({
  eventType: EventTypes.track,
  eventPayload: {
    event: ANSWER_GIVEN,
    properties,
  },
});

const customMapper = {
  [LOCATION_CHANGE]: (getState, action) => {
    try {
      const state = getState();
      const path = action.payload.pathname;

      return selectIsLoggedIn(state)
        ? [locationChangeEvent(state, path), identifyEvent()]
        : locationChangeEvent(state, path);
    } catch (e) {
      reportError(e);
    }
    return undefined;
  },

  [SET_CURRENT_USER]: identifyEvent,
  [ADD_PROJECT]: scopingStartedEvent,

  [SET_ANSWER]: (getState, action) => {
    try {
      const {
        payload: {
          field: { slug: fieldSlug, field_type: fieldType, required },
        },
      } = action;
      const fieldRequired = !!required;

      if (fieldType !== "use_code") {
        return answerGivenEvent({ fieldSlug, fieldType, fieldRequired });
      }
    } catch (e) {
      reportError(e);
    }
    return undefined;
  },
};

export default createTracker({ mapper: customMapper, client: analytics });
