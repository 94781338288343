import React, { useContext } from "react";
import { useSelector } from "react-redux";

import { selectTenant } from "reducers/tenant";

const CurrentTenantContext = React.createContext();

const CurrentTenantProvider = ({ children }) => {
  const tenant = useSelector(selectTenant);

  return <CurrentTenantContext.Provider value={tenant} children={children} />;
};

export const useCurrentTenantInfo = () => useContext(CurrentTenantContext);
export default CurrentTenantProvider;
