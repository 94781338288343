import * as R from "ramda";

import {
  ADD_REQUIREMENT_APPLICATION,
  ADD_STATE_TRANSITION,
  MERGE_REQUIREMENT_APPLICATION,
  UPDATE_REQUIREMENT_APPLICATION,
  UPDATE_REQUIREMENT_APPLICATIONS,
} from "actions";
import { arrayWrap, sortByDateAttr } from "utils/func";
import { parseTime } from "utils/time";

export default (state = {}, action) => {
  const pl = action.payload;
  switch (action.type) {
    case UPDATE_REQUIREMENT_APPLICATIONS:
      return pl;
    case UPDATE_REQUIREMENT_APPLICATION: {
      const { id } = pl;
      return R.assoc(id, { ...state[id], ...pl }, state);
    }
    case MERGE_REQUIREMENT_APPLICATION: {
      return R.assoc(pl.id, R.mergeDeepLeft(pl, state[pl.id]), state);
    }
    case ADD_REQUIREMENT_APPLICATION:
      return R.assoc(pl.id, pl, state);
    case ADD_STATE_TRANSITION: {
      const { applicationID, transition } = pl;
      const newState = {
        [applicationID]: { state_history: { [transition.id]: transition } },
      };
      return R.mergeDeepLeft(newState, state);
    }
    default:
      return state;
  }
};

export const selectRequirementApplications = (state) => R.values(state.requirementApplications);

export const selectRequirementApplication = (state, id) =>
  R.prop(id, state.requirementApplications);

export const selectRequirementApplicationForProjectID = (state, projectID) =>
  R.pipe(selectRequirementApplications, R.filter(R.propEq("project_id", projectID)))(state);

export const selectRequirementID = (state, id) =>
  R.path([id, "requirement_id"], state.requirementApplications);

export const getDescriptiveID = R.prop("descriptive_id");
export const getRequirementName = R.prop("requirement_name");
export const getStateHistory = R.pipe(R.propOr([], "state_history"), R.values);
export const selectStateHistory = R.pipe(selectRequirementApplication, getStateHistory);
export const getLastTransition = R.pipe(getStateHistory, R.last);
export const getCurrentState = R.pipe(getLastTransition, R.propOr("in_progress", "to_state"));
export const getHistoryState = R.prop("to_state");
export const getSubmittedAt = R.prop("submitted_at");
export const getRequirementID = R.prop("requirement_id");
export const getAssignedUserIDs = R.propOr([], "assigned_user_ids");
export const getProjectID = R.prop("project_id");

export const getCurrentHistoryState = R.pipe(getStateHistory, R.last, getHistoryState);

export const hasState = (to_state, app) =>
  R.any(R.propEq("to_state", to_state), getStateHistory(app));
export const hasAnyState = (to_states, app) =>
  R.any(R.compose(R.contains(R.__, to_states), R.prop("to_state")), getStateHistory(app));

export const getState = (to_state, app) =>
  R.find(R.propEq("to_state", to_state), getStateHistory(app));

const STATES = [
  "in_progress",
  "processed",
  "submitted",
  "rejected",
  "issued",
  "approved",
  "payable",
  "revoked",
  "no_longer_required",
  "submitted_external",
  "under_review",
];
export const [
  IN_PROGRESS,
  PROCESSED,
  SUBMITTED,
  REJECTED,
  ISSUED,
  APPROVED,
  PAYABLE,
  REVOKED,
  NO_LONGER_REQUIRED,
  SUBMITTED_EXTERNAL,
  UNDER_REVIEW,
] = STATES;

export const REJECTED_STATES = [REJECTED, REVOKED];
export const APPROVED_STATES = [ISSUED, PROCESSED];
export const SUBMITTED_STATES = [SUBMITTED, SUBMITTED_EXTERNAL];

export const getIssuedAt = R.pipe(
  getStateHistory,
  R.find(R.propEq("to_state", ISSUED)),
  R.prop("created_at"),
  parseTime,
);

export const isInProgress = R.pipe(getCurrentState, R.equals(IN_PROGRESS));
export const isSubmitted = R.pipe(getCurrentState, R.contains(R.__, SUBMITTED_STATES));
export const isUnsubmitted = R.complement(isSubmitted);
export const isRejected = R.pipe(getCurrentState, R.equals(REJECTED));
export const isIssued = R.pipe(getCurrentState, R.equals(ISSUED));
export const isApproved = R.pipe(getCurrentState, R.equals(APPROVED));
export const isPayable = R.pipe(getCurrentState, R.equals(PAYABLE));
export const isRevoked = R.pipe(getCurrentState, R.equals(REVOKED));
export const isNoLongerRequired = R.pipe(getCurrentState, R.equals(NO_LONGER_REQUIRED));

export const wasSubmitted = R.curry(hasAnyState)(SUBMITTED_STATES);
export const wasIssued = R.curry(hasState)(ISSUED);
export const wasProcessed = R.curry(hasState)(PROCESSED);

export const requiresPayment = R.prop("requires_payment");
export const getStateMetadata = R.curry((attrs, state) =>
  R.path(R.prepend("metadata", arrayWrap(attrs)), state),
);

export const getTransactionID = R.pipe(getLastTransition, getStateMetadata("transaction_id"));
export const getInvoiceID = R.pipe(R.propOr([], "unpaid_invoice_ids"), R.head);

export const getStatus = R.prop("status");
export const getDisplayID = (ra) => R.prop("display_id", ra) || ra.id;
export const getDerivedState = R.prop("derived_state");

export const getReviewResults = (requirementApplication, reviews) => {
  const applicationReviews = sortByDateAttr(
    R.propOr([], "application_reviews", requirementApplication),
    "created_at",
  );

  return R.map((review) => {
    const applicationReview = R.find(
      R.propEq("requirement_review_id", review.id),
      applicationReviews,
    );
    const result = R.merge(review, {
      created_at: R.prop("created_at", applicationReview),
      reviewedBy: R.prop("reviewer_full_name", applicationReview),
      result: R.propOr("pending", "result", applicationReview),
    });
    return result;
  }, reviews);
};

const COMPLETED_REVIEW_STATES = ["approve", "reject"];
export const isCompletedReview = (review) => R.includes(review.result, COMPLETED_REVIEW_STATES);
export const getPendingReviews = R.pipe(getReviewResults, R.reject(isCompletedReview));

export const allowUndo = R.pipe(getCurrentState, R.contains(R.__, [UNDER_REVIEW, REJECTED]));
