import React from "react";
import * as R from "ramda";
import CountUp from "react-countup";
import { useSelector } from "react-redux";

import Tooltip from "components/Tooltip";
import Text, { useGetText } from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import { selectFeesByID } from "reducers/fees";
import { selectRequirementsByID } from "reducers/requirements";
import { maybeMoney } from "utils/format";

import styles from "./Fee.scss";

const defaultCountUpProps = {
  duration: 1,
  delay: 0,
  decimals: 2,
  preserveValue: true,
  prefix: "$",
  separator: ",",
};

const MaybeCountUp = ({ value, animate }) => {
  if (!value || !animate) return maybeMoney(value);
  return <CountUp end={value} {...defaultCountUpProps} />;
};

const BaseFeesTotal = ({
  feeIDs,
  requirements,
  calloutPartial = true,
  hideZero = false,
  animate = false,
}) => {
  const { record } = useAnswerContext();
  const getText = useGetText();

  const fees = R.pick(feeIDs, record.calculated_fee_totals);
  const total = R.sum(R.values(fees));
  const isPartial = feeIDs.some((id) => (record.unsatisfied_fee_ids || []).includes(id));
  const hasExternalFees = R.any(R.propEq(true, "fees_calculated_externally"), requirements);
  const hasNoFees = R.values(fees).length === 0;

  if (hideZero && total === 0 && isPartial) return "...";

  const showTotal = calloutPartial && isPartial;

  if (hasExternalFees) return <Text t="guides.summary_table.cost_varies" />;
  if (hasNoFees) return <Text t="guides.summary_table.no_fees" />;

  return (
    <div className={styles.container}>
      <MaybeCountUp value={total} animate={animate} />
      {showTotal && <Tooltip icon="circle-info" message={getText("fees.incomplete_tooltip")} />}
    </div>
  );
};

export const FeesTotal = ({ feeIDs, ...props }) => {
  const requirementIDs = useSelector((state) =>
    R.uniq(R.map(R.prop("requirement_id"), selectFeesByID(state, feeIDs))),
  );
  const requirements = useSelector((state) => selectRequirementsByID(state, requirementIDs));
  return <BaseFeesTotal feeIDs={feeIDs} requirements={requirements} {...props} />;
};

export const FeeTotal = ({ feeID, ...props }) => <FeesTotal feeIDs={[feeID]} {...props} />;

export const RequirementsTotal = ({ requirementIDs, ...props }) => {
  const requirements = useSelector((state) => selectRequirementsByID(state, requirementIDs));
  const feeIDs = R.chain(R.propOr([], "fee_ids"), requirements);
  return <BaseFeesTotal feeIDs={feeIDs} requirements={requirements} {...props} />;
};

export const RequirementTotal = ({ requirementID, ...props }) => (
  <RequirementsTotal requirementIDs={[requirementID]} {...props} />
);
