import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import Icon from "components/Icon";
import Text from "containers/Text";
import { getFullAddress } from "utils/address";
import { formatAddress, formatMetersAsMiles } from "utils/format";
import { getDistance, getEndAddress, getStartAddress, isComplete } from "utils/route";
import { eventRoutesType } from "utils/sharedPropTypes";

import styles from "./EventRoutesFieldLegacy.scss";
import UnansweredField from "./UnansweredField";

export const EventRoutesField = () => <div />;

const formatFullAddress = R.pipe(getFullAddress, formatAddress);

const DisabledEventRoute = ({ route }) => (
  <div className={styles.routeContainer}>
    <div className={styles.icon}>
      <Icon size="lg" icon="route" />
    </div>
    <div className={styles.content}>
      <div className={styles.heading}>
        {formatMetersAsMiles(getDistance(route))} <Text t="guides.event_locations.mile_route" />
      </div>
      <div>
        <Text t="fields.event_route.start" />:{" "}
        <span className={styles.address}>{formatFullAddress(getStartAddress(route))}</span>
      </div>
      <div>
        <Text t="fields.event_route.end" />:{" "}
        <span className={styles.address}>{formatFullAddress(getEndAddress(route))}</span>
      </div>
    </div>
  </div>
);
DisabledEventRoute.propTypes = { route: eventRoutesType };

export const DisabledEventRoutesFieldLegacy = ({ value = [] }) => {
  const completeRoutes = R.filter(isComplete, value);

  return (
    <div className={styles.container}>
      {R.isEmpty(completeRoutes) && <UnansweredField />}
      {R.addIndex(R.map)(
        (route, i) => (
          <DisabledEventRoute key={i} route={route} />
        ),
        completeRoutes,
      )}
    </div>
  );
};
DisabledEventRoutesFieldLegacy.propTypes = { value: PropTypes.arrayOf(eventRoutesType) };
export const disabled = DisabledEventRoutesFieldLegacy;

export const IssuedEventRoutesFieldLegacy = ({ value = [] }) => {
  if (R.isEmpty(value)) {
    return (
      <div className={styles.container}>
        <UnansweredField />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <DisabledEventRoute route={R.head(value)} />
      {R.length(value) > 1 && (
        <div className={styles.routeContainer}>
          <div className={styles.icon}>
            <Icon size="lg" icon="route" />
          </div>
          <div className={styles.content}>
            <div className={styles.heading}>{R.length(value) - 1} other routes</div>
          </div>
        </div>
      )}
    </div>
  );
};
IssuedEventRoutesFieldLegacy.propTypes = { value: PropTypes.arrayOf(eventRoutesType) };
export const issued = IssuedEventRoutesFieldLegacy;

export default EventRoutesField;
