import { useCallback } from "react";
import * as R from "ramda";
import { useFormState } from "react-hook-form";

/**
 * Transforms request payloads from a react-hook-form input format into a rails compatable format.
 *
 * Needed b/c some input components may return data in a format incompatable with rails like react-select which returns { value: "", id: 1 }.
 * Chaning the values in the input is preferable but often complex b/c they also need to injest those same values back for display.
 *
 * Any other controlled input manipulations we find should be handled here as well.
 *
 * @param {object} json - The form request data to transform
 * @param {object} options
 * @property {String} options.selectKeys - keys of select options which need to be transformed from { value: "", id: 1 } to 1.
 */
export function transformPayload(json, { selectKeys = [] }) {
  selectKeys.forEach((k) => {
    json[k] = json[k]?.value;
  });
  return json;
}

export const backfillExpressionByType = (type, noun) => {
  if (
    !R.prop("expressions_attributes", noun) ||
    R.find(R.propEq("type", type), noun.expressions_attributes)
  )
    return noun;
  return R.assocPath(
    ["expressions_attributes"],
    R.append({ type, expression: "" }, noun.expressions_attributes),
    noun,
  );
};

/**
 * returns only the key/values that are dirty on the given form state
 * @param {} formState react-hook-form form state
 * @param {*} data react-hook-form form data
 * @returns {} data hash containing only the dirty values
 */
export function onlyDirtyValues(formState, data) {
  return R.pickAll(R.keys(formState.dirtyFields), data);
}

const makeDirtyFieldsSelector = R.compose(R.pickAll, R.keys, R.filter(R.identity));

export function useFormHandler({ handler, formControl, onlyDirtyFields = true }) {
  const { dirtyFields } = useFormState({ control: formControl });
  const filter = onlyDirtyFields ? makeDirtyFieldsSelector(dirtyFields) : R.identity;
  return useCallback(
    async (data) => {
      try {
        return await handler(filter(data));
      } catch (e) {
        console.error("error in useFormHandler", e);
        return null;
      }
    },
    [handler, filter],
  );
}

/**
 * Standard error handler for forms that associates rails generated errors with form inputs
 *
 * Flattens rails multi error messages into a single "," seperated string
 * @param {function} setError - react-hook-form setError func
 * @returns {function} - the fn that does the backend request. likely a useMutation().mutateAsync fn
 */
export const errorHandler =
  (setError) =>
  ({ response }) => {
    if (response?.status !== 422) return;

    Object.entries(response?.data?.errors).forEach(([attr, msgs]) => {
      const msg = Array.isArray(msgs) ? msgs.join(", ") : msgs;
      setError(attr, { type: "server", message: msg });
    });
  };

/**
 * Wraps input function to silently swallow errors, for use with react-hook-form. We usually use react-query mutations and the mutation catches errors and exposes them on the onError callback, while RHF expects the fn given to handleSubmit to return without throwing if the backend submit completed (with or without error).
 *
 * @param {function} fn - the fn that does the backend request. likely a useMutation().mutateAsync fn
 * @returns {function} - a wrapped version of the input fn that silently swallows errors. Probably given to RHF's useForm().handleSubmit
 */
export const swallowErrors =
  (fn) =>
  async (...args) => {
    try {
      return await fn(...args);
    } catch (e) {
      return null;
    }
  };
