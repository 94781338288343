import React from "react";

import { errorPropType } from "utils/sharedPropTypes";

import styles from "./Field.scss";

const ErrorMessage = ({ error, className = styles.errorMessage }) => {
  let msg;
  if (Array.isArray(error)) [msg] = error;
  else if (typeof error === "object") msg = error.message;
  else msg = error;

  return (
    <div role="alert" className={className} data-error>
      {msg}
    </div>
  );
};
ErrorMessage.propTypes = {
  error: errorPropType.isRequired,
};

export default ErrorMessage;
