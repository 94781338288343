import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import "react-dates/initialize";
import { SingleDatePicker, isInclusivelyAfterDay } from "react-dates";

import Icon from "components/Icon";
import {
  disabled as DisabledTextField,
  issued as IssuedTextField,
} from "components/project_form/TextField";
import withFocusToggle from "components/utilities/withFocusToggle";
import withHandlers from "components/utilities/withHandlers";
import { momentablePropType } from "utils/sharedPropTypes";
import { formatShortDateString, maybeParseTime, now, parseTime } from "utils/time";

import styles from "./CalendarField.scss";
import { immediateMomentValue } from "./behaviors";

const withFocus = R.compose(
  immediateMomentValue,
  withFocusToggle,
  withHandlers({
    onFocusChange:
      ({ setFocused, onBlur }) =>
      (e) => {
        const { focused } = e;

        const inputValue = window.event.target.value;
        if (inputValue === undefined) {
          // when input is undefined a day has been clicked
          onBlur();
        } else if (isFullDateString(inputValue) || inputValue.trim() === "") {
          // only save when we have a full string or nothing
          onBlur();
        }
        setFocused(focused);
      },
  }),
);

function isFullDateString(str) {
  const correctShape = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.exec(str);
  return correctShape && maybeParseTime(str)?.isValid();
}
const onlyWhenValid = (fn) => (value) => {
  // need to use global event here since react-dates doesn't pass the event up
  const inputValue = window.event.target.value;
  if (!inputValue) {
    if (!value) return;
    const day = maybeParseTime(value);
    if (!day) return;
    if (!day.isValid()) return;
    if (day.year() < 1000 || day.year() > 9999) return; // day.js sees 01/01/012 & 01/01/10000 as valid dates which are actually partually entered. We need to ignore them

    fn(day);
  }

  if (isFullDateString(inputValue)) {
    // react-dates will overwrite the incomplete input with the day.js date so ignore incomplete values
    fn(value);
  }
};

const NakedCalendarField = ({
  value,
  onChange,
  focused,
  onFocusChange,
  allowPastDates,
  placeholder,
  minDate,
  maxDate,
  initiallyVisibleMonth,
}) => {
  const isOutsideRange = (day) =>
    (!allowPastDates && !isInclusivelyAfterDay(day, now())) ||
    (minDate && parseTime(day).isBefore(minDate)) ||
    (maxDate && parseTime(day).isAfter(maxDate));

  return (
    <div className={styles.container}>
      <SingleDatePicker
        id="date_input"
        date={maybeParseTime(value)}
        initialVisibleMonth={initiallyVisibleMonth}
        focused={focused}
        onFocusChange={onFocusChange}
        onDateChange={onlyWhenValid(onChange)}
        placeholder={placeholder}
        numberOfMonths={1}
        noBorder
        isOutsideRange={isOutsideRange}
      />
    </div>
  );
};
export const BareCalendarField = withFocus(NakedCalendarField);

export const CalendarFieldComponent = (props) => (
  <div className={styles.fieldContainer}>
    <BareCalendarField {...props} />
  </div>
);

const CalendarField = (props) => {
  const { field } = props;
  const allowPastDates = R.path(["ui", "form_input", "allow_past_dates"], field);
  return <CalendarFieldComponent allowPastDates={allowPastDates} {...props} />;
};
CalendarField.propTypes = {
  field: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
};

export const DisabledCalendarField = ({ value }) => (
  <div className={styles.disabledContainer}>
    <Icon faStyle="regular" size="lg" icon="calendar" />
    <DisabledTextField value={value && formatShortDateString(value)} />
  </div>
);
DisabledCalendarField.propTypes = {
  value: momentablePropType,
};
export const disabled = DisabledCalendarField;

export const IssuedCalendarField = ({ value }) => (
  <IssuedTextField value={value && formatShortDateString(value)} />
);
IssuedCalendarField.propTypes = {
  value: momentablePropType,
};
export const issued = IssuedCalendarField;

export default CalendarField;
