import { connect } from "react-redux";

import { downloadIssuedRequirementPDF, downloadRequirementApplicationPDF } from "actions";
import Download, {
  DownloadApplicationLink as DownloadLinkComponent,
} from "components/projects/applications/Download";
import { getRequirementApplication, selectProjectByID } from "reducers/projects";
import { isIssued } from "reducers/requirementApplications";
import { selectDownloadInProgress } from "reducers/ui";

const mapStateToProps = (state, ownProps) => {
  const project = selectProjectByID(state, ownProps.projectID);

  return {
    project,
    isDownloadInProgress: selectDownloadInProgress(state),
    isIssued: isIssued(getRequirementApplication(project, ownProps.requirementApplicationID)),
  };
};

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...stateProps,
  ...ownProps,
  onDownloadApplication: () =>
    dispatch(
      downloadRequirementApplicationPDF({
        requirementApplicationID: ownProps.requirementApplicationID,
      }),
    ),
  onDownloadRequirement: () =>
    dispatch(
      downloadIssuedRequirementPDF({ requirementApplicationID: ownProps.requirementApplicationID }),
    ),
});

const enhance = connect(mapStateToProps, null, mergeProps);

export const DownloadLink = enhance(DownloadLinkComponent);
export default enhance(Download);
