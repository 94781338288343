/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import classnames from "classnames";
import * as R from "ramda";
import { NumericFormat } from "react-number-format";
import Slider from "react-rangeslider";
import TextareaAutosize from "react-textarea-autosize";

import "react-rangeslider/lib/index.css";
import ReactSelect, { components as ReactSelectComponents } from "react-select";
import ReactSelectAsync from "react-select/async";
import CreatableSelect from "react-select/creatable";

import ErrorBoundary from "components/ErrorBoundary";
import ExternalLink from "components/ExternalLink";
import Icon from "components/Icon";
import Markdown from "components/Markdown";
import Fragment from "components/SafeFragment";
import Tooltip from "components/Tooltip";
import { ButtonDiv } from "components/accessibility/Div";
import { escapeRegExp, isPresent } from "utils/func";
import { hoc } from "utils/hoc";
import upload from "utils/upload";

import styles from "./BareFields.scss";

const WithWrappable = ({
  Component: InputComponent,
  Container = Fragment,
  containerProps = {},
  baseName: _baseName,
  ...props
}) => (
  <Container {...containerProps}>
    <ErrorBoundary>
      <InputComponent {...props} />
    </ErrorBoundary>
  </Container>
);
WithWrappable.displayName = "WithWrappable";
export const wrappable = hoc(WithWrappable);

export const TextInput = wrappable(
  Object.assign(
    ({ classNames = [], forwardedRef, error, ...inputProps }) => (
      <input
        ref={forwardedRef}
        className={classnames(styles.textInput, classNames, {
          [styles.error]: error,
        })}
        type="text"
        {...inputProps}
      />
    ),
    { displayName: "TextInput" },
  ),
);

const enhancePropsWithRegister = (register, name, props) => {
  if (typeof register === "object")
    return {
      ...props,
      ...register,
    };
  if (typeof register === "function") {
    return {
      ...props,
      ...register(name),
    };
  }
  return props;
};

/*
These inputs are to be used by react-hook-form.

These inputs priortize using native inputs, inline with react-hook-form but can be controlled
when required.
*/
export const Text = ({ name, register, error, placeholder, ...props }) => (
  <input
    className={classnames(styles.textInput, {
      [styles.error]: error,
    })}
    type="text"
    aria-label={name}
    placeholder={placeholder}
    {...enhancePropsWithRegister(register, name, props)}
  />
);
export const TextWithIcon = ({ icon, ...props }) => (
  <div className={styles.textInputWithIconContainer}>
    <Icon icon={icon} />
    <Text {...props} />
  </div>
);

export const Checkbox = ({ error, name, register, ...props }) => (
  <input
    aria-label={name}
    className={classnames(styles.checkboxInput, { [styles.error]: error })}
    name={name}
    type="checkbox"
    {...enhancePropsWithRegister(register, name, props)}
  />
);

export const TextArea = ({ error, name, register, ...props }) => (
  <textarea
    aria-label={name}
    className={classnames(styles.textAreaInput, { [styles.error]: error })}
    name={name}
    {...enhancePropsWithRegister(register, name, props)}
  />
);

/**
 *
 * @param {*options} - [{ label, value }]
 * @returns
 */
export const Select = ({ error, name, options = [], register, includeBlank, ...props }) => (
  <select
    name={name}
    className={classnames(styles.selectInput, { [styles.error]: error })}
    {...enhancePropsWithRegister(register, name, props)}
  >
    {includeBlank && <option key="blank" aria-label="blank" />}
    {options.map((type) => (
      <option key={type.value} value={type.value}>
        {type.label}
      </option>
    ))}
  </select>
);
// end of react-hook-form inputs

export const ColorInput = wrappable(
  Object.assign(
    ({ classNames = [], forwardedRef, error, inputValue, ...inputProps }) => (
      <div className={styles.colorInput}>
        <input
          ref={forwardedRef}
          className={classnames(styles.textInput, classNames, {
            [styles.error]: error,
          })}
          type="text"
          {...inputProps}
        />
        <span className={styles.color} style={{ backgroundColor: inputValue }} />
      </div>
    ),
    { displayName: "ColorInput" },
  ),
);

const RadioButtonOption = ({ option, inputProps, value }) => {
  const selected = value === option.value;
  const id = `${inputProps.name}-${option.value}`;

  return (
    <div className={styles.radioButtonOption}>
      <label htmlFor={id} data-option={option.value}>
        <div
          className={classnames(
            styles.radioButton,
            selected ? styles.radioSelected : styles.radioNotSelected,
          )}
        >
          <i className={styles.radioIcon} />
        </div>
        <div className={styles.radioButtonOptionLabel}>{option.label}</div>
        {option.description && (
          <div className={styles.radioButtonOptionDescription}>
            <p>{option.description}</p>
          </div>
        )}

        <input type="radio" {...inputProps} value={option.value} checked={selected} id={id} />
      </label>
    </div>
  );
};

export const RadioButtonInput = wrappable(
  Object.assign(
    ({ classNames = [], options = {}, error, value, ...inputProps }) => (
      <div
        className={classnames(styles.radioButtonInput, classNames, {
          [styles.error]: error,
        })}
        data-radio={inputProps.name}
      >
        {options.map((option) => (
          <RadioButtonOption
            key={option.value}
            option={option}
            value={value}
            inputProps={inputProps}
          />
        ))}
      </div>
    ),
    { displayName: "RadioButtonInput" },
  ),
);

export const SelectInput = wrappable(
  Object.assign(
    ({
      classNames = [],
      options = {},
      forwardedRef,
      includeBlank = false,
      placeholder = false,
      error,
      ...inputProps
    }) => {
      const selectRef = useRef();

      return (
        <select
          className={classnames(styles.selectInput, classNames, {
            [styles.error]: error,
            [styles.placeholder]: placeholder && selectRef.current?.selectedIndex === 0,
          })}
          ref={(e) => {
            forwardedRef(e);
            selectRef.current = e;
          }}
          {...inputProps}
        >
          {includeBlank && <option key="blank" aria-label="blank" />}
          {placeholder && (
            <option key="placeholder" aria-label="placeholder" disabled hidden selected>
              {placeholder}
            </option>
          )}
          {options.map((type) => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>
      );
    },
    { displayName: "SelectInput" },
  ),
);

export const Select2Input = wrappable(
  Object.assign(
    // ({ classNames = [], error, ...inputProps }) => {
    ({ error, ...inputProps }) => {
      if (inputProps.disabled) {
        inputProps.name = null;
        inputProps.isDisabled = true;
      }
      return (
        <ReactSelect
          classNamePrefix="select"
          className={classnames(styles.select2Input, {
            [styles.error]: error,
          })}
          {...inputProps}
        />
      );
    },
    { displayName: "Select2Input" },
  ),
);

export const VirtualSelect2Input = ({
  maxListSize = 8,
  filter,
  options,
  value,
  resultCountRef,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const filteredOptions = useMemo(() => {
    if (!inputValue) {
      return options;
    }

    if (filter) {
      const filteredMatches = filter(inputValue);
      if (filteredMatches) {
        return filteredMatches;
      }
    }

    const regByInclusion = new RegExp(escapeRegExp(inputValue), "i");
    const regByStart = new RegExp(`^${escapeRegExp(inputValue)}`, "i");

    const allMatches = R.filter(({ label }) => regByInclusion.test(label), options);
    const [matchByStart, matchByInclusion] = R.partition(
      ({ label }) => regByStart.test(label),
      allMatches,
    );

    const results = [...matchByStart, ...matchByInclusion];

    return results;
  }, [inputValue, options, filter]);

  const slicedOptions = useMemo(
    () => filteredOptions.slice(0, maxListSize),
    [filteredOptions, maxListSize],
  );

  if (resultCountRef) {
    resultCountRef.current = slicedOptions.length;
  }

  return (
    <Select2Input {...props} onInputChange={setInputValue} options={slicedOptions} value={value} />
  );
};

export const AsyncSelect = ReactSelectAsync;

const ReactSelectInput = ({ selectProps, ...props }) => (
  <ReactSelectComponents.Input
    {...props}
    onPaste={selectProps.onPaste}
    disabled={selectProps.disabled}
  />
);

export const CreatableSelect2Input = wrappable(
  Object.assign(
    ({ classNames = [], error, components = {}, options: optionsProp, ...props }) => {
      const { value, onChange, getNewOptionData } = props;
      const [options, setOptions] = useReducer(R.union, optionsProp);
      useEffect(() => setOptions(value), [value]);

      const onPaste = useCallback(
        (event) => {
          const paste = (event.clipboardData || window.clipboardData).getData("text");
          const entries = R.filter(isPresent, paste.split(/\s*\n\s*/));
          if (entries.length === 1) return;

          event.preventDefault();
          const newOptions = R.map(getNewOptionData, entries);
          onChange(R.union(value, newOptions));
        },
        [onChange, getNewOptionData, value],
      );

      if (props.disabled) {
        props.name = null;
      }

      return (
        <CreatableSelect
          classNamePrefix="select"
          className={classnames(styles.select2Input, classNames, {
            [styles.error]: error,
          })}
          options={options}
          onPaste={onPaste}
          value={value}
          components={{ Input: ReactSelectInput, ...components }}
          {...props}
        />
      );
    },
    { displayName: "CreatableSelect2Input" },
  ),
);

export const NumericInput = wrappable(
  Object.assign(
    ({ classNames = [], error, forwardedRef, ...inputProps }) => (
      <NumericFormat
        className={classnames(styles.numberFormat, classNames, {
          [styles.error]: error,
        })}
        getInputRef={forwardedRef}
        {...inputProps}
      />
    ),
    { displayName: "NumericInput" },
  ),
);

const cleanTextareaProps = R.over(R.lensProp("value"), R.defaultTo(""));

const TextAreaInputNaked = ({
  classNames = [],
  rows = 2,
  error,
  forwardedRef: _,
  ...inputProps
}) => (
  <TextareaAutosize
    className={classnames(styles.textAreaInput, classNames, {
      [styles.error]: error,
    })}
    minRows={rows}
    {...cleanTextareaProps(inputProps)}
  />
);
TextAreaInputNaked.displayName = "TextAreaInput";
export const TextAreaInput = wrappable(TextAreaInputNaked);

const markdownSample = `
# Heading 1

## Head 2

* list item
    * lists can be nested
1. numbered list
    * lists can mix type at different levels
1. numbered list numbers may be manually incremented, but needn't be
    1. numbered list

Horizontal rules!

---

Paragraph with __bold__ and _italic_ text and a [link](http://url.com).

> block quotes are possible

:button[Link]{href="/admin/projects/$projectID/applications/$requirementApplicationID}
(ID replacement only work for some attributes and only in certain places)
`;
const markdownTooltipContent = `
This attribute supports [markdown syntax](https://support.squarespace.com/hc/en-us/articles/206543587-Markdown-cheat-sheet).

\`\`\`
${markdownSample}
\`\`\`

${markdownSample}
`;

export const MarkdownInput = wrappable(
  Object.assign(
    ({ renderPreview = R.identity, ...props }) => (
      <div className={styles.markdownInputContainer}>
        <div className={styles.contentContainer}>
          <TextAreaInputNaked {...props} />
          <div
            className={classnames(styles.preview, {
              [styles.empty]: !props.value,
            })}
          >
            {renderPreview(
              <Markdown
                source={props.value || props.placeholder}
                context={props.context}
                projectID={123456}
                requirementApplicationID={654321}
              />,
            )}
            {!props.value && !props.placeholder && <div>Enter markdown to see preview.</div>}
          </div>
        </div>
        <div className={styles.helpContainer}>
          <Tooltip message={markdownTooltipContent} />
        </div>
      </div>
    ),
    { displayName: "MarkdownInput" },
  ),
);

export const CheckboxInput = wrappable(
  Object.assign(
    ({
      classNames = [],
      forwardedRef,
      error,
      confirmationMessage: _confirmationMessage,
      ...inputProps
    }) => (
      // TODO: confirmation and styling
      <input
        className={classnames(styles.checkboxInput, classNames, {
          [styles.error]: error,
        })}
        type="checkbox"
        ref={forwardedRef}
        {...inputProps}
      />
    ),
    { displayName: "CheckboxInput_" },
  ),
);

export const AttachmentInput = wrappable(
  Object.assign(
    ({ error, value, onChange, forwardedRef: _, ...inputProps }) => {
      const fileRef = useRef(null);

      const selectFile = useCallback(() => fileRef.current.click(), []);
      const onAttach = useCallback(
        async (change) => {
          change.persist();
          const file = change.target.files[0];
          const attachmentID = await upload(file);

          onChange({
            signed_id: attachmentID,
            filename: file.name,
            content_type: file.type,
            size: file.size,
          });
        },
        [onChange],
      );

      const remove = useCallback(
        (e) => {
          e.preventDefault();
          e.stopPropagation();

          onChange({ _destroy: true });
        },
        [onChange],
      );

      if (value && value.filename) {
        return (
          <div className={styles.existingAttachment}>
            <ExternalLink href={value.url} className={styles.download}>
              <Icon icon="file" />
              <span>{value.filename}</span>
            </ExternalLink>
            <ButtonDiv data-remove-file className={styles.removeFile} onClick={remove}>
              Remove file
            </ButtonDiv>
          </div>
        );
      }

      return (
        <div className={styles.attachmentInput}>
          <ButtonDiv className="btn-bordered-sm" aria-invalid={!!error} onClick={selectFile}>
            Choose File
          </ButtonDiv>
          <input type="file" {...inputProps} ref={fileRef} onChange={onAttach} />
        </div>
      );
    },
    { displayName: "AttachmentInput_" },
  ),
);

export const SliderInput = wrappable(
  Object.assign(
    ({ value, onChange, min = 1, max = 100, step = 1 }) => (
      <Slider value={value} min={min} max={max} step={step} onChange={onChange} tooltip />
    ),
    { displayName: "SliderInput" },
  ),
);
