import React from "react";
import { Redirect } from "react-router-dom";

import Loader from "components/Loader";
import styles from "components/projects/requirement/Process.scss";
import Text from "containers/Text";
import { isPayable } from "reducers/requirementApplications";
import { projectPropType, requirementApplicationPropType } from "utils/sharedPropTypes";

const Process = ({ project, requirementApplication }) => {
  if (!isPayable(requirementApplication)) return <Redirect to={`/projects/${project.id}/apply`} />;
  return (
    <main role="main">
      <div className={styles.container}>
        <Loader large />
        <div className={styles.description}>
          <Text t="projects.requirement.payment.verifying" />
        </div>
      </div>
    </main>
  );
};
Process.propTypes = {
  requirementApplication: requirementApplicationPropType,
  project: projectPropType,
};

export default Process;
