import React from "react";

import Icon from "components/Icon";

import styles from "./ReadOnlyBadge.scss";

const ReadOnlyBadge = () => (
  <div className={styles.readOnly}>
    <Icon icon="pencil-slash" />
    READ ONLY VIEW
  </div>
);

export default ReadOnlyBadge;
