import { connect } from "react-redux";

import TopLine from "components/TopLine";
import { selectFullName, selectWebsiteURL } from "reducers/tenant";

const mapStateToProps = (state) => ({
  tenantFullName: selectFullName(state),
  tenantURL: selectWebsiteURL(state),
});

export default connect(mapStateToProps)(TopLine);
