import React from "react";
import classnames from "classnames";
import * as R from "ramda";
import { Link } from "react-router-dom";

import Icon from "components/Icon";
import LanguagePicker from "components/LanguagePicker";
import { ButtonDiv, MenuDiv } from "components/accessibility/Div";
import Text from "containers/Text";
import WhenPermitted from "containers/utilities/WhenPermitted";
import { useAuthnState } from "contexts/auth";
import { useIsLoginEnabled } from "contexts/featureFlags";
import { useSession } from "contexts/session";
import { getEmail, getFirstName } from "selectors/users";

import styles from "./DesktopMenu.scss";

const MenuLabel = ({ firstName, emailPrefix }) => (
  <div className={styles.menuLabel}>
    <Icon icon="user" size="lg" inverse />
    <span className={styles.username}>{firstName || emailPrefix}</span>
    <Icon icon="caret-down" size="sm" inverse />
  </div>
);

const LoggedInUserMenu = () => {
  const { user } = useSession();
  const firstName = getFirstName(user);
  const emailPrefix = R.compose(R.head, R.split("@"), R.defaultTo(""), getEmail)(user);

  return (
    <div className={styles.userMenu}>
      <MenuDiv
        className={styles.content}
        label={<MenuLabel firstName={firstName} emailPrefix={emailPrefix} />}
        data-logged-in-menu
      >
        <div className={styles.dropdown}>
          <div className={styles.arrow} />

          <WhenPermitted to="admin">
            <a href="/admin" role="menuitem" className={styles.item}>
              <Text t="nav.admin_link" />
            </a>
          </WhenPermitted>

          <WhenPermitted to="projects.index">
            <Link to="/projects" role="menuitem" className={styles.item} data-my-projects-link>
              <Text t="projects.my_projects" />
            </Link>
          </WhenPermitted>

          <Link to="/profile" role="menuitem" className={styles.item} data-my-profile-link>
            <Text t="nav.profile_link" />
          </Link>

          <Link to="/logout" role="menuitem" className={styles.item} data-sign-out>
            <Text t="sign_out" />
          </Link>
        </div>
      </MenuDiv>
    </div>
  );
};

const SignIn = () => {
  const loginEnabled = useIsLoginEnabled();
  const { activateLoginModal } = useAuthnState();

  if (!loginEnabled) return null;
  return (
    <div className={classnames(styles.userMenu, styles.menuLabel)}>
      <ButtonDiv onClick={activateLoginModal}>
        <Icon icon="user" size="lg" inverse />
        <span>
          <Text t="sign_in" />
        </span>
      </ButtonDiv>
    </div>
  );
};

const UserMenu = () => {
  const { isLoggedIn } = useSession();

  if (isLoggedIn) return <LoggedInUserMenu />;
  return <SignIn />;
};

const DesktopMenu = () => (
  <div className={styles.container}>
    <LanguagePicker />
    <UserMenu />
  </div>
);

export default DesktopMenu;
