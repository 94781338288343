import React from "react";
import classNames from "classnames";
import * as R from "ramda";
import { useSelector } from "react-redux";

import GeomsMap from "components/GeomsMap";
import { Section } from "components/Section";
import Fields from "components/guides/Fields";
import {
  Icon as PermissionIcon,
  Message as PermissionMessage,
} from "components/guides/ZoningDetails/Permission";
import ZoningDetails from "components/guides/ZoningDetails/ZoningDetails";
import EventMap from "components/guides/summary/EventMap";
import SummaryTable from "components/projects/SummaryTable";
import { useGetText } from "containers/Text";
import EventDetails from "containers/guides/summary/EventDetails";
import ZoningMap from "containers/guides/summary/ZoningMap";
import { useAnswerContext } from "containers/withAnswerContext";
import { selectCurrentActiveFieldsForProjectSlug } from "reducers/answerContexts";
import { rootFields } from "reducers/fields";
import { getIsZoningEnabled, selectZoning } from "reducers/projects";
import { compact, isBlank } from "utils/func";
import { getPermission } from "utils/zoning";

import styles from "./Summary.scss";

const isAnswered = (a) => {
  if (Array.isArray(a)) return !R.pipe(R.flatten, compact, R.isEmpty)(a);

  if (a instanceof Object) return !R.pipe(R.values, compact, R.flatten, R.isEmpty)(a);

  if (typeof a === "string" || a instanceof String) return !R.isEmpty(a);

  return !R.isNil(a);
};

const SPECIAL_EVENTS_LOCATION_FIELD_KEYS = [
  "event_geoms",
  "event_addresses",
  "event_location",
  "event_route",
];

const LOCATION_FIELD_KEYS = [...SPECIAL_EVENTS_LOCATION_FIELD_KEYS, "address"];

const useIsSpecialEventsLocation = () => {
  const answeredLocationFields = useAnsweredLocationFields();
  return answeredLocationFields.some((f) => SPECIAL_EVENTS_LOCATION_FIELD_KEYS.includes(f.key));
};

const isLocationField = R.propSatisfies((key) => R.includes(key, LOCATION_FIELD_KEYS), "key");

const selectAnsweredFields = (project, fields) =>
  R.filter((f) => isAnswered(R.path(["answers", f.key], project)), fields);

const useAnsweredLocationFields = () => {
  const { record: project } = useAnswerContext();
  const locationFields = useApplicantFields({
    select: R.filter(isLocationField),
  });
  return selectAnsweredFields(project, locationFields);
};

const useAnsweredFields = () => {
  const { record: project } = useAnswerContext();
  const activeFields = useSelector((state) =>
    selectCurrentActiveFieldsForProjectSlug(state, project, "summary"),
  );
  return selectAnsweredFields(project, activeFields);
};

const useAddressType = () => {
  const answeredLocationFields = useAnsweredLocationFields();
  const locationKeys = R.map(R.prop("key"), answeredLocationFields);

  if (locationKeys.includes("event_geoms")) return "geoms";
  if (locationKeys.includes("event_addresses")) return "geoms";
  if (locationKeys.includes("event_location")) return "location";
  if (locationKeys.includes("event_route")) return "location";
  if (locationKeys.includes("address")) return "address";
  return null;
};

const useGuideFields = () => {
  const locationFields = useAnsweredLocationFields();
  const answeredFields = useAnsweredFields();
  const locationIDs = R.map(R.prop("id"), locationFields);
  return R.reject((f) => locationIDs.includes(f.id), answeredFields);
};

export const useIsViewable = () => {
  const guideFields = useGuideFields();
  const answeredLocationFields = useAnsweredLocationFields();
  if (answeredLocationFields.length || guideFields.length) return true;

  return false;
};

const Map = () => {
  const addressType = useAddressType();
  const fields = useAnsweredLocationFields();
  if (!addressType) return null;

  switch (addressType) {
    case "geoms": {
      return <GeomsMap fields={fields} />;
    }
    case "location": {
      return <EventMap />;
    }
    default: {
      return <ZoningMap />;
    }
  }
};

const useApplicantFields = ({ select = R.identity }) => select(useSelector(rootFields));

const LocationDetails = () => {
  const getText = useGetText();
  const locationFields = useAnsweredLocationFields();
  const isSpecialEvent = useIsSpecialEventsLocation();
  if (locationFields.length === 0) return null;

  return (
    <div className={styles.fields}>
      {isSpecialEvent ? (
        <EventDetails />
      ) : (
        <>
          <Section heading={getText("guides.summary.location")}>
            <Fields
              fields={locationFields}
              behaviorType="disabled"
              hideRequired
              hideHelp
              hideLabel
            />
          </Section>
          <ZoningDetails fromSummary />
        </>
      )}
    </div>
  );
};

const Permission = () => {
  const { record: project } = useAnswerContext();
  const zoning = useSelector((state) => selectZoning(state, project) || {});
  const isZoningEnabled = getIsZoningEnabled(project);

  if (!isZoningEnabled || isBlank(zoning)) return null;

  const permission = getPermission(zoning);
  if (!permission) return null;

  return (
    <div className={styles.permission}>
      <PermissionIcon permission={permission} size="1x" />
      <PermissionMessage permission={permission} />
    </div>
  );
};

const Summary = () => {
  const { record } = useAnswerContext();
  const hideSidebar = R.pathEq(["guide", "guide_type"], "zoning", record);

  return (
    <div className={styles.container}>
      <Permission />
      <Map />
      <div className={classNames({ [styles.sideBySide]: !hideSidebar })}>
        <LocationDetails />
        {hideSidebar || (
          <div className={styles.sidebar}>
            <SummaryTable isAriaHidden />
          </div>
        )}
      </div>
      <ProjectDetails />
    </div>
  );
};

const ProjectDetails = () => {
  const getText = useGetText();
  const isSpecialEvent = useIsSpecialEventsLocation();
  const guideFields = useGuideFields();
  if (guideFields.length === 0) return null;

  return (
    <div className={styles.projectDetails}>
      <Section
        heading={getText(
          isSpecialEvent ? "guides.summary.event_details" : "guides.summary.project_details",
        )}
      >
        <Fields
          fields={guideFields}
          behaviorType="disabled"
          className={styles.fieldColumns}
          hideRequired
          hideHelp
        />
      </Section>
    </div>
  );
};

export default Summary;
