import React from "react";

import InspectableComponent from "components/projects/InspectableComponent";
import { useAnswerContext } from "containers/withAnswerContext";
import { useInspectorContext } from "contexts/inspector";
import { hoc } from "utils/hoc";

/**
 * HOC to make a component withInspectable when in debug mode
 */
const WithInspectable = ({ Component, ...props }) => {
  const inspectorContext = useInspectorContext();
  const answerContext = useAnswerContext();
  const { identifier } = props;
  if (identifier && inspectorContext.inspecting) {
    return (
      <InspectableComponent
        inspectorContext={inspectorContext}
        identifier={identifier}
        answerContext={answerContext}
      >
        <Component {...props} />
      </InspectableComponent>
    );
  }
  return <Component {...props} />;
};

export default hoc(WithInspectable);
