import React from "react";

import ErrorMessage from "components/project_form/ErrorMessage";
import { errorPropType } from "utils/sharedPropTypes";

// FIXME: this shouldn't be HOC; convert this to simple wrapper
export default (Component) => {
  const ReduxFormErrorWrapper = ({ error, ...props }) => (
    <div>
      <Component error={error} {...props} />
      {error ? <ErrorMessage error={error} /> : null}
    </div>
  );

  ReduxFormErrorWrapper.propTypes = {
    error: errorPropType,
  };
  return ReduxFormErrorWrapper;
};
