import React from "react";
import PropTypes from "prop-types";

import { administrativeBackgroundColor } from "../utils/sharedStyles";
import { CircularIcon } from "./Icon";
import Line from "./Line";
import styles from "./ModalHeader.scss";

const ModalHeader = ({ iconType, title, description, noLine = false }) => (
  <div className={styles.container}>
    <CircularIcon icon={iconType} size="3x" iconColor={administrativeBackgroundColor} />
    <h1 className={styles.banner}>{title}</h1>
    {description ? <div className={styles.description}>{description}</div> : null}
    {noLine ? null : <Line noMargin />}
  </div>
);

ModalHeader.propTypes = {
  iconType: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default ModalHeader;
