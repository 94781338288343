import { useCallback, useEffect, useRef, useState } from "react";

/**
 * Prevents race conditions where clicking a button coincides with another event disabling that button.
 * eg. focus is inside a text field and user clicks on the save button. The fields onBlur handler is first fired disabling the button, causing the click to no-op
 *
 * When a project save operation is pending, we immediately update a reference to indicate this state. However, the actual state setting the button to disabled is delayed until at least the next event loop.
 * If the user clicks the button while it's pending (but not yet registered as disabled) it's deferred until the pending state is resolved, ensuring that the project has been saved before proceeding.
 */
const useDefferedButtonHandler = (onNext, pending) => {
  const [pendingForButton, setPendingForButton] = useState(false);
  const pendingRef = useRef(pending);

  useEffect(() => {
    pendingRef.current = pending;
    setTimeout(() => setPendingForButton(pendingRef.current), 0);
  }, [pending]);

  // This needs to return a promise to avoid an endless spinner in the button when validation errors occur
  const callback = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const checkPendingAndProceed = async () => {
          if (pendingRef.current) {
            setTimeout(checkPendingAndProceed, 100);
          } else {
            try {
              await onNext();
              resolve();
            } catch (e) {
              reject(e);
            }
          }
        };
        checkPendingAndProceed();
      }),
    [onNext, pendingRef],
  );

  return [callback, pendingForButton];
};

export default useDefferedButtonHandler;
