import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import * as R from "ramda";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { fetchRequirementApplication } from "actions";
import { AdminButton, ButtonBordered } from "components/Button";
import Icon from "components/Icon";
import { ConfigUserFeature } from "components/privilegedUserFeatures";
import Text from "containers/Text";
import { useRenewal } from "queries/renewals";
import { useMutateRequirementApplication } from "queries/requirementApplications";
import {
  EXPIRED,
  EXPIRES,
  ISSUED_AT,
  RENEWAL_DUE,
  RENEWAL_OPEN,
  getActiveEventDates,
  getRenewalEventsProgress,
  hasRenewalEventPassed,
} from "reducers/renewals";
import { formatDateParam, formatShortDateString } from "utils/time";

import styles from "./RenewalTimeline.scss";

const EventTypeIcon = ({ type }) => {
  const icons = {
    [ISSUED_AT]: "badge-check",
    [RENEWAL_OPEN]: "sync-alt",
    [RENEWAL_DUE]: "hourglass-end",
    [EXPIRES]: "times-hexagon",
    [EXPIRED]: "times-hexagon",
  };
  const icon = R.propOr("clock", type, icons);
  return <Icon faStyle="solid" icon={icon} />;
};

const RenewalTimeline = ({ projectID, requirementApplicationID }) => {
  const dispatch = useDispatch();
  const { data: renewal, isLoading: isRenewalLoading } = useRenewal(
    projectID,
    requirementApplicationID,
  );
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm();
  const [isEditing, setIsEditing] = useState(false);

  // set initial form values after they are loaded
  useEffect(() => {
    if (renewal) {
      const renewalSchedule = getActiveEventDates(renewal).reduce((dates, { type, date }) => {
        dates[type] = formatDateParam(date);
        return dates;
      }, {});
      renewalSchedule.id = requirementApplicationID;
      reset(renewalSchedule);
    }
  }, [renewal, reset, requirementApplicationID]);

  const mutation = useMutateRequirementApplication();
  const onSubmit = useCallback(
    (data) => {
      mutation.mutate(data);
      // need to refetch application since it contains the renewal schedule
      dispatch(fetchRequirementApplication(requirementApplicationID));
      setIsEditing(false);
    },
    [setIsEditing, mutation, dispatch, requirementApplicationID],
  );
  const enableEditing = useCallback(() => setIsEditing(true), [setIsEditing]);

  if (isRenewalLoading || !renewal.showRenewalTimeline) return null;

  const wasDerivedSubmitted = !!renewal.derivedState; // if derived state is SUBMITTED or ISSUED (which means no null)
  const eventsProgress = getRenewalEventsProgress(renewal);
  const activeEventTypes = getActiveEventDates(renewal);

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.timelineAndControlsContainer, {
          [styles.disabled]: wasDerivedSubmitted,
        })}
        data-timeline-disabled={wasDerivedSubmitted}
        data-renewal-timeline
      >
        <div className={styles.timelineContainer}>
          <div className={styles.timeline}>
            <div className={styles.background} />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" {...register("id")} />
            <div className={styles.eventsContainer} data-renewal-state={renewal.renewalState}>
              {activeEventTypes.map(({ type, date }, i) => {
                const eventProgress = eventsProgress[i];
                const isActive = hasRenewalEventPassed(renewal, type);
                const isCurrent = type === renewal.renewalState;

                return (
                  <div
                    className={classnames(styles.eventContainer, styles[type], {
                      [styles.current]: isCurrent,
                      [styles.active]: isActive,
                    })}
                    key={type}
                  >
                    <div className={styles.progress} style={{ width: `${eventProgress}%` }} />
                    <EventTypeIcon type={type} />
                    <div className={styles.eventContentContainer}>
                      <div className={styles.label}>
                        <Text t={`requirement_application.${type}`} />
                      </div>
                      {(!isEditing || type === ISSUED_AT) && (
                        <div className={styles.date}>{date && formatShortDateString(date)}</div>
                      )}
                      {isEditing && type !== ISSUED_AT && (
                        <>
                          <input type="date" {...register(type)} />
                          {errors[type] && <div className="danger">{errors[type]}</div>}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className={classnames(styles.controlsContainer, {
                [styles.isEditing]: isEditing,
              })}
            >
              {isEditing && (
                <ButtonBordered onClick={() => setIsEditing(!isEditing)} label="Close" />
              )}
              {!isEditing && (
                <ConfigUserFeature>
                  <AdminButton onClick={enableEditing} label="Edit" />
                </ConfigUserFeature>
              )}
              {isEditing && <input type="submit" value="Save" disabled={!isDirty || !isValid} />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RenewalTimeline;
