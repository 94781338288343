import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateContext } from "actions";
import { FullScreenLoader } from "components/Loader";
import { useAnswerContext } from "containers/withAnswerContext";
import { useScopeProject } from "queries/projects";
import { isInProgress } from "reducers/projects";

const DoScopeProject = ({ children }) => {
  const dispatch = useDispatch();
  const { record: project, pending } = useAnswerContext();
  const { mutateAsync: scopeProject, isLoading } = useScopeProject(project.id, {
    onSuccess(project) {
      dispatch(updateContext(project));
    },
  });

  // since we rely on Redux store to render the Handoff page (a primary child use-case of this component), we need to wait until the project has been updated (via updateContext action) and that has flowed through the system, not merely waiting for the useScopeProject mutation, otherwise it will be relying on stale data and may redirect to /apply/summary since no Applications are available
  const notYetScoped = isInProgress(project);

  useEffect(() => {
    if (pending) return;
    if (!notYetScoped) return;
    scopeProject();
  }, [pending, notYetScoped, scopeProject]);

  if (isLoading || notYetScoped) return <FullScreenLoader label="ScopeProject" />;

  return children;
};

export default DoScopeProject;
