import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";
import { Link } from "react-router-dom-v5-compat";

import Icon from "components/Icon";
import Loader from "components/Loader";
import DueDate from "components/projects/DueDate";
import SkeuomorphicPaper from "components/utilities/SkeuomorphicPaper";
import { useIsInAdminSection } from "contexts/applicationSection";
import { useRenewal } from "queries/renewals";
import {
  getCurrentState,
  isNoLongerRequired,
  wasSubmitted,
} from "reducers/requirementApplications";
import { getName, getSlug } from "reducers/requirements";
import { isPresent } from "utils/func";
import { requirementApplicationPropType, requirementPropType } from "utils/sharedPropTypes";

import { RequirementTotal } from "./FeeTotals";
import styles from "./SkeuomorphicRequirement.scss";

const STATUS_ICONS = {
  approved: "badge-check",
  expired: "times-hexagon",
  in_progress: "",
  issued: "badge-check",
  no_longer_required: "",
  processed: "check-square",
  rejected: "times-octagon",
  revoked: "exclamation-triangle",
  submitted: "arrow-circle-right",
  submitted_external: "file-import",
  under_review: "ballot-check",
};

const STATUS_NAMES = {
  approved: "Approved",
  expired: "Expired",
  in_progress: "Unsubmitted",
  issued: "Issued",
  no_longer_required: "No longer required",
  processed: "Processed",
  rejected: "Rejected",
  revoked: "Revoked",
  submitted: "Submitted",
  submitted_external: "Transferred",
  under_review: "Under Review",
};

const mapStatusForApplicant = R.when(R.includes(R.__, ["in_progress"]), R.always(""));

const CallToAction = ({ requirement, requirementApplication }) => {
  const isAdmin = useIsInAdminSection();
  const { data: renewal = {}, isLoading } = useRenewal(
    requirementApplication.project_id,
    requirementApplication.id,
  );
  const { review_type: reviewType } = requirement;
  const currentState = getCurrentState(requirementApplication);
  const requirementState =
    reviewType === "Approved" && currentState === "issued" ? "approved" : currentState;

  if (isLoading) return <Loader />;

  const { name, icon } =
    renewal.renewalState === "expired"
      ? { name: STATUS_NAMES.expired, icon: STATUS_ICONS.expired }
      : R.compose(
          R.applySpec({
            name: R.prop(R.__, STATUS_NAMES),
            icon: R.prop(R.__, STATUS_ICONS),
          }),
          isAdmin ? R.identity : mapStatusForApplicant,
        )(requirementState);

  return (
    <div className={styles.requirementStatus}>
      <Icon icon={icon} />
      <span>{name}</span>
    </div>
  );
};

const SkeuomorphicRequirement = ({
  requirement,
  requirementApplication = {},
  selectedRequirementID,
  onClick,
  href,
  pageFoldBgColor,
  children,
}) => (
  <Link
    className={classnames(styles.container, {
      [styles.selected]: R.equals(requirement.id, selectedRequirementID),
    })}
    to={href}
    onClick={onClick}
    data-requirement
    data-requirement-slug={getSlug(requirement)}
    data-requirement-id={requirement.id}
  >
    <SkeuomorphicPaper pageFoldBgColor={pageFoldBgColor} size="small">
      <div className={styles.requirement}>
        <div className={styles.requirementHeader}>
          <div
            className={classnames(styles.requirementName, {
              [styles.disabled]: isNoLongerRequired(requirementApplication),
            })}
            data-requirement-name
          >
            {getName(requirement)}
          </div>
          {!isNoLongerRequired(requirementApplication) && (
            <>
              <RequirementTotal requirementID={requirement.id} />
              <DueDate requirement={requirement} />
            </>
          )}
        </div>
        {children}
        {isPresent(requirementApplication) && wasSubmitted(requirementApplication) && (
          <CallToAction requirement={requirement} requirementApplication={requirementApplication} />
        )}
      </div>
    </SkeuomorphicPaper>
  </Link>
);
SkeuomorphicRequirement.propTypes = {
  requirement: requirementPropType.isRequired,
  selectedRequirementID: PropTypes.number,
  requirementApplication: requirementApplicationPropType,
  onClick: PropTypes.func,
  pageFoldBgColor: PropTypes.string,
  children: PropTypes.node,
};

export default SkeuomorphicRequirement;
