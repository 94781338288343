import * as R from "ramda";

import { useAnswerContext } from "containers/withAnswerContext";

import useAvailableRequirements from "./useAvailableRequirements";

const usePartitionedRequirements = () => {
  const { record } = useAnswerContext();
  const requirements = useAvailableRequirements(record);

  const isActive = (requirement) =>
    R.includes(requirement.id, R.propOr([], "requirement_ids", record));

  return R.partition(isActive, requirements);
};

export default usePartitionedRequirements;
