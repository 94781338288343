import * as R from "ramda";

export const setAtIndex = (i, val, list) => R.pipe(R.remove(i, 1), R.insert(i, val))(list);

export const compact = R.reject(R.isNil);
export const pluckPresent = R.pipe(R.pluck, compact);
export const isIntersecting = (a, b) => R.pipe(R.intersection(b), R.complement(R.isEmpty))(a);

export const endsWith = R.curry((match, iter) => R.equals(R.takeLast(match.length, iter), match));

export const indexByID = R.indexBy(R.prop("id"));
export const overlaps = R.pipe(R.intersection, R.complement(R.isEmpty));
export const isBlank = R.anyPass([R.isEmpty, R.isNil]);
export const isPresent = R.complement(isBlank);
export const isFalsy = R.anyPass([R.isEmpty, R.complement(Boolean)]);
export const isString = (s) => typeof s === "string" || s instanceof String;
export const isPromise = (p) => typeof p === "object" && typeof p.then === "function";

export const sortByDateAttr = (collection, attr) =>
  R.sort((a, b) => {
    const [d1, d2] = R.map((d) => (d[attr] ? new Date(d[attr]) : new Date(0)), [a, b]);
    return d2.getTime() - d1.getTime();
  }, collection);

export const orderByStringAttr = (collection, attr) => sorterOn(attr)(collection);

export const sorterOn = (attr) =>
  R.sort((a, b) =>
    R.propOr("", attr, a).toLowerCase().localeCompare(R.propOr("", attr, b).toLowerCase()),
  );

export const move = R.curry((at, to, list) =>
  R.pipe(R.remove(at, 1), R.insert(to, R.prop(at, list)))(list),
);

export const swap = R.curry((index1, index2, list) => {
  if (index1 < 0 || index2 < 0 || index1 >= list.length || index2 >= list.length) return list; // index out of bound

  return R.pipe(
    R.set(R.lensIndex(index1), list[index2]),
    R.set(R.lensIndex(index2), list[index1]),
  )(list);
});

export const mapObject = R.compose(R.values, R.mapObjIndexed);

export const arrayWrap = (elem) => (Array.isArray(elem) ? elem : [elem]);

export const mapIndexed = R.addIndex(R.map);
export const reduceIndexed = R.addIndex(R.reduce);
export const indexedAll = R.addIndex(R.all);
export const firstPresent = (props, obj) =>
  R.pipe(
    R.props(props),
    R.find((o) => !R.isNil(o)),
  )(obj);

/**
 * Creates a new object with the own properties of the provided object, but the
 * keys renamed according to the keysMap object as `{oldKey: newKey}`.
 * When some key is not found in the keysMap, then it's passed as-is.
 *
 * Keep in mind that in the case of keys conflict is behaviour undefined and
 * the result may vary between various JS engines!
 *
 * @sig {a: b} -> {a: *} -> {b: *}
 */
export const renameKeys = R.curry((keysMap, obj) =>
  R.reduce((acc, key) => R.assoc(keysMap[key] || key, obj[key], acc), {}, R.keys(obj)),
);

export const mapKeys = R.curry((fn, obj) => R.fromPairs(R.map(R.adjust(0, fn), R.toPairs(obj))));

export const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export const EMPTY_OBJECT = {};
export const EMPTY_ARRAY = [];
