import React from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";

import { useAnswerContext } from "containers/withAnswerContext";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { getAnswerForField } from "reducers/answerContexts";
import { selectPrimaryFieldByType } from "reducers/fields";
import { getIsZoningEnabled, selectUseCode, selectZoning } from "reducers/projects";
import { getMunicipalityType, getZoningClearanceHeader } from "reducers/tenant";
import { isPresent } from "utils/func";
import { getUse } from "utils/useCode";
import { getPermission } from "utils/zoning";

import ParcelDetails from "./ParcelDetails";
import ZoneDetails from "./ZoneDetails";
import styles from "./ZoningDetails.scss";

const ZoningDetails = () => {
  const { record: project } = useAnswerContext();

  const addressField = useSelector((state) => selectPrimaryFieldByType(state, "address"));
  const zoning = useSelector((state) => selectZoning(state, project));
  const useCode = getUse(useSelector((state) => selectUseCode(state, project)));
  const tenant = useCurrentTenantInfo();

  const address = getAnswerForField(project, addressField);
  if (!address) return null;

  const hasPermission = isPresent(getPermission(zoning));

  const municipalityType = getMunicipalityType(tenant);
  const isZoningEnabled = getIsZoningEnabled(project);
  const zoningClearanceHeader = getZoningClearanceHeader(tenant);

  return (
    <div className={classnames(styles.container)}>
      {hasPermission && (
        <ZoneDetails
          zoning={zoning}
          useCode={useCode}
          isZoningEnabled={isZoningEnabled}
          zoningClearanceHeader={zoningClearanceHeader}
        />
      )}
      {isPresent(zoning) && <ParcelDetails zoning={zoning} municipalityType={municipalityType} />}
    </div>
  );
};

export default ZoningDetails;
