import React from "react";

import MailToLink from "components/MailToLink";

const ProjectUserChanged = ({
  Layout,
  event: {
    payload: {
      data: { before = {}, after = {} },
    },
  },
}) => (
  <Layout icon="people-arrows">
    Project owner was changed from {before.full_name} (
    <MailToLink email={before.email} inline icon={false} />) to {after.full_name} (
    <MailToLink email={after.email} inline icon={false} />)
  </Layout>
);

export default ProjectUserChanged;
