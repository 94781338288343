import React from "react";
import PropTypes from "prop-types";

import { Button } from "components/Button";
import Icon from "components/Icon";
import Loader, { InlineLoader } from "components/Loader";
import Text from "containers/Text";
import { childrenPropType } from "utils/sharedPropTypes";

import styles from "./Download.scss";

const Download = ({
  isDownloadInProgress,
  isIssued,
  onDownloadApplication,
  onDownloadRequirement,
  hideApplication,
}) => (
  <div className={styles.container}>
    {isDownloadInProgress ? (
      <Loader />
    ) : (
      <div className="action-container">
        {isIssued && (
          <Button
            data-download-requirement-button
            onClick={onDownloadRequirement}
            label={
              <>
                <Icon icon="download" />
                <span>
                  <Text t="projects.application.download_requirement_pdf" />
                </span>
              </>
            }
          />
        )}
        {!hideApplication && (
          <Button
            data-download-application-button
            onClick={onDownloadApplication}
            label={
              <>
                <Icon icon="download" />
                <span>
                  <Text t="projects.application.download_application_pdf" />
                </span>
              </>
            }
          />
        )}
      </div>
    )}
  </div>
);
Download.propTypes = {
  onDownloadApplication: PropTypes.func,
  onDownloadRequirement: PropTypes.func,
  isDownloadInProgress: PropTypes.bool,
  isIssued: PropTypes.bool,
  hideApplication: PropTypes.bool,
};

export const DownloadApplicationLink = ({
  isDownloadInProgress,
  onDownloadApplication,
  children,
}) =>
  isDownloadInProgress ? (
    <InlineLoader />
  ) : (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <span className={styles.link} onClick={onDownloadApplication}>
      {children}
    </span>
  );
DownloadApplicationLink.propTypes = {
  onDownloadApplication: PropTypes.func,
  isDownloadInProgress: PropTypes.bool,
  children: childrenPropType,
};

export default Download;
