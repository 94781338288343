import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { Button } from "components/Button";
import InlineModal from "components/shared/InlineModal";
import Text from "containers/Text";
import analytics from "services/analytics";
import { PAGE_NOT_FOUND } from "services/tracking";

import styles from "./NotFound.scss";

const NotFound = () => {
  useEffect(() => {
    analytics.track(PAGE_NOT_FOUND, { pathname: window.location.pathname });
  });

  const navigate = useNavigate();
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  return (
    <InlineModal
      data-non-routed-modal-name="NotFound"
      heading={<Text t="errors.not_found" />}
      onClose={goBack}
    >
      <div className={styles.actions}>
        <Button onClick={goBack}>
          <Text t="helpers.links.back" />
        </Button>
      </div>
    </InlineModal>
  );
};

export default NotFound;
