import * as R from "ramda";

import * as AddressUtil from "./address";

export const getPolyline = R.prop("overview_polyline");
export const getStartAddress = R.prop("start_address");
export const getEndAddress = R.prop("end_address");
export const getDistance = R.prop("distance");

export const isComplete = (r) =>
  r && AddressUtil.isComplete(getStartAddress(r)) && AddressUtil.isComplete(getEndAddress(r));
