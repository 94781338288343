// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-shared-FlashMessage__container--LO6qI {\n  border-left: solid 5px #0052AF;\n  background: rgba(0, 82, 175, 0.15);\n  padding: 10px 12px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n@media print {\n  .ui-components-shared-FlashMessage__container--LO6qI {\n    display: none;\n  }\n}\n\n.ui-components-shared-FlashMessage__header--Q7Xao {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.ui-components-shared-FlashMessage__heading--U20GJ {\n  font-size: 20px;\n  font-weight: bold;\n  line-height: 25px;\n  margin-bottom: 4px;\n}", "",{"version":3,"sources":["webpack://./ui/components/shared/FlashMessage.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,kCAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;AACF;AACE;EARF;IASI,aAAA;EAEF;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAEF;;AACA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AAEF","sourcesContent":[".container {\n  border-left: solid 5px #0052AF;\n  background: rgba(0, 82, 175, 0.15);\n  padding: 10px 12px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n\n  @media print {\n    display: none;\n  }\n}\n\n.header {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.heading {\n  font-size: 20px;\n  font-weight: bold;\n  line-height: 25px;\n  margin-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ui-components-shared-FlashMessage__container--LO6qI",
	"header": "ui-components-shared-FlashMessage__header--Q7Xao",
	"heading": "ui-components-shared-FlashMessage__heading--U20GJ"
};
export default ___CSS_LOADER_EXPORT___;
