import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Text from "containers/Text";
import { now } from "utils/time";

import styles from "./Disclaimers.scss";

export const FinalDecision = ({ municipalityType }) => (
  <div className={styles.section}>
    <Text
      t="pages.home.final_decision"
      municipalityTypeLowerCase={municipalityType.toLowerCase()}
    />
  </div>
);

FinalDecision.propTypes = {
  municipalityType: PropTypes.string.isRequired,
};

const ADACompliance = () => (
  <div className={styles.section}>
    <Text t="pages.home.ada_compliance" />
  </div>
);

const Copyright = () => (
  <div className={styles.section}>{`© ${now().format("YYYY")} Open Counter Enterprises Inc.`}</div>
);

export const DisclaimersWithoutContainer = ({ municipalityType, disclaimer }) => (
  <div>
    <div className={styles.line} />
    <div className={styles.content}>
      {disclaimer ? (
        <div className={classnames(styles.disclaimers, styles.section)}>{disclaimer}</div>
      ) : (
        <FinalDecision municipalityType={municipalityType} />
      )}
      <ADACompliance />
      <Copyright />
    </div>
  </div>
);

DisclaimersWithoutContainer.propTypes = {
  municipalityType: PropTypes.string.isRequired,
  disclaimer: PropTypes.string,
};

const Disclaimers = ({ ...props }) => (
  <div className={styles.container}>
    <DisclaimersWithoutContainer {...props} />
  </div>
);

export default Disclaimers;
