import * as R from "ramda";

import { parseTime, startOfDay } from "utils/time";

export const getRenewalState = R.propOr("", "renewal_state"); // export const isRenewable = R.prop("renewable");

export const getRenewalOpen = R.prop("renewal_open");
export const getRenewalDue = R.prop("renewal_due");
export const getExpiry = R.prop("expires");

export const hasRenewalEventPassed = (r, e) => {
  const date = R.prop(e, r);
  if (!date) return false;

  return parseTime(date).isSameOrBefore(startOfDay());
};

const EVENT_TYPES = ["issued_at", "renewal_open", "renewal_due", "expires", "expired"];
export const [ISSUED_AT, RENEWAL_OPEN, RENEWAL_DUE, EXPIRES, EXPIRED] = EVENT_TYPES;

export const getActiveEventDates = (r) =>
  R.reduce(
    (dates, type) => {
      const date = R.prop(type, r);
      if (!date) return dates;
      return R.append({ type, date }, dates);
    },
    [],
    EVENT_TYPES,
  );

export const getRenewalEventsProgress = (r) => {
  const nowOrSubmitted = parseTime(r.derivedSubmittedAt).startOf("day");
  const eventDates = getActiveEventDates(r);

  const eventProgress = [];
  for (let i = 1; i < eventDates.length; i++) {
    const eventStart = parseTime(eventDates[i - 1].date).startOf("day");
    const eventEnd = parseTime(eventDates[i].date).startOf("day");

    const total = eventEnd.diff(eventStart, "days");
    let progress = nowOrSubmitted.diff(eventStart, "days");
    if (progress < 0) progress = 0;
    if (progress > total) progress = total;

    if (total <= 0) eventProgress.push(i > 1 ? eventProgress[i - 1] : 100);
    else eventProgress.push(Math.floor((progress / total) * 100));
  }

  return eventProgress;
};

export const getDaysTillRenewalDue = (pa) => {
  const exp = getRenewalDue(pa);
  if (!exp) return null;

  return parseTime(exp).diff(startOfDay(), "days");
};
