import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import departments from "./departments";
import fees from "./fees";
import fields from "./fields";
import guides from "./guides";
import network from "./network";
import pages from "./pages";
import projects from "./projects";
import requirementApplications from "./requirementApplications";
import requirements from "./requirements";
import session from "./session";
import tenant from "./tenant";
import ui from "./ui";
import users from "./users";
import vault from "./vault";
import versions from "./versions";

export default combineReducers({
  departments,
  fees,
  fields,
  form: formReducer,
  guides,
  network,
  pages,
  projects,
  requirementApplications,
  requirements,
  session,
  tenant,
  ui,
  users,
  vault,
  versions,
});
