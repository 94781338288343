import React, { useCallback, useEffect, useState } from "react";
import * as R from "ramda";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

import { ButtonBordered, SubmitButton } from "components/Button";
import Tooltip from "components/Tooltip";
import { Checkbox } from "components/forms/BareFields";
import InlineModal from "components/shared/InlineModal";
import SearchFilter from "components/utilities/listFilters/SearchFilter";
import { useAnswerContext } from "containers/withAnswerContext";
import { useUsers } from "queries/admin/users";
import { useDepartmentsList } from "queries/entities";
import { useAssignSubscriptions, useAssignedUserIDs } from "queries/messages";
import { orderByStringAttr } from "utils/func";

import styles from "./SubscribeUsers.scss";

/**
 * for filtering OpenCounter staff
 *
 * @private
 * @param {Object} user
 * @returns {Boolean}
 */
const isOpenCounterStaff = (user) => user.is_opencounter_staff || user["opencounter_staff?"];

/**
 * user filtering
 *
 * @private
 * @param {String} search
 * @param {Object} user
 * @returns {Boolean}
 */
const isSearchUser = (search, user) => {
  if (search) {
    return (
      user?.email?.toLocaleLowerCase().includes(search?.toLocaleLowerCase()) ||
      user?.first_name?.toLocaleLowerCase().includes(search?.toLocaleLowerCase()) ||
      user?.last_name?.toLocaleLowerCase().includes(search?.toLocaleLowerCase()) ||
      user?.full_name?.toLocaleLowerCase().includes(search?.toLocaleLowerCase())
    );
  }
  return true;
};

const groupByDepartments = (users) => {
  // Function to handle the grouping
  const reducer = (acc, user) => {
    if (user.department_ids.length === 0) {
      // Group users without a department under a special key
      acc.no_department = acc.no_department || [];
      acc.no_department.push(user);
    } else {
      // Group users by their department_ids
      user.department_ids.forEach((deptId) => {
        acc[deptId] = acc[deptId] || [];
        acc[deptId].push(user);
      });
    }
    return acc;
  };

  // Use Ramda's reduce function
  return R.reduce(reducer, {}, users);
};

const useFilteredUsers = () => {
  const [filter, setFilter] = useState(null);
  const setFilterFromEvent = useCallback((e) => setFilter(e.target.value), [setFilter]);
  const { data: users = [] } = useUsers({
    select: (users) =>
      orderByStringAttr(
        users.filter((user) => isSearchUser(filter, user) && !isOpenCounterStaff(user)),
        "last_name",
      ),
  });
  return [users, setFilterFromEvent];
};

const SubscribeUsers = ({ onClose }) => {
  const {
    record: { id },
  } = useAnswerContext();

  const { data: userIDs = [] } = useAssignedUserIDs(id);
  const [users, setFilter] = useFilteredUsers();
  const departmentIds = R.pipe(R.pluck("department_ids"), R.flatten, R.uniq)(users);
  const { data: departments = [] } = useDepartmentsList({
    select: (departments) =>
      R.indexBy(
        R.prop("id"),
        orderByStringAttr(
          departments.filter((dept) => departmentIds.includes(dept.id)),
          "name",
        ),
      ),
  });

  const groupedUsers = groupByDepartments(users);

  const formMethods = useForm({
    defaultValues: {
      user_ids: userIDs,
    },
  });

  useEffect(() => {
    formMethods.setValue("user_ids", userIDs.map(String));
  }, [userIDs, formMethods]);

  const { handleSubmit } = formMethods;

  const { mutate } = useAssignSubscriptions(id);

  const onSubmit = handleSubmit(async (data) => {
    mutate(data);
    onClose();
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <InlineModal heading="Assign message subscriptions" onClose={onClose}>
          <h2 className={styles.subHeading}>
            Select one or more staff members to assign messages for this project to:
          </h2>
          <section className={styles.searchFilter}>
            <SearchFilter
              name="search"
              onChange={setFilter}
              placeholder="Search by name or email"
            />
          </section>
          <>
            {R.keys(groupedUsers).map((deptId) => (
              <DepartmentUsers
                key={deptId}
                department={departments[deptId]}
                users={groupedUsers[deptId]}
              />
            ))}

            <DepartmentUsers key="no_department" users={departments.no_department} />
          </>
          <div className={styles.actions}>
            <SubmitButton data-save-button onClick={onSubmit} />
            <ButtonBordered onClick={onClose} className={styles.cancelButton}>
              <span>Cancel</span>
            </ButtonBordered>
          </div>
        </InlineModal>
      </form>
    </FormProvider>
  );
};

const DepartmentUsers = ({ department, users }) => {
  const { register } = useFormContext();

  if (!users || users.length === 0) return null;

  return (
    <section>
      <h3 className={styles.sectionHeading}>{department?.name || "No Department"}</h3>
      <div className={styles.users}>
        {users.map((user) => (
          <div key={user.id} className={styles.user}>
            <label>
              <Checkbox name="user_ids" value={user.id} register={register} />
              {user.full_name} <Tooltip icon="envelope" message={user.email} />
            </label>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SubscribeUsers;
