import React, { useEffect, useState } from "react";

import TextField from "components/project_form/TextField";
import { isBlank } from "utils/func";

const AnimatedTextField = ({
  ignorePlaceholders = false,
  isFocused,
  placeholders = [],
  value,
  ...props
}) => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const timer = () => {
      setIndex((prevIndex) => (prevIndex === placeholders.length - 1 ? 0 : prevIndex + 1));
    };
    setInterval(timer, 2000);

    return () => {
      clearInterval(timer);
    };
  }, [placeholders]);

  const displayPlaceholder = !ignorePlaceholders && !isFocused && isBlank(value);
  const placeholder = placeholders[index];

  const displayedValue = displayPlaceholder ? `Example: ${placeholder}` : value;

  return <TextField isPlaceholder={displayPlaceholder} value={displayedValue} {...props} />;
};

export default AnimatedTextField;
