import * as R from "ramda";

import { SET_CURRENT_USER, UPDATE_USER, UPDATE_USERS } from "actions";

export default (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_USERS:
      return R.mergeLeft(payload, state);
    case UPDATE_USER:
      return R.assoc(payload.id, payload, state);
    case SET_CURRENT_USER: {
      const { id } = payload;
      if (id) return R.over(R.lensProp(payload.id), R.mergeLeft(payload), state);
      return state;
    }
    default:
      return state;
  }
};

export const selectUsers = R.prop("users");
const EMPTY_USER = { role_names: [] };
export const selectUserByID = (id) => R.pathOr(EMPTY_USER, ["users", id]);
