import * as R from "ramda";

import { SAVE_ANSWERS } from "../actions";

export default class AnswerBuffer {
  constructor() {
    this.answerBuffer = [];
    // this.contextBuffer = buffers.sliding(1);
  }

  put(action) {
    if (R.isEmpty(this.answerBuffer)) {
      this.answerBuffer = R.append(action, this.answerBuffer);
      return;
    }

    const fields = R.pipe(
      R.append(action),
      R.map((action) => action.payload[action.type === SAVE_ANSWERS ? "fields" : "field"]),
      R.flatten,
      R.uniq,
    )(this.answerBuffer);

    const commonPayload = R.omit(["field", "fields"], action.payload);

    this.answerBuffer = [{ type: SAVE_ANSWERS, payload: { fields, ...commonPayload } }];
  }

  take() {
    return this.answerBuffer.shift();
    // if (!R.isEmpty(this.answerBuffer)) return this.answerBuffer.shift();
    // if (!this.contextBuffer.isEmpty()) return this.contextBuffer.take();
    // return undefined;
  }

  flush() {
    const actions = this.answerBuffer;
    this.answerBuffer = [];
    return actions;
  }

  isEmpty() {
    return R.isEmpty(this.answerBuffer); // && this.contextBuffer.isEmpty();
  }

  hasPendingContextSaves() {
    return false;
  }
}
