import React, { useEffect } from "react";
import * as R from "ramda";
import { useDispatch } from "react-redux";
import { BrowserRouter, MemoryRouter, useHistory } from "react-router-dom";
import { CompatRouter, useNavigate } from "react-router-dom-v5-compat";
import { createAction } from "redux-actions";

import { arrayWrap } from "utils/func";

/// /// ///
// See https://github.com/remix-run/react-router/discussions/8753
// NOTE: This is a lightweight implementation of the connected-react-router library, which itself does not support react-router v6 nor the v5-compat upgrade process. Once we have eliminated Redux, we can eliminate this component and replace it with only the BrowserRouter

export const LOCATION_CHANGE = "@@router/LOCATION_CHANGE";
export const HISTORY_PUSH = "@@router/PUSH";
export const HISTORY_GO_BACK = "@@router/GO_BACK";
export const HISTORY_REPLACE = "@@router/REPLACE";

export const push = createAction(HISTORY_PUSH);
export const goBack = createAction(HISTORY_GO_BACK);
export const replace = createAction(HISTORY_REPLACE);

let listeners = [];
const onHistoryAction = (cb) => {
  listeners = R.append(cb, listeners);
  return () => {
    listeners = R.without(cb, listeners);
  };
};

export const middleware = (_store) => (next) => (action) => {
  if (
    !(
      action.type === HISTORY_GO_BACK ||
      action.type === HISTORY_PUSH ||
      action.type === HISTORY_REPLACE
    )
  )
    return next(action);
  return R.forEach(R.applyTo(action), listeners);
};

const ConnectedRouter = ({ children }) => {
  const navigate = useNavigate();
  useEffect(
    () =>
      onHistoryAction(({ type, payload }) => {
        console.debug({ type, payload });
        const [path, opts = {}] = arrayWrap(payload);
        switch (type) {
          case HISTORY_PUSH: {
            navigate(path, opts);
            break;
          }
          case HISTORY_REPLACE: {
            navigate(path, { ...opts, replace: true });
            break;
          }
          case HISTORY_GO_BACK: {
            navigate(-1);
            break;
          }
          default:
            console.log("ConnectedRouter.onHistoryAction saw unexpected action", { type, payload });
        }
      }),
    [navigate],
  );

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(
    () => history.listen((location) => dispatch({ type: LOCATION_CHANGE, payload: location })),
    [history, dispatch],
  );

  return children;
};

const Router = ({ children }) => (
  <BrowserRouter>
    <CompatRouter>
      <ConnectedRouter>{children}</ConnectedRouter>
    </CompatRouter>
  </BrowserRouter>
);
export default Router;

export const TestRouter = ({ children, ...props }) => (
  <MemoryRouter {...props}>
    <CompatRouter>
      <ConnectedRouter>{children}</ConnectedRouter>
    </CompatRouter>
  </MemoryRouter>
);
