import { connect } from "react-redux";

import Disclaimers from "../../components/home/Disclaimers";

const mapStateToProps = (state) => {
  const { municipality_type: municipalityType, disclaimer } = state.tenant;

  return {
    municipalityType,
    disclaimer,
  };
};

export default connect(mapStateToProps)(Disclaimers);
