import * as R from "ramda";

import { useServerVariables } from "contexts/serverVariables";
import { compact } from "utils/func";

const joinPath = R.compose(R.join("/"), compact);

const useMapboxStaticImageUrl = ({
  username,
  style_id,
  overlay,
  lon,
  lat,
  zoom,
  bearing = 0,
  pitch = 0,
  auto,
  width,
  height,
  hidpi = true,
}) => {
  const { mapboxToken } = useServerVariables();

  let position;
  if (auto) position = "auto";
  else position = [lon, lat, zoom, bearing, pitch].join(",");

  const url_base = joinPath([
    "https://api.mapbox.com/styles/v1",
    username,
    style_id,
    "static",
    overlay,
    position,
    `${width}x${height}${hidpi && "@2x"}`,
  ]);
  const uri = new URL(url_base);
  uri.searchParams.append("logo", "false");
  uri.searchParams.append("access_token", mapboxToken);
  return uri.toString();
};

export default useMapboxStaticImageUrl;
