import React from "react";
import Map from "react-map-gl";
import { useSelector } from "react-redux";

import ErrorBoundary from "components/ErrorBoundary";
import styles from "components/maps/StaticMap.scss";
import { useAnswerContext } from "containers/withAnswerContext";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { useServerVariables } from "contexts/serverVariables";
import { getAnswerForField } from "reducers/answerContexts";
import { selectPrimaryFieldByType } from "reducers/fields";
import { useMapStyle } from "services/MapStyleBuilder";
import zoningCache from "services/ZoningCache";
import { isBlank } from "utils/func";

const StaticMap = ({ height = 200 }) => {
  const { record } = useAnswerContext();
  const { id: tenantID, tile_version } = useCurrentTenantInfo();
  const { clearances, radiusRules, address } = useSelector((state) => {
    const addressField = selectPrimaryFieldByType(state, "address");
    if (!addressField) return {};
    const address = getAnswerForField(record, addressField);
    if (!address) return {};

    const clearances = zoningCache.getZonesPerPermission(state, record);
    const radiusRules = zoningCache.getPermissionsForRadiusRules(state, record);

    return {
      address,
      clearances,
      radiusRules,
    };
  });
  const { mapboxToken } = useServerVariables();
  const mapStyle = useMapStyle({
    tenantID,
    tileVersion: tile_version,
    clearances,
    radiusRules,
    address,
    type: "default",
  });

  if (isBlank(address)) return null;

  const { latitude, longitude } = address;

  return (
    <div className={styles.mapContiner}>
      <ErrorBoundary>
        <Map
          style={{ height }}
          mapStyle={mapStyle}
          latitude={latitude}
          longitude={longitude}
          zoom={16}
          mapboxAccessToken={mapboxToken}
        />
      </ErrorBoundary>
    </div>
  );
};

export default StaticMap;
