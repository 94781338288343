/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import classNames from "classnames";

import Icon from "components/Icon";
import SafePortal from "components/SafePortal";
import { ButtonDiv } from "components/accessibility/Div";
import Text from "containers/Text";

import styles from "./InlineModal.scss";

// TODO: add Esc keybinding to close?

const absorbClick = (e) => {
  e.stopPropagation();
};
const InlineModal = ({ onClose, heading, subheading, children, className, ...props }) => (
  <SafePortal targetID="modal">
    <div className={styles.backdrop} onClick={onClose}>
      <div className={classNames(styles.modal, className)} onClick={absorbClick} {...props}>
        <div
          className={classNames(styles.header, {
            [styles.withClose]: !!onClose,
          })}
        >
          {onClose && (
            <ButtonDiv onClick={onClose} className={styles.close} data-close-modal>
              <Text t="close" />
              <Icon icon="times" size="lg" faStyle="solid" />
            </ButtonDiv>
          )}
          {heading && <h1 className={styles.heading}>{heading}</h1>}
          {subheading && <div className={styles.subheading}>{subheading}</div>}
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  </SafePortal>
);

export default InlineModal;
