import React from "react";
import PropTypes from "prop-types";

import Icon from "components/Icon";

import styles from "./SearchFilter.scss";

const SearchFilter = ({ value, stateValue, onChange, placeholder, label, name }) => (
  <div className={styles.container}>
    {label && <div className={styles.label}>{label}</div>}
    <div className={styles.input} data-search>
      <input
        defaultValue={stateValue}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type="text"
        name={name}
        aria-label={name}
      />

      <div className={styles.icon}>
        <Icon icon="search" size="lg" inverse />
      </div>
    </div>
  </div>
);

SearchFilter.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  stateValue: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SearchFilter;
