import React from "react";

import Text from "containers/Text";

import styles from "./UnansweredField.scss";

const UnansweredField = () => (
  <div className={styles.unanswered} data-unanswered>
    <Text t="forms.not_provided" />
  </div>
);

export default UnansweredField;
