import React from "react";
import { useForm } from "react-hook-form";
import { Route, Routes } from "react-router-dom-v5-compat";

import { SubmitButton } from "components/Button";
import { ButtonDiv } from "components/accessibility/Div";
import * as Inputs from "components/forms/BareFields";
import { ApplicantLabeledInput, Form, FormActions, FormRow } from "components/forms/Layout";
import InlineModal from "components/shared/InlineModal";
import Text from "containers/Text";
import { useAuthnState, useOnAuthnSuccess } from "contexts/auth";
import { useApplicantAuthentication } from "queries/auth";
import { errorHandler, swallowErrors } from "utils/form";

import styles from "./AuthPage.scss";

const LoginFactorPassword = ({ onClose, onForgot, onReset }) => {
  const { email } = useAuthnState();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({ values: { email } });
  const onSuccess = useOnAuthnSuccess();
  const { mutateAsync } = useApplicantAuthentication({
    onSuccess({ after_sign_in_path }) {
      onSuccess(after_sign_in_path);
    },
    onError: errorHandler(setError),
  });

  return (
    <InlineModal
      onClose={onClose}
      heading={
        <Routes>
          <Route path="/projects/*" element={<Text t="projects.handoff.save_your_project" />} />
          <Route path="*" element={<Text t="pages.login.banner" />} />
        </Routes>
      }
      subheading={
        <Routes>
          <Route path="/projects/*" element={<Text t="projects.handoff.enter_your_password" />} />
          <Route path="*" element={null} />
        </Routes>
      }
    >
      <Form
        onSubmit={handleSubmit(swallowErrors(mutateAsync))}
        name="applicant-login"
        data-login-form
      >
        <FormRow>
          <div>
            <Text t="pages.login.signing_in_as" className={styles.emphasis} /> {email}
          </div>
          {/* hidden email input is needed as a hint for accessibility and password managers */}
          <input hidden type="text" name="email" autoComplete="email" />
        </FormRow>

        <FormRow>
          <ApplicantLabeledInput label={<Text t="pages.login.password" />} error={errors?.password}>
            <Inputs.Text
              register={register}
              name="password"
              type="password"
              required
              autoComplete="current-password"
            />
          </ApplicantLabeledInput>
        </FormRow>

        <FormActions>
          <div>
            <SubmitButton data-login formControl={control}>
              <Text t="pages.login.sign_in" />
            </SubmitButton>
          </div>

          <div>
            <ButtonDiv onClick={onReset}>
              <Text t="pages.login.reset" />
            </ButtonDiv>
            <ButtonDiv onClick={onForgot}>
              <Text t="pages.forgot_password.link" />
            </ButtonDiv>
          </div>
        </FormActions>
      </Form>
    </InlineModal>
  );
};

export default LoginFactorPassword;
