import React from "react";
import * as R from "ramda";
import { Link } from "react-router-dom";

import { getApplicationAdminURL, getTransactionAdminURL } from "selectors/routes";
import { formatMoney } from "utils/format";

import EventLogPropTypes from "./propTypes";

const AdminLinks = ({ event, application, amount }) => {
  const projectID = R.prop("project_id", event);
  const applicationID = R.prop("id", application);
  const requirementID = R.prop("requirement_id", application);
  const requirementName = R.prop("requirement_name", application);
  const transactionID = R.path(["payload", "data", "transaction_id"], event);

  const transactionURL = getTransactionAdminURL({ id: transactionID, project_id: projectID });
  const applicationURL = getApplicationAdminURL({
    project_id: projectID,
    id: applicationID,
    requirement_id: requirementID,
  });

  return (
    <>
      <Link to={transactionURL} data-payment-link>
        Payment
      </Link>{" "}
      submitted for <Link to={applicationURL}>{requirementName}</Link>: ${formatMoney(amount)}
    </>
  );
};

const MaybeApplicationLink = ({ event, amount, applicantContext }) => {
  const content = <span>Payment received: ${formatMoney(amount)}</span>;
  if (applicantContext) return content;

  const projectID = R.prop("project_id", event);
  const id = R.path(["payload", "data", "transaction_id"], event);
  const url = getTransactionAdminURL({ id, project_id: projectID });

  return (
    <Link to={url} data-payment-link>
      {content}
    </Link>
  );
};

const PaymentSubmitted = ({ Layout, event, timestamp, applicantContext, applicationView }) => {
  const applications = R.path(["payload", "data", "applications"], event);
  if (R.length(applications) > 1)
    throw new Error("Payments over multiple requirements are not supported!");

  const application = R.nth(0, applications);

  const amount = R.path(["payload", "data", "amount"], event);

  return (
    <Layout icon="usd-circle">
      {applicantContext || applicationView ? (
        <MaybeApplicationLink event={event} amount={amount} applicantContext={applicantContext} />
      ) : (
        <AdminLinks event={event} application={application} amount={amount} />
      )}
      {timestamp}
    </Layout>
  );
};
PaymentSubmitted.propTypes = EventLogPropTypes;

export default PaymentSubmitted;
