import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import { ButtonDiv } from "components/accessibility/Div";
import Text from "containers/Text";
import { getEnumLabelByCode } from "reducers/fields";
import { formatId } from "utils/format";
import { isPresent } from "utils/func";
import { errorPropType, fieldPropType, selectOptionPropType } from "utils/sharedPropTypes";

import { CheckboxFieldComponent } from "./CheckboxField";
import styles from "./MultiSelectCheckboxField.scss";
import { immediateValue } from "./behaviors";

const MultiSelectCheckboxItem = ({
  onChange,
  selectOption: { checked, code, label, description, name },
  descriptionId,
  error,
}) => {
  const labelId = `checkbox-label-${formatId(name)}`;

  return (
    <ButtonDiv
      className={styles.item}
      onClick={(e) => {
        e.preventDefault();
        onChange(code);
      }}
      data-checkbox={name}
    >
      <CheckboxFieldComponent
        labelId={labelId}
        descriptionId={descriptionId}
        value={checked}
        onChange={R.T}
        name={name}
        error={error}
      />

      <div className={styles.label} id={labelId}>
        {label}
        {!(R.isEmpty(description) || R.isNil(description)) && (
          <div className={styles.description}>{description}</div>
        )}
      </div>
    </ButtonDiv>
  );
};

MultiSelectCheckboxItem.propTypes = {
  selectOption: selectOptionPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  descriptionId: PropTypes.string,
  error: errorPropType,
};

export const MultiSelectCheckboxFieldComponent = ({
  labelId: descriptionId,
  value,
  field,
  onChange,
  error,
  inline,
  className,
}) => {
  const { key, enum: enumConfig } = field;
  const presentValue = value || [];
  const checkValue = (code) => {
    const ix = R.findIndex(R.equals(code))(presentValue);
    if (ix === -1) {
      onChange([...presentValue, code]);
    } else {
      onChange(R.remove(ix, 1, presentValue));
    }
  };

  const hasAnyDescriptions = R.any(R.propSatisfies(isPresent, "description"), enumConfig);

  return (
    <div
      className={
        className ||
        classnames(styles.container, {
          [styles.inline]: inline,
          [styles.withDescriptions]: hasAnyDescriptions,
        })
      }
    >
      {R.map(
        (option) => (
          <MultiSelectCheckboxItem
            key={option.label}
            selectOption={{
              ...option,
              name: `${key}-${option.code}`,
              checked: presentValue.includes(option.code),
            }}
            onChange={checkValue}
            descriptionId={descriptionId}
            error={error}
          />
        ),
        enumConfig,
      )}
    </div>
  );
};

MultiSelectCheckboxFieldComponent.propTypes = {
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  field: fieldPropType,
  onChange: PropTypes.func,
  labelId: PropTypes.string,
  error: errorPropType,
  inline: PropTypes.bool,
  className: PropTypes.string,
};

const readonly = (behavior) => {
  const ReadonlyMultiSelectCheckboxField = ({ field, value }) => (
    <div className={styles[behavior]}>
      <ul className={styles.list}>
        {R.map(
          (code) => (
            <li key={code}>{getEnumLabelByCode(field, code)}</li>
          ),
          value || [],
        )}
      </ul>
      {R.isEmpty(value) && (
        <span className={styles.unanswered}>
          <Text t="forms.not_provided" />
        </span>
      )}
    </div>
  );
  ReadonlyMultiSelectCheckboxField.displayName = `ReadonlyMultiSelectCheckboxField[${behavior}]`;
  return ReadonlyMultiSelectCheckboxField;
};

export const disabled = readonly("disabled");
export const issued = readonly("issued");

export default immediateValue(MultiSelectCheckboxFieldComponent);
