import React from "react";
import plur from "plur";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import FeeChange from "components/changesets/FeeChange";
import { useFindEntities } from "queries/entities";
import { getRequirementApplicationByRequirementID, selectProjectByID } from "reducers/projects";
import { getMutationIdentifiersFor, getTotalAmountChanged } from "selectors/mutations";
import { getChangesetAdminURL, getChangesetURL } from "selectors/routes";

import styles from "./ChangesetApplied.scss";
import { ApplicationLink } from "./MaybeApplicationLink";
import EventLogPropTypes from "./propTypes";
import sharedStyles from "./sharedStyles.scss";

const useFindEntitiesForMutations = (mutations, type) => {
  const identifiers = getMutationIdentifiersFor(mutations, type);
  const { data: entities = [] } = useFindEntities(identifiers);

  return entities;
};

const AddedRequirement = ({ requirement, project, applicantContext }) => {
  const application = getRequirementApplicationByRequirementID(project, requirement.id);

  return (
    <div>
      <ApplicationLink
        application={application}
        requirementName={requirement.name}
        applicantContext={applicantContext}
      />
    </div>
  );
};

const Content = ({ event, applicantContext }) => {
  const note = R.path(["payload", "data", "note"], event);
  const projectID = R.prop("project_id", event);

  const project = useSelector((state) => selectProjectByID(state, projectID));

  const mutations = R.path(["payload", "data", "mutations"], event);
  const feeMutations = R.chain(R.propOr([], "fee_mutations"), mutations);
  const nameMutation = R.path(["payload", "data", "changeset", "mutations"], event).find(
    (mutation) => mutation.key === "project_name",
  );
  const changedAnswers = R.pipe(
    R.chain(R.prop("changed_answers")),
    R.uniq,
    R.map(R.concat("field:")),
  )(mutations);
  const { data: changedFields = [] } = useFindEntities(changedAnswers);

  const addedRequirements = useFindEntitiesForMutations(mutations, "added");
  const removedRequirements = useFindEntitiesForMutations(mutations, "removed");

  return (
    <>
      {note && (
        <div className={sharedStyles.noteContent} data-note-content>
          {note}
        </div>
      )}
      {nameMutation && (
        <div className={styles.section}>
          <div className={styles.label}>Changed answers:</div>
          <div className={styles.changedAnswers}>
            <div>Project Name</div>
          </div>
        </div>
      )}
      {changedAnswers.length > 0 && (
        <div className={styles.section}>
          <div className={styles.label}>Changed {plur("answer", changedFields)}:</div>
          <div className={styles.changedAnswers}>
            {changedFields.map((field) => (
              <div key={field.key}>{field.field_label}</div>
            ))}
          </div>
        </div>
      )}
      {addedRequirements.length > 0 && (
        <div className={styles.section}>
          <div className={styles.label}>New {plur("requirement", addedRequirements)}:</div>
          <div className={styles.list}>
            {addedRequirements.map((req) => (
              <AddedRequirement
                key={req.identifier}
                requirement={req}
                project={project}
                applicantContext={applicantContext}
              />
            ))}
          </div>
        </div>
      )}
      {removedRequirements.length > 0 && (
        <div className={styles.section}>
          <div className={styles.label}>No longer required:</div>
          <div>
            {removedRequirements.map((req) => (
              <div key={req.identifier} className={styles.removed}>
                {req.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {feeMutations.length > 0 && (
        <div className={styles.section}>
          <div className={styles.feeChange}>
            <FeeChange change={getTotalAmountChanged(feeMutations)} />
          </div>
        </div>
      )}
    </>
  );
};
const ChangesetApplied = ({ Layout, event, timestamp, applicantContext = false }) => {
  const by = R.path(["payload", "data", "by"], event);

  const additionalContent = <Content event={event} applicantContext={applicantContext} />;
  const getURL = applicantContext ? getChangesetURL : getChangesetAdminURL;
  const url = getURL({ id: event.context_id, project_id: event.project_id });

  return (
    <Layout icon="edit" additionalContent={additionalContent}>
      Project edited by {applicantContext ? "staff" : by}
      {timestamp}
      <Link to={url} className={sharedStyles.detailLink}>
        View details
      </Link>
    </Layout>
  );
};

ChangesetApplied.propTypes = EventLogPropTypes;

export default ChangesetApplied;
