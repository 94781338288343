import React from "react";
import { Link } from "react-router-dom";

import InlineModal from "components/shared/InlineModal";
import Text from "containers/Text";
import { useSession } from "contexts/session";

import styles from "./Forbidden.scss";

const Forbidden = () => {
  const { isLoggedIn } = useSession();

  return (
    <InlineModal
      data-non-routed-modal-name="Forbidden"
      data-forbidden
      heading={<Text t="pages.forbidden.title" />}
    >
      <p>
        <Text t="pages.forbidden.message" />
      </p>
      <div className={styles.buttons}>
        <a className="btn-sm" href="/">
          <Text t="pages.home.title" />
        </a>
        {isLoggedIn ? (
          <Link className="btn-sm danger" to="/logout">
            <Text t="sign_out" />
          </Link>
        ) : null}
      </div>
    </InlineModal>
  );
};

export default Forbidden;
