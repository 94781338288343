// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-App__wrapper--ro5hQ {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: stretch;\n  align-content: stretch;\n  justify-content: flex-start;\n  min-height: 100%;\n}\n\n@media print {\n  .ui-components-App__no-print--oFXQj {\n    display: none !important;\n  }\n}", "",{"version":3,"sources":["webpack://./ui/components/App.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,sBAAA;EACA,oBAAA;EACA,sBAAA;EACA,2BAAA;EACA,gBAAA;AACF;;AAGE;EADF;IAEI,wBAAA;EACF;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: stretch; // cross-axis (horizontal)\n  align-content: stretch;\n  justify-content: flex-start; // main axis (vert)\n  min-height: 100%;\n}\n\n.no-print {\n  @media print {\n    display: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ui-components-App__wrapper--ro5hQ",
	"no-print": "ui-components-App__no-print--oFXQj",
	"noPrint": "ui-components-App__no-print--oFXQj"
};
export default ___CSS_LOADER_EXPORT___;
