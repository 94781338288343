import * as R from "ramda";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnly";
import createSagaMiddleware from "redux-saga";

import { middleware as routerMiddleware } from "components/Router";
import rootReducer from "reducers";
import rootSaga from "sagas";
import EventsToState from "services/EventsToState";
import zoningCache from "services/ZoningCache";
import { reportError } from "services/errorReporter";
import tracker from "services/tracking";

const HIDDEN = "<HIDDEN>";

const mask = R.unless(R.isNil, (input) =>
  R.is(String, input) ? "*".repeat(input.length) : HIDDEN,
);

let maskAllPasswordValues;
// eslint-disable-next-line prefer-const
maskAllPasswordValues = R.cond([
  [
    R.is(Object),
    R.mapObjIndexed((v, k) => (R.includes("password", k) ? mask(v) : maskAllPasswordValues(v))),
  ],
  [R.is(Array), R.map(maskAllPasswordValues)],
  [R.T, R.identity],
]);

const configureStore = ({ initialState }) => {
  const sagaMiddleware = createSagaMiddleware({ onError: reportError });
  const enhancer = composeWithDevTools(applyMiddleware(sagaMiddleware, routerMiddleware, tracker));

  const store = createStore(rootReducer, initialState, enhancer);

  sagaMiddleware.run(rootSaga);

  EventsToState.configure(store);
  zoningCache.configure(store);
  return store;
};

export default configureStore;
