import React from "react";
import { useSelector } from "react-redux";

import ExternalLink from "components/ExternalLink";
import StaticMap from "components/maps/StaticMap";
import { useAnswerContext } from "containers/withAnswerContext";
import { getIsZoningEnabled, getZoning, selectLocationDescription } from "reducers/projects";
import { getOverlays, getParcelIDs, getPermission, getZoningDistrict } from "utils/zoning";

import styles from "./Details.scss";

const ZoneLinks = ({ zone }) => (
  <>
    {zone.municipal_code_url && (
      <ExternalLink data-testid="muni-code-link" href={zone.municipal_code_url}>
        Municipal Code
      </ExternalLink>
    )}
    {zone.municipal_code_url && zone.development_standards_url && ", "}
    {zone.development_standards_url && (
      <ExternalLink data-testid="dev-standards-link" href={zone.development_standards_url}>
        Development Standards
      </ExternalLink>
    )}
  </>
);
const ZoningDetails = ({ withMap = false }) => {
  const { record: project } = useAnswerContext();

  const locationDescription = useSelector((state) => selectLocationDescription(state, project));

  const zoning = getZoning(project);
  const zoningDistrict = getZoningDistrict(zoning);
  const permissionName = getPermission(zoning)?.display_name;
  const isZoningEnabled = getIsZoningEnabled(project);
  const overlays = getOverlays(zoning);
  const parcelIDs = getParcelIDs(zoning);

  const zone = zoning.zones[0];

  return (
    <div className={styles.container}>
      {withMap && <StaticMap height={300} />}

      <h4 className={styles.subHeader}>Location</h4>
      <p data-testid="location-description">{locationDescription}</p>

      {parcelIDs.length > 0 && (
        <>
          <h4 className={styles.subHeader}>Parcel(s)</h4>
          <ul>
            {parcelIDs.map((id) => (
              <li key={`parcelID-${id}`}>{id}</li>
            ))}
          </ul>
        </>
      )}

      {permissionName && isZoningEnabled && (
        <>
          <h4 className={styles.subHeader}>Zoning Clearance</h4>
          <p data-testid="zoning-permission" className={styles.content}>
            {permissionName}
          </p>
        </>
      )}

      {zone && (
        <>
          <h4 className={styles.subHeader}>Zoning District</h4>
          <p>{zoningDistrict}</p>
          <p>{zone.description}</p>
          <p>
            <ZoneLinks zone={zone} />
          </p>
        </>
      )}

      {overlays.length > 0 && (
        <>
          <h4 className={styles.subHeader}>Zoning Overlays</h4>
          <p>
            <ul>
              {overlays.map((overlay) => (
                <li key={`overlay-${overlay?.admin_name}`}>
                  {overlay?.admin_name} <ZoneLinks zone={overlay} />
                </li>
              ))}
            </ul>
          </p>
        </>
      )}
    </div>
  );
};

export default ZoningDetails;
