import invalidations from "./invalidations";
import resourceOperations from "./resourceOperations";
import * as R from "ramda";
import { useQueryClient } from "react-query";
import { Invoice } from "types/entities";

const operationsFor = (projectID: number) =>
  resourceOperations({
    basePath: `/api/projects/${projectID}/invoices`,
    baseKey: "invoices",
    dataKey: "invoice",
  });

export const useProjectInvoices = <T = Invoice[]>(
  projectID: number,
  opts = {}
) => operationsFor(projectID).useRead<T>(null, { staleTime: 0, ...opts });

export const useProjectRequirementInvoices = (
  projectID: number,
  requirementID: number,
  { select = R.T, ...opts } = {}
) =>
  useProjectInvoices(projectID, {
    ...opts,
    select: R.filter(
      R.allPass([
        R.where({ requirement_ids: R.includes(Number(requirementID)) }),
        select,
      ])
    ),
  });

export const useProjectInvoice = (projectID: number, id: number, opts = {}) =>
  useProjectInvoices<Invoice | null>(projectID, {
    ...opts,
    select: R.find(R.propEq("id", Number(id))),
  });

export const useUpdateProjectInvoice = (
  projectID: number,
  id: number,
  { onSuccess = R.identity, ...opts } = {}
) => {
  const queryClient = useQueryClient();
  return operationsFor(projectID).useUpdate<Invoice>(id, {
    onSuccess(updatedInvoice) {
      invalidations.projects(queryClient, projectID);
      onSuccess(updatedInvoice);
    },
    ...opts,
  });
};
