import { compose } from "ramda";

import ReduxFormErrorWrapper from "components/utilities/ReduxFormErrorWrapper";
import withFocusToggle from "components/utilities/withFocusToggle";
import withProps from "components/utilities/withProps";

const reshapeProps = ({
  focused,
  focus,
  unfocus,
  input,
  meta: { error, submitFailed, visited },
  placeholder,
  type,
  defaultValue,
  ...props
}) => {
  const { name, onChange, value, onFocus, onBlur } = input || {};

  return {
    value: value || defaultValue,
    onChange,
    onSave: unfocus,
    onBlur: (e) => {
      onBlur(e);
      return unfocus(e);
    },
    onFocus: (e) => {
      onFocus(e);
      return focus(e);
    },
    isFocused: focused,
    error: (visited && !focused) || submitFailed ? error : null,
    password: type === "password",
    field: {
      placeholder,
    },
    name,
    ...props,
  };
};

export default compose(withFocusToggle, withProps(reshapeProps), ReduxFormErrorWrapper);
