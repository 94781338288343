import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import Text from "containers/Text";

import { RadioButtonOption } from "../RadioButtonField";
import styles from "./SearchResults.scss";

export const SearchResults = ({ value, onSelect }) => {
  const results = R.propOr([], "results", value);
  const selectedValue = R.path(["use", "slug"], value);
  const resultsByCategory = R.groupBy(R.prop("category_name"), results);

  return (
    <div className={styles.container}>
      <div id="use-code-search-result-label" className={styles.intro}>
        <Text t="forms.use_search_result_message" numberOfResults={results.length} />
      </div>
      <div
        role="radiogroup"
        aria-labelledby="use-code-search-result-label"
        onFocus={(e) => e.stopPropagation()}
        data-results
        className={styles.categories}
      >
        {R.map(
          (category) => (
            <div className={styles.category} key={category}>
              <div className={styles.categoryName}>{category}</div>
              {R.map(
                (u) => (
                  <div data-use-code-results key={u.full_name}>
                    <RadioButtonOption
                      fieldKey="use_code_search_results"
                      selectOption={{ ...u, code: u.slug, label: u.name }}
                      selectedValue={selectedValue}
                      onChange={() => onSelect(u)}
                      visibleLines={2}
                      asHeading
                    />
                  </div>
                ),
                resultsByCategory[category],
              )}
            </div>
          ),
          R.keys(resultsByCategory),
        )}
      </div>
    </div>
  );
};

SearchResults.propTypes = {
  value: PropTypes.object,
  onSelect: PropTypes.func,
};

export default SearchResults;
