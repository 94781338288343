/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";
import Autocomplete from "react-autocomplete";

import { Button, ButtonBordered, TextButton } from "components/Button";
import Icon from "components/Icon";
import Fields from "components/guides/Fields";
import ZoningMap from "components/maps/ZoningMap";
import { issued as IssuedTextField } from "components/project_form/TextField";
import Text, { useText } from "containers/Text";
import useAbortableEffect from "hooks/useAbortableEffect";
import useDebounce from "hooks/useDebounce";
import useGeocoder from "hooks/useGeocoder";
import useToggleState from "hooks/useToggleState";
import useZoningField from "hooks/useZoningField";
import { isAnswerBlankForField } from "reducers/projects";
import { reportError } from "services/errorReporter";
import { getFullAddress } from "utils/address";
import { isPresent } from "utils/func";
import { addressPropType, errorPropType, fieldPropType } from "utils/sharedPropTypes";

import inlineStyles from "./InlineZonedAddressField.scss";
import styles from "./ZonedAddressField.scss";
import Permission from "./ZonedAddressField/Permission";
import { immediateValueNoSave } from "./behaviors";

const ZonedAddressField = ({
  value,
  field,
  isFocused,
  required,
  error,
  onChange,
  onFocus,
  onBlur,
  hideIcon,
  withShadow,
  onClear: onClearField,
}) => {
  const geocoder = useGeocoder(field);
  const [fullAddress, setMapFullAddress] = useState(getFullAddress(value) || "");
  const icon = !R.isEmpty(fullAddress) ? "check" : "search";
  const [inputText, setInputText] = useState(fullAddress);
  const [suggestionText, setSuggestionText] = useState(inputText);
  const [suggestions, setSuggestions] = useState([]);

  useAbortableEffect(
    async ({ aborted }) => {
      try {
        const suggestions = await geocoder.fetchSuggestions(suggestionText);
        if (aborted) return;
        setSuggestions(R.map((s) => ({ value: s, label: s.text }), suggestions || []));
      } catch (e) {
        reportError(e);
      }
    },
    [suggestionText],
  );

  const setSuggestion = useDebounce(setSuggestionText, 50);

  const onInputChange = useCallback(
    (_, val) => {
      setInputText(val);
      if (val.length > 3) {
        setSuggestion(val);
      }
    },
    [setSuggestion, setInputText],
  );

  const onSuggestionSelected = useCallback(
    (_, { value }) => {
      document.activeElement.blur();

      geocoder
        .suggestionToAddress(value)
        .then((address) => {
          setInputText(address.text);
          onChange({ full_address: address.text, ...R.pick(["latitude", "longitude"], address) });
        })
        .catch(reportError);
    },
    [geocoder, onChange],
  );

  useEffect(() => {
    const address = getFullAddress(value) || "";
    setMapFullAddress(address);
    setInputText(address);
  }, [value]);

  const onClear = useCallback(() => {
    setSuggestions([]);
    setInputText("");
    onClearField();
  }, [onClearField]);

  return (
    <div
      className={classnames(styles.fieldContainer, {
        [styles.withShadow]: withShadow,
      })}
      data-autocomplete
    >
      <Autocomplete
        value={isFocused ? inputText : fullAddress}
        items={suggestions}
        getItemValue={(item) => item.value.text}
        wrapperProps={{
          className: classnames(styles.autocompleteWrapper, { [styles.focused]: isFocused }),
        }}
        inputProps={{
          "aria-label": field.field_label,
          "aria-invalid": !!error,
          "aria-required": !!required,
          "data-zoned-address": true,
          onFocus,
          onBlur,
          className: styles.autocompleteInput,
          placeholder: "Example: 10 Street Name",
          autoComplete: "off",
        }}
        onSelect={onSuggestionSelected}
        onChange={onInputChange}
        renderMenu={(items) => {
          if (R.isEmpty(items)) return <div />;
          return <div className={styles.itemContainer} children={items} />;
        }}
        renderItem={({ label }, isHighlighted) => (
          <div
            data-autocomplete-item
            className={classnames([styles.item, { [styles.highlighted]: isHighlighted }])}
            key={label}
          >
            {label}
          </div>
        )}
      />
      {isFocused && !R.isEmpty(inputText) && (
        <div className={styles.clearButton} onMouseDown={onClear} data-clear-btn>
          <Icon icon="times-circle" />
        </div>
      )}
      {hideIcon ? null : (
        <div className={styles.fieldIconContainer} data-address-icon={icon}>
          <Icon icon={icon} inverse />
        </div>
      )}
    </div>
  );
};
ZonedAddressField.propTypes = {
  value: addressPropType,
  onClear: PropTypes.func.isRequired,
  isFocused: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  withShadow: PropTypes.bool,
  hideIcon: PropTypes.bool,
  error: errorPropType,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func.isRequired,
  field: fieldPropType,
};

const InlineZonedAddressField = ({ field, answerContext, withoutUseCodeConditions = false }) => {
  const {
    value: mapFullScreen,
    setOn: setMapFullScreen,
    setOff: unsetMapFullScreen,
  } = useToggleState(false);

  const isAddressSelected = !isAnswerBlankForField(answerContext.record, field);
  const selectAddressText = useText("fields.zoning.select_address");
  const closeText = useText("close");
  const onClose = useCallback(
    (e) => {
      unsetMapFullScreen();
      e.stopPropagation();
    },
    [unsetMapFullScreen],
  );

  const {
    zoning,
    permission,
    category,
    conditionFields,
    unansweredFieldCount,
    mapType,
    setMapType,
    isInCityBoundary,
    isZoningEnabled,
    onAddressChange,
    onConditionChange,
    onLocationChange,
  } = useZoningField(field, answerContext);

  if (!field) return null;

  const classes = classnames(inlineStyles.combinedAddressField, {
    [inlineStyles.mapFullScreen]: mapFullScreen,
    [inlineStyles.mapNotFullScreen]: !mapFullScreen,
    [inlineStyles.largePreviewMap]: !isAddressSelected,
    [inlineStyles.smallPreviewMap]: isAddressSelected,
    [inlineStyles.closable]: isAddressSelected,
  });

  return (
    <div>
      <div className={classes} data-testid="inline-map-wrapper">
        <div className={inlineStyles.actionsContainer}>
          <TextButton onClick={setMapFullScreen}>
            <Text t="fields.zoning.full_screen" /> <Icon icon="arrows-maximize" />
          </TextButton>
        </div>
        <div className={inlineStyles.addressTextContainer}>
          <Fields fields={[field]} onChange={onAddressChange} hideLabel hideError isNested />
          {mapFullScreen && (
            <div className={inlineStyles.actionContainer}>
              <Button
                data-close-button
                onClick={onClose}
                disabled={!isAddressSelected}
                label={selectAddressText}
              />
              <ButtonBordered onClick={onClose} label={closeText} />
            </div>
          )}
        </div>
        <div className={inlineStyles.mapWrapper}>
          <ZoningMap
            onLocationChange={onLocationChange}
            isFullScreen={mapFullScreen}
            isZoningEnabled={isZoningEnabled}
            addressField={field}
            unansweredFieldCount={unansweredFieldCount}
            zoning={zoning}
            mapType={mapType}
            setMapType={setMapType}
            hidePopup
          />
        </div>
        <div
          className={classnames(inlineStyles.close, {
            [inlineStyles.hidden]: !isAddressSelected || !mapFullScreen,
          })}
        >
          <Button
            onClick={(e) => {
              unsetMapFullScreen();
              e.stopPropagation();
            }}
            label={selectAddressText}
          />
        </div>
      </div>

      <div className={inlineStyles.detailsContainer}>
        <Permission
          unansweredFieldCount={unansweredFieldCount}
          permission={permission}
          category={category}
          isZoningEnabled={isZoningEnabled}
          withoutUnansweredQuestions={withoutUseCodeConditions}
        />
        {!isInCityBoundary && (
          <div className={inlineStyles.cityBoundary}>
            <Text t="guides.location.not_in_city_error" />
          </div>
        )}
        {isPresent(conditionFields) && !withoutUseCodeConditions && (
          <Fields fields={conditionFields} onChange={onConditionChange} />
        )}
      </div>
    </div>
  );
};
InlineZonedAddressField.propTypes = {
  field: fieldPropType,
};

export const inline = InlineZonedAddressField;

const DisabledZonedAddressField = ({ value, className }) => (
  <div className={styles.disabledContainer}>
    <Icon icon="map-marker-alt" size="lg" faStyle="regular" />
    <span className={className}>{R.prop("full_address", value)}</span>
  </div>
);
export const disabled = DisabledZonedAddressField;

export const IssuedZonedAddressField = ({ value }) => (
  <IssuedTextField value={R.prop("full_address", value)} />
);
IssuedZonedAddressField.propTypes = {
  value: addressPropType,
};
export const issued = IssuedZonedAddressField;

export default immediateValueNoSave(ZonedAddressField);
