import * as R from "ramda";

const emptyState = {};

export default (state = emptyState, { type, _payload }) => {
  switch (type) {
    default:
      return state;
  }
};

export const selectVaultAddr = R.path(["vault", "endpoint"]);
