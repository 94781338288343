import React, { useCallback } from "react";
import * as R from "ramda";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { ApplicantField, TextInput } from "components/forms/HookFields";
import { Form, FormRow } from "components/forms/Layout";
import ErrorMessage from "components/project_form/ErrorMessage";
import { useCreateTransaction } from "queries/transactions";
import { defineFields } from "utils/formDefinitions";

import styles from "./IntegratedPayment.scss";
import PaymentLayout from "./PaymentLayout";
import Section from "./Section";
import useOnNext from "./useOnNext";

const fields = defineFields({
  first_name: {
    labelKey: "projects.requirement.payment.credit_card.first_name",
    validate: { required: "First name is required" },
    Component: TextInput,
  },
  last_name: {
    labelKey: "projects.requirement.payment.credit_card.last_name",
    validate: { required: "Last name is required" },
    Component: TextInput,
  },
  address_line1: {
    labelKey: "projects.requirement.payment.credit_card.address_1",
    validate: { required: "Address is required" },
    Component: TextInput,
  },
  address_city: {
    labelKey: "projects.requirement.payment.credit_card.city",
    validate: { required: "City is required" },
    Component: TextInput,
  },
  address_state: {
    labelKey: "projects.requirement.payment.credit_card.state",
    validate: { required: "State is required" },
    Component: TextInput,
  },
  address_zip: {
    labelKey: "projects.requirement.payment.credit_card.zip",
    validate: { required: "Zipcode is required" },
    Component: TextInput,
  },
});

const IntegratedPayment = ({ createToken, paymentType, setPaymentType, processor, children }) => {
  const formMethods = useForm({ mode: "onChange" });
  const { projectID, invoiceID } = useParams();
  const { mutateAsync } = useCreateTransaction(projectID, invoiceID);

  const { trigger, setError, formState, clearErrors, handleSubmit, setValue } = formMethods;
  const errorMessage = R.pathOr(null, ["errors", "payment", "message"], formState);

  const makePayment = useCallback(async () => {
    try {
      clearErrors("payment");
      const trig = await trigger();
      if (!trig) {
        return false;
      }

      const token = await createToken(formMethods);
      setValue("token", token);

      await handleSubmit(mutateAsync)();

      return true;
    } catch (err) {
      setError("payment", {
        type: "custom",
        message: R.pathOr("Payment failed", ["response", "data", "_error"], err),
      });

      return false;
    }
  }, [
    clearErrors,
    trigger,
    setValue,
    handleSubmit,
    setError,
    createToken,
    formMethods,
    mutateAsync,
  ]);

  const { onNext, isLoading } = useOnNext({ paymentType, afterAction: makePayment });

  return (
    <PaymentLayout
      paymentType={paymentType}
      setPaymentType={setPaymentType}
      onNext={onNext}
      isLoading={isLoading}
    >
      <FormProvider {...formMethods}>
        <Section headerTextKey="projects.requirement.payment.credit_card.header">
          <Form>
            <FormRow>
              <ApplicantField definition={fields.first_name} />
              <ApplicantField definition={fields.last_name} />
            </FormRow>
            <ApplicantField definition={fields.address_line1} />
            <FormRow>
              <ApplicantField definition={fields.address_city} />
              <ApplicantField definition={fields.address_state} />
              <ApplicantField definition={fields.address_zip} />
            </FormRow>
            <div className={styles.creditCardContainer} data-processor={processor}>
              {children}
              {errorMessage && (
                <div className={styles.error}>
                  <ErrorMessage error={errorMessage} />
                </div>
              )}
            </div>
          </Form>
        </Section>
      </FormProvider>
    </PaymentLayout>
  );
};

export default IntegratedPayment;
