import React from "react";
import classnames from "classnames";
import * as R from "ramda";

import SendMessage from "./SendMessage";
import styles from "./SendMessageFooter.scss";

const SendMessageFooter = ({ adminView, afterSubmit = R.T, showHeading = false }) => (
  <div className={classnames(styles.footerContainer, { [styles.adminFooterContainer]: adminView })}>
    <div className={styles.footerContent}>
      <SendMessage afterSubmit={afterSubmit} adminView={adminView} showHeading={showHeading} />
    </div>
  </div>
);

export default SendMessageFooter;
