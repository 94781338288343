import { isValidISODateString } from "iso-datestring-validator";
import * as R from "ramda";

import dayjs from "utils/dayjs";

import { isBlank, isPresent } from "./func";

// eslint-disable-next-line no-invalid-regexp, prefer-regex-literals
const iso8601TimestampWithZonePattern = new RegExp(
  `T[0-9]{2}              # hours
    (:?[0-9]{2}           # mins
      (:?[0-9]{2}         # sec
        (\\.[0-9]{3})?)?)? # ms
    ([+-][0-9]{2}         # offset hours
      (:?[0-9]{2})?       # offset mins
    |Z                    # UTC flag
    )$`,
  "x",
);
const isIsoTimestampWithZone = R.both(
  isValidISODateString,
  R.test(iso8601TimestampWithZonePattern),
);

// parse time like Moment.js does; if value is blank-ish, returns an object representing now
export const parseTime = (v) => {
  try {
    if (dayjs.isDayjs(v)) return v.tz();
    // handle odd blank-ish values which Day.js is less forgiving of
    if (isBlank(v)) return dayjs().tz();
    if (!R.is(String)) return dayjs.tz(v).tz();
    const djs = dayjs(v);
    if (!djs.isValid()) return djs; // check validity without using .tz() as that plugin throws on parsing errors instead of returning an object marked invalid
    if (isIsoTimestampWithZone(v)) return djs.tz(); // when Zone present, parse without setting the zone first
    return dayjs.tz(v).tz(); // otherwise set the default TZ prior to parsing
  } catch (e) {
    e.inputValue = v;
    throw e;
  }
};

// parse time like moment.js, except if value isBlank return null
export const maybeParseTime = R.ifElse(isPresent, parseTime, R.always(null));

export const now = () => dayjs().tz();
export const startOfDay = () => now().startOf("day");

const formatDate = R.curry((formatStr, date) =>
  R.when(
    R.identity,
    R.pipe(
      parseTime,
      // R.invoker(0, "tz"), // TODO: without invoking tz(), this outputs browser-local time
      R.invoker(1, "format")(formatStr),
    ),
  )(date),
);
const dateFormats = {
  param: "YYYY-MM-DD",
  humanWithTime: "MMM D, YYYY, h:mma",
  longDay: "dddd, MMM D, YYYY",
  longDayNoYear: "dddd, MMM D",
  shortDateFdSlash: "MM/DD/YYYY",
  shortDate: "MMM D, YYYY",
  dateAsDayFullMonth: "MMMM D, YYYY",
  time: "h:mm a",
};
export const formatDateParam = formatDate(dateFormats.param);
export const formatDateString = formatDate(dateFormats.humanWithTime);
export const formatShortDateString = formatDate(dateFormats.shortDate);
export const formatShortDateFdSlash = formatDate(dateFormats.shortDateFdSlash);
export const formatDateStringAsDay = formatDate(dateFormats.shortDate);
export const formatDateStringAsDayFullMonth = formatDate(dateFormats.dateAsDayFullMonth);
export const formatDateStringAsTime = formatDate(dateFormats.time);
export const formatAsLongDay = formatDate(dateFormats.longDay);
export const formatAsLongDayNoYear = formatDate(dateFormats.longDayNoYear);
export const formatDateStringAsLongDay = formatAsLongDay;
export const formatTime = formatDateStringAsTime;
export const formatMomentCompact = formatDate(dateFormats.shortDateFdSlash);
export const formatEventTime = formatDate("MM/DD/YYYY h:mma");

export const getDueDate = (eventStart, daysToSubmit) =>
  parseTime(eventStart).subtract(daysToSubmit, "days").startOf("day");

export const groupByDay = (prop, list) =>
  R.pipe(
    R.groupBy((e) => R.prop(prop, e).startOf("day")),
    R.toPairs,
    R.sortBy(([day, _]) => parseTime(day)),
    R.reverse,
  )(list);
