import React from "react";

import { useCurrentTenantInfo } from "contexts/currentTenant";
import inboxImage from "images/inbox.svg";

import styles from "./NoMessages.scss";

const NoMessages = ({ admin, message }) => {
  const tenant = useCurrentTenantInfo();
  message ||= admin
    ? "To send a message to the applicant for this project, use the form below."
    : "To send a message to staff, use the form below.";

  return (
    <div className={styles.noMessages}>
      <img src={inboxImage} alt="Under construction" />
      <h3>This project has no messages yet.</h3>
      {tenant.messaging_enabled && <div>{message}</div>}
    </div>
  );
};

export default NoMessages;
