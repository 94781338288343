import * as R from "ramda";

export default class Address {
  constructor(lat, lon, text) {
    this.latitude = lat;
    this.longitude = lon;
    this.text = text;
  }

  isComplete() {
    R.none(R.isEmpty, ["latitude", "longitude", "text"]);
  }
}
