import React from "react";
import classNames from "classnames";

import { childrenPropType } from "utils/sharedPropTypes";

import styles from "./FooterShell.scss";

const FooterShell = ({ className, children }) => (
  <div className={styles.box}>
    <div className={classNames(styles.footer, className)}>{children}</div>
  </div>
);

FooterShell.propTypes = {
  children: childrenPropType,
};

export default FooterShell;
