import * as R from "ramda";
import { connect } from "react-redux";

import EventDetails from "components/guides/summary/EventDetails";
import { withAnswerContextConsumer } from "containers/withAnswerContext";
import { selectFieldBySlug, selectPrimaryFieldByType } from "reducers/fields";
import { filterUnansweredFields } from "reducers/projects";

const mapStateToProps = (state, { answerContext: { record: project } }) => {
  const routesField = selectPrimaryFieldByType(state, "event_route");
  const locationsField = selectPrimaryFieldByType(state, "event_location");
  const geomsField = selectFieldBySlug(state, "event-geoms");
  const addressesField = selectFieldBySlug(state, "event-addresses");

  const answeredPositionFields = filterUnansweredFields(project, [
    routesField,
    locationsField,
    geomsField,
    addressesField,
  ]);

  return {
    answeredPositionFields,
    timesField: selectPrimaryFieldByType(state, "event_time"),
  };
};

export default R.compose(withAnswerContextConsumer, connect(mapStateToProps))(EventDetails);
