import React from "react";
import * as R from "ramda";
import { Popup } from "react-map-gl";

import Text, { useText } from "containers/Text";
import {
  getBaseZone,
  getEffectiveZone,
  getPermission,
  getZonePermissionCategory,
} from "utils/zoning";

import "mapbox-gl/dist/mapbox-gl.css";
import styles from "./MapPopup.scss";
import PermissionBubble from "./PermissionBubble";

export const Content = ({ zoning, isInCityBoundary = true, unansweredFieldCount = 0 }) => {
  const effectiveZone = getEffectiveZone(zoning);
  const category = getZonePermissionCategory(effectiveZone) || "prohibited";
  const translatedCategory = useText(`guides.location.${category}`);

  const permission = getPermission(zoning);
  if (!isInCityBoundary)
    return (
      <div className={styles.content}>
        <Text t="guides.location.not_in_city_error" />
      </div>
    );

  const zone = getBaseZone(zoning);
  if (zone && unansweredFieldCount > 0)
    return (
      <div className={styles.content}>
        <Text
          t="guides.location.unanswered_questions"
          category={translatedCategory}
          unansweredFieldCount={unansweredFieldCount}
        />
      </div>
    );

  if (permission && !R.isEmpty(permission))
    return <PermissionBubble category={category} baseZone={zone} effectiveZone={effectiveZone} />;

  return (
    <div className={styles.content}>
      <Text t="guides.location.not_in_zone_error" />
    </div>
  );
};

const MapPopup = (props) => {
  const { address } = props;
  if (!address || !address.latitude) return null;

  return (
    <div
      className={styles.popup}
      ref={(e) => {
        if (e && e.addEventListener) {
          e.addEventListener("mousedown", (ev) => ev.stopPropagation());
          e.addEventListener("touchdown", (ev) => ev.stopPropagation());
          e.addEventListener("dblclick", (ev) => ev.stopPropagation());
          e.addEventListener("pointerdown", (ev) => ev.stopPropagation());
        }
      }}
    >
      <Popup
        dynamicPosition={false}
        latitude={address.latitude}
        longitude={address.longitude}
        closeButton={false}
        closeOnClick={false}
        anchor="bottom"
      >
        <Content {...props} />
      </Popup>
    </div>
  );
};

export default MapPopup;
