import React from "react";
import * as R from "ramda";

import { formatTime } from "utils/time";

import applicationStyles from "./ApplicationLogEntry.scss";
import MaybeApplicationLink from "./MaybeApplicationLink";
import EventLogPropTypes from "./propTypes";
import sharedStyles from "./sharedStyles.scss";

const NoteAdded = ({ Layout, event, applicantContext, applicationView, timestamp, context }) => {
  const by = R.path(["payload", "data", "by"], event);
  const note = R.path(["payload", "data", "note"], event);

  const additionalContent = applicationView ? (
    <>
      <span className={applicationStyles.note}>{note}</span>
      <span className={applicationStyles.timestamp}>{formatTime(event.timestamp)}</span>
    </>
  ) : (
    <div className={sharedStyles.noteContent} data-note-content>
      {note}
    </div>
  );

  return (
    <Layout
      icon="sticky-note"
      containerStyles={[
        sharedStyles.warning,
        {
          [sharedStyles.colorText]: context === "handoff",
        },
      ]}
      additionalContent={additionalContent}
    >
      {applicantContext || applicationView ? (
        <>Note added {applicantContext ? " by staff" : by && ` by ${by}`}</>
      ) : (
        <>
          <MaybeApplicationLink event={event} /> application: Note added {by && ` by ${by}`}
          {timestamp}
        </>
      )}
    </Layout>
  );
};
NoteAdded.propTypes = EventLogPropTypes;

export default NoteAdded;
