import * as R from "ramda";
import { SubmissionError } from "redux-form";
import { call, cancelled, put } from "redux-saga/effects";
import { v4 as uuid } from "uuid";

import {
  networkRequestCancelled,
  networkRequestError,
  networkRequestStart,
  networkRequestSuccess,
} from "actions";
import NetworkError from "utils/NetworkError";

export default function* apiCall({ returnNetworkErrors = true, ...netPayload }, fn, ...args) {
  let response;
  const requestID = uuid();
  const payload = R.assoc("requestID", requestID)(netPayload);

  try {
    yield put(networkRequestStart(payload)); // still used by many legacy things to show loaders
    response = yield call(fn, ...args);
    yield put(networkRequestSuccess(payload));

    return response;
  } catch (error) {
    if (error instanceof SubmissionError) throw error; // submission errors are only for validation messaging

    const { url, status: errorCode } = R.propOr({}, "response", error);
    const errorAttrs = {
      ...payload,
      errorCode,
      url,
      ...error.props,
    };
    yield put(networkRequestError(errorAttrs)); // still used, only for blocking saga progression while awaiting session refetch (the refetch itself happens by )

    // we return the error here to avoid verbose boilerplate code / complexity at each call site (try/catch filter out error type, rethrow all other errors, etc).
    if (error instanceof NetworkError && returnNetworkErrors) return error;

    throw error;
  } finally {
    if (yield cancelled()) {
      yield put(networkRequestCancelled(payload));
    }
  }
}
