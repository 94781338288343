import React, { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";

import ExternalLink from "components/ExternalLink";
import Icon from "components/Icon";
import Loader from "components/Loader";
import { ButtonDiv } from "components/accessibility/Div";
import { immediateValue } from "components/project_form/behaviors";
import Text from "containers/Text";
import routes from "utils/routes";

import styles from "./FileField.scss";

const formData = (as, file, fields = {}) => {
  const data = new FormData();
  Object.keys(fields).forEach((key) => data.append(key, fields[key]));
  data.append(as, file);
  return data;
};

const FileField = ({ field, value, error, required, onClear, onChange, clearError, setError }) => {
  // TODO: answerContext should handle loading state
  const [isLoading, setIsLoading] = useState(false);
  const fileInput = useRef();
  const selectFile = useCallback(() => fileInput.current.click(), []);
  const onAttach = useCallback(
    async (change) => {
      change.persist();
      clearError();
      const file = change.target.files[0];
      if (file.size > 2e7) {
        setError("File is too large. Please upload a file smaller than 20 megabytes.");
      }
      setIsLoading(true);

      const presignResponse = await fetch(`/attachments/store/presign?t=${new Date().getTime()}`);
      const presignData = await presignResponse.json();
      await fetch(presignData.url, {
        method: "POST",
        body: formData(presignData.as, file, presignData.fields),
      });
      setIsLoading(false);

      onChange({
        id: presignData.id,
        filename: file.name,
        content_type: file.type,
        size: file.size,
      });
    },
    [onChange, clearError, setError],
  );

  const elementID = `file-upload-${field.id}`;
  const allowRemoval = !field.is_list;
  if (value && value.filename)
    return <DisabledFileField value={value} onClear={onClear} allowRemoval={allowRemoval} />;

  if (isLoading)
    return (
      <span className={styles.loading}>
        <Loader />
      </span>
    );

  return (
    <div>
      <label className={styles.inputContainer}>
        <ButtonDiv
          className={styles.button}
          aria-invalid={!!error}
          aria-required={!!required}
          onClick={selectFile}
        >
          <Text t="choose_file" />
        </ButtonDiv>
        {!value && (
          <span className={styles.noFileSelected}>
            <Text t="no_file_selected" />
          </span>
        )}
      </label>
      <input
        type="file"
        id={elementID}
        ref={fileInput}
        className={styles.input}
        onChange={onAttach}
      />
    </div>
  );
};

const Unanswered = () => (
  <div className={styles.disabled}>
    <span className={styles.unanswered}>
      {" "}
      <Text t="forms.not_provided" />{" "}
    </span>
  </div>
);

const DisabledFileField = ({ value, onClear, allowRemoval = false }) => {
  if (!value) return <Unanswered />;

  return (
    <div className={styles.disabled}>
      <div className={styles.actionContainer}>
        <ExternalLink href={value.url} className={styles.download}>
          <Icon icon="file" />
          <span data-file-name>{value.filename}</span>
        </ExternalLink>
        {allowRemoval && onClear && (
          <ButtonDiv data-remove-file className={styles.removeFile} onClick={onClear}>
            <Text t="remove_file" />
          </ButtonDiv>
        )}
      </div>
    </div>
  );
};
export const disabled = DisabledFileField;

export const IssuedFileField = ({ value, answerContext: { record } }) => {
  if (!value) return <Unanswered />;

  return (
    <div className={styles.disabled}>
      <div className={styles.actionContainer}>
        <Link to={routes.projectAttachments(record.id)}>
          <Icon icon="file" />
          <span data-file-name>{value.filename}</span>
        </Link>
      </div>
    </div>
  );
};
export const issued = IssuedFileField;

export default immediateValue(FileField);
