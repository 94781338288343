import * as ActiveStorage from "@rails/activestorage";

const upload = (file) => {
  const directUpload = new ActiveStorage.DirectUpload(file, "/rails/active_storage/direct_uploads");
  const promise = new Promise((resolve, reject) => {
    directUpload.create((error, attributes) => {
      if (error) {
        reject(error);
        return;
      }
      resolve(attributes.signed_id);
    });
  });
  return promise;
};

export default upload;
