import React from "react";
import * as R from "ramda";

import { useServerVariables } from "contexts/serverVariables";
import { hoc } from "utils/hoc";
import objectToQueryString from "utils/objectToQueryString";

const WithGoogleMapsUrl = ({ Component, params, ...props }) => {
  const { googleMapsKey } = useServerVariables();
  const paramsWithDefaults = R.mergeLeft(params, {
    v: "3.exp",
    libraries: "geometry,drawing",
    key: googleMapsKey,
  });
  const url = `https://maps.googleapis.com/maps/api/js?${objectToQueryString(paramsWithDefaults)}`;
  return <Component {...props} googleMapURL={url} />;
};
export const withGoogleMapsUrl = (params) => hoc(WithGoogleMapsUrl, { params });
