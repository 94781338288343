import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Route, Routes } from "react-router-dom-v5-compat";

import { ButtonBordered, SubmitButton } from "components/Button";
import * as Inputs from "components/forms/BareFields";
import { ApplicantLabeledInput, Form, FormActions, FormRow } from "components/forms/Layout";
import InlineModal from "components/shared/InlineModal";
import Text, { useText } from "containers/Text";
import { useAuthnState } from "contexts/auth";
import { useAuthn } from "queries/auth";
import { errorHandler, swallowErrors } from "utils/form";
import { markLoginSkipped } from "utils/sessionStorage";

const LoginFactorEmail = ({ onClose, onNext }) => {
  const { email, setEmail } = useAuthnState();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: { email },
  });

  const { mutateAsync } = useAuthn({
    onMutate({ email }) {
      setEmail(email);
    },
    onSuccess: onNext,
    onError: errorHandler(setError),
  });

  const isHandoff = useLocation().pathname.match("^/projects/*");
  const onSkip = useCallback(() => {
    if (isHandoff) markLoginSkipped();
    onClose();
  }, [isHandoff, onClose]);

  return (
    <InlineModal
      onClose={onSkip}
      heading={useText(isHandoff ? "projects.handoff.save_your_project" : "pages.login.banner")}
      subheading={useText(
        isHandoff ? "projects.handoff.enter_your_email" : "pages.login.subheader",
      )}
    >
      <Form onSubmit={handleSubmit(swallowErrors(mutateAsync))} name="authn-init" data-login-form>
        <FormRow>
          <ApplicantLabeledInput label={<Text t="pages.login.email" />} error={errors?.email}>
            <Inputs.Text
              register={register("email", { required: true })}
              type="email"
              autoComplete="username"
              placeholder="email@example.com"
            />
          </ApplicantLabeledInput>
        </FormRow>

        <FormActions>
          <div>
            <SubmitButton allowSubmitNonDirty data-login formControl={control}>
              <Routes>
                <Route path="/projects/*" element={<Text t="pages.login.sign_in_or" />} />
                <Route path="*" element={<Text t="pages.login.next" />} />
              </Routes>
            </SubmitButton>
            {isHandoff && (
              <ButtonBordered onClick={onSkip} label={<Text t="helpers.links.skip_for_now" />} />
            )}
          </div>
        </FormActions>
      </Form>
    </InlineModal>
  );
};

LoginFactorEmail.propTypes = {};

export default LoginFactorEmail;
