import * as R from "ramda";

import { useEntityById, useRequirementsList } from "queries/entities";

/**
 * Get requirements available to a record based on it's guide
 * @return {Array} - Array of requirements (with minimal attributes)
 */
const useAvailableRequirements = ({ guide_id: guideID }) => {
  const { data: guide } = useEntityById("Guide", guideID, { enabled: !!guideID });
  const requirementIDs = guide ? guide.requirement_ids : [];

  const { data: requirements = [] } = useRequirementsList({
    select: R.filter(R.propSatisfies((id) => requirementIDs.includes(id), "id")),
    enabled: requirementIDs.length > 0,
  });

  return requirements;
};

export default useAvailableRequirements;
