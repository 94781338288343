import React from "react";
import classnames from "classnames";

import MailToLink from "components/MailToLink";
import UnansweredField from "components/project_form/UnansweredField";
import errorStyles from "sharedStyles/errors.scss";

import Icon from "../Icon";
import DidYouMean from "../utilities/DidYouMean";
import styles from "./EmailField.scss";
import { DisabledTextField } from "./TextField";
import { textInput } from "./behaviors";

export const DisabledEmailField = ({ value }) =>
  value ? <DisabledTextField value={<MailToLink email={value} />} /> : <UnansweredField />;

export const disabled = DisabledEmailField;

const EmailField = ({ value, onChange, onBlur, error, required }) => {
  const onAcceptSuggestion = (suggestion) => onChange({ target: { value: suggestion } });

  return (
    <div className={styles.container}>
      <div className={styles.inputWithError}>
        <input
          className={classnames({
            [errorStyles.errorBorder]: error,
          })}
          type="email"
          value={value || ""}
          placeholder="username@email.com"
          onChange={onChange}
          onBlur={onBlur}
          aria-required={!!required}
          aria-invalid={!!error}
        />
        {error ? <Icon icon="exclamation" /> : null}
      </div>
      <DidYouMean onClick={onAcceptSuggestion} email={value} className={styles.didYouMean} />
    </div>
  );
};

export default textInput(EmailField);
