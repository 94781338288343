import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import * as R from "ramda";

import useComponent from "hooks/useComponent";

import { useCurrentTenantInfo } from "./currentTenant";

const HotKeyContext = React.createContext();

const hotKeysReducer = (state, action) => {
  switch (action.type) {
    case "add":
      return [...state, action.hotkey];
    case "remove":
      return R.without([action.hotkey], state);
    default:
      throw new Error();
  }
};

const defaultGotoItems = ({ slug }) => [
  { name: "Versions", path: "/config/versions", section: "Config" },
  { name: "Tenant", path: `/config/tenants/${slug}/edit`, section: "Config" },
  { name: "Requirements", path: "/config/requirements", section: "Config" },
  { name: "Fields", path: "/config/fields", section: "Config" },
  { name: "fees", path: "/config/fees", section: "Config", key: "s" },
  { name: "Applications", path: "/admin/applications", section: "Admin" },
  { name: "Projects", path: "/admin/projects", section: "Admin" },
  {
    name: "Home",
    path: "/?version=HEAD",
    section: "Applicant",
  },
];

const processGotoItem = (item) => {
  const key = item.key || item.name.charAt(0).toLowerCase();
  const displayName = item.key ? item.name : item.name.slice(1);
  return { ...item, key, displayName };
};

const gotoItemsReducer = (state, action) => {
  switch (action.type) {
    case "add":
      return [...state, processGotoItem(action.item)];
    case "remove":
      return state.filter((item) => item.name !== action.name);
    case "init":
      return [...state, ...action.items.map(processGotoItem)];
    default:
      throw new Error();
  }
};

const HotKeysProvider = ({ children }) => {
  const [enabled, setEnabled] = useState(true);
  const [hotkeys, dispatch] = useReducer(hotKeysReducer, []);
  const tenant = useCurrentTenantInfo();
  const [gotoItems, dispatchGotoItems] = useReducer(gotoItemsReducer, []);

  useEffect(() => {
    if (tenant.slug) {
      const initialGotoItems = defaultGotoItems({ slug: tenant.slug });
      dispatchGotoItems({ type: "init", items: initialGotoItems });
    }
  }, [tenant.slug]);

  const [Overlay, setOverlay, clearOverlay] = useComponent();
  const [Container, setContainer, clearContainer] = useComponent();
  const close = useCallback(() => {
    clearOverlay();
    clearContainer();
    setEnabled(true);
  }, [clearContainer, clearOverlay]);

  const register = useCallback((hotkey) => dispatch({ type: "add", hotkey }), [dispatch]);
  const unregister = useCallback((hotkey) => dispatch({ type: "remove", hotkey }), [dispatch]);

  const addGotoItem = useCallback((item) => {
    dispatchGotoItems({ type: "add", item });
  }, []);

  const removeGotoItem = useCallback((name) => {
    dispatchGotoItems({ type: "remove", name });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setOverlayAndDisable = useCallback((overlay) => {
    setOverlay(overlay);
    setEnabled(false);
  });

  const value = useMemo(
    () => ({
      setOverlay: setOverlayAndDisable,
      setContainer,
      register,
      unregister,
      gotoItems,
      addGotoItem,
      removeGotoItem,
      enabled,
      setEnabled,
      close,
    }),
    [
      setOverlayAndDisable,
      setContainer,
      register,
      unregister,
      gotoItems,
      addGotoItem,
      removeGotoItem,
      enabled,
      setEnabled,
      close,
    ],
  );

  return (
    <HotKeyContext.Provider value={value}>
      <Container close={close}>
        <Overlay close={close} hotkeys={hotkeys} />
        {children}
      </Container>
    </HotKeyContext.Provider>
  );
};

export default HotKeysProvider;
export const useHotKeyContext = () => useContext(HotKeyContext);
export const useGotoItem = (name, { path, key, section, enabled = true }) => {
  const { addGotoItem, removeGotoItem } = useHotKeyContext();

  /* eslint-disable-next-line consistent-return */
  useEffect(() => {
    if (enabled) {
      addGotoItem({ name, path, key, section });
      return () => removeGotoItem(name);
    }
  }, [name, enabled, path, key, section, addGotoItem, removeGotoItem]);
};
