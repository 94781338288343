import React, { useEffect } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Truncate from "react-truncate";

import Text from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import { selectUseCode } from "reducers/projects";
import analytics from "services/analytics";
import { ZONING_LOOKUP_CALCULATED } from "services/tracking";
import { getName } from "utils/useCode";
import { getZoneZoningDistrict } from "utils/zoning";

import styles from "./MapPopup.scss";

const useTracking = (permissionCategory, baseZone) => {
  const { record } = useAnswerContext();
  const zoningDistrict = getZoneZoningDistrict(baseZone);
  const useCode = useSelector((state) => getName(selectUseCode(state, record)));

  useEffect(() => {
    analytics.track(ZONING_LOOKUP_CALCULATED, {
      permissionCategory,
      zoningDistrict,
      useCode,
    });
  }, [permissionCategory, zoningDistrict, useCode]);
};

const PermissionBubble = ({ category, baseZone, effectiveZone }) => {
  useTracking(category, baseZone);

  const displayName = R.path(["permission", "display_name"], effectiveZone) || category;
  const permissionDescription = R.path(["permission", "description"], effectiveZone) || "";

  return (
    <div>
      <header className={classnames(styles.header, styles[category])}>{displayName}</header>
      <div className={styles.content}>
        <div>
          <Truncate lines={5}>{permissionDescription}</Truncate>
        </div>
        <div className={styles.learnMoreContainer}>
          <Link data-learn-more to="location/zoning-details">
            <Text t="guides.location.learn_more" />
          </Link>
        </div>
      </div>
    </div>
  );
};

PermissionBubble.propTypes = {
  category: PropTypes.string.isRequired,
};

export default PermissionBubble;
