import React, { useCallback } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom-v5-compat";

import { TextButton } from "components/Button";
import Icon from "components/Icon";
import Text from "containers/Text";
import { childrenPropType } from "utils/sharedPropTypes";
import { administrativeBackgroundColor, modalBackgroundColor } from "utils/sharedStyles";

import styles from "./LegacyModal.scss";
import { ButtonDiv } from "./accessibility/Div";

const CancelButtonDefault = ({ onClick }) => (
  <TextButton onClick={onClick}>
    <Text t="cancel" />
  </TextButton>
);

export const useCloseModalBehavior = ({ returnTo, onClose } = {}) => {
  const navigate = useNavigate();
  return useCallback(() => {
    if (onClose) {
      onClose();
      return;
    }
    navigate(returnTo || -1); // default is to "go back"
  }, [navigate, onClose, returnTo]);
};

const LegacyModal = ({
  children,
  returnTo,
  backgroundColor = modalBackgroundColor,
  closeColor = administrativeBackgroundColor,
  showCancel,
  hideClose,
  onClose,
  fullWidth,
  fullHeight,
  contentClassName,
  childrenClassName,
  CancelButton = CancelButtonDefault,
  nonCovering = false,
  ...htmlProps
}) => {
  const closeModal = useCloseModalBehavior({ onClose, returnTo });
  const style = backgroundColor ? { backgroundColor } : {};
  const cancel = showCancel ? <CancelButton onClick={closeModal} /> : null;

  return (
    <main
      role="main"
      className={classnames(styles.container, {
        [styles.nonCovering]: nonCovering,
      })}
      style={style}
      {...htmlProps}
    >
      <div
        className={classnames(
          styles.content,
          { [styles.fullWidth]: fullWidth, [styles.fullHeight]: fullHeight },
          contentClassName,
        )}
      >
        {!hideClose && <ModalCloseButton onClick={closeModal} color={closeColor} />}
        <div className={classnames(styles.children, childrenClassName)}>{children}</div>
        {cancel && <div className={styles.footer}>{cancel}</div>}
      </div>
    </main>
  );
};
LegacyModal.propTypes = {
  returnTo: PropTypes.string,
  children: childrenPropType.isRequired,
  backgroundColor: PropTypes.string,
  showCancel: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hideClose: PropTypes.bool,
  onClose: PropTypes.func,
  closeColor: PropTypes.string,
  nonCovering: PropTypes.bool,
  CancelButton: PropTypes.func,
};

export const ModalCloseButton = ({ onClick, color }) => {
  const style = color ? { color } : {};
  return (
    <ButtonDiv onClick={onClick} style={style} className={styles.close} data-close-modal>
      <Text t="close" />
      <Icon icon="times" size="lg" />
    </ButtonDiv>
  );
};

ModalCloseButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
};

export default LegacyModal;
