import React from "react";
import * as R from "ramda";

import MaybeApplicationLink from "./MaybeApplicationLink";
import EventLogPropTypes from "./propTypes";
import sharedStyles from "./sharedStyles.scss";

const ApplicationRevoked = ({
  Layout,
  event,
  timestamp,
  applicantContext,
  applicationView,
  context,
}) => {
  const note = R.path(["payload", "data", "note"], event);

  return (
    <Layout
      icon="exclamation-triangle"
      containerStyles={[
        sharedStyles.error,
        {
          [sharedStyles.colorText]: context === "handoff",
        },
      ]}
    >
      {applicantContext || applicationView ? (
        <>Revoked{context !== "handoff" && `: ${note}`}</>
      ) : (
        <>
          <MaybeApplicationLink event={event} /> requirement revoked: {note}
        </>
      )}
      {timestamp}
    </Layout>
  );
};
ApplicationRevoked.propTypes = EventLogPropTypes;

export default ApplicationRevoked;
