import resourceOperations from "../resourceOperations";
import * as R from "ramda";
import { User } from "types/adminEntities";

const operation = resourceOperations({
  basePath: "/api/admin/users",
  baseKey: "users",
  versioned: false,
});

export const useUsers = (opts = {}) =>
  operation.useRead<User[]>(null, opts, R.values);
