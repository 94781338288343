export const administrativeBackgroundColor = "#f6f6f6";
export const opencounterGreen = "#229145";
export const adminEmphasis = opencounterGreen;
export const legalBackgroundColor = "#fbfbfb";
export const tableHeaderColor = "#dbd5b5";
export const modalBackgroundColor = "#434343";

export const darkText = "#333";
export const permitted = "#6db67d";
export const permittedFg = opencounterGreen;
export const conditional = "#f0ce8a";
export const conditionalFg = "#c58900";
export const prohibited = "#d2283e";
export const prohibitedFg = "#cc3430";
export const other = "#f0ce8a";

export default {
  opencounterGreen,
  administrativeBackgroundColor,
  adminEmphasis,
  legalBackgroundColor,
  tableHeaderColor,
  modalBackgroundColor,
  darkText,
  permitted,
  conditional,
  prohibited,
  other,
};
