import React, { useContext } from "react";

export const FeatureFlagContext = React.createContext({});

export const FeatureFlagProvider = ({ children, value }) => {
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = {
    ...value,
  };
  return <FeatureFlagContext.Provider value={contextValue}>{children}</FeatureFlagContext.Provider>;
};

export const useFeatureFlags = () => useContext(FeatureFlagContext);

export const useIsLoginEnabled = () => useFeatureFlags().login;
