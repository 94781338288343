import { useEffect, useState } from "react";
import * as R from "ramda";
import { useWatch } from "react-hook-form";

import { indexedAll, mapKeys } from "utils/func";

/**
 * For use in react-hook-forms to suppress components if field input values aren't met
 * @param {object} dependencies - Keys are the form field names, values are literals or functions that take the value
 * @param {string} baseName - The base name to add on to the dep names (for use w/ batch and arrays)
 * @return {boolean} - True unless all dependencies have been met
 */
export default (dependencies, baseName = "") => {
  const [inactive, setInactive] = useState(true);

  const dependentOn = mapKeys(R.concat(baseName), dependencies || {});
  const dependentNames = R.keys(dependentOn);
  const dependentValues = useWatch({ name: dependentNames });

  useEffect(() => {
    if (dependentNames.length > 0) {
      const allPass = indexedAll((name, idx) => {
        if (typeof dependentOn[name] === "function") {
          return dependentOn[name](dependentValues[idx]);
        }
        return R.equals(dependentOn[name], dependentValues[idx]);
      }, dependentNames);
      setInactive(!allPass);
    } else {
      setInactive(false);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [dependentValues]);

  return inactive;
};
