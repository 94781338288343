// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-utilities-ReadMore__link--g8MdA {\n  display: inline;\n  white-space: nowrap;\n  text-decoration: underline;\n  color: inherit;\n  padding: 0 1px;\n  border: 2px solid transparent;\n}", "",{"version":3,"sources":["webpack://./ui/components/utilities/ReadMore.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;EACA,0BAAA;EACA,cAAA;EACA,cAAA;EACA,6BAAA;AACF","sourcesContent":[".link {\n  display: inline;\n  white-space: nowrap;\n  text-decoration: underline;\n  color: inherit;\n  padding: 0 1px; // fixes incorrect ellipsis width calc\n  border: 2px solid transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "ui-components-utilities-ReadMore__link--g8MdA"
};
export default ___CSS_LOADER_EXPORT___;
