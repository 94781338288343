import React from "react";

import ExternalLink from "components/ExternalLink";
import Icon from "components/Icon";

const GithubLink = () => (
  <ExternalLink
    href="/api/internal/github-redirect/commit"
    title="View deployed code in GitHub"
    hideIcon
  >
    <Icon icon="github" faStyle="brands" size="2x" />
  </ExternalLink>
);

GithubLink.propTypes = {};

export default GithubLink;
