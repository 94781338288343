import React, { useCallback } from "react";
import classnames from "classnames";
import queryString from "query-string";
import * as R from "ramda";

import { LinkEmphasis, SmallButton, SmallButtonBordered } from "components/Button";
import Icon from "components/Icon";
import Text, { useGetText } from "containers/Text";
import { ColorsProvider } from "contexts/colors";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { useMarkMessageRead } from "queries/messages";
import { getColors } from "reducers/tenant";
import { formatDateString, formatTime } from "utils/time";

import Attachment from "./Attachment";
import styles from "./Message.scss";
import { ApplicationLink } from "./SendMessage";

// TODO: move to utils
const buildMailtoURI = (email, params) =>
  R.compose(
    R.join("?"),
    R.filter(R.complement(R.isEmpty)),
  )([`mailto:${email}`, queryString.stringify(params)]);

const Avatar = ({ message }) => {
  const tenant = useCurrentTenantInfo();
  const colors = getColors(tenant);
  const initials = message.user?.full_name
    .split(" ")
    .map((n) => n[0])
    .slice(0, 2)
    .join("");

  return (
    <ColorsProvider primary={colors.primary} secondary={colors.secondary}>
      <div className={classnames(styles.avatar, { [styles.userAvatar]: message.from_applicant })}>
        {initials}
      </div>
    </ColorsProvider>
  );
};

export const MessageContent = ({ message, adminView }) => {
  const { mutate } = useMarkMessageRead(message.project_id, message.id);
  const tenant = useCurrentTenantInfo();
  const markAsRead = useCallback(() => mutate({ read: true }), [mutate]);
  const markAsUnread = useCallback(() => mutate({ read: false }), [mutate]);
  const getText = useGetText();
  const showAsUnread = adminView && message.from_applicant && !message.read_at;

  return (
    <div className={styles.messageContainer} data-message>
      <div className={styles.avatarWithIndicator}>
        {showAsUnread && <div className={styles.unreadIndicator} />}
        <Avatar message={message} />
      </div>
      <div className={styles.messageContent}>
        <div className={styles.messageHeader}>
          <div className={styles.messageAuthor}>
            <span>{message.user?.full_name}</span>
          </div>
          <div className={styles.messageDate}>
            <span>{formatTime(message.created_at)}</span>
          </div>
        </div>
        {message.context_id && (
          <ApplicationLink applicationID={message.context_id} adminView={adminView} />
        )}
        <div className={styles.messageBody} data-message-body>
          <span>{message.message}</span>
        </div>
        {message.attachments.length > 0 && (
          <div className={styles.messageAttachments}>
            {message.attachments.map((attachment) => (
              <Attachment key={attachment.url} attachment={attachment} />
            ))}
          </div>
        )}
        {adminView && message.from_applicant && (
          <div className={styles.messageActions}>
            {(!tenant.messaging_enabled || message.user.is_guest) && (
              <SmallButton href={buildMailtoURI(message.user.email)}>
                <Icon icon="envelope" />
                <span>Email</span>
              </SmallButton>
            )}
            {message.read_at ? (
              <div className={styles.readBy}>
                <Text
                  t="messages.read_by"
                  user={message.read_by_user.full_name}
                  time={formatDateString(message.read_at)}
                />
                <LinkEmphasis label={getText("messages.mark_unread")} onClick={markAsUnread} />
              </div>
            ) : (
              <SmallButtonBordered onClick={markAsRead}>
                <Icon icon="envelope-circle-check" />{" "}
                <span>
                  <Text t="messages.mark_read" />
                </span>
              </SmallButtonBordered>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const Message = ({ message, adminView }) => (
  <div className={styles.message} id={`message-${message.id}`}>
    <MessageContent message={message} adminView={adminView} />
  </div>
);

export default Message;
