import React, { useCallback, useRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { useActiveToggle } from "components/utilities/withActiveToggle";
import { handleSpacebarOrEnter } from "utils/accessibility";

import styles from "./Div.scss";

/* Disabling eslint from these classes for now, until a few suggestions to the spec
 * are merged, allowing for roles and aria- attributes. */

export const ButtonDiv = ({ children, onClick, role, isAriaHidden, forwardedRef, ...props }) => {
  const roleString = role ? `button ${role}` : "button";

  /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
  return (
    <div
      tabIndex={isAriaHidden ? -1 : 0}
      role={roleString}
      onClick={onClick}
      ref={forwardedRef}
      onKeyDown={handleSpacebarOrEnter(onClick)}
      {...props}
    >
      {children}
    </div>
  );
};

ButtonDiv.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  role: PropTypes.string,
  isAriaHidden: PropTypes.bool,
};

export const ButtonDivWithRef = React.forwardRef((props, ref) => (
  <ButtonDiv {...props} forwardedRef={ref} />
));

export const PresentationDiv = ({ children, onClick, ...props }) => (
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  <div role="presentation" onClick={onClick} {...props}>
    {children}
  </div>
);

PresentationDiv.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export const MenuDiv = ({ children, className, label, ...props }) => {
  const { deactivate, active, toggleActive } = useActiveToggle();
  const container = useRef();
  const handleBlur = useCallback(
    (e) => {
      if (!container.current?.contains(e.relatedTarget)) {
        deactivate();
      }
    },
    [deactivate],
  );

  const handleClick = useCallback(
    (e) => {
      toggleActive();
      e.stopPropagation();
    },
    [toggleActive],
  );

  return (
    <div
      role="button menu"
      tabIndex={0}
      className={classnames([styles.menu, className], { [styles.active]: active })}
      ref={container}
      onClick={handleClick}
      onKeyDown={handleSpacebarOrEnter(handleClick)}
      onBlur={handleBlur}
      aria-expanded={active}
      {...props}
    >
      <div className={styles.menuLabel}>{label}</div>
      <div className={styles.menuOptions}>{children}</div>
    </div>
  );
};

MenuDiv.propTypes = {
  label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string])
    .isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};
