import { useCallback } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";

import { updateValidationError } from "actions";
import { useAnswerContext } from "containers/withAnswerContext";
import { useValidateProject as useValidateProjectQuery } from "queries/projects";
import { selectCurrentActiveFieldsForProjectSlug } from "reducers/answerContexts";
import { selectFieldsByID } from "reducers/fields";
import { selectIsProjectSavePending } from "reducers/network";
import { selectOrderedInvalidFieldsByID } from "reducers/projects";
import { isBlank, isPresent } from "utils/func";

import useWaitForReduxStateChange from "./useWaitForReduxStateChange";

const useWaitForAnswerBufferSagaToEmpty = () =>
  useWaitForReduxStateChange(R.compose(R.not, Boolean, selectIsProjectSavePending));

export const useValidateProject = (
  projectID,
  { page, fieldIDs: fieldIDsArg = [], contextuallyRequiredFieldIDs = [], ...queryOpts },
) => {
  const dispatch = useDispatch();
  const answerContext = useAnswerContext();
  const { record: project } = answerContext;

  const { fieldIDs, additionallyRequiredFieldIDs, hasInvalidFields } = useSelector((state) => {
    let additionallyRequiredFieldIDs = contextuallyRequiredFieldIDs;
    let fieldIDs;
    if (isPresent(page)) {
      const { slug: pageSlug, all_fields_required = false } = page;
      fieldIDs = R.compose(R.pluck("id"), selectCurrentActiveFieldsForProjectSlug)(
        state,
        project,
        pageSlug,
      );
      if (all_fields_required) additionallyRequiredFieldIDs = fieldIDs;
    } else {
      fieldIDs = R.compose(R.pluck("id"), selectFieldsByID)(state, fieldIDsArg);
    }
    const hasInvalidFields = R.compose(isPresent, selectOrderedInvalidFieldsByID)(
      state,
      project,
      fieldIDs,
    );

    return {
      fieldIDs,
      hasInvalidFields,
      additionallyRequiredFieldIDs,
    };
  });

  const {
    mutateAsync: validateProject,
    data: errors,
    isSuccess,
    ...queryReturn
  } = useValidateProjectQuery(projectID, queryOpts);

  const waitForAnswerSaves = useWaitForAnswerBufferSagaToEmpty();
  const mutateAsync = useCallback(async () => {
    await waitForAnswerSaves;

    const errors = await validateProject({
      fieldIDs,
      contextuallyRequiredFieldIDs: additionallyRequiredFieldIDs,
    });

    dispatch(updateValidationError({ answerContext: { record: { id: projectID } }, errors }));

    return isBlank(errors);
  }, [
    waitForAnswerSaves,
    projectID,
    validateProject,
    dispatch,
    fieldIDs,
    additionallyRequiredFieldIDs,
  ]);

  return {
    mutateAsync,
    isSuccess,
    errors,
    isValid: !hasInvalidFields && isSuccess && isBlank(errors),
    ...queryReturn,
  };
};
