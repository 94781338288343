import React from "react";
import classnames from "classnames";
import { NumericFormat } from "react-number-format";

import errorStyles from "sharedStyles/errors.scss";
import { isBlank } from "utils/func";

import styles from "./NumberField.scss";
import { textInput } from "./behaviors";

export const IntegerField = ({ labelId, value, onChange, onBlur, error, required, disabled }) => (
  <div className={styles.container}>
    <NumericFormat
      aria-invalid={!!error}
      aria-labelledby={labelId}
      aria-required={!!required}
      className={classnames({ [errorStyles.errorBorder]: error })}
      decimalScale={0}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      thousandSeparator=","
      type="tel" // We are using the tel type here b/c we want thousand separators which isn't supported on type=number
      defaultValue={isBlank(value) ? "" : value}
    />
  </div>
);

export default textInput(IntegerField);
