import React from "react";
import * as R from "ramda";

import { reportError } from "services/errorReporter";

import IntegratedPayment from "./IntegratedPayment";
import { CardElement, SpreedlyProvider, useSpreedlyContext } from "./spreedly";

const SpreedlyPayments = ({ paymentType, setPaymentType }) => {
  const spreedly = useSpreedlyContext();

  const createToken = async (formMethods) => {
    if (!spreedly) {
      reportError(new Error("Error initializing spreedly"));
    }
    try {
      const formValues = formMethods.getValues();

      const tokenValues = {
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        month: formValues.month,
        year: formValues.year,
        address1: formValues.address_line1,
        city: formValues.address_city,
        state: formValues.address_state,
        zip: formValues.address_zip,
      };

      const token = await spreedly.createToken(tokenValues);
      return { id: token };
    } catch (errors) {
      const error = R.map(R.prop("message"), errors).join(", ");
      const err = new Error(error);
      err.response = { data: { _error: error } };
      throw err;
    }
  };

  return (
    <IntegratedPayment
      paymentType={paymentType}
      setPaymentType={setPaymentType}
      createToken={createToken}
      processor="spreedly"
    >
      <CardElement />
    </IntegratedPayment>
  );
};

const SpreedlyPaymentsContainer = (props) => (
  <SpreedlyProvider>
    <SpreedlyPayments {...props} />
  </SpreedlyProvider>
);

export default SpreedlyPaymentsContainer;
