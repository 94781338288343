import React from "react";
import { useSelector } from "react-redux";

import Fields from "components/guides/Fields";
import { selectFields } from "reducers/fields";

export default function Attachments() {
  const fields = useSelector((state) => selectFields(state));
  const attachmentFields = Object.values(fields).filter(
    (f) => f.field_type === "attachment" && f.parent_id == null,
  );

  return (
    <div className="admin-container">
      <h1>Attachments</h1>
      <Fields fields={attachmentFields} hideIfUnanswered behaviorType="disabled" />
    </div>
  );
}
