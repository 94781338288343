import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useSelector } from "react-redux";

import { FeeLineItem, TotalLineItem } from "components/projects/requirement/Fees";
import Text from "containers/Text";
import { getName as getFeeName, selectFeeByID } from "reducers/fees";
import { requirementPropType } from "utils/sharedPropTypes";

import styles from "./Receipt.scss";

const LineItem = ({ item }) => {
  const fee = useSelector((state) => selectFeeByID(state, item.fee_id));

  return <FeeLineItem cost={R.prop("amount", item)} name={getFeeName(fee)} />;
};

const Receipt = ({ requirement, lineItems }) => {
  const total = R.pipe(R.pluck("amount"), R.sum)(lineItems);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>
        <Text
          t={`projects.requirement.summary.requirement_name${
            requirement.type === "Upload" ? "_upload" : ""
          }`}
          name={requirement.name}
        />
      </h2>
      {R.map(
        (item) => (
          <LineItem key={item.id} item={item} />
        ),
        R.reverse(R.sortBy(R.prop("amount"), lineItems)),
      )}
      <TotalLineItem cost={total} />
    </div>
  );
};
Receipt.propTypes = {
  requirement: requirementPropType.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Receipt;
