import React from "react";
import * as R from "ramda";
import { Controller, get, useFormContext, useWatch } from "react-hook-form";

import * as Fields from "components/forms/BareFields";
import {
  ApplicantLabeledInput,
  CompactLabeledInput,
  HorizontalLabeledInput,
  LabeledInput,
  UnlabeledInputWithErrors,
} from "components/forms/Layout";
import ApplicantFormField from "components/project_form/Field";
import { TelephoneFieldComponent } from "components/project_form/TelephoneField";
import withProps from "components/utilities/withProps";
import { useGetText } from "containers/Text";
import { useFormRecordContext } from "contexts/form";
import useDependentOn from "hooks/useDependentOn";
import withHook from "hooks/withHook";
import { useCurrentTenant } from "queries/config/tenants";
import { compact } from "utils/func";
import { hoc } from "utils/hoc";

export const withInputValue = withHook("inputValue", useWatch, ({ name }) => [{ name }]);

const withForwardedRef = (propName = "forwardedRef") => {
  const WithForwardedRef = React.forwardRef(({ Component, ...props }, ref) => (
    <Component {...R.assoc(propName, ref, props)} />
  ));
  WithForwardedRef.displayName = "WithForwardedRef";
  return hoc(WithForwardedRef);
};

const WithHookForm = ({ Component, name, registerOpts = {}, ...inputProps }) => {
  const { register } = useFormContext();
  const registerProps = register(name, registerOpts);
  return <Component {...inputProps} {...registerProps} />;
};
const withHookForm = R.compose(hoc(WithHookForm), withForwardedRef());

const WithController = ({ Component: BareInput, name, registerOpts = {}, ...props }) => (
  <Controller
    name={name}
    rules={registerOpts}
    render={({ field: { ref: _ref, ...field } }) => <BareInput {...field} {...props} name={name} />}
  />
);
export const withController = R.compose(hoc(WithController), withForwardedRef());

const withSelectController = (Select) =>
  function SelectController({
    name,
    options = [],
    isMulti,
    formValueLens = R.lensProp("value"),
    optionLabelLens = R.lensProp("label"),
    optionValueLens = R.lensProp("value"),
    ...props
  }) {
    const onSelectChange = (onChange) => (selectedOptions) => {
      if (isMulti) {
        onChange(R.map(R.view(formValueLens), selectedOptions));
      } else {
        onChange(R.view(formValueLens, selectedOptions));
      }
    };

    // find (or create) the OptionType that corresponds to the given form value member
    const findOption = (formValueMember) =>
      R.find(R.compose(R.equals(formValueMember), R.view(formValueLens)), options) ||
      R.mergeRight(getNewOptionData(formValueMember), R.set(formValueLens, formValueMember, {}));

    const selectValue = (formValue) => {
      if (!formValue) {
        return null;
      }

      if (isMulti) {
        return R.map(findOption, formValue);
      }

      return findOption(formValue);
    };

    const getNewOptionData = (enteredValue) =>
      R.compose(R.set(optionValueLens, enteredValue), R.set(optionLabelLens, enteredValue))({});

    return (
      <Controller
        name={name}
        render={({ field: { value: formValue, onChange, onBlur, name } }) => (
          <Select
            name={name}
            onBlur={onBlur}
            hideSelectedOptions
            value={selectValue(formValue)}
            onChange={onSelectChange(onChange)}
            {...props}
            isMulti={isMulti}
            options={options}
            getOptionValue={R.view(optionValueLens)}
            getOptionLabel={R.view(optionLabelLens)}
            getNewOptionData={getNewOptionData}
          />
        )}
      />
    );
  };

const invokeIfFn =
  (fnOrValue) =>
  (...args) =>
    typeof fnOrValue === "function" ? fnOrValue(...args) : fnOrValue;

/**
 * Returns a set of props for the Component passed to <LabeledField>
 * @param {FieldDefinition} - The form field definition
 * @param {Object} - Additional inputProps that were passed to LabeledField
 */
const useFieldProps = (definition, { baseName = "", field, ...inputProps }) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { disabled: disabledByCtx, record, newRecord } = useFormRecordContext();
  const required =
    inputProps.required ||
    R.path(["validate", "required"], definition) ||
    invokeIfFn(definition.required)({ record, newRecord });
  const disabledLocally = invokeIfFn(definition.disabled)({ record, newRecord });
  const disabled = disabledByCtx || disabledLocally;
  const name = `${baseName}${definition.name}`;
  const error = get(errors, name);
  const defaultValue = R.prop(definition.name, field) || definition.default;
  const registerOpts = R.mergeLeft(
    compact({
      required: required ? true : null,
      disabled: disabledLocally,
    }),
    definition.validate,
  );

  return {
    ...inputProps,
    disabled,
    required,
    baseName,
    defaultValue,
    placeholder: definition.placeholder,
    error,
    field,
    name,
    registerOpts,
  };
};

export const AsyncSelect = ({ name, control, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => <Fields.AsyncSelect {...props} name={name} {...field} />}
  />
);

export const TextInput = withHookForm(Fields.TextInput);

// Controlled components
export const TextAreaInput = withController(Fields.TextAreaInput);
export const MarkdownInput = withController(Fields.MarkdownInput);
export const SelectInput = withHookForm(Fields.SelectInput);
export const CheckboxInput = withHookForm(Fields.CheckboxInput);
export const RadioButtonInput = withController(Fields.RadioButtonInput);
export const ColorInput = R.compose(withHookForm, withInputValue)(Fields.ColorInput);
export const ImageInput = withController(Fields.AttachmentInput);
export const AttachmentInput = withController(Fields.AttachmentInput);
export const NumericInput = withController(Fields.NumericInput);

export const Select2Input = withSelectController(Fields.Select2Input);
export const VirtualSelect2Input = withSelectController(Fields.VirtualSelect2Input);
export const CreatableSelect2Input = withSelectController(Fields.CreatableSelect2Input);

export const TelephoneInput = ({ name, control, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <TelephoneFieldComponent
        {...props}
        name={name}
        value={field.value}
        onChange={field.onChange}
      />
    )}
  />
);

export const ApplicantFieldInput = ({ name, ...props }) => {
  const { data: tenant } = useCurrentTenant();

  return (
    <Controller
      name={name}
      render={({ field: { ref: _ref, ...fieldProps } }) => (
        <ApplicantFormField
          onFocus={R.T}
          onSave={R.T}
          tenant={tenant}
          {...fieldProps}
          {...props}
          onChange={({ value }) => fieldProps.onChange(value)}
          simplified
        />
      )}
    />
  );
};

export const SliderInput = withController(Fields.SliderInput);

const innermostComponentName = (Component) => {
  const name = Component.displayName || Component.name;
  const matches = name.match(/\((\w*)\)/);
  return matches ? matches[1] : name;
};

export const LabeledField = ({
  definition,
  width,
  Container = LabeledInput,
  classNames = [],
  ...inputProps
}) => {
  const { Component } = definition;
  const fieldProps = useFieldProps(definition, inputProps);
  const inactive = useDependentOn(definition.dependentOn, fieldProps.baseName);
  const getText = useGetText();
  const label = definition.labelKey ? getText(definition.labelKey) : definition.label;

  if (inactive) {
    return null;
  }

  if (definition.Container) {
    Container = definition.Container;
  }

  return (
    <Component
      Container={Container}
      containerProps={{
        helpText: definition.helpText,
        error: fieldProps.error,
        fieldName: definition.name,
        fieldType: innermostComponentName(Component),
        label,
        width,
        classNames,
        ...definition.containerProps,
      }}
      {...fieldProps}
    />
  );
};

export const CompactLabeledField = withProps({
  Container: CompactLabeledInput,
})(LabeledField);

export const HorizontalLabeledField = withProps({
  Container: HorizontalLabeledInput,
})(LabeledField);

export const CompactField = withProps({
  Container: UnlabeledInputWithErrors,
})(LabeledField);

export const ApplicantField = withProps({
  Container: ApplicantLabeledInput,
  classNames: [],
})(LabeledField);
