import * as R from "ramda";
import { connect } from "react-redux";

import { downloadPDF } from "actions";
import HelpBar from "components/guides/HelpBar";
import { withAnswerContextConsumer } from "containers/withAnswerContext";
import { getExternalHelpURL, selectGuideByID } from "reducers/guides";
import { selectIsInlinePage } from "reducers/projects";
import { selectMunicipalityType } from "reducers/tenant";
import { selectCurrentPageSlug, selectDownloadInProgress } from "reducers/ui";

const mapStateToProps = (state, { answerContext: { record }, showPDFDownload }) => ({
  externalHelpURL: getExternalHelpURL(selectGuideByID(state, record.guide_id)),
  municipalityType: selectMunicipalityType(state),
  isDownloadInProgress: selectDownloadInProgress(state),
  showPDFDownload:
    showPDFDownload ||
    (selectCurrentPageSlug(state) === "summary" && !selectIsInlinePage(state, record)),
});

const mapDispatchToProps = (dispatch, { answerContext }) => ({
  onDownloadPDF: () => dispatch(downloadPDF(answerContext)),
});

export default R.compose(
  withAnswerContextConsumer,
  connect(mapStateToProps, mapDispatchToProps),
)(HelpBar);
