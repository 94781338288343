import debounce from "lodash.debounce";
import * as R from "ramda";

import withHandlers from "components/utilities/withHandlers";

const withFieldValue = withHandlers({
  getValue:
    () =>
    ({ target }) =>
      target.value,
});

const withCheckboxValue = withHandlers({
  getValue:
    () =>
    ({ target }) =>
      target.checked,
});

const withBooleanValue = withHandlers({
  getValue:
    () =>
    ({ target }) =>
      target.value === "true",
});

export const withGivenValue = withHandlers({
  getValue: () => (value) => value,
});

export const withMomentValue = withHandlers({
  getValue: () => (value) => value ? value.format() : null,
});

const saveOnBlur = withHandlers(() => {
  let hasChanged = false;

  return {
    onChange: (props) => (change) => {
      hasChanged = true;
      props.onChange(props.getValue(change));
    },
    onBlur: (props) => () => {
      if (!hasChanged) return;

      props.onSave();
      hasChanged = false;
    },
  };
});

const saveOnChange = withHandlers({
  onChange: (props) => (change) => {
    props.onChange(props.getValue(change));
    setTimeout(props.onSave, 0); // Let state changes work
  },
  onBlur: () => R.always(true),
});

const neverSave = withHandlers({
  onChange: (props) => (change) => props.onChange(props.getValue(change)),
  onBlur: () => R.always(true),
});

export const saveOnDebouncedChange = (wait) =>
  withHandlers({
    onChange: (props) => {
      const debouncedSave = debounce(props.onSave, wait);

      return (change) => {
        const value = props.getValue(change);
        props.onChange(value);
        debouncedSave();
      };
    },
    onBlur: () => R.always(true),
  });

export const debouncedValue = (wait) => R.compose(withGivenValue, saveOnDebouncedChange(wait));
export const immediateValue = R.compose(withGivenValue, saveOnChange);
export const immediateMomentValue = R.compose(withMomentValue, saveOnBlur);
export const immediateValueNoSave = R.compose(withGivenValue, neverSave);
export const immediateInput = R.compose(withFieldValue, saveOnChange);
export const checkedInput = R.compose(withCheckboxValue, saveOnChange);
export const booleanInput = R.compose(withBooleanValue, saveOnChange);
export const textInput = R.compose(withFieldValue, saveOnBlur);
export const debouncedTextInput = (wait) => R.compose(withFieldValue, saveOnDebouncedChange(wait));
