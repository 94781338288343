import React, { useCallback, useContext, useMemo, useState } from "react";
import * as R from "ramda";

import useToggleState from "hooks/useToggleState";

import { useSession } from "./session";

const AuthnContext = React.createContext({
  email: null,
  setEmail: R.T,
  activateLoginModal: R.T,
  closeLoginModal: R.T,
  loginModalActive: false,
});
export const useAuthnState = () => useContext(AuthnContext);

export const AuthnStateProvider = ({ children }) => {
  const [email, setEmail] = useState();
  const {
    value: loginModalActive,
    setOn: activateLoginModal,
    setOff: closeLoginModal,
  } = useToggleState(false);

  const value = useMemo(
    () => ({
      email,
      setEmail,
      activateLoginModal,
      closeLoginModal,
      loginModalActive,
    }),
    [email, setEmail, activateLoginModal, closeLoginModal, loginModalActive],
  );
  return <AuthnContext.Provider value={value}>{children}</AuthnContext.Provider>;
};

const AuthnReturnToContext = React.createContext(null);
export const AuthnReturnToProvider = ({ onReturn, children }) => (
  <AuthnReturnToContext.Provider value={onReturn} children={children} />
);

export const useOnAuthnSuccess = () => {
  const onReturn = useContext(AuthnReturnToContext);
  const { closeLoginModal } = useAuthnState();
  const {
    query: { refetch: refetchSession },
  } = useSession();
  return useCallback(
    async (serverProvidedDefaultReturnTo) => {
      await refetchSession("login");
      if (onReturn) onReturn(serverProvidedDefaultReturnTo);
      else closeLoginModal();
    },
    [refetchSession, closeLoginModal, onReturn],
  );
};
