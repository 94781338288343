import React, { useEffect, useMemo, useRef, useState } from "react";
import { Map } from "react-map-gl";
import { useSelector } from "react-redux";
import WebMercatorViewport from "viewport-mercator-project";

import { useAnswerContext } from "containers/withAnswerContext";
import { useServerVariables } from "contexts/serverVariables";
import { getAnswerForField } from "reducers/answerContexts";
import { selectTenant } from "reducers/tenant";
import { selectInitialAddressViewport } from "selectors/addressViewport";
import { useEventMapStyle } from "services/MapStyleBuilder";

function GeomsMap({ fields = [], height = "15em" }) {
  const defaultViewport = useSelector(selectInitialAddressViewport);
  const tenant = useSelector(selectTenant);
  const [viewport, setViewport] = useState(defaultViewport);
  const mapRef = useRef();
  const { mapboxToken } = useServerVariables();
  const { record: project } = useAnswerContext();

  const geomIDs = useMemo(
    () =>
      fields
        .filter((f) => f.field_type === "geom")
        .flatMap((f) => getAnswerForField(project, f) || []),
    [project, fields],
  );

  const points = useMemo(
    () =>
      fields
        .filter((f) => f.field_type === "address")
        .flatMap((f) => getAnswerForField(project, f) || []),
    [fields, project],
  );

  useEffect(() => {
    if (geomIDs.length === 0) return;

    fetch(`/api/geoms/bounds?geomIDs=${geomIDs.join(",")}`)
      .then((r) => r.json())
      .then((bounds) => {
        // HACK: docs / exammples say just passing the vp should work but fitToBounds isn't working unless I give it a width / height, test again after update
        const { offsetHeight: height, offsetWidth: width } = mapRef.current.getMap().getContainer();
        const wmvp = new WebMercatorViewport({ width, height });
        setViewport(wmvp.fitBounds(bounds, { padding: 40 }));
      });
  }, [geomIDs, setViewport, tenant.id, points]);

  const mapStyle = useEventMapStyle({
    tenantID: tenant.id,
    geomsAnswer: geomIDs,
    addressesAnswer: points,
    hideGeoms: true,
  });

  if (geomIDs.length + points.length === 0) return null;

  return (
    <Map
      {...viewport}
      mapStyle={mapStyle}
      ref={mapRef}
      style={{ height }}
      mapboxAccessToken={mapboxToken}
    />
  );
}

export default GeomsMap;
