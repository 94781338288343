import React from "react";

import MaybeApplicationLink from "./MaybeApplicationLink";
import EventLogPropTypes from "./propTypes";

const ApplicationIssued = ({ Layout, event, timestamp, applicantContext, applicationView }) => {
  const verb = event.payload.data.requirement_review_type || "Issued";

  return (
    <Layout icon="badge-check">
      {applicantContext || applicationView ? (
        <> {verb}</>
      ) : (
        <>
          <MaybeApplicationLink event={event} /> {verb.toLowerCase()}
        </>
      )}
      {timestamp}
    </Layout>
  );
};
ApplicationIssued.propTypes = EventLogPropTypes;

export default ApplicationIssued;
