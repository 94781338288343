import React from "react";
import PropTypes from "prop-types";

import Text, { useText } from "containers/Text";

import { RadioButtonFieldComponent } from "./RadioButtonField";
import { disabled as DisabledTextField } from "./TextField";
import { booleanInput } from "./behaviors";

const YesNoField = ({ field, ...props }) => (
  <RadioButtonFieldComponent
    field={{
      ...field,
      enum: [
        { label: <Text t="yes" />, code: true },
        { label: <Text t="no" />, code: false },
      ],
    }}
    {...props}
  />
);
export default booleanInput(YesNoField);

export const DisabledYesNoField = ({ value }) => {
  const translated = useText(value ? "yes" : "no");
  let translatedValue = null;
  if (typeof value === "boolean") translatedValue = translated;

  return <DisabledTextField value={translatedValue} />;
};
DisabledYesNoField.propTypes = {
  value: PropTypes.bool,
};
export const disabled = DisabledYesNoField;
