import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ApplicantField, NumericInput } from "components/forms/HookFields";
import { FormRow, LabeledInput } from "components/forms/Layout";
import withProps from "components/utilities/withProps";
import { selectSpreedlyKey } from "reducers/tenant";
import { defineFields } from "utils/formDefinitions";

import styles from "./spreedly.scss";

const SPREEDLY_LIB_URL = "https://core.spreedly.com/iframe/iframe-v1.min.js";

const useLoadSpreedly = () => {
  const [spreedly, setSpreedly] = useState(window.Spreedly);
  const [ready, setReady] = useState(false);
  const createToken = useCallback(
    async (values) => {
      if (ready) {
        const promise = new Promise((resolve, reject) => {
          spreedly.on("paymentMethod", (token) => {
            resolve(token);
          });
          spreedly.on("errors", (errors) => {
            reject(errors);
          });
        });
        spreedly.tokenizeCreditCard(values);
        return promise;
      }
      throw new Error("Token created before spreedly is ready");
    },
    [spreedly, ready],
  );

  useEffect(() => {
    if (!spreedly) {
      const script = document.createElement("script");
      script.src = SPREEDLY_LIB_URL;
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener("load", () => setSpreedly(window.Spreedly));
      // [JB] removing these event listeners breaks the transition
      // return () => {
      // script.removeEventListener("load", listener);
      // };
    }
  }, [spreedly]);

  /* eslint-disable-next-line consistent-return */
  useEffect(() => {
    if (spreedly) {
      spreedly.on("ready", () => {
        spreedly.setFieldType("number", "text");
        spreedly.setNumberFormat("prettyFormat");
        spreedly.setStyle(
          "number",
          "background-color: white; border: solid 1px #ccc; width: 82%; padding: 1rem; border-radius: 3px; line-height: 1.15; font-size: 16px; font-family: Circular, 'Helvetica Neue', Helvetica, Arial, sans - serif",
        );
        spreedly.setStyle(
          "cvv",
          "background-color: white; border: solid 1px #ccc; width: 50%; padding: 1rem; border-radius: 3px; line-height: 1.15; font-size: 16px; font-family: Circular, 'Helvetica Neue', Helvetica, Arial, sans - serif",
        );
        setReady(true);
      });
      spreedly.on("consoleError", (errors) => {
        console.error("Spreedly console error", errors);
      });

      return () => {
        try {
          spreedly.removeHandlers();
        } catch (e) {
          // NOOP: this happens often
        }
      };
    }
  }, [spreedly]);

  if (!spreedly) return null;

  return {
    init: spreedly.init,
    spreedly,
    createToken,
    ready,
  };
};

const SpreedlyContext = createContext({ spreedly: null });
export const useSpreedlyContext = () => useContext(SpreedlyContext);

export const SpreedlyProvider = ({ children }) => {
  const spreedly = useLoadSpreedly();

  return <SpreedlyContext.Provider value={spreedly} children={children} />;
};

const expirationFields = defineFields({
  month: {
    Component: withProps({ format: "##", classNames: [styles.monthInput] })(NumericInput),
    validate: {
      required: "Month is required",
      pattern: { value: /^(0?[1-9]|1[0-2])$/, message: "Invalid month" },
    },
  },
  year: {
    Component: withProps({ format: "####", classNames: [styles.yearInput] })(NumericInput),
    validate: {
      required: "Year is required",
      pattern: { value: /^2(0|1)\d{2}$/g, message: "Invalid year" },
    },
  },
});

export const CardElement = () => {
  const spreedlyPublicKey = useSelector(selectSpreedlyKey);
  const spreedly = useSpreedlyContext();
  const numberEl = "spreedly-card";
  const cvvEl = "spreedly-cvv";

  useEffect(() => {
    if (spreedly) {
      spreedly.spreedly.init(spreedlyPublicKey, { numberEl, cvvEl });
    }
  }, [spreedly, spreedlyPublicKey]);

  if (!spreedly) {
    return null;
  }

  return (
    <div className={styles.formContainer} data-spreedly-ready={spreedly.ready}>
      <FormRow>
        <LabeledInput label="Credit Card Number" classNames={[styles.numberContainer]}>
          <div id={numberEl} />
        </LabeledInput>

        <LabeledInput label="CVV">
          <div id={cvvEl} />
        </LabeledInput>

        <ApplicantField definition={expirationFields.month} />
        <ApplicantField definition={expirationFields.year} />
      </FormRow>
    </div>
  );
};
