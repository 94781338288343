import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import ErrorPage, { ErrorBanner } from "components/ErrorPage";
import Icon from "components/Icon";
import { SuperuserFeature } from "components/privilegedUserFeatures";
import { reportError } from "services/errorReporter";
import isHeadless from "utils/isHeadless";
import { childrenPropType } from "utils/sharedPropTypes";

import styles from "./ErrorBoundary.scss";

function getExpiringLock() {
  const ttl = 10000;
  const key = "reload-on-error";

  const itemString = window.sessionStorage.getItem(key);
  if (itemString) {
    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;
    if (!isExpired) return false;
  }

  const item = {
    expiry: new Date().getTime() + ttl,
  };
  window.sessionStorage.setItem(key, JSON.stringify(item));
  return true;
}

function reloadOnce() {
  if (!getExpiringLock()) return;
  window.location.reload(true); // the `true` arg here is non-standard but it means the equivalent of Shift-Reload in some browsers
}

function isChunkLoadError(error) {
  return error && error.name === "ChunkLoadError";
}

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  async componentDidCatch(error, { componentStack }) {
    const errorOwnProps = R.omit(["message", "stack"], error);
    console.error("ErrorBoundary.didCatch", error, { errorOwnProps }, componentStack);
    await reportError(error, { context: { component: componentStack } });
    if (isHeadless()) throw error;
    if (isChunkLoadError(error)) reloadOnce();
  }

  render() {
    const { hasError, error } = this.state;
    const { children, type } = this.props;
    if (!hasError) return children;

    if (type === "SWW") return <ErrorPage error={error} />;
    if (type === "BANNER")
      return (
        <>
          <ErrorBanner error={error} />
          {children}
        </>
      );
    if (type === "SILENT") return null;

    return (
      <div className={styles.errorContainer}>
        <Icon icon="bomb" size="3x" />
        <SuperuserFeature>
          <pre className={styles.stack}>{error.stack}</pre>
        </SuperuserFeature>
      </div>
    );
  }
}
ErrorBoundary.propTypes = {
  children: childrenPropType,
  type: PropTypes.string,
};
