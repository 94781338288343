import React, { useContext } from "react";

const ApplicationSectionContext = React.createContext();

export const useApplicationSection = () => useContext(ApplicationSectionContext);
export const useIsInAdminSection = () => useApplicationSection() === "admin";
export const useIsInApplicantSection = () => useApplicationSection() === "applicant";
export const useIsInConfigSection = () => useApplicationSection() === "config";

export default ApplicationSectionContext;
