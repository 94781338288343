import React from "react";

import useToggleState from "hooks/useToggleState";
import { hoc } from "utils/hoc";

export const useActiveToggle = (initial = false) => {
  const { value, toggle, set, setOn, setOff } = useToggleState(initial);
  return {
    active: value,
    setActive: set,
    toggleActive: toggle,
    activate: setOn,
    deactivate: setOff,
  };
};

const WithActiveToggle = ({ initialValue = false, Component, ...props }) => {
  const hookProps = useActiveToggle(initialValue);

  return <Component {...props} {...hookProps} />;
};

const withActiveToggle = hoc(WithActiveToggle, { initialValue: false });
export default withActiveToggle;

export const withActivatedToggle = hoc(WithActiveToggle, { initialValue: true });
