import * as R from "ramda";

import { reportError } from "services/errorReporter";

const DisabledNoneField = () => null;

function getInputField(inputType) {
  switch (inputType) {
    case "button":
      return require("./ButtonField");
    case "check_box":
      return require("./CheckboxField");
    case "component":
      return require("./ComponentField");
    case "currency":
      return require("./CurrencyField");
    case "date_select":
      return require("./CalendarField");
    case "date_time":
      return require("./DateTime");
    case "email":
      return require("./EmailField");
    case "file":
      return require("./FileField");
    case "float":
      return require("./FloatField");
    case "instruction":
      return require("./InstructionField");
    case "integer":
      return require("./IntegerField");
    case "list":
      return require("./ListField");
    case "multi_select_list":
      return require("./MultiSelectCheckboxField");
    case "radio_button":
      return require("./RadioButtonField");
    case "select":
      return require("./SelectField");
    case "sensitive":
      return require("./SensitiveField");
    case "signature":
      return require("./SignatureField");
    case "telephone":
      return require("./TelephoneField");
    case "terms_and_condition":
      return require("./TermsAndConditionsField");
    case "text":
      return require("./TextField");
    case "text_area":
      return require("./TextAreaField");
    case "use_code":
      return require("./UseCodeField");
    case "yes_no":
      return require("./YesNoField");
    case "event_geom":
      return require("./EventGeomsField");
    case "building_type":
      return require("./BuildingTypeField");
    case "only_disabled_address":
      return require("./OnlyDisabledAddressField");
    case "none":
      return { disabled: DisabledNoneField };
    case "address":
    case "zoned_address":
      return require("./ZonedAddressField");
    case "zoning":
      return require("./ZoningField");
    case "requirement_catalog":
      return require("./RequirementCatalogField");
    case "overrides":
      return require("./OverridesField");
    case "event_time":
      return require("./EventTimesField");
    case "event_location":
      return require("./EventLocationsFieldLegacy");
    case "event_route":
      return require("./EventRoutesFieldLegacy");
    default: {
      reportError(new Error(`unsupported field type: ${inputType}`), {
        context: {
          component: "fieldLookup",
          severity: "warning",
        },
        params: { inputType },
      });
      return require("./TextField");
    }
  }
}

export const fieldForInputType = (inputType, { behaviorType = "default" } = {}) => {
  const field = getInputField(inputType);

  if (R.has(behaviorType, field)) return field[behaviorType];

  if (behaviorType === "disabled") return getInputField("text").disabled;
  if (behaviorType === "issued") {
    if (R.has("disabled", field)) return field.disabled;
    return getInputField("text").issued;
  }

  return field.default;
};
