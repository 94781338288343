// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-contexts-colors__wrapper--kNh52 {\n  width: 100%;\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n  align-items: stretch;\n}", "",{"version":3,"sources":["webpack://./ui/contexts/colors.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,oBAAA;AACJ","sourcesContent":[".wrapper {\n    width: 100%;\n    display: flex;\n    flex-grow: 1;\n    flex-direction: column;\n    align-items: stretch;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ui-contexts-colors__wrapper--kNh52"
};
export default ___CSS_LOADER_EXPORT___;
