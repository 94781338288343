import React from "react";
import * as R from "ramda";
import Select from "react-select";

import { useEntitiesListForType } from "queries/entities";
import { indexByID } from "utils/func";
import { recordsToOptions } from "utils/toOptions";

import styles from "./shared.scss";
import usePartitionedRequirements from "./usePartitionedRequirements";

export const ALL_REQUIREMENTS = {
  label: "All requirements",
  value: undefined,
};

const RequirementSelector = ({ focusedRequirement, setFocusedRequirement }) => {
  const [activeRequirements, inactiveRequirements] = usePartitionedRequirements();
  const requirements = [...activeRequirements, ...inactiveRequirements];

  const byDepartmentID = R.groupBy(R.prop("department_id"), inactiveRequirements);
  const departmentIDs = R.map(Number, R.keys(byDepartmentID));
  const { data: departments = [] } = useEntitiesListForType("Department", {
    select: R.filter((d) => R.includes(R.prop("id", d), departmentIDs)),
  });

  const departmentsByID = indexByID(departments);

  const inactiveOptions = R.reduce(
    (acc, [id, departmentRequirements]) =>
      R.append(
        {
          label: R.path([id, "name"], departmentsByID),
          options: recordsToOptions(R.sortBy(R.prop("admin_name"), departmentRequirements)),
        },
        acc,
      ),
    [],
    R.toPairs(byDepartmentID),
  );

  const focusedRequirementOptions = [
    ALL_REQUIREMENTS,
    {
      label: "Active Requirements",
      options: recordsToOptions(R.sortBy(R.prop("admin_name"), activeRequirements)),
    },
    ...R.sortBy(R.prop("label"), inactiveOptions),
  ];

  let focusedRequirementOption;
  if (focusedRequirement) {
    focusedRequirementOption = {
      value: focusedRequirement.id,
      label: R.prop("name", focusedRequirement),
    };
  } else {
    focusedRequirementOption = ALL_REQUIREMENTS;
  }

  const handleRequirementChange = (change) => {
    if (!change) {
      setFocusedRequirement(undefined);
      return;
    }
    const requirement = R.find(R.propEq("id", change.value), requirements);
    setFocusedRequirement(requirement);
  };

  return (
    <Select
      classNamePrefix="select"
      className={styles.requirementSelector}
      value={focusedRequirementOption}
      options={focusedRequirementOptions}
      onChange={handleRequirementChange}
      placeholder="Type to search requirements"
      aria-label="focused-requirement"
      isSearchable
      isClearable={focusedRequirement}
    />
  );
};

export default RequirementSelector;
