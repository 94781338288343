// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-payments-spreedly__form-container--08sKW iframe {\n  height: 4em;\n}\n.ui-components-payments-spreedly__form-container--08sKW iframe input {\n  background-color: white;\n}\n.ui-components-payments-spreedly__form-container--08sKW .ui-components-payments-spreedly__number-container--i6YkT {\n  flex-basis: 150%;\n  padding-right: 0.4em;\n}\n.ui-components-payments-spreedly__form-container--08sKW .ui-components-payments-spreedly__year-input--_2qM0 {\n  width: 4.5em;\n}\n.ui-components-payments-spreedly__form-container--08sKW .ui-components-payments-spreedly__month-input--i87zb {\n  width: 3em;\n}", "",{"version":3,"sources":["webpack://./ui/components/payments/spreedly.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAEI;EACE,uBAAA;AAAN;AAIE;EACE,gBAAA;EACA,oBAAA;AAFJ;AAKE;EACE,YAAA;AAHJ;AAME;EACE,UAAA;AAJJ","sourcesContent":[".form-container {\n  iframe {\n    height: 4em;\n\n    input {\n      background-color: white;\n    }\n  }\n\n  .number-container {\n    flex-basis: 150%;\n    padding-right: 0.4em;\n  }\n\n  .year-input {\n    width: 4.5em;\n  }\n\n  .month-input {\n    width: 3em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-container": "ui-components-payments-spreedly__form-container--08sKW",
	"formContainer": "ui-components-payments-spreedly__form-container--08sKW",
	"number-container": "ui-components-payments-spreedly__number-container--i6YkT",
	"numberContainer": "ui-components-payments-spreedly__number-container--i6YkT",
	"year-input": "ui-components-payments-spreedly__year-input--_2qM0",
	"yearInput": "ui-components-payments-spreedly__year-input--_2qM0",
	"month-input": "ui-components-payments-spreedly__month-input--i87zb",
	"monthInput": "ui-components-payments-spreedly__month-input--i87zb"
};
export default ___CSS_LOADER_EXPORT___;
