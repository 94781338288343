import React from "react";
import classnames from "classnames";

import styles from "./TelephoneLink.scss";

const TelephoneLink = ({ number, className, ...props }) => {
  if (!number) return null;
  return (
    <a
      className={classnames(className, styles.link)}
      href={`tel:${number.replaceAll(/[^0-9+]+/g, "")}`}
      {...props}
    >
      {number}
    </a>
  );
};
export default TelephoneLink;
