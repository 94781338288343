import { useEffect, useRef } from "react";

/**
 * Prevents state updates on unmounted components
 * @return {function} - function which takes a function that is only called if the component is still mounted
 */
const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  return (fn) => {
    if (isMounted.current) {
      fn();
    }
  };
};

export default useIsMounted;
