import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import { FlexColumn } from "components/FlexColumn";
import { errorPropType, fieldPropType, tenantPropType } from "utils/sharedPropTypes";

import styles from "./ComponentField.scss";
import FormField from "./Field";

const ComponentField = ({ field, value, onChange, onSave, error, tenant, answerContext }) => {
  const { children } = field;

  const onChildChange =
    ({ component_key }) =>
    (_field, childValue) => {
      onChange(R.compose(R.assoc(component_key, childValue), R.defaultTo({}))(value));
    };

  if (!children) {
    return null;
  }

  return (
    <FlexColumn className={styles.fieldGroup} data-component-field>
      {children.map((childField) => (
        <FormField
          answerContext={R.compose(
            R.assoc("onChange", onChildChange(childField)),
            R.assoc("onSave", onSave),
          )(answerContext)}
          error={R.prop(R.prop("id", childField), error)}
          field={childField}
          key={childField.id}
          onBlur={R.T}
          onFocus={R.T}
          tenant={tenant}
          value={R.prop(R.prop("component_key", childField), value)}
        />
      ))}
    </FlexColumn>
  );
};

ComponentField.propTypes = {
  error: errorPropType,
  field: PropTypes.shape({ children: PropTypes.arrayOf(fieldPropType) }),
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  tenant: tenantPropType.isRequired,
  value: PropTypes.object,
};

export const DisabledComponentField = ({ field, value, answerContext }) => {
  if (!field.children) return null;
  const children = R.map(
    (child) => (
      <FormField
        key={child.key}
        answerContext={answerContext}
        field={child}
        value={R.prop(child.component_key, value)}
        behaviorType="disabled"
        hideHelp
        hideRequired
      />
    ),
    field.children,
  );
  return <div className={styles.fieldGroup}>{children}</div>;
};
DisabledComponentField.propTypes = {
  field: PropTypes.shape({
    children: PropTypes.arrayOf(fieldPropType),
  }),
  value: PropTypes.object,
};
export const disabled = DisabledComponentField;

export const IssuedComponentField = ({ field, value, answerContext }) => {
  if (!field.children) return null;
  return R.map(
    (child) => (
      <FormField
        key={child.key}
        field={child}
        answerContext={answerContext}
        value={R.prop(child.component_key, value)}
        behaviorType="issued"
        hideHelp
        hideRequired
      />
    ),
    field.children,
  );
};
export const issued = IssuedComponentField;

export default ComponentField;
