import "whatwg-fetch";
import { X_VAULT_TOKEN } from "utils/httpHeaderNames";

const request = async (resource, { addr, token, method = "POST" }, payload = {}) => {
  const requestConfig = {
    credentials: "omit",
    method,
    headers: {},
  };

  if (token) requestConfig.headers[X_VAULT_TOKEN] = token;

  if (requestConfig.method !== "HEAD" && requestConfig.method !== "GET") {
    requestConfig.headers["Content-Type"] = "application/json";
    requestConfig.body = JSON.stringify(payload);
  }

  const url = new URL(resource, `${addr}/v1/`);

  const response = await fetch(url, requestConfig);
  if (response.status === 204) return null;
  const json = await response.json();
  if (response.ok) return json;
  throw new Error(json);
};

export function unwrap({ token, addr }) {
  return request("sys/wrapping/unwrap", { token, addr });
}

export function tokenLookupSelf({ token, addr }) {
  return request("auth/token/lookup-self", { method: "GET", token, addr });
}

export function encrypt({ token, addr, backendPath: path, keyName, context, plaintext }) {
  const params = { token, method: "PUT", addr };
  return request(`${path}/encrypt/${keyName}`, params, {
    context: btoa(context),
    plaintext: btoa(plaintext),
    type: "aes256-gcm96",
    derived: "true",
  });
}

export function decrypt({ token, addr, backendPath: path, keyName, context, ciphertext }) {
  const params = { token, addr };
  return request(`${path}/decrypt/${keyName}`, params, {
    context: btoa(context),
    ciphertext,
  });
}

export function backendPath(tenant) {
  return `tenant/${tenant.slug}/transit`;
}
