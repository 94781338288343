// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-project_form-InstructionField__container--h9uBj h3 {\n  font-size: 1.2em;\n  line-height: 1.5em;\n}\n.ui-components-project_form-InstructionField__container--h9uBj span {\n  font-weight: normal;\n}", "",{"version":3,"sources":["webpack://./ui/components/project_form/InstructionField.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,mBAAA;AADJ","sourcesContent":[".container {\n  h3 {\n    font-size: 1.2em;\n    line-height: 1.5em;\n  }\n\n  span {\n    font-weight: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ui-components-project_form-InstructionField__container--h9uBj"
};
export default ___CSS_LOADER_EXPORT___;
