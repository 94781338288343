import Address from "../address";
import Base from "./base";

export default class Testing extends Base {
  suggestionURL(input) {
    input = encodeURIComponent(input);
    return `/api/zoning/geocoder/suggestions?project_id=${this.projectID}&query=${input}`;
  }

  jsonToSuggestions(json) {
    return json;
  }

  async suggestionToAddress(s) {
    return new Address(s.latitude, s.longitude, s.text);
  }
}
