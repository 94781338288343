import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./ButtonField.scss";
import { immediateInput } from "./behaviors";

const Button = ({ code, label, selectedValue, onChange }) => (
  <button
    key={label}
    type="button"
    className={classnames({ [styles.active]: code === selectedValue })}
    onClick={onChange}
    value={code}
  >
    {label}
  </button>
);

Button.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  code: PropTypes.string,
  selectedValue: PropTypes.string,
};

const ButtonField = ({ value, field, onChange, error, required }) => {
  const buttons = field.enum.map((e) => (
    <Button key={e.code} code={e.code} label={e.label} selectedValue={value} onChange={onChange} />
  ));

  return (
    <div className={styles.container} aria-invalid={!!error} aria-required={!!required}>
      {buttons}
    </div>
  );
};
export default immediateInput(ButtonField);
