import React from "react";

import Loader from "components/Loader";
import { useIsInAdminSection } from "contexts/applicationSection";
import useParam from "hooks/useParam";
import { useChangeset } from "queries/admin/changesets";
import { getProjectAdminURL } from "selectors/routes";

import AnswerChanges from "./AnswerChanges";
import styles from "./Changeset.scss";
import ChangesetModal from "./ChangesetModal";
import RequirementChanges from "./RequirementChanges";

const Changeset = () => {
  const adminView = useIsInAdminSection();
  const changesetID = useParam("changesetID");
  const { data: changeset } = useChangeset(changesetID);

  if (!changeset) return <Loader />;

  const returnTo = adminView && getProjectAdminURL({ id: changeset.project_id });

  return (
    <ChangesetModal header="Project edit details" returnTo={returnTo}>
      <h2 className={styles.editedBy}>Project edited by {changeset.user_full_name}</h2>
      <AnswerChanges changeset={changeset} />
      <RequirementChanges
        changeset={changeset}
        requirementsHeading="The above changes had the following effects on the project:"
      />
    </ChangesetModal>
  );
};

export default Changeset;
