import React from "react";
import * as R from "ramda";
import { connect, useSelector, useStore } from "react-redux";

import Markdown from "components/Markdown";
import {
  getAccelaACAName,
  getFullName,
  getMunicipalityType,
  getName,
  selectTenant,
} from "reducers/tenant";
import { selectFormattedText } from "selectors/translations";
import { isBlank } from "utils/func";

const Text = ({ t, markdown = false, className, ...interpolationValues }) => {
  let str = useText(t, interpolationValues);
  if (markdown) str = <Markdown source={str} />;

  return (
    <span data-i18n-key={t} className={className}>
      {str}
    </span>
  );
};
export default Text;

const selectFormattedTextUsingDefaults = (state, key, values) => {
  const tenant = selectTenant(state);
  const valuesWithDefaults = R.merge(
    R.applySpec({
      tenantName: getName,
      municipalityType: R.compose(R.unless(isBlank, R.toLower), getMunicipalityType),
      tenantFullName: getFullName,
      aca: getAccelaACAName,
    })(tenant),
    values,
  );

  return selectFormattedText(state, key, valuesWithDefaults);
};

export const withGetText = connect(
  (state) => ({ state }),
  null,
  ({ state }, _dispatchProps, ownProps) => ({
    getText: (key, values = {}) => selectFormattedTextUsingDefaults(state, key, values),
    ...ownProps,
  }),
);

export const useGetText = () => {
  const store = useStore();
  const state = store.getState();
  return (key, values = {}) => selectFormattedTextUsingDefaults(state, key, values);
};

export const useText = (key, values = {}) =>
  useSelector((state) => selectFormattedTextUsingDefaults(state, key, values));
