import React from "react";

import ProjectMessages from "components/messaging/ProjectMessages";
import { useSession } from "contexts/session";

const MessagesPage = (props) => {
  const { isLoggedIn } = useSession();

  if (!isLoggedIn) return null;

  return (
    <div>
      <ProjectMessages {...props} />
    </div>
  );
};

export default MessagesPage;
