import React from "react";
import * as R from "ramda";

import Text from "containers/Text";

import sharedStyles from "./sharedStyles.scss";

export const ApplicationACASubmissionStarted = ({ Layout, event, timestamp }) => {
  const requirementName = R.path(["payload", "data", "requirement_name"], event);

  return (
    <Layout icon="arrow-right-to-file">
      <Text
        t="projects.log_entries.requirement_application_aca_submission_started"
        requirementName={requirementName}
      />
      {timestamp}
    </Layout>
  );
};

export const ApplicationACASubmissionCompleted = ({ Layout, event, timestamp }) => {
  const { requirement_name, accela_record_id } = R.path(["payload", "data"], event);

  return (
    <Layout
      icon="circle-check"
      additionalContent={
        accela_record_id ? (
          <Text
            t="projects.log_entries.requirement_application_aca_submission_completed.record_id"
            recordID={accela_record_id}
          />
        ) : null
      }
    >
      <Text
        t="projects.log_entries.requirement_application_aca_submission_completed.summary"
        requirementName={requirement_name}
      />
      {timestamp}
    </Layout>
  );
};

export const ApplicationACASubmissionError = ({ Layout, event, timestamp, applicantContext }) => {
  const { requirement_name, error } = R.path(["payload", "data"], event);

  return (
    <Layout
      icon="triangle-exclamation"
      containerStyles={[sharedStyles.error]}
      additionalContent={error && !applicantContext && `Error from ACA: ${error}`}
    >
      <Text
        t="projects.log_entries.requirement_application_aca_submission_error"
        requirementName={requirement_name}
      />
      {timestamp}
    </Layout>
  );
};
