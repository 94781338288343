/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import { Button, TextButton } from "components/Button";
import Icon from "components/Icon";
import { dataAttrsForField } from "components/project_form/Field";
import Label from "components/project_form/Label";
import Text from "containers/Text";
import { mapIndexed, setAtIndex } from "utils/func";
import { errorPropType, fieldPropType } from "utils/sharedPropTypes";

import styles from "./ListField.scss";
import { fieldForInputType } from "./fieldLookup";

class ListField extends React.Component {
  onItemChange = (index) => (itemValue) => {
    const { onChange, value = [] } = this.props;
    onChange(setAtIndex(index, itemValue, value));
  };

  onRemoveItem = (index) => () => {
    const { onChange, onSave, value = [] } = this.props;
    onChange(R.remove(index, 1, value));
    onSave();
  };

  onAddAnother = () => {
    const { onChange, value = [] } = this.props;
    const items = R.isEmpty(value) ? [undefined] : value;
    onChange(R.append(undefined, items));
  };

  itemUIType() {
    return R.path(["field", "ui", "form_input", "item_ui", "type"], this.props);
  }

  items() {
    const { field, value = [], error, ...remainingProps } = this.props;
    const Field = fieldForInputType(this.itemUIType());
    const listItemErrors = error instanceof Array ? error : [];
    const dataAttrs = dataAttrsForField(field, true);

    return mapIndexed(
      (itemValue, index) => (
        <li
          className={styles.itemContainer}
          key={index}
          data-field-list-item={index}
          {...dataAttrs}
        >
          <Label field={field} />
          <div className={styles.itemContents}>
            <Field
              {...remainingProps}
              error={R.nth(index, listItemErrors || [])}
              value={itemValue}
              field={field}
              onChange={this.onItemChange(index)}
            />
            <div className={styles.actions}>
              <TextButton onClick={this.onRemoveItem(index)}>
                <Icon icon="trash-alt" />
                <Text t="remove" />
              </TextButton>
            </div>
          </div>
        </li>
      ),
      R.isEmpty(value) ? [undefined] : value,
    );
  }

  render() {
    return (
      <div className={styles.container}>
        <ul className={styles.list} data-field-list data-item-ui-type={this.itemUIType()}>
          {this.items()}
        </ul>
        <div className={styles.actions}>
          <Button onClick={this.onAddAnother} data-list-field-add>
            <Icon icon="plus" />
            <Text t="forms.add_another" />
          </Button>
        </div>
      </div>
    );
  }
}
ListField.propTypes = {
  field: fieldPropType.isRequired,
  value: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  error: errorPropType,
};

export const DisabledListField = ({ field, value = [], answerContext }) => {
  const Field = fieldForInputType(field.ui.form_input.item_ui.type, { behaviorType: "disabled" });
  return (
    <ul className={styles.list} data-field-list>
      {mapIndexed(
        (v, index) => (
          <li className={styles.itemContainer} key={index} data-field-list-item>
            <Label field={field} behaviorType="disabled" />
            <Field value={v} field={field} answerContext={answerContext} />
          </li>
        ),
        value,
      )}
    </ul>
  );
};
DisabledListField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.any),
  field: PropTypes.shape({
    ui: PropTypes.shape({
      form_input: PropTypes.shape({
        item_ui: PropTypes.shape({
          type: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    children: PropTypes.arrayOf(fieldPropType),
  }),
};
export const disabled = DisabledListField;

export default ListField;
