import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";

import { useSession } from "contexts/session";
import { useParams } from "hooks/useParam";
import { useProjectInvoice } from "queries/invoices";
import { getName as getFeeName, selectFeesWithTotalsForRequirementID } from "reducers/fees";
import { selectProjectByID } from "reducers/projects";
import { getName as getRequirementName, selectRequirementBySlug } from "reducers/requirements";
import { getCityBaseAPCInvoiceURL, selectTenant } from "reducers/tenant";
import navigateHard from "utils/navigateHard";

import PaymentLayout from "./PaymentLayout";
import useOnNext from "./useOnNext";

const useOnCityBasePayment = () => {
  const { projectID, requirementSlug, invoiceID } = useParams();
  const tenant = useSelector(selectTenant);
  const apcInvoiceURL = getCityBaseAPCInvoiceURL(tenant);
  const requirement = useSelector((state) => selectRequirementBySlug(state, requirementSlug));
  const requirementName = getRequirementName(requirement);
  const project = useSelector((state) => selectProjectByID(state, projectID));
  const { user: applicant } = useSession();

  const { data: invoice } = useProjectInvoice(projectID, invoiceID);
  const invoiceFeeTotals = R.reduce(
    (totals, lineItem) => R.assoc(lineItem.fee_id, lineItem.amount, totals),
    {},
    invoice?.line_items || [],
  );

  const feesWithCosts = useSelector((state) =>
    selectFeesWithTotalsForRequirementID(state, invoiceFeeTotals, requirement.id),
  );

  const lineItems = R.map((f) => {
    const feeName = getFeeName(f);
    return {
      amount: f.cost,
      description: requirementName,
      sub_description: feeName,
      custom_attributes: [
        {
          key: "requirement_name",
          value: requirementName,
        },
        {
          key: "fee_name",
          value: feeName,
        },
      ],
    };
  }, feesWithCosts);

  const { origin } = document.location;
  const payload = {
    account_id: applicant.id.toString(),
    return_url: {
      url: `${origin}/projects/${project.id}/requirements/${requirementSlug}/process/${invoiceID}`,
      label: "Return to Permits Portal",
    },
    cancel_url: {
      url: window.location.href,
      label: "Cancel",
    },
    custom_attributes: [{ key: "opencounter_id", value: invoiceID }],
    allowed_payment_methods: ["CARD", "BANK"],
    line_items: lineItems,
  };

  return async () => {
    const { url: redirectURL } = await fetch(apcInvoiceURL, {
      method: "POST",
      credentials: "omit",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    navigateHard(redirectURL);
  };
};

const CityBasePayment = ({ paymentType, setPaymentType }) => {
  const onPay = useOnCityBasePayment();
  const { onNext, isLoading } = useOnNext({
    paymentType,
    afterAction: onPay,
    shouldNavigate: false,
  });

  return (
    <PaymentLayout
      paymentType={paymentType}
      setPaymentType={setPaymentType}
      onNext={onNext}
      isLoading={isLoading}
    />
  );
};

export default CityBasePayment;
