import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Icon from "components/Icon";
import { errorPropType } from "utils/sharedPropTypes";

import styles from "./TextField.scss";
import UnansweredField from "./UnansweredField";
import { debouncedTextInput, textInput } from "./behaviors";

export const TextFieldComponent = ({
  value,
  onChange,
  onBlur,
  onFocus,
  field,
  error,
  password,
  type,
  autoComplete,
  name,
  isPlaceholder,
  isSearchInput,
  inputRef,
  labelId,
  required,
  maxlength,
  disabled,
}) => {
  const containerClasses = classnames({
    [styles.container]: !isSearchInput,
    [styles.searchInputContainer]: isSearchInput,
  });
  const inputClasses = classnames({
    [styles.placeholder]: isPlaceholder,
    [styles.error]: error,
  });

  const optionalOpts = {};
  if (maxlength) {
    optionalOpts.maxlength = maxlength;
  }

  const inputRefProps = inputRef ? { ref: inputRef } : {};
  type = type || "text";
  if (password) type = "password";

  return (
    <div className={containerClasses}>
      <input
        className={inputClasses}
        value={value || ""}
        placeholder={field.placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        type={type}
        autoComplete={autoComplete}
        name={name}
        aria-labelledby={labelId}
        aria-invalid={!!error}
        aria-required={!!required}
        disabled={disabled}
        {...optionalOpts}
        {...inputRefProps}
      />
      {error ? <Icon icon="exclamation" /> : null}
    </div>
  );
};

TextFieldComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  field: PropTypes.shape({
    placeholder: PropTypes.string,
  }).isRequired,
  password: PropTypes.bool,
  error: PropTypes.oneOfType([errorPropType, PropTypes.bool]),
  name: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  isPlaceholder: PropTypes.bool,
  isSearchInput: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  labelId: PropTypes.string,
  required: PropTypes.bool,
  maxlength: PropTypes.number,
  disabled: PropTypes.bool,
};

export const DisabledTextField = ({ value }) => (
  <div className={styles.disabled}>{value || <UnansweredField />}</div>
);
DisabledTextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
};
export const disabled = DisabledTextField;

export const IssuedTextField = ({ value }) => (
  <div className={styles.issued}>{value || <UnansweredField />}</div>
);
IssuedTextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export const issued = IssuedTextField;

export const instant = debouncedTextInput(500)(TextFieldComponent);
export default textInput(TextFieldComponent);
