import React from "react";
import PropTypes from "prop-types";

import { childrenPropType } from "utils/sharedPropTypes";

const getPosition = ({ touches }) => {
  const { pageX, pageY } = touches[0];
  return { x: pageX, y: pageY };
};

class Swipeable extends React.Component {
  constructor() {
    super();
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.state = {
      delta: null,
      initialTouch: null,
    };
  }

  handleTouchStart(event) {
    this.setState({ initialTouch: getPosition(event), delta: 0 });
  }

  /* touchEnd doesn't use the event, but let's note the parameter */
  /* eslint-disable no-unused-vars */
  handleTouchEnd(event) {
    const { delta } = this.state;
    const { swipeThreshold, direction } = this.props;

    if (Math.abs(delta) > swipeThreshold) {
      if (direction === "horizontal") {
        const { onSwipeLeft, onSwipeRight } = this.props;
        const handler = delta < 0 ? onSwipeLeft : onSwipeRight;
        handler(delta);
      } else {
        const { onSwipeUp, onSwipeDown } = this.props;
        const handler = delta < 0 ? onSwipeUp : onSwipeDown;
        handler(delta);
      }
    }

    this.setState({
      initialTouch: { x: 0, y: 0 },
      delta: 0,
    });
  }
  /* eslint-enable no-unused-vars */

  handleTouchMove(event) {
    event.preventDefault();
    const delta = this.getDelta(event);
    const { moveThreshold, direction } = this.props;

    this.setState({ delta });

    if (Math.abs(delta) > moveThreshold) {
      if (direction === "horizontal") {
        const { onMoveLeft, onMoveRight } = this.props;
        const handler = delta < 0 ? onMoveLeft : onMoveRight;
        handler(delta);
      } else {
        const { onMoveUp, onMoveDown } = this.props;
        const handler = delta < 0 ? onMoveUp : onMoveDown;
        handler(delta);
      }
    }

    return false;
  }

  getDelta(event) {
    const { direction } = this.props;
    const { initialTouch: { x, y } = {} } = this.state;
    const position = getPosition(event);
    if (direction === "horizontal") {
      return position.x - x;
    }
    return position.y - y;
  }

  render() {
    const { component, tagName, children } = this.props;
    const Component = component || tagName;
    return (
      <Component
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        onTouchMove={this.handleTouchMove}
        onTouchCancel={this.handleTouchEnd}
      >
        {children}
      </Component>
    );
  }
}

Swipeable.propTypes = {
  direction: PropTypes.string,
  tagName: PropTypes.string,
  component: PropTypes.element,
  swipeThreshold: PropTypes.number,
  moveThreshold: PropTypes.number,
  onSwipeLeft: PropTypes.func,
  onSwipeUp: PropTypes.func,
  onSwipeRight: PropTypes.func,
  onSwipeDown: PropTypes.func,
  onMoveLeft: PropTypes.func,
  onMoveUp: PropTypes.func,
  onMoveRight: PropTypes.func,
  onMoveDown: PropTypes.func,
  children: childrenPropType.isRequired,
};

Swipeable.defaultProps = {
  tagName: "div",
  swipeThreshold: 10,
  moveThreshold: 5,
  onSwipeUp() {},
  onSwipeDown() {},
  onSwipeLeft() {},
  onSwipeRight() {},
  onMoveUp() {},
  onMoveDown() {},
  onMoveLeft() {},
  onMoveRight() {},
};

export default Swipeable;
