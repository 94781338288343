import React from "react";
import * as R from "ramda";

import { isBlank } from "utils/func";

import MaybeApplicationLink from "./MaybeApplicationLink";
import EventLogPropTypes from "./propTypes";

const getIconVerbName = (before, after, addedNames) => {
  const nAfter = R.length(after);
  let icon = "";
  let verb = "";
  let name = "";
  if (isBlank(after)) {
    icon = "user-times";
    verb = "unassigned";
    name = null;
  } else {
    if (isBlank(before)) {
      verb = "assigned";
      icon = nAfter > 1 ? "users" : "user-plus";
    } else {
      verb = "reassigned";
      icon = "exchange";
    }
    if (nAfter > 1) {
      name = `${nAfter} staff members`;
    } else {
      name = R.nth(0, addedNames);
    }
  }

  return { icon, verb, name };
};

const RequirementApplicationAssigned = ({
  Layout,
  event,
  timestamp,
  applicantContext,
  applicationView,
}) => {
  const before = R.path(["payload", "data", "before"], event);
  const after = R.path(["payload", "data", "after"], event);
  const addedNames = R.path(["payload", "data", "added_names"], event);
  const { icon, verb, name } = getIconVerbName(before, after, addedNames);
  const verbUpper = verb[0].toUpperCase() + verb.slice(1);

  return (
    <Layout icon={icon}>
      {applicantContext || applicationView ? (
        <>
          {verbUpper} {applicantContext ? "" : name && ` to ${name}`}
        </>
      ) : (
        <span data-assignment-text>
          <MaybeApplicationLink event={event} /> application {verb}
          {applicantContext ? "" : name && ` to ${name}`}
        </span>
      )}
      {timestamp}
    </Layout>
  );
};
RequirementApplicationAssigned.propTypes = EventLogPropTypes;

export default RequirementApplicationAssigned;
