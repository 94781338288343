import { parse } from "query-string";
import * as R from "ramda";

import { isBlank } from "utils/func";
import param from "utils/objectToQueryString";

export const basename = (path) => path.substring(0, path.lastIndexOf("/"));
export const dirname = (path) => path.split("/").pop();

export const currentURL = (location) => location.toString().replace(location.search, "");

export const urlFrom = (str) => (str ? new URL(str, window.location) : new URL(window.location));

export const isConfigURL = (url) => !!urlFrom(url).pathname.match(/^\/config\/?/);
export const isAdminURL = (url) => !!urlFrom(url).pathname.match(/^\/admin\/?/);

export const isAdminPage = (location) =>
  isAdminURL(location) || location.search === "?redirect=%2Fadmin";

export const isAbsolute = (url) => /^([a-z]+:\/\/|\/\/)/i.test(url);
export const isMailTo = (url) => /^mailto:/i.test(url);

export const getParam = (loc, param) => R.prop(param, parse(loc.search));

export const paramPath = (param) => R.map(R.replace(/\]$/, ""), param.split("["));

export const toSlug = (str) =>
  str &&
  str
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/-+/g, "-")
    .trim();

export const toDisplayURL = (url) => url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0];

export const baseURLforSubdomain = (subdomain) => {
  const { hostname, port } = window.location;

  const newHostname = hostname.replace(/^.*?(?=\.)/, subdomain);

  return R.reject(isBlank, [newHostname, port]).join(":");
};

export const buildURL = (location) => {
  if (typeof location === "string") {
    return new URL(location, window.location);
  }
  const { pathname, search, hash } = location;
  const url = new URL(window.location);
  if (pathname) url.pathname = pathname;
  if (search) url.search = search;
  if (hash) url.hash = hash;
  return url;
};

export const getPathFromURL = (url) => url.href.replace(url.origin, "");

export const addFilterParam = (basePath, filter) =>
  filter ? `${basePath}?${param(filter)}` : basePath;
