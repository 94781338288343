import resourceOperations, { ReadOptions } from "../resourceOperations";
import * as R from "ramda";
import { Version } from "types/configEntities";

const operation = resourceOperations({
  basePath: "/api/config/versions",
  baseKey: ["config", "versions"],
  dataKey: "version",
  versioned: true, // FIXME(rtlong): this should not be versioned, but currently setting this to false results in changed behavior in the Version diff
});

// TODO: should have a way to not only invalidate via pusher, but force other config users to update when HEAD changes
export const useVersion = (id: number, opts: ReadOptions = {}) =>
  operation.useRead<Version>(id, opts);

export const useVersions = (opts: ReadOptions = {}) =>
  operation.useRead<Version[]>(
    null,
    opts,
    R.pipe(R.values as any, R.reverse as any)
  );

export const useRecordHistory = ({ id, type }, opts: ReadOptions = {}) => {
  const queryParams = new URLSearchParams({ id, type }).toString();
  return operation.useRead(`history?${queryParams}`, opts);
};

export const useUpdateVersion = (id: number, opts = {}) =>
  operation.useUpdate<Version>(id, opts);

export const useCommitVersion = (id: number, opts = {}) =>
  operation.useUpdate<Version>([String(id), "commit"], opts);
