import React, { useState } from "react";

import { hoc } from "utils/hoc";

// withState attempts to recreate the behavior from recompose's withState HOC, using modern APIs (hooks)
const withState = (getterName, setterName, initialValue) => {
  const WithState = ({ Component, ...props }) => {
    if (typeof initialValue === "function") initialValue = initialValue(props);
    const [get, set] = useState(initialValue);
    const withStateProps = {
      [getterName]: get,
      [setterName]: set,
    };
    return <Component {...props} {...withStateProps} />;
  };
  return hoc(WithState);
};

export default withState;
