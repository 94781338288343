import React from "react";
import * as R from "ramda";

import ApplicationLogEntry from "components/event_log/ApplicationLogEntry";
import lookupEntry, { hasRenderableEntries } from "components/event_log/lookup";
import { formatShortDateString, groupByDay } from "utils/time";

import styles from "./LogEntries.scss";

const LogEntries = ({ history, applicantContext = false, context }) =>
  R.map(([date, events]) => {
    const sortedEvents = R.pipe(R.sortBy(R.prop("timestamp")), R.reverse)(events);
    if (!hasRenderableEntries(sortedEvents)) return null;

    return (
      <div className={styles.entry} key={date}>
        <h4 className={styles.date}>{formatShortDateString(date)}</h4>
        <div className={styles.seperator} />
        {R.addIndex(R.map)((event) => {
          if (!lookupEntry(event)) return null;
          return (
            <div key={R.prop("id", event)}>
              <ApplicationLogEntry
                event={event}
                context={context}
                applicantContext={applicantContext}
              />
            </div>
          );
        }, sortedEvents)}
      </div>
    );
  }, groupByDay("timestamp", history));

export default LogEntries;
