export default safeDebug;

function safeDebug(...args) {
  // detect if running in a Node.JS environment
  if (typeof window === "undefined") {
    // When we run some of our code via Node.js, the caller cares about the STDOUT and expects it to contain a single, valid JSON object; in Node's console API, console.debug outputs to STDOUT, so use .warn() instead which is an alias of .error() and outputs to STDERR instead.
    console.warn(...args);
  } else {
    console.debug(...args);
  }
}
