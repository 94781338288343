/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* global google */
import React, { useCallback, useRef, useState } from "react";
import classnames from "classnames";
import * as R from "ramda";
import { withScriptjs } from "react-google-maps";
import Autocomplete from "react-google-maps/lib/components/places/Autocomplete";
import { useSelector } from "react-redux";

import { SmallButton } from "components/Button";
import { FlexColumn } from "components/FlexColumn";
import GlobalHeader from "components/Header";
import Icon from "components/Icon";
import { ButtonDiv } from "components/accessibility/Div";
import { ConnectedFooter as Footer } from "components/projects/Footer";
import { useActiveToggle } from "components/utilities/withActiveToggle";
import withProps from "components/utilities/withProps";
import Text, { useGetText } from "containers/Text";
import HelpBar from "containers/guides/HelpBar";
import { useAnswerContext } from "containers/withAnswerContext";
import { getAnswerForField } from "reducers/answerContexts";
import { selectPrimaryFieldByType, selectSystemFieldBySlug } from "reducers/fields";
import { getTitle } from "reducers/pages";
import { getBounds, selectTenant } from "reducers/tenant";
import { selectDeviceType } from "reducers/ui";
import zoningCache from "services/ZoningCache";
import Google from "services/geocoding/geocoders/google";
import { withGoogleMapsUrl } from "services/withGoogleMapsUrl";
import { getFullAddress } from "utils/address";
import { formatAddress, formatMetersAsMiles } from "utils/format";
import { mapIndexed, renameKeys } from "utils/func";
import * as Location from "utils/location";
import {
  addressToPosition,
  directionsResultToRoute,
  fetchDirections,
  positionToAddress,
} from "utils/map";
import * as Route from "utils/route";

import Map from "./EventLocations/Map";
import styles from "./EventLocationsLegacy.scss";
import mobileStyles from "./EventLocationsMobileLegacy.scss";

// TODO: kill selectFieldStateAttribute
//
const useLegacyEventLocation = () => {
  const answerContext = useAnswerContext();
  const { record, onChange, onSave } = answerContext;
  const [hoveredLocationIndex, setHoveredLocationIndex] = useState();
  const [hoveredRouteIndex, setHoveredRouteIndex] = useState();
  const [mapState, setMapState] = useState({});
  const locationsField = useSelector((state) => selectPrimaryFieldByType(state, "event_location"));
  const routesField = useSelector((state) => selectPrimaryFieldByType(state, "event_route"));

  const defaultPositionType = useSelector((state) =>
    selectSystemFieldBySlug(state, "default-event-position-type"),
  );
  const defaultMapMode = getAnswerForField(record, defaultPositionType) || "location";
  const [mapMode, setMapMode] = useState(defaultMapMode);
  const tenant = useSelector(selectTenant);
  const activeField = mapMode === "location" ? locationsField : routesField;

  const [activeRouteAttribute, setActiveRouteAttribute] = useState("start_address");

  const locations = getAnswerForField(record, locationsField) || [];
  const currentLocation = R.last(locations);
  let routes = getAnswerForField(record, routesField) || [];
  let currentRoute = R.last(routes);

  if (Route.isComplete(currentRoute) && !currentRoute.directions) {
    currentRoute = {};
    routes = R.append(currentRoute, routes);
  }

  const locationRef = useRef();
  const routeStartRef = useRef();
  const routeEndRef = useRef();

  const zoomToAddress = (address) => {
    const latLng = addressToPosition(address);
    return setMapState(latLng, 15);
  };

  // TODO: memoize
  const geocoder = new Google(tenant, activeField);

  const [outsideCityBoundaryLocation, setOutsideCityBoundaryLocation] = useState();

  const setLocationAddress = async (value) => {
    const address = renameKeys({ text: "full_address" }, value);
    const parcelIDs = await zoningCache.parcelIDsForPoint(
      tenant.id,
      address.latitude,
      address.longitude,
    );
    const loc = { parcels: parcelIDs, ...Location.addressToLocation(address) };
    value = R.append(loc, R.dropLast(1, locations));
    onChange(locationsField, value);
    onSave(locationsField);
    zoomToAddress(value);
  };

  const updateEventLocation = async ({ latLng }) => {
    let address = positionToAddress(latLng);
    const isInCityBoundary = await zoningCache.isInBoundary(
      tenant.id,
      address.latitude,
      address.longitude,
    );
    setOutsideCityBoundaryLocation(isInCityBoundary ? null : address);

    if (!isInCityBoundary) return;

    if (mapMode === "location") {
      address = await geocoder.reverseGeocode(address.latitude, address.longitude);
      await setLocationAddress(address);
    } else {
      let newRoute = R.assoc(activeRouteAttribute, address, R.last(routes));

      if (!(Route.getStartAddress(newRoute) && Route.getEndAddress(newRoute))) {
        address = await geocoder.reverseGeocode(address.latitude, address.longitude);

        address.full_address = address.text; // TODO: cleanup
        newRoute = R.assoc(activeRouteAttribute, address, R.last(routes));
      } else {
        newRoute = await fetchDirections(newRoute);
      }
      const value = R.append(newRoute, R.dropLast(1, routes));
      onChange(activeField, value);
      if (Route.isComplete(newRoute)) {
        onSave(activeField);
      }
      setActiveRouteAttribute((prevAttr) =>
        prevAttr === "start_address" ? "end_address" : "start_address",
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const setRouteStartOrEnd = (attr) => (value) => {
    const route = R.assoc(attr, value, currentRoute);

    if (!Route.isComplete(route)) {
      onChange(routesField, R.append(route, R.dropLast(1, routes)));
      return zoomToAddress(value);
    }

    fetchDirections(route).then((route) => {
      onChange(routesField, R.append(route, R.dropLast(1, routes)));
      onSave(routesField);
    });
  };

  const setRouteStartAddress = setRouteStartOrEnd("start_address");
  const setRouteEndAddress = setRouteStartOrEnd("end_address");

  const addAnotherLocation = () => {
    onChange(locationsField, R.flatten([locations, {}]));
    // locationRef.current.value = "";
  };

  const addAnotherRoute = () => {
    onChange(routesField, R.flatten([routes, {}]));
    routeStartRef.current.value = "";
    routeEndRef.current.value = "";
  };

  const onRouteChange = () => {
    onChange(
      activeField,
      R.append(directionsResultToRoute(this.directions), R.dropLast(1, routes)),
    );
    onSave(activeField);
  };

  const removeFrom = (field, list) =>
    R.map(
      (v) => () => {
        const value = R.pipe(R.reject(R.equals(v)), R.reject(R.isEmpty))(list);
        onChange(field, value);
        onSave(field);
      },
      list,
    );

  const removeLocations = removeFrom(locationsField, locations);
  const removeRoutes = removeFrom(routesField, routes);

  const completeLocations = R.filter(Location.isComplete, locations);
  const completeRoutes = R.filter(Route.isComplete, routes);

  const validate = () => {
    if (R.isEmpty(completeLocations) && R.isEmpty(completeRoutes))
      return { textKey: "guides.event_locations.location_required_error" };
    return null;
  };
  return {
    setLocationAddress,
    setRouteStartAddress,
    setRouteEndAddress,
    addAnotherLocation,
    addAnotherRoute,
    onRouteChange,
    removeRoutes,
    removeLocations,
    updateEventLocation,
    locationsField,
    routesField,
    activeField,
    locations,
    routes,
    currentLocation,
    currentRoute,
    setHoveredLocationIndex,
    hoveredLocationIndex,
    setHoveredRouteIndex,
    hoveredRouteIndex,
    setActiveRouteAttribute,
    outsideCityBoundaryLocation,
    setOutsideCityBoundaryLocation,
    mapMode,
    setMapMode,
    locationRef,
    routeStartRef,
    routeEndRef,
    mapState,
    setMapState,
    completeLocations,
    completeRoutes,
    validate,
    zoomToAddress,
  };
};

const EventAutocomplete = R.compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMapsUrl({ libraries: "geometry,drawing,places" }),
  withScriptjs,
)(
  ({
    id,
    setValue,
    value,
    inputRef,
    label,
    setOutsideCityBoundaryLocation,
    onFocus: afterFocus,
  }) => {
    const { zoomToAddress } = useLegacyEventLocation();
    const [inputValue, setInputValue] = useState(value);
    const { active: isFocused, activate, deactivate } = useActiveToggle(true);
    const tenant = useSelector(selectTenant);
    const bounds = getBounds(tenant);

    const autocompleteRef = useRef();
    const onFocus = () => {
      activate();
      if (afterFocus) afterFocus();
    };

    const onChange = ({ target: { value } }) => {
      setInputValue(value);
    };

    const onPlaceChanged = useCallback(async () => {
      const place = autocompleteRef.current.getPlace();
      if (!place.geometry) return;
      const address = positionToAddress(place.geometry.location, place.formatted_address);
      const isInCityBoundary = await zoningCache.isInBoundary(
        tenant.id,
        address.latitude,
        address.longitude,
      );
      setOutsideCityBoundaryLocation(isInCityBoundary ? null : address);
      if (isInCityBoundary) {
        setValue(address);
        return;
      }
      setInputValue("");
      // reset map state to get back to initial zoom / center values
      zoomToAddress({ center: addressToPosition(address), zoom: 14 });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Autocomplete
        onPlaceChanged={onPlaceChanged}
        ref={autocompleteRef}
        options={{
          bounds: new google.maps.LatLngBounds(
            new google.maps.LatLng(bounds.min_lat, bounds.min_lon),
            new google.maps.LatLng(bounds.max_lat, bounds.max_lon),
          ),
          strictBounds: true,
        }}
      >
        <input
          data-autocomplete
          id={id}
          type="text"
          ref={inputRef}
          placeholder={label}
          value={isFocused ? inputValue : value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={deactivate}
          className={styles.autocompleteInput}
          data-id={id}
        />
      </Autocomplete>
    );
  },
);

const InputArea = ({
  locationsField,
  routesField,
  setLocationAddress,
  setRouteStartAddress,
  setRouteEndAddress,
  currentLocation,
  currentRoute,
  mapMode,
  routeStartFocused,
  routeEndFocused,
  active,
  locationRef,
  routeStartRef,
  routeEndRef,
  setOutsideCityBoundaryLocation,
}) => {
  const getText = useGetText();

  if (mapMode === "location")
    return (
      <div className={mobileStyles.inputContainer}>
        <EventAutocomplete
          field={locationsField}
          id="location"
          setValue={setLocationAddress}
          inputRef={locationRef}
          value={getFullAddress(Location.getAddress(currentLocation))}
          label={getText("guides.event_locations.location_input_directions")}
          setOutsideCityBoundaryLocation={setOutsideCityBoundaryLocation}
        />
      </div>
    );

  // TODO: use getters
  return (
    <div
      className={classnames(mobileStyles.inputContainer, {
        [mobileStyles.fullScreen]: active,
      })}
    >
      <div>
        <EventAutocomplete
          field={routesField}
          id="route-start"
          setValue={setRouteStartAddress}
          value={R.path(["start_address", "full_address"], currentRoute)}
          inputRef={routeStartRef}
          label={getText("guides.event_locations.route_start_input_directions")}
          onFocus={routeStartFocused}
          setOutsideCityBoundaryLocation={setOutsideCityBoundaryLocation}
        />
      </div>

      <EventAutocomplete
        field={routesField}
        id="route-end"
        setValue={setRouteEndAddress}
        value={R.path(["end_address", "full_address"], currentRoute)}
        inputRef={routeEndRef}
        label={getText("guides.event_locations.route_end_input_directions")}
        onFocus={routeEndFocused}
        setOutsideCityBoundaryLocation={setOutsideCityBoundaryLocation}
      />
    </div>
  );
};

export const SavedRoute = ({ r, removeRoute, i, setHoveredRouteIndex }) => (
  <div
    className={styles.savedPlaceContainer}
    onMouseOver={() => setHoveredRouteIndex(i)}
    onMouseOut={() => {
      setHoveredRouteIndex(null);
    }}
  >
    <div className={styles.savedPlaceIcon}>
      <Icon size="lg" icon="route" />
    </div>
    {formatMetersAsMiles(Route.getDistance(r))} <Text t="guides.event_locations.mile_route" />
    {removeRoute && (
      <ButtonDiv
        className={styles.savedPlaceRemove}
        onClick={removeRoute}
        {...{ [`data-remove-route-${i}`]: true }}
      >
        <Icon icon="trash-alt" />
      </ButtonDiv>
    )}
  </div>
);

// TODO: use disabled field rendering if possible
export const SavedLocation = ({ l, removeLocation, i, setHoveredLocationIndex }) => (
  // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
  <div
    className={styles.savedPlaceContainer}
    data-saved-location
    onMouseOver={() => {
      setHoveredLocationIndex(i);
    }}
    onMouseOut={() => {
      setHoveredLocationIndex(null);
    }}
  >
    <div className={styles.savedPlaceIcon}>
      <Icon size="lg" icon="map-marker-alt" />
    </div>
    {formatAddress(getFullAddress(Location.getAddress(l)))}
    {removeLocation && (
      <ButtonDiv className={styles.savedPlaceRemove} onClick={removeLocation}>
        <Icon icon="trash-alt" />
      </ButtonDiv>
    )}
  </div>
);

export const SavedLocations = ({
  locations,
  removeLocations,
  addAnotherLocation,
  setHoveredLocationIndex,
}) => (
  <div>
    {Location.isComplete(R.last(locations)) && (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid
      <a className={styles.addAnotherLocation} data-add-another onClick={addAnotherLocation}>
        <Text t="guides.event_locations.add_another_location" />
      </a>
    )}

    {locations.length > 1 && (
      <div className={styles.placesContainer}>
        <div className={styles.savedPlacesDescription}>
          <Text t="guides.event_locations.your_places" />
        </div>
        {R.pipe(
          R.dropLast(1),
          mapIndexed((loc, i) => (
            <SavedLocation
              key={`location-${i}`}
              l={loc}
              i={i}
              removeLocation={removeLocations[i]}
              setHoveredLocationIndex={setHoveredLocationIndex}
            />
          )),
        )(locations)}
      </div>
    )}
  </div>
);

export const SavedRoutes = ({ routes, removeRoutes, addAnotherRoute, setHoveredRouteIndex }) => (
  <div>
    {Route.isComplete(R.last(routes)) && (
      <a className={styles.addAnotherLocation} data-add-another onClick={addAnotherRoute}>
        <Text t="guides.event_locations.add_another_route" />
      </a>
    )}

    {routes.length > 1 && (
      <div className={styles.placesContainer}>
        <div className={styles.savedPlacesDescription}>
          <Text t="guides.event_locations.your_routes" />
        </div>
        {R.pipe(
          R.dropLast(1),
          mapIndexed((route, i) => (
            <SavedRoute
              key={`route-${i}`}
              i={i}
              r={route}
              removeRoute={removeRoutes[i]}
              setHoveredRouteIndex={setHoveredRouteIndex}
            />
          )),
          R.reverse(),
        )(routes)}
      </div>
    )}
  </div>
);

export const Tabs = ({ mapMode, setMapMode, routes, locations, inline }) => {
  const onChangeMapModeToLocation = () => setMapMode("location");
  const onChangeMapModeToRoute = () => setMapMode("route");

  return (
    <div
      className={classnames({
        [styles.inline]: inline,
      })}
    >
      <div className={styles.mapModeTabs}>
        <div
          data-location-tab
          className={classnames(styles.tab, {
            [styles.selected]: mapMode === "location",
          })}
          onClick={onChangeMapModeToLocation}
        >
          <Text t="guides.event_locations.select_a_location" />{" "}
          {!R.isEmpty(locations) && (
            <div className={styles.placeCount} data-location-count>
              {locations.length}
            </div>
          )}
        </div>
        <div
          data-route-tab
          className={classnames(styles.tab, {
            [styles.selected]: mapMode === "route",
          })}
          onClick={onChangeMapModeToRoute}
        >
          <Text t="guides.event_locations.select_a_route" />
          {!R.isEmpty(routes) && (
            <div className={styles.placeCount} data-route-count>
              {routes.length}
            </div>
          )}
        </div>
      </div>
      <div className={styles.inputDirections}>
        {mapMode === "location" ? (
          <Text t="guides.event_locations.location_directions" />
        ) : (
          <Text t="guides.event_locations.route_directions" />
        )}{" "}
      </div>
    </div>
  );
};

export const PageHeader = ({ page }) => (
  <h1 className={styles.header} data-guide-title>
    {getTitle(page)}
  </h1>
);

export const Sidebar = ({
  locationsField,
  routesField,
  setMapMode,
  mapMode,
  setHoveredLocationIndex,
  setHoveredRouteIndex,
  locations,
  currentLocation,
  routes,
  currentRoute,
  setActiveRouteAttribute,
  locationRef,
  routeStartRef,
  routeEndRef,
  setLocationAddress,
  setRouteStartAddress,
  setRouteEndAddress,
  addAnotherLocation,
  addAnotherRoute,
  removeLocations,
  removeRoutes,
  page,
  completeLocations,
  completeRoutes,
  setOutsideCityBoundaryLocation,
}) => {
  const getText = useGetText();
  const routeStartFocused = () => setActiveRouteAttribute("start_address");
  const routeEndFocused = () => setActiveRouteAttribute("end_address");

  return (
    <div className={styles.sidebarContainer}>
      <PageHeader page={page} />
      <Tabs
        mapMode={mapMode}
        setMapMode={setMapMode}
        routes={completeRoutes}
        locations={completeLocations}
      />
      <div className={styles.inputContainer}>
        {mapMode === "location" ? (
          <div
            data-field-key={locationsField.key}
            data-field-name={locationsField.field_label}
            data-field-type={locationsField.field_type}
            data-field-ui-type={locationsField.ui.form_input.type}
          >
            <EventAutocomplete
              field={locationsField}
              id="location"
              setValue={setLocationAddress}
              value={getFullAddress(Location.getAddress(currentLocation))}
              inputRef={locationRef}
              label={getText("guides.event_locations.location_input_directions")}
              setOutsideCityBoundaryLocation={setOutsideCityBoundaryLocation}
            />

            <SavedLocations
              locations={locations}
              removeLocations={removeLocations}
              setHoveredLocationIndex={setHoveredLocationIndex}
              addAnotherLocation={addAnotherLocation}
            />
          </div>
        ) : (
          <div>
            <div
              data-field-key={routesField.key}
              data-field-name={routesField.field_label}
              data-field-type={routesField.field_type}
              data-field-ui-type={routesField.ui.form_input.type}
            >
              <EventAutocomplete
                field={routesField}
                id="route-start"
                setValue={setRouteStartAddress}
                value={R.path(["start_address", "full_address"], currentRoute)}
                label={getText("guides.event_locations.route_start_input_directions")}
                inputRef={routeStartRef}
                onFocus={routeStartFocused}
                setOutsideCityBoundaryLocation={setOutsideCityBoundaryLocation}
              />

              <EventAutocomplete
                field={routesField}
                id="route-end"
                setValue={setRouteEndAddress}
                value={R.path(["end_address", "full_address"], currentRoute)}
                label={getText("guides.event_locations.route_end_input_directions")}
                inputRef={routeEndRef}
                onFocus={routeEndFocused}
                setOutsideCityBoundaryLocation={setOutsideCityBoundaryLocation}
              />
            </div>

            <SavedRoutes
              routes={routes}
              removeRoutes={removeRoutes}
              addAnotherRoute={addAnotherRoute}
              setHoveredRouteIndex={setHoveredRouteIndex}
            />
          </div>
        )}
      </div>
      <div className={styles.helpContainer}>
        <HelpBar />
      </div>
    </div>
  );
};

const InlineMapContainer = ({
  setLocationAddress,
  setRouteStartAddress,
  setRouteEndAddress,
  onRouteChange,
  locationsField,
  routesField,
  locations,
  routes,
  currentLocation,
  currentRoute,
  hoveredLocationIndex,
  hoveredRouteIndex,
  outsideCityBoundaryLocation,
  mapMode,
  locationRef,
  routeStartRef,
  routeEndRef,
  mapState,
  setMapState,
  updateEventLocation,
  routeStartFocused,
  routeEndFocused,
  removeLocations,
  setHoveredLocationIndex,
  addAnotherLocation,
  removeRoutes,
  addAnotherRoute,
  setHoveredRouteIndex,
  setMapMode,
  completeRoutes,
  completeLocations,
  setOutsideCityBoundaryLocation,
}) => {
  const getText = useGetText();
  const { active, activate, deactivate } = useActiveToggle(false);

  return (
    <>
      <Tabs
        mapMode={mapMode}
        setMapMode={setMapMode}
        routes={completeRoutes}
        locations={completeLocations}
      />
      <div
        className={classnames(mobileStyles.mapContainer, {
          [mobileStyles.fullScreen]: active,
        })}
      >
        <Map
          mapMode={mapMode}
          disableInteraction={!active}
          hoveredLocationIndex={hoveredLocationIndex}
          hoveredRouteIndex={hoveredRouteIndex}
          locations={locations}
          currentLocation={currentLocation}
          routes={routes}
          currentRoute={currentRoute}
          outsideCityBoundaryLocation={outsideCityBoundaryLocation}
          mapState={mapState}
          setMapState={setMapState}
          onMapClick={!active ? activate : updateEventLocation}
          onRouteChange={onRouteChange}
        />
        <InputArea
          locationsField={locationsField}
          routesField={routesField}
          setLocationAddress={setLocationAddress}
          setRouteStartAddress={setRouteStartAddress}
          setRouteEndAddress={setRouteEndAddress}
          currentLocation={currentLocation}
          currentRoute={currentRoute}
          mapMode={mapMode}
          routeStartFocused={routeStartFocused}
          routeEndFocused={routeEndFocused}
          active={active}
          locationRef={locationRef}
          routeStartRef={routeStartRef}
          routeEndRef={routeEndRef}
          setOutsideCityBoundaryLocation={setOutsideCityBoundaryLocation}
        />
        {active && (
          <div className={mobileStyles.fullScreenFooter}>
            <SmallButton label="Close" onClick={deactivate} />
            <SmallButton
              label={getText(
                mapMode === "location"
                  ? "guides.event_locations.mobile_add_location"
                  : "guides.event_locations.mobile_add_route",
              )}
              disabled={
                mapMode === "location"
                  ? !Location.isComplete(currentLocation)
                  : !Route.isComplete(currentRoute)
              }
              onClick={deactivate}
            />
          </div>
        )}
      </div>
      {mapMode === "location" ? (
        <SavedLocations
          locations={locations}
          removeLocations={removeLocations}
          setHoveredLocationIndex={setHoveredLocationIndex}
          addAnotherLocation={addAnotherLocation}
        />
      ) : (
        <SavedRoutes
          routes={routes}
          removeRoutes={removeRoutes}
          addAnotherRoute={addAnotherRoute}
          setHoveredRouteIndex={setHoveredRouteIndex}
        />
      )}
    </>
  );
};

const EventLocationsMobile = ({ page }) => {
  const {
    setLocationAddress,
    setRouteStartAddress,
    setRouteEndAddress,
    addAnotherLocation,
    addAnotherRoute,
    onRouteChange,
    removeRoutes,
    removeLocations,
    locationsField,
    routesField,
    locations,
    routes,
    currentLocation,
    currentRoute,
    setHoveredLocationIndex,
    hoveredLocationIndex,
    setHoveredRouteIndex,
    hoveredRouteIndex,
    setActiveRouteAttribute,
    outsideCityBoundaryLocation,
    setOutsideCityBoundaryLocation,
    mapMode,
    setMapMode,
    locationRef,
    routeStartRef,
    routeEndRef,
    mapState,
    setMapState,
    updateEventLocation,
    completeLocations,
    completeRoutes,
    validate,
  } = useLegacyEventLocation();

  const routeStartFocused = () => setActiveRouteAttribute("start_address");
  const routeEndFocused = () => setActiveRouteAttribute("end_address");

  return (
    <FlexColumn fullHeight>
      <GlobalHeader />
      <div className={mobileStyles.container}>
        <PageHeader page={page} />
        <InlineMapContainer
          setLocationAddress={setLocationAddress}
          setRouteStartAddress={setRouteStartAddress}
          setRouteEndAddress={setRouteEndAddress}
          onRouteChange={onRouteChange}
          locationsField={locationsField}
          routesField={routesField}
          locations={locations}
          routes={routes}
          currentLocation={currentLocation}
          currentRoute={currentRoute}
          hoveredLocationIndex={hoveredLocationIndex}
          hoveredRouteIndex={hoveredRouteIndex}
          outsideCityBoundaryLocation={outsideCityBoundaryLocation}
          setOutsideCityBoundaryLocation={setOutsideCityBoundaryLocation}
          mapMode={mapMode}
          setMapMode={setMapMode}
          locationRef={locationRef}
          routeStartRef={routeStartRef}
          routeEndRef={routeEndRef}
          mapState={mapState}
          setMapState={setMapState}
          updateEventLocation={updateEventLocation}
          routeStartFocused={routeStartFocused}
          routeEndFocused={routeEndFocused}
          removeLocations={removeLocations}
          setHoveredLocationIndex={setHoveredLocationIndex}
          addAnotherLocation={addAnotherLocation}
          removeRoutes={removeRoutes}
          addAnotherRoute={addAnotherRoute}
          setHoveredRouteIndex={setHoveredRouteIndex}
          completeRoutes={completeRoutes}
          completeLocations={completeLocations}
        />
        <div className={mobileStyles.helpContainer}>
          <HelpBar />
        </div>
      </div>
      <Footer validate={validate} />
    </FlexColumn>
  );
};

export const EventLocationsInline = () => {
  const {
    setLocationAddress,
    setRouteStartAddress,
    setRouteEndAddress,
    addAnotherLocation,
    addAnotherRoute,
    onRouteChange,
    removeRoutes,
    removeLocations,
    locationsField,
    routesField,
    locations,
    routes,
    currentLocation,
    currentRoute,
    setHoveredLocationIndex,
    hoveredLocationIndex,
    setHoveredRouteIndex,
    hoveredRouteIndex,
    outsideCityBoundaryLocation,
    setOutsideCityBoundaryLocation,
    mapMode,
    setMapMode,
    locationRef,
    routeStartRef,
    routeEndRef,
    mapState,
    setMapState,
    updateEventLocation,
    completeLocations,
    completeRoutes,
    routeStartFocused,
    routeEndFocused,
  } = useLegacyEventLocation();

  return (
    <div className={styles.inlineContainer}>
      <InlineMapContainer
        setLocationAddress={setLocationAddress}
        setRouteStartAddress={setRouteStartAddress}
        setRouteEndAddress={setRouteEndAddress}
        onRouteChange={onRouteChange}
        locationsField={locationsField}
        routesField={routesField}
        locations={locations}
        routes={routes}
        currentLocation={currentLocation}
        currentRoute={currentRoute}
        hoveredLocationIndex={hoveredLocationIndex}
        hoveredRouteIndex={hoveredRouteIndex}
        outsideCityBoundaryLocation={outsideCityBoundaryLocation}
        setOutsideCityBoundaryLocation={setOutsideCityBoundaryLocation}
        mapMode={mapMode}
        setMapMode={setMapMode}
        locationRef={locationRef}
        routeStartRef={routeStartRef}
        routeEndRef={routeEndRef}
        mapState={mapState}
        setMapState={setMapState}
        updateEventLocation={updateEventLocation}
        routeStartFocused={routeStartFocused}
        routeEndFocused={routeEndFocused}
        removeLocations={removeLocations}
        setHoveredLocationIndex={setHoveredLocationIndex}
        addAnotherLocation={addAnotherLocation}
        removeRoutes={removeRoutes}
        addAnotherRoute={addAnotherRoute}
        setHoveredRouteIndex={setHoveredRouteIndex}
        completeRoutes={completeRoutes}
        completeLocations={completeLocations}
      />
    </div>
  );
};

const EventLocationsPage = ({ page }) => {
  const {
    setLocationAddress,
    setRouteStartAddress,
    setRouteEndAddress,
    addAnotherLocation,
    addAnotherRoute,
    onRouteChange,
    removeRoutes,
    removeLocations,
    locationsField,
    routesField,
    locations,
    routes,
    currentLocation,
    currentRoute,
    setHoveredLocationIndex,
    hoveredLocationIndex,
    setHoveredRouteIndex,
    hoveredRouteIndex,
    setActiveRouteAttribute,
    outsideCityBoundaryLocation,
    setOutsideCityBoundaryLocation,
    mapMode,
    setMapMode,
    locationRef,
    routeStartRef,
    routeEndRef,
    mapState,
    setMapState,
    updateEventLocation,
    completeLocations,
    completeRoutes,
    validate,
  } = useLegacyEventLocation();

  return (
    <FlexColumn exactHeightContent>
      <GlobalHeader />
      <div className={styles.container}>
        <Sidebar
          setHoveredRouteIndex={setHoveredRouteIndex}
          setHoveredLocationIndex={setHoveredLocationIndex}
          mapMode={mapMode}
          setMapMode={setMapMode}
          locationsField={locationsField}
          routesField={routesField}
          locations={locations}
          currentLocation={currentLocation}
          routes={routes}
          currentRoute={currentRoute}
          setActiveRouteAttribute={setActiveRouteAttribute}
          locationRef={locationRef}
          routeStartRef={routeStartRef}
          routeEndRef={routeEndRef}
          setLocationAddress={setLocationAddress}
          setRouteStartAddress={setRouteStartAddress}
          setRouteEndAddress={setRouteEndAddress}
          addAnotherLocation={addAnotherLocation}
          addAnotherRoute={addAnotherRoute}
          removeRoutes={removeRoutes}
          removeLocations={removeLocations}
          page={page}
          completeLocations={completeLocations}
          completeRoutes={completeRoutes}
          setOutsideCityBoundaryLocation={setOutsideCityBoundaryLocation}
        />
        <Map
          mapMode={mapMode}
          hoveredLocationIndex={hoveredLocationIndex}
          hoveredRouteIndex={hoveredRouteIndex}
          locations={locations}
          currentLocation={currentLocation}
          routes={routes}
          currentRoute={currentRoute}
          outsideCityBoundaryLocation={outsideCityBoundaryLocation}
          mapState={mapState}
          setMapState={setMapState}
          onMapClick={updateEventLocation}
          onRouteChange={onRouteChange}
        />
      </div>
      <Footer validate={validate} />
    </FlexColumn>
  );
};

const EventLocations = ({ page }) => {
  const deviceType = useSelector(selectDeviceType);

  if (deviceType === "mobile") {
    return <EventLocationsMobile page={page} />;
  }
  return <EventLocationsPage page={page} />;
};

export default EventLocations;
