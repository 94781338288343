import * as R from "ramda";

import * as actions from "actions";
import { selectFieldsIndexedByID } from "reducers/fields";
import { EMPTY_ARRAY, isBlank, renameKeys } from "utils/func";

// TODO: merge back into projects_reducer
export const reducer = (state = {}, action) => {
  const pl = action.payload;
  const maybeRecord = R.path(["answerContext", "record"], pl);

  switch (action.type) {
    case actions.UPDATE_CONTEXT:
      return R.assoc(pl.id, R.merge(R.propOr({}, pl.id, state), pl), state);
    case actions.UPDATE_VALIDATION_ERROR:
      return R.assocPath([maybeRecord.id, "errors"], pl.errors || {})(state);
    case actions.CLEAR_VALIDATION_ERROR:
      return R.dissocPath([maybeRecord.id, "errors", pl.field.id], state);
    case actions.CLEAR_ANSWER:
      return setAnswer(state, maybeRecord.id, pl.field, null);
    case actions.SET_ANSWER:
      return setAnswer(state, maybeRecord.id, pl.field, pl.value);
    case actions.REMOVE_INACTIVE_ANSWERS: {
      const activeAnswers = R.pick(
        pl.activeFieldKeys,
        R.pathOr({}, [maybeRecord.id, "answers"], state),
      );
      const withoutInactiveAnswers = R.assoc("answers", activeAnswers, state[maybeRecord.id]);
      return R.assoc(
        maybeRecord.id,
        { ...state[maybeRecord.id], ...withoutInactiveAnswers },
        state,
      );
    }
    default:
      return state;
  }
};

export const setAnswer = (state, contextID, field, value) => {
  const updatedState = R.assocPath([contextID, "answers", field.key], value, state);
  updatedState[contextID].errors = R.dissoc(field.id, state[contextID].errors);

  return updatedState;
};

export const getAnswerForField = (record, field) =>
  R.path(["answers", R.path(["key"], field)], record);

export const getAnswersForFields = (record, fields) =>
  R.pickAll(R.map(R.prop("key"), fields), R.propOr({}, "answers", record));

export const getAnswersForFieldsIndexByID = (record, fields) =>
  renameKeys(
    R.reduce((memo, field) => R.assoc(field.key, field.id, memo), {}, fields),
    getAnswersForFields(record, fields),
  );

export const getErrorForField = (record, field) => R.path(["errors", field.id], record);

export const getErrors = R.propOr({}, "errors");

export const getContextType = (context) => R.toLower(R.prop("type", context));

export const getGuidePages = R.path(["user_pages", "guide"]);

const selectFieldRequirements = (state, context) => {
  const fieldsByID = selectFieldsIndexedByID(state);
  const allPages = getGuidePages(context);
  const pageSlugs = R.propOr([], "order")(allPages);

  return R.reduce(
    (memo, slug) => {
      const fieldIDs = R.path(["pages", slug], allPages);
      const fields = R.pipe(R.map(String), R.props(R.__, fieldsByID), R.reject(isBlank))(fieldIDs);

      return R.assoc(slug, fields, memo);
    },
    {},
    pageSlugs,
  );
};

const pageIdentifier = (...p) => ["page", ...p].join(":");

export const getAllPageIdentifiers = (context) => {
  const pages = R.propOr({}, "user_pages")(context);
  return R.reduce(
    (memo, page) => [
      ...memo,
      pageIdentifier(page),
      ...R.map((p) => pageIdentifier(page, p), R.propOr([], "order")(pages[page])),
    ],
    [],
    R.keys(pages),
  );
};

const getFieldIdsFromEntity = (entity, dataKey) =>
  R.chain((key) => R.path([dataKey, key])(entity))(entity.order);

export const getActiveFieldIDs = (context) => {
  const activeFieldIDs = R.reduce((fieldIDs, entity) => {
    switch (entity.type) {
      case "FieldList": {
        return R.concat(fieldIDs, entity.list);
      }
      case "PageList": {
        return R.concat(fieldIDs, getFieldIdsFromEntity(entity, "pages"));
      }
      case "SectionList": {
        return R.concat(fieldIDs, getFieldIdsFromEntity(entity, "sections"));
      }
      default: {
        return fieldIDs;
      }
    }
  }, [])(R.values(context.user_pages));

  return R.uniq(activeFieldIDs);
};

export const selectCurrentFieldsForProjectSlug = (state, project, pageSlug) => {
  if (!pageSlug) return EMPTY_ARRAY;
  return selectFieldRequirements(state, project)[pageSlug] || EMPTY_ARRAY;
};

export const selectCurrentActiveFieldsForProjectSlug = (state, project, pageSlug) => {
  const fields = selectCurrentFieldsForProjectSlug(state, project, pageSlug);
  return R.reject(R.prop("calculated"), fields);
};

export const getCanonicalID = R.prop("canonical_id");
export const getParentID = R.prop("parent_id");
