import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const tryScrollToElement = (locationHash) => {
  const hash = locationHash.replace("#", "");
  if (hash) {
    const element = document.getElementById(hash);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      return element;
    }
  }
  return false;
};

export const waitForHashToScroll = (locationHash) => {
  const interval = setInterval(() => {
    const found = tryScrollToElement(locationHash);
    if (found) {
      clearInterval(interval);
    }
  }, 100);
  return interval;
};

const useScrollToHash = () => {
  const { hash: locationHash } = useLocation();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (!locationHash) return undefined;

    if (!scrolled) {
      const interval = waitForHashToScroll(locationHash, () => setScrolled(true));

      return () => clearInterval(interval);
    }
    return undefined;
  }, [locationHash, scrolled]);
};

export default useScrollToHash;
