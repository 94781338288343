import React from "react";
import * as R from "ramda";

import MaybeApplicationLink from "./MaybeApplicationLink";
import sharedStyles from "./sharedStyles.scss";

// where should this stuff go?
const reviewResultsVerbs = {
  reject: "rejection",
  approve: "approval",
};
export default function RequirementApplicationReviewUndone({
  Layout,
  event,
  timestamp,
  applicantContext,
  applicationView,
}) {
  const by = R.path(["payload", "data", "by"], event);
  const data = event?.payload?.data;
  const reviewName = data?.review_name;
  const reviewResult = data?.review_result;
  const additionalContent = applicationView ? null : (
    <div className={sharedStyles.noteContent}>{reviewName}</div>
  );

  return (
    <Layout icon="rotate-left" additionalContent={additionalContent}>
      {applicationView ? (
        <>
          {reviewName} {reviewResultsVerbs[reviewResult]} undone
          {applicantContext ? " by staff" : by && ` by ${by}`}
        </>
      ) : (
        <>
          <MaybeApplicationLink event={event} /> application {reviewName}{" "}
          {reviewResultsVerbs[reviewResult]} undone
          {applicantContext ? " by staff" : by && ` by ${by}`}
        </>
      )}
      {timestamp}
    </Layout>
  );
}
