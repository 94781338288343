import React from "react";

import { useAnswerContext } from "containers/withAnswerContext";
import { useProjectDocuments } from "selectors/documents";

import { Documents, MessageAttachments, UploadRequirements } from "../Documents";

const RequirementsByDocuments = () => {
  const { record: project } = useAnswerContext();
  const { attachments, documents, uploadRequirements } = useProjectDocuments(project);

  return (
    <>
      <UploadRequirements uploadRequirements={uploadRequirements} />
      <Documents documents={documents} uploadRequirements={uploadRequirements} />
      <MessageAttachments attachments={attachments} />
    </>
  );
};

export default RequirementsByDocuments;
