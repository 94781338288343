import React from "react";
import PropTypes from "prop-types";
import { Provider as ReduxProvider } from "react-redux";

import App from "components/App";
import ErrorBoundary from "components/ErrorBoundary";
import { NetworkErrorBoundary } from "components/NetworkErrorBoundary";
import Router from "components/Router";
import SessionRelatedNetworkErrorHandler from "components/SessionRelatedNetworkErrorHandler";
import { AuthnStateProvider } from "contexts/auth";
import CurrentTenantProvider from "contexts/currentTenant";
import { FeatureFlagProvider } from "contexts/featureFlags";
import QueryProvider from "contexts/queryClient";
import { ServerVariablesProvider } from "contexts/serverVariables";
import { SessionProvider } from "contexts/session";
import VersionedClientProvider from "contexts/versionedClient";
import { wrap as reportErrors } from "services/errorReporter";
import { PusherProvider } from "services/pusher";

ReduxProvider.displayName = "ReduxProvider";

const Root = ({ store, pusherConfig, featureFlags, configVariables }) => (
  <ErrorBoundary type="SWW">
    <NetworkErrorBoundary>
      <ServerVariablesProvider value={configVariables}>
        <FeatureFlagProvider value={featureFlags}>
          <AuthnStateProvider>
            <ReduxProvider store={store}>
              {/* TODO: can we move ReduxProvider to child of SessionProvider? */}
              <QueryProvider>
                <SessionProvider>
                  <PusherProvider config={pusherConfig}>
                    <CurrentTenantProvider>
                      <VersionedClientProvider>
                        <Router>
                          <SessionRelatedNetworkErrorHandler />
                          <App />
                        </Router>
                      </VersionedClientProvider>
                    </CurrentTenantProvider>
                  </PusherProvider>
                </SessionProvider>
              </QueryProvider>
            </ReduxProvider>
          </AuthnStateProvider>
        </FeatureFlagProvider>
      </ServerVariablesProvider>
    </NetworkErrorBoundary>
  </ErrorBoundary>
);
Root.propTypes = {
  store: PropTypes.object,
};

export default reportErrors(Root);
