import React, { lazy, useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import NotFound from "components/NotFound";
import Changeset from "components/changesets/Changeset";
import RequirementPayment from "components/payments/PaymentPage";
import ApplicationDetails from "components/projects/ApplicationDetails";
import Attachments from "components/projects/Attachments";
import Handoff from "components/projects/Handoff";
import RequirementFormFillingPage from "components/projects/RequirementFormFillingPage";
import ResumeProjectRedirect from "components/projects/ResumeProjectRedirect";
import ProjectForm from "components/projects/pages/ProjectForm";
import ZoningCheckPage from "components/projects/pages/ZoningCheck";
import ZoningVerificationPage from "components/projects/pages/ZoningVerification";
import SubmitApplication from "components/projects/requirement/SubmitApplication";
import {
  ProjectIsScopeable,
  RequireRealUser,
  RequirementApplicationIsInProgress,
  RequirementApplicationIsPayable,
  RequirementIsIssued,
} from "components/utilities/guards";
import ProcessPayment from "containers/projects/requirement/Process";
import RequirementSummary from "containers/projects/requirement/Summary";

import DoScopeProject from "./DoScopeProject";
import HotKeys from "./HotKeys";

const IssuedRequirementApplication = lazy(() =>
  import(/* webpackPrefetch: true */ "containers/IssuedRequirementApplication"),
);
const Guides = lazy(() => import(/* webpackPrefetch: true */ "components/guides"));
const PrintableRequirementSummary = lazy(() =>
  import("components/printables/PrintableRequirementSummary"),
);
const PrintableProjectSummary = lazy(() => import("components/printables/PrintableProjectSummary"));

const ProjectsRoutes = () => {
  const match = useRouteMatch();
  // [jb] doesn't seem like this is doing anything
  const [saveProgressFlowWasDismissed] = useState(false);

  return (
    <>
      <HotKeys />
      <Switch>
        <Route
          exact
          path={`${match.path}/applications/:requirementApplicationID/issued_requirement`}
          render={(props) => (
            <RequirementIsIssued>
              <IssuedRequirementApplication {...props} />
            </RequirementIsIssued>
          )}
        />

        <Route
          exact
          path={`${match.path}/applications/:requirementApplicationID/submitted_requirement`}
          component={PrintableRequirementSummary}
        />

        <Route path={`${match.path}/guide/:guidePage*`}>
          <ProjectIsScopeable>
            <Guides />
          </ProjectIsScopeable>
        </Route>

        <Route path={`${match.path}/attachments`}>
          <RequireRealUser>
            <Attachments />
          </RequireRealUser>
        </Route>

        <Route exact path={`${match.path}/zoning-verification`}>
          <ZoningVerificationPage />
        </Route>
        <Route exact path={`${match.path}/zoning-check`}>
          <ZoningCheckPage />
        </Route>
        <Route exact path={`${match.path}/form`}>
          <ProjectForm />
        </Route>

        <Route exact path={`${match.path}/summary`}>
          <Redirect to={`${match.url}/apply/summary`} />
        </Route>

        <Route
          exact
          path={`${match.path}/requirements/:requirementSlug/summary`}
          render={(props) => (
            <RequireRealUser>
              <RequirementSummary {...props} />
            </RequireRealUser>
          )}
        />

        <Route exact path={`${match.path}/requirements/:requirementSlug/payment/:invoiceID?`}>
          <RequirementApplicationIsPayable>
            <RequirementPayment />
          </RequirementApplicationIsPayable>
        </Route>

        <Route
          exact
          path={`${match.path}/requirements/:requirementSlug/process/:id?`}
          render={(props) => (
            <RequireRealUser>
              <ProcessPayment {...props} />
            </RequireRealUser>
          )}
        />

        <Route
          exact
          path={`${match.path}/:requirementOrDirect(requirements|direct)/:requirementSlug/submit`}
        >
          <RequireRealUser>
            <DoScopeProject>
              <SubmitApplication />
            </DoScopeProject>
          </RequireRealUser>
        </Route>

        <Route
          exact
          path={`${match.path}/:requirementOrDirect(requirements|direct)/:requirementSlug`}
        >
          <RequirementApplicationIsInProgress>
            <RequirementFormFillingPage />
          </RequirementApplicationIsInProgress>
        </Route>

        <Route exact path={`${match.path}/application/:requirementID`}>
          <ApplicationDetails />
        </Route>

        <Route path={`${match.path}/apply`}>
          <DoScopeProject>
            <Handoff saveProgressFlowWasDismissed={saveProgressFlowWasDismissed} />
          </DoScopeProject>
        </Route>
        <Route exact path={`${match.path}/printable_summary`}>
          <PrintableProjectSummary />
        </Route>

        <Route exact path={`${match.path}/changesets/:changesetID`}>
          <RequireRealUser>
            <Changeset />
          </RequireRealUser>
        </Route>

        <Route exact path={`${match.path}`}>
          <ResumeProjectRedirect />
        </Route>

        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default ProjectsRoutes;
