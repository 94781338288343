import axios from "axios";
import * as R from "ramda";

import { reportError } from "services/errorReporter";
import Address from "services/geocoding/address";
import Base from "services/geocoding/geocoders/base";
import ReverseGeocodingAddressNotFoundError from "services/geocoding/geocoders/reverseGeocodingAddressNotFoundError";
import toGetParams from "utils/objectToQueryString";

export default class ESRI extends Base {
  suggestionURL(text) {
    const params = {
      text,
      f: "pjson",
      maxSuggestions: 5,
      location: this.center.longitude.toFixed(4) + "," + this.center.latitude.toFixed(4),
    };

    if (this.field.constrain_address_to_city) {
      const b = this.bounds;
      params.searchExtent = JSON.stringify({
        xmin: b.min_lon.toFixed(4),
        ymin: b.min_lat.toFixed(4),
        xmax: b.max_lon.toFixed(4),
        ymax: b.max_lat.toFixed(4),
        spatialReference: {
          wkid: 4326,
        },
      });
    }

    return `${this.options.url}/suggest?${toGetParams(params)}`;
  }

  jsonToSuggestions(json) {
    return json.suggestions;
  }

  async suggestionToAddress(suggestion) {
    // don't include location since it hurts results (even though docs recomend it)
    const params = {
      singleLine: suggestion.text,
      magicKey: suggestion.magicKey,
      maxLocations: 1,
      sourceCountry: "USA",
      locationType: "rooftop",
      f: "pjson",
      outSR: 4326,
    };

    try {
      const { data: json } = await axios.get(
        `${this.options.url}/findAddressCandidates?${toGetParams(params)}`,
      );
      const a = R.path(["candidates", 0], json);

      // eslint-disable-next-line no-alert
      if (!a) return alert(`Address ${suggestion.text} not found`);

      const { x, y } = a.location;
      return new Address(y, x, a.address);
    } catch (e) {
      reportError(e);
      throw e;
    }
  }

  reverseGeocodeURL(lat, lon) {
    const params = {
      location: JSON.stringify({
        x: lon,
        y: lat,
        spatialReference: { wkid: 4326 },
      }),
      f: "pjson",
    };

    return `${this.options.url}/reverseGeocode?${toGetParams(params)}`;
  }

  jsonToAddress(json, lat, lng) {
    const a = json.address;
    if (!a)
      throw new ReverseGeocodingAddressNotFoundError(
        `No address found while reverse geocoding ${(lat, lng)}.`,
      );
    return new Address(a.latitude, a.longitude, a.Match_addr);
  }
}
