import React from "react";
import { Link } from "react-router-dom";

import Icon from "components/Icon";
import Text from "containers/Text";
import { useRenewal } from "queries/renewals";
import { useRequirementApplication } from "queries/requirementApplications";
import routes from "utils/routes";

import styles from "./RenewalOutOfDate.scss";

export default function RenewalOutOfDate({
  projectID,
  requirementApplicationID: raID,
  forAdmin = false,
}) {
  const { data: renewal, isLoading: isRenewalLoading } = useRenewal(projectID, raID);
  const { data: requirementApplication, isLoading: isRALoading } = useRequirementApplication(
    projectID,
    raID,
  );

  if (isRenewalLoading || isRALoading || !renewal.isOutOfDate) return null;
  const { canonicalProjectID, canonicalID } = renewal;

  const url = forAdmin
    ? routes.admin.requirementApplication(canonicalProjectID, canonicalID)
    : `/projects/${canonicalProjectID}/application/${requirementApplication.requirement_id}`;

  return (
    <div className={styles.container}>
      <Icon icon="circle-info" />
      <div className={styles.title}>
        <Text t="projects.application.renewals.out_of_date.title" />
      </div>
      <div>
        <Text t="projects.application.renewals.out_of_date.message" />
      </div>
      <Link to={url} className="btn-sm">
        <Text t="projects.application.renewals.out_of_date.action" />
      </Link>
    </div>
  );
}
