import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { changeVersion, createDirectApplicationProject, createProject } from "actions";
import Footer from "components/home/Footer";
import Guides from "components/home/Guides";
import Disclaimers from "containers/home/Disclaimers";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { selectFixedGuide, selectVisibleGuides } from "reducers/guides";
import { selectTenantVersionIsCurrent } from "reducers/tenant";
import { getParam } from "utils/urls";

import styles from "./Home.scss";

const Home = ({ fixedGuide }) => {
  const dispatch = useDispatch();
  const tenant = useCurrentTenantInfo();
  const getStarted = useCallback(
    (guide) => {
      const {
        id: guideID,
        intake_requirement_id: intakeRequirementID,
        is_intake: isIntake,
      } = guide;

      // Intake Guides: https://github.com/opencounter/opencounter/issues/4667
      if (intakeRequirementID && isIntake) {
        return dispatch(
          createDirectApplicationProject({ guideID, requirementID: intakeRequirementID }),
        );
      }

      return dispatch(createProject({ guideID }));
    },
    [dispatch],
  );

  const {
    name: tenantName,
    state_abbreviation: stateAbbreviation,
    home_page_title,
    site_title,
    home_page_message,
    bounds,
    address,
    phone,
    zipcode,
    website_url: websiteURL,
    municipality_type: municipalityType,
    disclaimer,
  } = tenant;

  const { guides, title, HTMLTitle, message } = useSelector((state) => {
    const fixed = selectFixedGuide(state) || fixedGuide;
    if (fixed) {
      return {
        guides: [fixed],
        title: fixed.splash_title || home_page_title,
        HTMLTitle: fixed.html_title || site_title,
        message: fixed.message || home_page_message,
      };
    }
    return {
      guides: selectVisibleGuides(state),
      title: home_page_title,
      HTMLTitle: site_title,
      message: home_page_message,
    };
  });

  const guidesProps = {
    tenantName,
    message,
    title,
    stateAbbreviation,
    bounds,
    guides,
    address,
    phone,
    zipcode,
    websiteURL,
    municipalityType,
    disclaimer,
    getStarted,
  };

  const isCurrentVersion = useSelector(selectTenantVersionIsCurrent);
  const location = useLocation();
  const versionParamFromURL = getParam(location, "version");
  useEffect(() => {
    // if not current version, set to current unlesss url param version exists
    if (isCurrentVersion) return;
    if (versionParamFromURL) return;
    dispatch(changeVersion({ version_number: "current" }));
  });

  return (
    <div>
      <Helmet>
        <title>{HTMLTitle}</title>
      </Helmet>
      <main role="main">
        <Guides {...guidesProps} />
      </main>
      <div className={styles.footers}>
        <div className={styles.footer}>
          <Footer />
        </div>
        <div className={styles.line} />
        <Disclaimers />
      </div>
    </div>
  );
};

Home.propTypes = {
  HTMLTitle: PropTypes.string,
};

export default Home;
