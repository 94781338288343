export default class NetworkError extends Error {
  constructor(msg, response, props = {}) {
    super(msg);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NetworkError);
    }
    this.name = "NetworkError";
    this.response = response;
    this.props = props;
  }
}
