/* eslint no-constant-condition: ["error", { "checkLoops": false }] */

import * as R from "ramda";

import { SET_PAGES } from "actions";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_PAGES:
      return R.merge(state, action.payload);
    default:
      return state;
  }
};

export const selectPagesForGuide = (state, record, guide) => {
  const isOverviewShown = R.path(["answers", "overview_shown"], record);
  const pages = state.pages[guide.slug];
  if (isOverviewShown) return pages;

  return R.reject(R.propEq("slug", "overview"), pages);
};

export const selectPageBySlug = (state, guide, slug) => {
  if (!guide) return null;
  const pages = state.pages[guide.slug];
  return R.find(R.propEq("slug", slug), pages);
};

export const selectPageBySlugAndGuideSlug = (state, guideSlug, pageSlug) => {
  const pages = state.pages[guideSlug];
  return R.find(R.propEq("slug", pageSlug), pages || []);
};

export const getSlug = R.prop("slug");
export const getTitle = R.prop("title");
