import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Route, Routes } from "react-router-dom-v5-compat";

import { SubmitButton } from "components/Button";
import { ButtonDiv } from "components/accessibility/Div";
import * as Inputs from "components/forms/BareFields";
import {
  ApplicantLabeledInput,
  Form,
  FormActions,
  FormRow,
  HorizontalLabeledInput,
} from "components/forms/Layout";
import InlineModal from "components/shared/InlineModal";
import Text from "containers/Text";
import { useAuthnState, useOnAuthnSuccess } from "contexts/auth";
import { useCreateApplicant } from "queries/auth";
import { errorHandler, swallowErrors } from "utils/form";

import { matches_password } from "./validations";

const SignupForm = ({ onClose, onReset }) => {
  const { email, setEmail } = useAuthnState();
  const onSuccess = useOnAuthnSuccess();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm({
    defaultValues: { email },
    mode: "onTouched",
    progressive: true,
  });

  const { mutateAsync } = useCreateApplicant({
    onMutate({ email }) {
      setEmail(email);
    },
    onSuccess({ after_sign_in_path }) {
      onSuccess(after_sign_in_path);
    },
    onError: errorHandler(setError),
  });

  return (
    <InlineModal
      onClose={onClose}
      heading={<Text t="pages.signup.banner" />}
      subheading={
        <Routes>
          <Route
            path="/projects/*"
            element={<Text t="projects.handoff.save_and_track_your_progress" />}
          />
          <Route path="*" element={null} />
        </Routes>
      }
    >
      <Form
        onSubmit={handleSubmit(swallowErrors(mutateAsync))}
        name="create-applicant"
        data-create-account-form
      >
        <FormRow>
          <ApplicantLabeledInput
            label={<Text t="pages.signup.first_name" />}
            error={errors?.first_name}
          >
            <Inputs.Text register={register("first_name")} type="text" autoComplete="given-name" />
          </ApplicantLabeledInput>
          <ApplicantLabeledInput
            label={<Text t="pages.signup.last_name" />}
            error={errors?.last_name}
          >
            <Inputs.Text register={register("last_name")} type="text" autoComplete="family-name" />
          </ApplicantLabeledInput>
        </FormRow>

        <FormRow>
          <ApplicantLabeledInput label={<Text t="pages.signup.email" />} error={errors?.email}>
            <Inputs.Text
              type="email"
              autoComplete="email"
              placeholder="email@example.com"
              register={register("email", { required: "is required" })}
            />
          </ApplicantLabeledInput>
          <ApplicantLabeledInput
            label={<Text t="pages.signup.phone" />}
            error={errors?.phone_number}
          >
            <Inputs.Text
              register={register("phone_number")}
              type="text"
              autoComplete="tel-national"
            />
          </ApplicantLabeledInput>
        </FormRow>

        <FormRow>
          <ApplicantLabeledInput
            label={<Text t="pages.signup.password" />}
            error={errors?.password}
          >
            <Inputs.Text
              register={register("password", {
                minLength: { value: 8, message: "must be at least 8 characters in length" },
                maxLength: { value: 128, message: "must be no more than 128 characters in length" },
                required: "is required",
              })}
              type="password"
              autoComplete="new-password"
            />
          </ApplicantLabeledInput>
          <ApplicantLabeledInput
            label={<Text t="pages.signup.password_confirmation" />}
            error={errors?.password_confirmation}
          >
            <Inputs.Text
              register={register("password_confirmation", {
                required: "is required",
                validate: { matches_password },
              })}
              type="password"
              autoComplete="new-password"
              data-1p-ignore
            />
          </ApplicantLabeledInput>
        </FormRow>

        <FormRow>
          <HorizontalLabeledInput
            reversed
            label={<Text markdown t="pages.signup.terms_and_conditions" />}
          >
            <Inputs.Checkbox register={register("terms_and_conditions", { required: true })} />
          </HorizontalLabeledInput>
        </FormRow>

        <FormActions>
          <div>
            <SubmitButton data-create-account formControl={control}>
              <Text t="pages.signup.create_account" />
            </SubmitButton>
          </div>

          <div>
            <ButtonDiv onClick={onReset}>
              <Text t="pages.login.reset" />
            </ButtonDiv>
          </div>
        </FormActions>
      </Form>
    </InlineModal>
  );
};
SignupForm.propTypes = {
  onReset: PropTypes.func.isRequired,
};

export default SignupForm;
