import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import Field from "../../containers/Field";
import styles from "./Fields.scss";

const Fields = ({ fields, onChange, page, animateFieldChanges, className, ...props }) => (
  <div className={classNames(className, styles.fieldsContainer)}>
    {R.map(
      (f) => (
        <Field
          field={f}
          key={f.id}
          onChange={onChange}
          page={page}
          animateFieldChanges={animateFieldChanges}
          hideLabel={page && page.hide_labels}
          {...props}
        />
      ),
      fields,
    )}
  </div>
);

Fields.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.object,
  onChange: PropTypes.func,
  animateFieldChanges: PropTypes.bool,
};

export default Fields;
