import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import Icon from "components/Icon";
import Text from "containers/Text";
import { isBlank } from "utils/func";
import { stateHistoryPropType } from "utils/sharedPropTypes";

import styles from "./PizzaTracker.scss";

const segments = {
  notIssuable: ["submitted", "under_review", "processed"],
  issuable: ["submitted", "under_review", "issued"],
  payableOnIssuance: ["submitted", "under_review", "payable", "issued"],
  rejected: ["submitted", "under_review", "rejected"],
  revoked: ["submitted", "under_review", "issued", "revoked"],
};

const getSegments = (history, isIssuable, isPayableOnIssuance) => {
  const wasProcessed = R.any(R.propEq("to_state", "processed"), history);
  const wasIssued = R.any(R.propEq("to_state", "issued"), history);

  if ((!isIssuable && !wasIssued) || (isIssuable && wasProcessed)) {
    return segments.notIssuable;
  }
  if (history[history.length - 1]?.to_state === "rejected") return segments.rejected;
  if (R.any(R.propEq("to_state", "revoked"), history)) return segments.revoked;
  if (isPayableOnIssuance) return segments.payableOnIssuance;
  return segments.issuable;
};

const Segment = ({ segment, stateNames, lightBackground }) => {
  const isCurrent = R.pipe(R.last, R.equals(segment))(stateNames);
  const isPrevious = !isCurrent && R.any(R.equals(segment), stateNames);
  const isFinal = isCurrent && R.contains(segment, ["processed", "issued", "rejected", "approved"]);
  const isRejected = segment === "rejected";
  const isRevoked = segment === "revoked";
  const isSubmitted = segment === "submitted";
  const isUnsubmitted = segment === "in_progress";
  const isIssued = isCurrent && ["issued", "approved"].includes(segment);

  let icon;
  if (isIssued && isFinal) {
    icon = "badge-check";
  } else if (isRejected) {
    icon = "times-hexagon";
  } else if (isRevoked) {
    icon = "exclamation-triangle";
  } else if (isPrevious || isFinal || isSubmitted) {
    icon = "check-circle";
  } else if (isUnsubmitted) {
    icon = "file-alt";
  }

  return (
    <div
      data-segment={segment}
      data-is-previous={isPrevious}
      data-is-current={isCurrent}
      data-is-rejected={isRejected}
      data-is-revoked={isRevoked}
      className={classnames(styles.segment, {
        [styles.previous]: isPrevious,
        [styles.current]: isCurrent,
        [styles.rejected]: isRejected,
        [styles.revoked]: isRevoked,
        [styles.lightBackground]: lightBackground,
      })}
    >
      <span className={styles.content}>
        {icon && <Icon icon={icon} />}
        <span className={styles.title} data-title>
          <Text t={`projects.application.states.${segment}`} />
        </span>
      </span>
    </div>
  );
};
Segment.propTypes = {
  segment: PropTypes.string.isRequired,
  stateNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  lightBackground: PropTypes.bool,
};

const PizzaTracker = ({
  history,
  isIssuable,
  showUnsubmitted,
  lightBackground,
  hideUnderReview,
  isPayableOnIssuance,
  isReviewTypeApproved,
}) => {
  let stateNames = R.pluck("to_state", history);
  let segments = getSegments(history, isIssuable, isPayableOnIssuance);

  if (isBlank(history) || showUnsubmitted) {
    segments = R.prepend("in_progress", segments);
    stateNames = R.prepend("in_progress", stateNames);
  }

  const hasPostReviewPayment = R.pipe(
    R.aperture(2),
    R.find(R.equals(["under_review", "payable"])),
    R.complement(R.isNil),
  )(stateNames);

  if (hasPostReviewPayment && !isPayableOnIssuance) {
    const index = R.indexOf("under_review", stateNames);
    segments = R.insert(index, "payable", segments);
  }

  if (hideUnderReview) {
    segments = R.reject(R.equals("under_review"), segments);
    stateNames = R.reject(R.equals("under_review"), stateNames);
  }

  if (isReviewTypeApproved) {
    const index = R.indexOf("issued", stateNames);
    segments = R.reject(R.equals("issued"), R.insert(index, "approved", segments));
    stateNames = stateNames.includes("issued")
      ? R.reject(R.equals("issued"), R.insert(index, "approved", stateNames))
      : stateNames;
  }

  return (
    <div className={styles.container} data-progress-bar>
      {R.map(
        (segment) => (
          <Segment
            key={segment}
            segment={segment}
            stateNames={stateNames}
            lightBackground={lightBackground}
          />
        ),
        segments,
      )}
    </div>
  );
};
PizzaTracker.propTypes = {
  history: PropTypes.arrayOf(stateHistoryPropType).isRequired,
  isIssuable: PropTypes.bool,
  showUnsubmitted: PropTypes.bool,
  lightBackground: PropTypes.bool,
  hideUnderReview: PropTypes.bool,
  isReviewTypeApproved: PropTypes.bool,
};

export default PizzaTracker;
