// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-components-guides-ZoningDetails-Header__container--mtnab {\n  padding-top: 3rem;\n  text-align: center;\n  margin: 0 auto;\n}", "",{"version":3,"sources":["webpack://./ui/components/guides/ZoningDetails/Header.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,cAAA;AACF","sourcesContent":[".container {\n  padding-top: 3rem;\n  text-align: center;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ui-components-guides-ZoningDetails-Header__container--mtnab"
};
export default ___CSS_LOADER_EXPORT___;
