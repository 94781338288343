import React, { useCallback, useEffect, useState } from "react";
import * as R from "ramda";

import TermsAndConditions from "components/TermsAndConditions";
import { useSession } from "contexts/session";
import { useVersionedClient } from "contexts/versionedClient";
import { useAcceptTermsAndConditions } from "queries/users";
import ocClient, {
  isCSRFError,
  isTermsAndConditionsError,
  isUnauthorizedError,
} from "services/ocClient";

// SessionRelatedNetworkErrorHandler does two things:
//   - show the user Terms and Conditions modal when needed (either driven by the session response or any 489 error code)
//   - directly handles errors in our OC Axios API client, only those which are always safe to handle globally: 401, 488 (CSRF), or 489 (T&C)
// NOTE: For errors we might want to catch closer to the call site, do not handle them here. Axios' response interceptors mechanism used here hooks in prior to the async request returning to calling code, so if an error is handled here in such a way that this component unrenders the calling component, calling code will never get the chance to react to it.
const SessionRelatedNetworkErrorHandler = () => {
  const {
    user: { id: userID },
    mustAcceptTerms,
    query: { invalidate: invalidateSession },
  } = useSession();

  const [showTac, setTacError] = useState(mustAcceptTerms);

  const errorInterceptor = useCallback(
    async (error) => {
      if (isCSRFError(error)) invalidateSession("CSRF error response");
      // nb: invalidateSession in this case likely results in a sign-in modal coming up. TODO: it would be really nice to store this error state, so we can show some message on the signin page that we know you *were* logged in and we need you to reauthenticate
      else if (isUnauthorizedError(error)) invalidateSession("401 error");
      else if (isTermsAndConditionsError(error)) setTacError(true);
      throw error;
    },
    [setTacError, invalidateSession],
  );

  const versionedClient = useVersionedClient();
  useEffect(() => {
    const baseRegistration = ocClient.interceptors.response.use(R.identity, errorInterceptor);

    // set up the same interceptor on the versionedClient, which is a separate Axios instance, derived from the ocClient (but the interceptors are not copied over like the rest of the configuration)
    const versionedRegistration = versionedClient.interceptors.response.use(
      R.identity,
      errorInterceptor,
    );
    return () => {
      ocClient.interceptors.response.eject(baseRegistration);
      versionedClient.interceptors.response.eject(versionedRegistration);
    };
  }, [versionedClient, errorInterceptor]);

  const onAcceptTAC = useAcceptTermsAndConditions(userID, {
    onSuccess: () => {
      setTacError(false);
    },
  });

  if (showTac) return <TermsAndConditions onAccept={onAcceptTAC} />;
  return null;
};
export default SessionRelatedNetworkErrorHandler;
