import { createSelector } from "reselect";

import { selectCenter } from "reducers/tenant";

export const getInitialAddressViewport = (center) => ({
  ...center,
  zoom: 11,
  startDragLngLat: null,
  isDragging: false,
});

export const selectInitialAddressViewport = createSelector(
  [selectCenter],
  getInitialAddressViewport,
);
