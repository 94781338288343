import * as R from "ramda";
import { createSelector } from "reselect";

import * as actions from "../actions";

export const statuses = {
  pending: "pending",
  error: "error",
};

export const types = {
  Mutation: "mutation",
  Fetch: "fetch",
  Request: "fetch", // deprecated name maps to "fetch"
};

const initialState = {
  requests: {},
  project_save_pending: false,
};

const lensRequests = R.lensProp("requests");

export default (state = initialState, action) => {
  const { type: actionType, payload } = action;

  switch (actionType) {
    case actions.SET_PROJECT_SAVE_PENDING:
      return R.assoc("project_save_pending", payload, state);

    case actions.NETWORK_REQUEST_START: {
      const { requestID, entityID, entityType, type } = payload;
      const matchesThisRequest = R.whereEq({ type, entityID, entityType });
      const thisRequest = {
        ...payload,
        status: statuses.pending,
        type: types[type],
        at: Date.now(),
      };
      return R.over(
        lensRequests,
        R.compose(R.assoc(requestID, thisRequest), R.reject(matchesThisRequest)),
      )(state);
    }
    case actions.NETWORK_REQUEST_ERROR: {
      const { requestID, type } = payload;
      return R.over(
        R.lensPath(["requests", requestID]),
        R.mergeLeft({
          ...payload,
          type: types[type],
          status: statuses.error,
        }),
      )(state);
    }
    case actions.CLEAR_TAC_ERRORS: {
      return R.over(
        lensRequests,
        R.reject(R.whereEq({ errorCode: 403, terms_and_conditions_accepted: false })),
      )(state);
    }
    case actions.NETWORK_REQUEST_SUCCESS:
    case actions.NETWORK_REQUEST_CANCELLED: {
      const { requestID } = payload;
      const path = ["requests", requestID];
      return R.dissocPath(path)(state);
    }
    default:
      return state;
  }
};

// request model accessors
export const getErrorCode = R.prop("errorCode");
export const getEntityID = R.prop("entityID");
export const getEntityType = R.prop("entityType");
export const getType = R.prop("type");
export const getRequestID = R.prop("requestID");
export const getURL = R.prop("url");
export const isError = R.propEq("status", statuses.error);
export const isPending = R.propEq("status", statuses.pending);
export const isUnauthorizedError = R.and(isError, R.propEq("errorCode", 401));

const isFetch = R.propEq("type", types.Fetch);

// Redux selectors
const selectAllRequests = R.path(["network", "requests"]);
const selectRequests = createSelector(selectAllRequests, R.filter(isFetch));

const filterIsPending = R.pipe(R.values, R.filter(isPending));
const selectPendingRequests = createSelector(selectRequests, filterIsPending);

const matchingEntity = ({ id, type }) => R.whereEq({ entityID: id, entityType: type });

// only used by containers/admin/projects
export const selectPendingRequestsFor = (state, { id, type }) =>
  R.compose(R.filter(matchingEntity({ id, type })), selectPendingRequests)(state);

// used a lot:
export const selectIsRequestPendingFor = (state, { id, type }) =>
  R.compose(R.any(matchingEntity({ id, type })), selectPendingRequests)(state);

// only used by components/admin/Inquiries
export const selectIsRequestPendingForType = (state, entityType) =>
  R.compose(R.any(R.whereEq({ entityType })), selectPendingRequests)(state);

export const selectIsProjectSavePending = R.pathOr(false, ["network", "project_save_pending"]);

export const selectNetworkErrors = R.compose(R.filter(isError), selectAllRequests);
