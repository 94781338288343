import * as R from "ramda";

import { renameKeys } from "utils/func";

import * as AddressUtil from "./address";

export const getAddress = R.prop("address");

export const addressToLocation = (address) => ({
  address: renameKeys({ text: "full_address" }, address),
});

export const isComplete = (l) => l && AddressUtil.isComplete(getAddress(l));
