import React, { useMemo } from "react";
import * as R from "ramda";

import { hoc } from "utils/hoc";

// withHandlers attempts to recreate the behavior from recompose's HOC, using modern APIs (hooks)
const withHandlers = (handlerCreators) => {
  const WithHandlers = ({ Component, ...props }) => {
    if (typeof handlerCreators === "function") handlerCreators = handlerCreators(props);
    const handlerProps = useMemo(
      () => R.map((creatorFn) => creatorFn(props), handlerCreators),
      [props],
    );
    return <Component {...props} {...handlerProps} />;
  };
  return hoc(WithHandlers);
};

export default withHandlers;
