import React from "react";

import { AddressList } from "./EventGeomsField";
import styles from "./EventGeomsField.scss";

export function DisabledOnlyDisabledAddressField({ value }) {
  if (!value) return null;
  if (!Array.isArray(value)) value = [value];

  return (
    <div className={styles.disabledContainer}>
      <div className={styles.locationsContainer}>
        <AddressList value={value} />
      </div>
    </div>
  );
}
export const disabled = DisabledOnlyDisabledAddressField;

const OnlyDisabledAddressField = () => null;
export default OnlyDisabledAddressField;
